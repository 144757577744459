import { makeAutoObservable } from "mobx";

import { RootStore } from "store/root.store";
import { EventView } from "store/types";
import { CursorHandler, getDefaultCursorData } from "tools/cursorHandler/cursorHandler";
import { JsonSearchType, TableSortField } from "components/ui";
import { CursorData, CursorFilters } from "tools/cursorHandler/cursorHandler.types";
import { getEvents } from "libs/api/methods/events-methods";
import * as eventsMethods from "libs/api/methods/events-methods";

const sortBy = (sortField: TableSortField) => ({
  sortByField: "META.timestamp",
  sortOrder: sortField?.order === 1 ? "asc" : "desc",
});

export class EventsStore {
  jsonSearch: JsonSearchType = {};
  cursorData = getDefaultCursorData<EventView>();

  constructor(readonly rootStore: RootStore) {
    this.rootStore = rootStore;

    this.setJsonSearch = this.setJsonSearch.bind(this);

    makeAutoObservable(this);

    this.cursorData.handler = this.getCursorHandler(this.cursorData);
  }

  getCursorHandler(cursorData: CursorData<EventView>) {
    return new CursorHandler(cursorData, eventsMethods.getEvents, { apiSort: true, sortBy });
  }

  setJsonSearch(jsonSearch: JsonSearchType) {
    if (!jsonSearch) return;

    this.jsonSearch = jsonSearch;
  }

  getEventsAggregatedData(search: CursorFilters) {
    return eventsMethods.getEventsAggregatedData(search);
  }

  async getEventById(accountId: string, eventId: string): Promise<EventView | undefined> {
    const events = await getEvents(
      {
        accountId,
        query: `detail.eventID:"${eventId}"`,
        count: 1,
      },
      true,
    );

    return events?.data?.[0];
  }
}
