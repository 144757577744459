import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Box, Typography } from "@mui/material";

import { formattedYearDate } from "tools";
import { ProviderIcon, Table, IssueBy } from "components/ui";
import { DashboardIssue, DashboardTableIssue, UI_TABLES } from "store/types";
import { RootStore } from "store/root.store";
import {
  DashboardIssueAdditionalInfo,
  DashboardIssueAdditionalInfoHeader,
  ResolvedArrowDarkSvg,
  ResolvedArrowLightSvg,
} from "routes/DashboardRoute/components/DashboardIssues/components";
import { Debounce } from "tools/debounce";
import { getDashboardFields, getDashboardIssueGroupedData } from "routes/DashboardRoute/dashboard.utils";

const debounce = Debounce();

type DashboardIssuesType = {
  store: RootStore;
  data: DashboardIssue[];
  issue: DashboardTableIssue;
  groupedBy?: Array<keyof DashboardIssue>;
};

const DashboardIssues = ({ store, data, groupedBy, issue, ...props }: DashboardIssuesType) => {
  const { ui, dashboard } = store!;

  const openFields = getDashboardFields({ withActions: true });
  const resolvedFields = getDashboardFields({ resolved: true, withActions: true });
  const justifiedFields = getDashboardFields({ justified: true, withActions: true });

  useEffect(() => {
    const active = data.find((i) => i.id === ui.infoItem?.activeItem?.id);

    if (!active) return;

    dashboard.setInfoItemIssue(issue);
  }, [dashboard, data, ui, issue, ui.infoItem?.activeItem]);

  useEffect(() => {
    const active = data.find((i) => i.id === ui.infoItem?.activeItem?.id);

    if (!active || !groupedBy) return;

    const groupedData = getDashboardIssueGroupedData(data, groupedBy);
    const groupedItem = groupedData.find((i) => i?.grouped?.find((g) => g.id === active.id));

    debounce(() => {
      ui.updateInfoItem({
        data: groupedData,
        activeItem: groupedItem,
      });
    });
  }, [dashboard, data, ui, groupedBy]);

  const mobileTopSection = ({ item }: { item: DashboardIssue }) => (
    <Box>
      <ProviderIcon groupedCount={item.grouped?.length} variant="title" name={item.name} provider={item.provider} />

      {(issue.resolved || issue.justified) && (
        <Box
          marginTop={3}
          padding={3}
          borderRadius={1}
          bgcolor={ui.isLightTheme ? "#E6EEFB" : "#5A5FA0"}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection="row"
        >
          <Box>
            <Typography component="div" variant="main">
              Detected At
            </Typography>
            <Typography component="div" variant="table">
              {formattedYearDate(item.created_at)}
            </Typography>
          </Box>

          {ui.isLightTheme ? <ResolvedArrowLightSvg /> : <ResolvedArrowDarkSvg />}

          <Box>
            <Typography component="div" variant="main">
              {item.current_state?.operator_id || item.current_state?.operator
                ? issue.resolved
                  ? "Resolved By"
                  : "Justified By"
                : issue.resolved
                ? "Resolved At"
                : "Justified At"}
            </Typography>
            <Typography component="div" variant="table">
              {(issue.resolved || issue.justified) && (
                <>
                  {(item.current_state?.operator_id || item.current_state?.operator) && (
                    <>
                      <IssueBy
                        justText
                        state={item.current_state?.state}
                        userId={item.current_state?.operator_id}
                        type={item.current_state?.operator}
                      />
                      <br />
                    </>
                  )}
                  {formattedYearDate(item.current_state?.created_at)}
                </>
              )}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );

  return (
    <Table
      type={
        issue.resolved ? UI_TABLES.RESOLVED_ISSUES : issue.justified ? UI_TABLES.JUSTIFIED_ISSUES : UI_TABLES.ISSUES
      }
      mobileTopSection={mobileTopSection}
      loadMoreCount={4}
      fields={issue.resolved ? resolvedFields : issue.justified ? justifiedFields : openFields}
      groupedBy={groupedBy}
      data={getDashboardIssueGroupedData(data, groupedBy)}
      noDataText="No issues detected"
      removePadding
      additionalInfoHeader={<DashboardIssueAdditionalInfoHeader />}
      additionalInfo={<DashboardIssueAdditionalInfo groupedBy={groupedBy} />}
      {...props}
    />
  );
};

export default inject("store")(observer(DashboardIssues));
