import React, { useState, useEffect, useMemo } from "react";
import { inject, observer } from "mobx-react";
import { Box, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import { HelpOutline } from "@mui/icons-material";
import { useFormik } from "formik";
import * as yup from "yup";

import { RootStore } from "store/root.store";
import { AWSSvg, GCPSvg } from "components/icons";
import { Button, ButtonWrapper, FormFieldsBuilder, ModalLoading } from "components/ui";
import { Account, CLOUD_PLATFORM } from "store/types";
import {
  AWSAccountContent,
  GCPAccountContent,
} from "components/modals/AddAccountModal/components/AddAccountFormStepOne/components";
import { AddAccountProgressList } from "components/modals/AddAccountModal/components";

type AddAccountFormStepOneProps = {
  store?: RootStore;
  setAccountData: (account?: Account) => void;
};

const defaultValidationSchema = {
  name: yup.string().required("Please provide the Display Name"),
};

const awsAccountIdValidationSchema = {
  awsAccountId: yup
    .string()
    .required("Please provide an AWS Account ID")
    .matches(/^\d{12}$/, "Invalid AWS Account ID. An account ID should be a 12-digit number"),
};

const gcpAccountIdValidation = {
  gcpAccountId: yup
    .string()
    .required("Please provide a GCP Project ID")
    .matches(
      /^[a-z][a-z0-9-]{4,}[a-z0-9]$/,
      "Invalid GCP Project ID. A Project ID may have lowercase letters, digits, or hyphens. " +
        "It must be between 6 and 30 characters and start with a lowercase letter and end with a letter or number",
    ),
};

const AddAccountFormStepOne = ({ store, setAccountData }: AddAccountFormStepOneProps) => {
  const initialValues = {
    provider: CLOUD_PLATFORM.AWS,
    name: "",
    awsAccountId: "",
    gcpAccountId: "",
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));
  const [validationSchema, setValidationSchema] = useState(yup.object(defaultValidationSchema));

  const [showGcpAdditionalStep, setShowGcpAdditionalStep] = useState(false);

  const defaultItems = useMemo(
    () => [
      {
        property: "provider",
        label: "Cloud Provider",
        exclusive: true,
        fullWidth: true,
        type: "buttonGroup",
        light: true,
        items: [
          {
            value: CLOUD_PLATFORM.AWS,
            component: <AWSSvg height={20} />,
          },
          {
            value: CLOUD_PLATFORM.GCP,
            component: <GCPSvg height={20} />,
          },
        ],
      },
      {
        property: "name",
        label: "Display Name",
      },
    ],
    [],
  );

  const awsItems = useMemo(
    () => [
      ...defaultItems,
      {
        property: "awsAccountId",
        label: "AWS Account ID",
        icon: HelpOutline,
        iconTooltip: !isMobile && <AWSAccountContent />,
        iconClick: () =>
          isMobile &&
          store?.ui.openConfirmModal({
            component: <AWSAccountContent />,
          }),
      },
    ],
    [defaultItems, isMobile, store?.ui],
  );

  const gcpItems = useMemo(
    () => [
      ...defaultItems,
      {
        property: "gcpAccountId",
        label: "GCP Project ID",
        icon: HelpOutline,
        iconTooltip: !isMobile && <GCPAccountContent />,
        iconClick: () =>
          isMobile &&
          store?.ui.openConfirmModal({
            component: <GCPAccountContent />,
          }),
      },
    ],
    [defaultItems, isMobile, store?.ui],
  );

  const [items, setItems] = useState(defaultItems);

  const frmk = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async ({ awsAccountId, gcpAccountId, ...values }) => {
      if (values.provider === CLOUD_PLATFORM.GCP && !showGcpAdditionalStep) {
        setShowGcpAdditionalStep(true);

        return;
      }

      const account = await store?.accounts.addAccount({
        ...values,
        name: values.name?.trim(),
        account_id: values.provider === CLOUD_PLATFORM.AWS ? awsAccountId : gcpAccountId,
      });

      setAccountData(account);
    },
  });

  useEffect(() => {
    setValidationSchema(
      yup.object({
        ...defaultValidationSchema,
        ...(frmk.values.provider === CLOUD_PLATFORM.AWS ? awsAccountIdValidationSchema : gcpAccountIdValidation),
      }),
    );

    setItems(frmk.values.provider === CLOUD_PLATFORM.AWS ? awsItems : gcpItems);
  }, [frmk.values.provider, awsItems, gcpItems]);

  if (store?.accounts.isModalLoading) return <ModalLoading />;

  return (
    <Box data-testid="addAccountModalStepOne" display="flex">
      <AddAccountProgressList provider={frmk.values.provider} currentStep={showGcpAdditionalStep ? 2 : 1} />

      <Box
        component="form"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        onSubmit={frmk.handleSubmit}
        width={{ xs: 1, laptop: 600 }}
      >
        {showGcpAdditionalStep ? (
          <Typography variant="main">
            Enable GCP Audit Logging Default Configuration settings for the selected project:{" "}
            <Link
              target="_blank"
              href={`https://console.cloud.google.com/iam-admin/audit/allservices?project=${frmk.values.gcpAccountId}`}
            >
              https://console.cloud.google.com/iam-admin/audit/allservices?project={frmk.values.gcpAccountId}
            </Link>
            .
            <br />
            <br />
            Ensure that all three checkboxes on the above page (&quot;Admin Read&quot;, &quot;Admin Write&quot;, and
            &quot;Data Read&quot;) are selected and then click the &quot;Save&quot; button. Once this step is completed,
            return to this page to finish onboarding.
          </Typography>
        ) : (
          <FormFieldsBuilder formik={frmk} items={items} />
        )}

        <ButtonWrapper>
          <>
            {showGcpAdditionalStep ? (
              <Button onClick={() => setShowGcpAdditionalStep(false)} text="Back" variant="text" />
            ) : (
              <Button onClick={store?.ui.closeModal} text="Cancel" variant="text" />
            )}

            <Button text="Continue" type="submit" />
          </>
        </ButtonWrapper>
      </Box>
    </Box>
  );
};

export default inject("store")(observer(AddAccountFormStepOne));
