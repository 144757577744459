import { ReactElement } from "react";
import { Box, Paper, SxProps } from "@mui/material";

type ElementWrapperProps = {
  children: ReactElement;
  onClick?: () => void;
  customSx?: SxProps;
};

export const ElementWrapper = ({ children, onClick, customSx }: ElementWrapperProps) => (
  <Box
    component={Paper}
    elevation={0}
    onClick={onClick}
    sx={{
      gap: 4,
      flexDirection: "column",
      display: "flex",
      position: "relative",
      overflow: "hidden",
      width: 1,
      borderRadius: { xs: 1, laptop: 2 },
      paddingX: 3,
      paddingY: 4,
      ...customSx,
    }}
  >
    {children}
  </Box>
);
