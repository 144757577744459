import React from "react";
import { Box, useTheme } from "@mui/material";

import { lightTextSecondaryDarkColor } from "theme/commonVariables";
import { CheckboxSvg } from "components/icons";

export const CheckboxListItem = ({ currentStep, index }: { currentStep?: number; index?: number }) => {
  const theme = useTheme();

  return (
    <Box
      height={20}
      width={20}
      borderRadius={0.5}
      bgcolor={
        currentStep !== undefined && index !== undefined
          ? currentStep > index + 1
            ? "primary.main"
            : "various.inactiveColor"
          : theme.palette.mode === "light"
          ? lightTextSecondaryDarkColor
          : "#282454"
      }
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexShrink={0}
    >
      <CheckboxSvg />
    </Box>
  );
};
