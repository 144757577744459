import { makeAutoObservable } from "mobx";
import { addMonths } from "date-fns";

import { RootStore } from "store/root.store";
import {
  DATA_STATS,
  FILTER_CONDITION,
  FILTER_CONDITION_TYPE,
  FILTER_DATE_TYPES,
  FILTER_OPERATOR,
  FILTER_PAGES,
  FilterConditionKey,
  FilterConditions,
  FilterData,
  FilterItem,
  FilterSaveData,
  SavedFilter,
} from "store/types";
import { getFilters, removeFilter, saveFilter, updateFilter } from "libs/api/methods";
import { getDaysDiff } from "tools";

export const FILTER_CONDITIONS: FilterConditions = Object.keys(FILTER_CONDITION).map((condition) => ({
  condition: condition as FilterConditionKey,
  type: ["IS", "IS_NOT"].includes(condition)
    ? FILTER_CONDITION_TYPE.EQUAL
    : ["IS_ONE_OF", "IS_NOT_ONE_OF"].includes(condition as FILTER_CONDITION)
    ? FILTER_CONDITION_TYPE.LIST
    : FILTER_CONDITION_TYPE.BOOLEAN,
}));

export class FilterStore {
  constructor(readonly rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  getDefaultDataStatsItem() {
    return {
      field: "",
      operator: DATA_STATS.Count,
      name: "",
    };
  }

  getDefaultFilterItem(type: FILTER_PAGES) {
    return {
      field: "",
      value: "",
      values: [],
      condition: this.getFilterConditions(type)[0]?.condition,
    };
  }

  getDefaultFilter(type: FILTER_PAGES): FilterData {
    return {
      operator: FILTER_OPERATOR.AND,
      type,
      dates: {
        type: FILTER_DATE_TYPES.DAYS_RANGE,
        values: type === FILTER_PAGES.EVENTS ? [-6, 0] : [-getDaysDiff(new Date(), addMonths(new Date(), 3)), 0],
      },
      items:
        type === FILTER_PAGES.INVENTORY
          ? [this.getDefaultFilter(FILTER_PAGES.INVENTORY_DETAILS)]
          : [this.getDefaultFilterItem(type)],
    };
  }

  getFilterConditions(type?: FILTER_PAGES): FilterConditions {
    if (type === FILTER_PAGES.EVENTS) {
      return FILTER_CONDITIONS.filter((f) => f.type !== FILTER_CONDITION_TYPE.BOOLEAN);
    }

    if ([FILTER_PAGES.INVENTORY, FILTER_PAGES.INVENTORY_DETAILS].includes(type as FILTER_PAGES)) {
      return FILTER_CONDITIONS.filter((f) => f.type === FILTER_CONDITION_TYPE.EQUAL);
    }

    return FILTER_CONDITIONS;
  }

  async saveFilter(data: FilterSaveData & { id?: string }, returnRes?: boolean) {
    const { id } = data;
    let res;

    if (id) {
      res = await updateFilter(id, data);
    } else {
      res = await saveFilter(data);
    }

    return returnRes ? res : this.getSavedFilters(data.type);
  }

  async getSavedFilters(type: FILTER_PAGES): Promise<SavedFilter[]> {
    try {
      const filters = await getFilters(type);

      if (type !== FILTER_PAGES.INVENTORY) return filters;

      const filterForChange = filters.filter((s) =>
        (s.filter as FilterData)?.items.find((i) => ["type", "location"].includes((i as FilterItem).field as string)),
      );

      if (!filterForChange.length) return filters;

      await Promise.all(
        filterForChange.map((data) =>
          this.saveFilter(
            {
              ...data,
              filter: {
                ...data.filter,
                items: (data.filter as FilterData).items?.map((i) => {
                  const item = i as FilterItem;

                  if (!["type", "location"].includes(item.field as string)) return i;

                  return {
                    condition: item.condition === "IS" ? "IS_ONE_OF" : "IS_NOT_ONE_OF",
                    value: "",
                    values: [{ id: item.value, name: item.value }],
                    field: `${item.field}s`,
                  };
                }),
              },
            } as FilterSaveData,
            true,
          ),
        ),
      );

      return this.getSavedFilters(FILTER_PAGES.INVENTORY);
    } catch {
      return [];
    }
  }

  removeFilterAction(id: string) {
    return removeFilter(id);
  }

  async removeFilter(id: string) {
    await Promise.all([
      this.removeFilterAction(id),
      this.rootStore.widgets.addOrRemoveWidgetSettings({
        removeIds: this.rootStore.widgets.widgetSettings.filter((w) => w.filterId === id).map((w) => w.id),
      }),
    ]);
  }
}
