export const INDEX_ROUTE = "/";
export const SIGNUP_ROUTE = "/signup";
export const SIGNUP_VALIDATE_INVITATION_ROUTE = "/signup/steps/validateInvitation";
export const SIGNUP_CREATE_ORG = "/signup/steps/createOrg";
export const ACCEPT_INVITATION_ROUTE = "/signup/acceptInvitation";
export const LOGIN_ROUTE = "/login";
export const LOGIN_ORG_ROUTE = "/login/org";
export const DASHBOARD_ROUTE = "/dashboard";
export const DASHBOARD_BY_ID_ROUTE = "/dashboard/:dashboardId";
export const DASHBOARD_HISTORICAL_ROUTE = "/dashboard/historical";
export const RULES_ROUTE = "/rules";
export const DEPENDABOT_ROUTE = "/dependabot";
export const EVENTS_ROUTE = "/events";
export const INVENTORY_ROUTE = "/inventory";
export const INTEGRATIONS_ROUTE = "/integrations";
export const ACCOUNTS_ROUTE = "/accounts";
export const USERS_ROUTE = "/settings/users";
export const NOTIFICATIONS_ROUTE = "/settings/notifications";
export const CLOUD_MAP_ACCOUNTS_ROUTE = "/accounts/cloudMap";
export const CLOUD_MAP_ASSETS_ROUTE = "/accounts/:accountId/resources/:resourceId/cloudMap";
export const INVENTORY_BY_RESOURCE_ID_ROUTE = "/accounts/:accountId/resources/:resourceId/asset";
export const UNDO_REMEDIATION_ROUTE = "/accounts/:accountId/issues/:issueId/undoRemediation";
export const ISSUE_BY_ID_ROUTE = "/accounts/:accountId/issues/:issueId";
export const ISSUES_BY_RESOURCE_ID_ROUTE = "/accounts/:accountId/resources/:resourceId/issues";
export const ISSUES_BY_RULE_ID_ROUTE = "/accounts/:accountId/rules/:ruleId/issues";
export const EVENT_BY_ID_ROUTE = "/accounts/:accountId/events/:eventId";
export const COMPLIANCE_ROUTE = "/compliance";
export const COMPLIANCE_BY_ID_ROUTE = "/compliance/:id";

export const ACTION_FIELD_PROPERTY = "actionField";

export const MENU_WIDTH = 280;
export const MENU_SMALL_VIEW_WIDTH = 69;

export const BIG_BUTTON_HEIGHT = 44;
export const TABLE_HEIGHT = 40;
export const SMALL_HEIGHT = 30;

export const TABLE_TRUNCATE_LENGTH = 65;

export const INITIALIZING_STEP = "INITIALIZING";
