import React, { MouseEvent } from "react";
import { Box, Paper, useMediaQuery, useTheme } from "@mui/material";
import { inject, observer } from "mobx-react";

import { MenuLogo, Nav, ThemeSwitcher } from "components/layouts/LoggedInFlow/components/VerticalMenu/components";
import { RootStore } from "store/root.store";
import { MENU_WIDTH } from "consts";

const VerticalMenu = ({ store }: { store?: RootStore }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const click = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    store?.ui.closeInfoItem();
  };

  return (
    <Box
      onClick={click}
      boxSizing="content-box"
      data-testid="verticalMenu"
      width={{ xs: store?.ui.mobileMenuOpened ? MENU_WIDTH : 0, laptop: "auto" }}
      minWidth={store?.ui.getMenuWidth(isMobile)}
      paddingRight={{ laptop: 5 }}
      borderRight="1px solid"
      borderColor={{ xs: "background.default", laptop: "transparent" }}
      borderRadius={0}
      minHeight="100vh"
      zIndex={2}
      position="fixed"
      sx={{
        overflow: "scroll",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "&::-webkit-scrollbar": { display: "none" },
      }}
      height="100vh"
    >
      <Box height={1}>
        <Box
          minHeight={1}
          paddingX={{ xs: 3, laptop: store?.ui.smallView ? 2 : 3 }}
          paddingTop={5}
          paddingBottom={{ xs: 18, laptop: 5 }}
          component={Paper}
          bgcolor="background.paperBGSecond"
          elevation={0}
        >
          <MenuLogo />
          <Nav />
          <ThemeSwitcher />
        </Box>
      </Box>
    </Box>
  );
};

export default inject("store")(observer(VerticalMenu));
