import { Box, Typography } from "@mui/material";

import { FILTER_OPERATOR, FilterData } from "store/types";

type FilterOperatorProps = {
  filterElement: FilterData;
};

export const FilterOperator = ({ filterElement }: FilterOperatorProps) => {
  if (!filterElement?.items || filterElement.items.length < 2) return null;

  const color = filterElement.operator === FILTER_OPERATOR.AND ? "#00B081" : "#1F90FF";
  const bgColor = filterElement.operator === FILTER_OPERATOR.AND ? "#DBFFF5" : "#E8F8FF";

  return (
    <Box
      width={{ xs: 10, sm: 30, laptop: 50 }}
      position="relative"
      paddingBottom={2}
      marginLeft={{ xs: 0, sm: -1, laptop: -2 }}
    >
      <Box
        border={`2px solid ${color}`}
        borderRight="none"
        position="absolute"
        width={0.5}
        top="32px"
        bottom="42px"
        right={0}
      />
      <Box display="flex" alignItems="center" justifyContent="center" height={1} zIndex={1} position="relative">
        <Typography variant="main" paddingX={2} paddingY={0.5} bgcolor={bgColor} color={color} borderRadius={1}>
          {filterElement.operator}
        </Typography>
      </Box>
    </Box>
  );
};
