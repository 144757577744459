import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "mobx-react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";

import AppRoutes from "routes";
import store from "store";
import { ErrorBoundary, ThemeProvider, MessageProvider } from "components/providers";
import { Layout } from "components/layouts";
import { getSelf } from "libs/api/methods";
import { isMobile } from "tools";

const App = () => {
  useEffect(() => {
    (async () => {
      const user = await getSelf().catch(() => null);

      store?.auth.setUserData(user);
    })();
  }, []);

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <ThemeProvider>
          <BrowserRouter>
            <Layout>
              <MessageProvider>
                <DndProvider backend={isMobile() ? TouchBackend : HTML5Backend}>
                  <AppRoutes />
                </DndProvider>
              </MessageProvider>
            </Layout>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
