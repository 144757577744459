import React, { useState, MouseEvent, useMemo } from "react";
import { Box, Menu, MenuItem } from "@mui/material";
import { MoreVert } from "@mui/icons-material";

import { Button, TableActionProps, TableItemProps } from "components/ui";

type TableActionsProps = {
  actions: TableActionProps[];
  item: TableItemProps;
  notInTable?: boolean;
};

export const TableActions = ({ actions, item, notInTable }: TableActionsProps) => {
  const [actionsAnchorEl, setActionsAnchorEl] = useState<null | HTMLElement>(null);

  const filteredActions = useMemo(() => actions.filter((action) => !action.hide?.(item)), [actions, item]);

  const openActions = (event: MouseEvent<HTMLElement>) => setActionsAnchorEl(event.currentTarget);

  const closeActions = () => setActionsAnchorEl(null);

  const actionClick = (e: MouseEvent<HTMLElement>, action: TableActionProps) => {
    e.stopPropagation();

    action.onClick(item);

    closeActions();
  };

  if (!filteredActions.length) return null;

  return (
    <>
      <Box data-testid="tableActions" display="flex" position="relative" top={notInTable ? 0 : 2} onClick={openActions}>
        <MoreVert sx={{ color: "text.secondary", cursor: "pointer", fontSize: 25 }} />
      </Box>
      <Menu
        anchorEl={actionsAnchorEl}
        open={!!actionsAnchorEl}
        onClose={closeActions}
        sx={{
          "& .MuiList-root": {
            paddingY: 2,
            paddingX: 0,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          },
        }}
      >
        {filteredActions.map((action) => (
          <MenuItem
            key={action.key}
            onClick={(e) => actionClick(e, action)}
            sx={{
              justifyContent: "center",
              "&.Mui-focusVisible": { background: "none" },
              "&:focus-visible": { background: "none" },
              ...(action.withHover ? {} : { "&:hover": { background: "none", cursor: "default" } }),
              "&.MuiMenuItem-root": {
                paddingY: 0,
                paddingX: 2,
              },
            }}
          >
            <Button fullWidth smallWidth {...action.getButtonProps(item)} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
