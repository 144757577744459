import React from "react";
import { inject, observer } from "mobx-react";
import { Box, Divider, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { RootStore } from "store/root.store";
import {
  AdditionalInfoTable,
  ProviderServiceTypeIcon,
  InfoLabels,
  JSON_VIEWER_VARIANTS,
  JsonViewer,
  PossibleJsonValue,
  ProviderIcon,
  TagsItemList,
  InventoryByResourceIdLink,
  UnderlineLink,
  Button,
  JsonSearchType,
} from "components/ui";
import { CLOUD_PLATFORM, FEATURE, Inventory } from "store/types";
import { getIconTypeByResource } from "tools";
import { inventoryFields } from "routes/InventoryRoute/components/inventoryTable";
import { getConsoleLinksFromInventory, getInventoryResourceLink } from "tools/utils";
// import InventoryPriceHistory from "routes/InventoryRoute/components/inventoryPriceHistory";

type InventoryAdditionalInfoProps = {
  store?: RootStore;
  asset?: Inventory;
  standalonePage?: boolean;
  lastUpdatedBySource?: string;
  lastUpdatedBy?: string;
  cloudmap?: {
    action: () => void;
    text?: string;
  };
  clickOnTag?: (property: string, value: PossibleJsonValue) => void;
};

const InventoryAdditionalInfo = ({
  store,
  asset,
  lastUpdatedBySource,
  lastUpdatedBy,
  standalonePage,
  clickOnTag,
  cloudmap,
}: InventoryAdditionalInfoProps) => {
  const navigate = useNavigate();

  const item = asset || (store?.ui.infoItem?.activeItem as Inventory);
  const account = store?.accounts.activeAccounts.find((a) => a.provider_account_id === item?.provider_account_id);
  const tableFields = inventoryFields(!!store?.auth.isFeatureEnabled(FEATURE.INTEGRATIONS_TERRAFORM)).filter(
    (f) => f.additionalInfo === "table",
  );
  const consoleLink = getConsoleLinksFromInventory({
    provider: account?.provider as CLOUD_PLATFORM,
    item,
  });

  const setJsonSearch = (value: JsonSearchType) => {
    store?.inventory.setLastUpdatedJsonSearch();
    store?.inventory.setJsonSearch(value);
  };

  const goToAssetPage = () =>
    navigate(getInventoryResourceLink({ accountId: account?.id as string, resourceId: item.provider_id }));

  return (
    <Box display="flex" gap={standalonePage ? 5 : 4} flexDirection="column">
      {standalonePage && (
        <Box display="flex" alignItems="center" gap={4}>
          <Typography variant="main" fontWeight={700} fontSize={18}>
            Name
          </Typography>
          <Typography sx={{ wordBreak: "break-word" }} fontWeight={700} variant="title">
            {item.name}
          </Typography>
        </Box>
      )}

      <InfoLabels
        inColumn={standalonePage}
        labels={[
          {
            title: "",
            value: (
              <Box fontWeight={600}>
                <ProviderServiceTypeIcon text={item.type} type={getIconTypeByResource(item.type)} />
              </Box>
            ),
          },
          {
            title: "Account",
            value: account ? (
              <ProviderIcon name={account?.name} provider={account?.provider} />
            ) : (
              item?.provider_account_id
            ),
          },
          {
            title: "Account ID",
            value: item?.provider_account_id,
          },
          {
            title: "Location",
            value: item?.location,
          },
          ...[
            {
              title: "Last updated by",
              value: lastUpdatedBy || "",
            },
            {
              title: "Update Source",
              value: lastUpdatedBySource || "",
            },
          ].filter((i) => i.value),
        ]}
      />

      {!standalonePage && (
        <Typography sx={{ wordBreak: "break-word" }} variant="title" fontWeight={700} component="div">
          {account ? (
            <InventoryByResourceIdLink showLink accountId={account.id} resourceId={item.provider_id} />
          ) : (
            item.name
          )}
        </Typography>
      )}

      <AdditionalInfoTable inRow={standalonePage} item={item} fields={tableFields} />

      {!!consoleLink && (
        <Typography variant="main">
          <UnderlineLink
            underline
            separateSite
            to={consoleLink}
            text={`Go to ${account?.provider?.toUpperCase() || "Provider"} Console`}
          />
        </Typography>
      )}

      {/* {standalonePage && <InventoryPriceHistory asset={item} />} */}

      {!!Object.entries(item?.tags || {}).length && (
        <>
          <Divider />

          <Box display="flex" flexDirection="column" gap={3}>
            <Typography variant="main" fontSize={16} fontWeight={700}>
              Tags
            </Typography>
            <TagsItemList clickOnTag={clickOnTag} tags={item?.tags} />
          </Box>
        </>
      )}

      {!standalonePage && (
        <Box>
          <JsonViewer
            copy
            setJsonSearch={standalonePage ? undefined : setJsonSearch}
            jsonSearch={standalonePage ? undefined : store?.inventory?.jsonSearch}
            variants={[JSON_VIEWER_VARIANTS.JSON, JSON_VIEWER_VARIANTS.TABLE]}
            json={item?.details}
          />
        </Box>
      )}

      {!!cloudmap && (
        <Box display="flex" justifyContent={{ xs: "space-between", laptop: "flex-end" }} gap={5}>
          <Button onClick={goToAssetPage} text="Go to Asset page" variant="text" />
          {!!cloudmap.text && <Button onClick={cloudmap.action} text={cloudmap.text} />}
        </Box>
      )}
    </Box>
  );
};

export default inject("store")(observer(InventoryAdditionalInfo));
