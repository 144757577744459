import { api } from "libs";
import { API_CLOUD_ACCOUNTS_CLOUD_MAP_DATA, API_ROLES } from "libs/api/endpoints";
import { AccountCloudMapItem, PermissionsWithRole } from "store/types";

export const getRoles = async (): Promise<PermissionsWithRole[]> => {
  const res = await api.get(API_ROLES, {
    doNotShowErrorMessage: true,
  });

  return res.roles;
};

export const getCloudMapData = async (accountId: string): Promise<AccountCloudMapItem[][]> =>
  api.get(API_CLOUD_ACCOUNTS_CLOUD_MAP_DATA(accountId), {
    errorMessage: {
      title: "Failed to get CloudMap data",
    },
  });
