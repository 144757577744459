import React, { ReactElement, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Box } from "@mui/material";

import { RootStore } from "store/root.store";
import { Modal, Toaster } from "components/ui";
import { ConfirmModal } from "components/modals";

type LayoutProps = { store?: RootStore; children: ReactElement };

const Layout = ({ store, children }: LayoutProps) => {
  useEffect(() => {
    const handleEsc = ({ key }: KeyboardEvent) => {
      if (key !== "Escape") return;

      setTimeout(() => store?.ui.closeInfoItem());
    };

    window.addEventListener("keydown", handleEsc);

    return () => window.removeEventListener("keydown", handleEsc);
  }, [store?.ui]);

  return (
    <>
      {children}

      {!!store?.ui.modal && (
        <Modal {...store?.ui.modal} showLoading={store?.ui?.showModalLoading} onClose={store?.ui.closeModal} />
      )}

      {!!store?.ui.confirmModal && (
        <Modal
          {...store?.ui.confirmModal}
          showLoading={store?.ui?.showConfirmModalLoading}
          component={undefined}
          onClose={() => store?.ui.closeConfirmModal()}
        >
          <ConfirmModal
            {...store?.ui.confirmModal}
            setShowConfirmModalLoading={store?.ui.setShowConfirmModalLoading}
            onClose={store?.ui.closeConfirmModal}
          />
        </Modal>
      )}

      {!!store?.ui.message && (
        <>
          <Box
            display={{ xs: "inherit", laptop: "none" }}
            onClick={() => store?.ui.removeMessage()}
            position="absolute"
            top={0}
            right={0}
            left={0}
            bottom={0}
            bgcolor="background.toaster"
            zIndex={1500}
          />
          <Toaster message={store?.ui.message} onClose={store?.ui.removeMessage} />
        </>
      )}
    </>
  );
};

export default inject("store")(observer(Layout));
