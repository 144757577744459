import React, { MouseEvent } from "react";
import { Box, Divider } from "@mui/material";
import { inject, observer } from "mobx-react";
import { Close } from "@mui/icons-material";

import { RootStore } from "store/root.store";
import { TableActions } from "components/ui/Table/components";
import { ArrowSvg } from "components/icons";

export const INFO_ITEM_WIDTH = { laptop: "50vw", laptopLg: "45vw", lg: "40vw" };

const InfoItem = ({ store }: { store?: RootStore }) => {
  const { infoItem, closeInfoItem, updateInfoItem } = store!.ui;

  const goTo = (index: number) => {
    const activeIndex = infoItem?.data.findIndex((d) => d.id === infoItem?.activeItem.id) || 0;
    let nextPossibleIndex = activeIndex + index;

    if (nextPossibleIndex < 0) nextPossibleIndex = infoItem!.data.length - 1;
    else if (nextPossibleIndex >= infoItem!.data.length) nextPossibleIndex = 0;

    updateInfoItem({
      activeItem: infoItem?.data?.[nextPossibleIndex],
    });
  };

  return (
    <Box
      zIndex={10}
      position="fixed"
      top={0}
      right={0}
      height="100vh"
      width={{ xs: 1, ...INFO_ITEM_WIDTH }}
      borderRadius={{ xs: "unset", laptop: "10px 0 0 10px" }}
      bgcolor="background.paper"
      borderLeft="1px solid"
      borderColor="background.default"
      paddingY={{ xs: 6, laptop: 8 }}
      paddingX={{ xs: 3, laptop: 5 }}
      overflow="scroll"
      onClick={(e: MouseEvent<HTMLElement>) => e.stopPropagation()}
      color="text.primary"
    >
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Box display="flex" alignItems="center">
          <Close onClick={closeInfoItem} sx={{ cursor: "pointer", color: "text.primary" }} />

          {infoItem!.data.length > 1 && (
            <Box paddingY={infoItem?.header ? 2 : 0} marginX={{ xs: 10, laptop: 7 }} display="flex">
              <Box
                onClick={() => goTo(-1)}
                marginRight={{ xs: 6, laptop: 4 }}
                sx={{
                  svg: {
                    transform: "rotate(-90deg)",
                  },
                }}
              >
                <ArrowSvg />
              </Box>

              <Box
                onClick={() => goTo(1)}
                sx={{
                  svg: {
                    transform: "rotate(90deg)",
                  },
                }}
              >
                <ArrowSvg />
              </Box>
            </Box>
          )}
        </Box>

        <Box marginLeft="auto" display="flex" alignItems="center">
          {!!infoItem?.header && <Box>{infoItem?.header}</Box>}

          {!!infoItem?.actions?.length && <TableActions item={infoItem?.data[0]} actions={infoItem?.actions} />}
        </Box>
      </Box>

      <Divider sx={{ marginY: 5 }} />

      {store?.ui.infoItem?.component}
    </Box>
  );
};

export default inject("store")(observer(InfoItem));
