import React from "react";
import { Box, Typography } from "@mui/material";

import { AUTH_PROVIDER, TYPE_AUTH } from "store/types";
import { SocialAuthForm } from "components/containters";
import { UnderlineLink } from "components/ui";

type AuthPromptProps = { type: TYPE_AUTH; onlyType?: AUTH_PROVIDER; hideOppositeMethod?: boolean };

const AuthPrompt = ({ type, onlyType, hideOppositeMethod }: AuthPromptProps) => (
  <Box textAlign="center" padding={{ xs: 0, laptop: 5 }}>
    <Typography sx={{ typography: { xs: "h1", laptop: "windowTitle" } }} component="div" marginBottom={3}>
      {type === TYPE_AUTH.LOGIN ? "Welcome back" : "Sign Up"}
    </Typography>

    <Typography variant="h4" component="div" marginBottom={5}>
      {type === TYPE_AUTH.LOGIN ? "Log in to your account" : "Select an identity provider to continue"}
    </Typography>

    <SocialAuthForm onlyType={onlyType} type={type} />

    {!hideOppositeMethod && (
      <Typography component="div" fontSize={15} marginTop={5} variant="main" color="background.oppositePaperBG">
        {type === TYPE_AUTH.LOGIN ? "Not a customer yet?" : "Already a customer?"}

        <Box component="span" marginLeft={1}>
          <UnderlineLink
            to={type === TYPE_AUTH.LOGIN ? "/signup" : "/login"}
            text={type === TYPE_AUTH.LOGIN ? "Sign Up" : "Log In"}
          />
        </Box>
      </Typography>
    )}
  </Box>
);

export default AuthPrompt;
