import { ReactElement } from "react";
import { DialogProps } from "@mui/material";

import { TableActionProps, TableItemProps } from "components/ui";

export enum LOCAL_STORAGE_ITEM {
  THEME_MODE_LOCAL_STORAGE = "themeMode",
  SMALL_VIEW_LOCAL_STORAGE = "smallView",
  ROWS_PER_PAGE_LOCAL_STORAGE = "rowsPerPageV2",
  TABLE_SORT_LOCAL_STORAGE = "tableSort",
  SELECTED_DROPDOWN_VALUES = "selectedDropdownValueV2",
  GITHUB_DEPENDABOT_FILTERS = "githubDependabotFilters",
  DASHBOARD_GROUPED_BY = "dashboardGroupBy",
  SELECTED_TABS = "selectedTab",
}

export enum SESSION_STORAGE_ITEM {
  URL_AFTER_SIGN_IN = "urlAfterSignIn",
  INVENTORY_TABS = "inventoryTabs",
}

export enum UI_TABLES {
  USERS = "users",
  INVITATIONS = "invitations",
  NOTIFICATION_CHANNELS = "notificationChannels",
  RULES_PACK = "rulesPack",
  INVENTORY = "inventory",
  INVENTORY_RELATED_RESOURCES = "inventoryRelatedResources",
  EVENTS = "events",
  ACCOUNTS = "accounts",
  VULNERABILITIES = "vulnerabilities",
  ACCOUNT_ONBOARDING = "accountOnboarding",
  AWS_COST_INTEGRATION = "awsCostIntegration",
  ISSUES = "issues",
  RESOLVED_ISSUES = "resolvedIssues",
  JUSTIFIED_ISSUES = "justifiedIssues",
  JSON_VIEW = "jsonView",
  INTEGRATION = "integration",
  COMPLIANCE_ITEM = "complianceItem",
  ORG_ONBOARDING = "orgOnboarding",
}

export enum UI_TABS {
  ISSUES = "issues",
  INVENTORY = "inventory",
  HISTORICAL = "historical",
}

export enum DROPDOWNS {
  DASHBOARD_ACCOUNT_EVENT_CHART = "dashboardAccountEventChart",
  CLOUD_MAP_ACCOUNT = "cloudmapAccount",
  CLOUD_MAP_ASSETS = "cloudmapAssets",
  INVENTORY_ACCOUNT = "inventoryAccount",
  EVENTS_ACCOUNT = "eventsAccount",
  DEPENDABOT_INSTALLATIONS = "dependabotInstallations",
  ORG_LOGIN = "orgLogin",
  RULES_PROVIDER = "rulesProvider",
  HISTORICAL_DASHBOARD = "historicalDashboard",
}

export enum UI_THEME {
  DARK,
  LIGHT,
}

export enum MESSAGE_VARIANT {
  ERROR,
  INFO,
  SUCCESS,
  WARNING,
}

export interface Message {
  id?: string;
  title?: string;
  message?: string;
  variant?: MESSAGE_VARIANT;
  duration?: number;
}

export type ModalWidth = "xs" | "sm" | "md" | "lg" | "xl" | "laptop" | "laptopLg" | "laptopLgx" | "lgXl";

export type UIModal = {
  component?: ReactElement | string;
  title?: string;
  text?: string;
  open?: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
  onCloseButtonClick?: () => void;
  maxWidth?: ModalWidth;
  minWidth?: ModalWidth;
  type?: "confirmModal";
  confirmText?: string;
  closeText?: string;
  fullWidth?: boolean;
  forbidFullScreen?: boolean;
};

export type UIDialog = UIModal & DialogProps;

export type UIInfoItem = {
  activeItem: TableItemProps;
  data: TableItemProps[];
  actions?: TableActionProps[];
  component?: ReactElement | string;
  header?: ReactElement;
  type: UI_TABLES | string;
};
