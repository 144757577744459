export enum API_KEY_PERMISSIONS {
  Read = "read",
  Issues = "issues",
  "Cloud Accounts" = "cloud_accounts",
  Rules = "rules",
  Notifications = "notifications",
  Integrations = "integrations",
  Users = "users",
  Roles = "roles",
}

export type ApiKey = {
  id: string;
  name: string;
  permissions: Record<string, boolean>;
  created_at: string;
  created_by_user_id: string;
  expires_at: Date;
};

export type AddApiKey = {
  expires_at?: Date;
  name: string;
  permissions: Record<string, boolean>;
  expiration: number;
};

export type UpdateApiKey = AddApiKey & {
  id: string;
};
