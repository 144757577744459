import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { ElementType, Fragment } from "react";

import { TableFields, TableItemProps } from "components/ui";
import { getFieldValue } from "tools";

type AdditionalInfoTableProps = {
  item?: TableItemProps;
  fields: TableFields[];
  actionField?: TableFields;
  customActionComponent?: ElementType;
  eachFieldAction?: boolean;
  inRow?: boolean;
};

const ActionField = ({
  field,
  item,
  actionField,
  customActionComponent: CustomActionComponent,
}: {
  item?: TableItemProps;
  actionField?: TableFields;
  field: TableFields;
  customActionComponent?: ElementType;
}) =>
  CustomActionComponent ? (
    <CustomActionComponent item={item} field={field} />
  ) : (
    getFieldValue({ field: actionField!, item: item! })
  );

export const AdditionalInfoTable = ({
  item,
  fields,
  actionField,
  eachFieldAction,
  customActionComponent,
  inRow,
}: AdditionalInfoTableProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const showActions = (field: TableFields, index: number) =>
    (!!actionField || !!customActionComponent) && ((!!eachFieldAction && !field.dontShowOnEachFieldAction) || !index);

  if (!item) return null;

  if (inRow)
    return (
      <Box display="flex" gap={{ xs: 4, laptop: 5 }} flexWrap="wrap">
        {fields
          .filter((field) => !field.hideFieldValue?.(item))
          .map((field) => (
            <Box key={field.property} display="flex" gap={4} alignItems="center" flexWrap="wrap">
              <Typography variant="main" fontWeight={700}>
                {field.originalBeforeGroupingName || field.name}
              </Typography>
              <Typography variant="table">{getFieldValue({ field, item })}</Typography>
            </Box>
          ))}
      </Box>
    );

  return (
    <TableContainer data-testid="additionalInfoTable">
      <Table>
        <TableBody>
          {fields
            .filter((field) => !field.hideFieldValue?.(item))
            .map((field, index) => (
              <Fragment key={field.property}>
                {showActions(field, index) && isMobile && (
                  <TableRow>
                    <TableCell colSpan={2} sx={{ padding: 0, paddingY: 1 }}>
                      <ActionField
                        customActionComponent={customActionComponent}
                        item={item}
                        field={field}
                        actionField={actionField}
                      />
                    </TableCell>
                  </TableRow>
                )}
                <TableRow sx={{ verticalAlign: "middle" }}>
                  <TableCell
                    width="1px"
                    sx={{
                      whiteSpace: { sm: "nowrap" },
                      paddingLeft: 0,
                      paddingRight: { xs: 2.5, laptop: 4 },
                      paddingY: 1,
                    }}
                  >
                    <Typography variant="main" fontWeight={700} whiteSpace="nowrap" display="flex">
                      {field.originalBeforeGroupingName || field.name}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      ...(!field.dontBreakInAdditionalTable && { wordBreak: "break-word" }),
                      paddingX: 0,
                      paddingY: 1,
                    }}
                  >
                    <Typography variant="table" display="flex">
                      {getFieldValue({ field, item })}
                    </Typography>
                  </TableCell>
                  {showActions(field, index) && !isMobile && (
                    <TableCell width="1px" sx={{ padding: 0, paddingLeft: { xs: 2.5, laptop: 4 } }}>
                      <ActionField
                        customActionComponent={customActionComponent}
                        item={item}
                        field={field}
                        actionField={actionField}
                      />
                    </TableCell>
                  )}
                </TableRow>
              </Fragment>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
