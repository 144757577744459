import React, { MouseEvent } from "react";
import { Box, Typography } from "@mui/material";
import { inject, observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

import { RootStore } from "store/root.store";
import { darkTextPrimaryColor, primaryMainDark } from "theme/commonVariables";
import { AddDashboardModal } from "components/modals";
import { DASHBOARD_BY_ID_ROUTE, MENU_WIDTH } from "consts";
import { DashboardSvg, MainDashboardSvg, NewDashboardSvg } from "components/icons";

const DashboardDropdownMenu = ({ store }: { store?: RootStore }) => {
  const { dashboards } = store!.multipleDashboards;

  const navigate = useNavigate();

  const makeMainDashboard = (e: MouseEvent<SVGElement>, id: string) => {
    e.stopPropagation();

    return store?.multipleDashboards.makeMain(id);
  };

  const goToDashboard = (id: string) => navigate(DASHBOARD_BY_ID_ROUTE.replace(":dashboardId", id));

  const addNewDashboard = () =>
    store?.ui.openModal({
      title: "Create a name",
      maxWidth: "sm",
      component: <AddDashboardModal />,
    });

  return (
    <Box
      sx={{
        svg: {
          width: 24,
          height: 24,
        },
      }}
    >
      {dashboards.map((dashboard) => (
        <Box
          key={dashboard.id}
          paddingX={2.5}
          paddingY={2}
          display="flex"
          gap={3}
          alignItems="center"
          onClick={() => goToDashboard(dashboard.id)}
          sx={{
            cursor: "pointer",
            ...(!store?.ui.isLightTheme &&
              !dashboard.filter?.isMain && {
                svg: {
                  path: {
                    fill: darkTextPrimaryColor,
                  },
                },
              }),
          }}
        >
          {dashboard.filter?.isMain ? (
            <MainDashboardSvg />
          ) : (
            <DashboardSvg onClick={(e: MouseEvent<SVGElement>) => makeMainDashboard(e, dashboard.id)} />
          )}
          <Typography
            maxWidth={MENU_WIDTH - 100}
            component="div"
            variant="main"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {dashboard.name}
          </Typography>
        </Box>
      ))}
      <Typography
        color="primary.main"
        variant="main"
        paddingX={2.5}
        paddingY={2}
        display="flex"
        gap={3}
        alignItems="center"
        onClick={addNewDashboard}
        sx={{
          cursor: "pointer",
          ...(!store?.ui.isLightTheme && {
            svg: {
              path: {
                fill: primaryMainDark,
              },
            },
          }),
        }}
      >
        <NewDashboardSvg />
        Add New
      </Typography>
    </Box>
  );
};

export default inject("store")(observer(DashboardDropdownMenu));
