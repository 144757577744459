import React from "react";

import { Input } from "components/ui";

type AuthOrgSignUpProps = {
  name: string;
  onChange: (val: string) => void;
  error?: string;
};

const AuthOrgSignUp = ({ name, onChange, error }: AuthOrgSignUpProps) => (
  <Input error={error} value={name} onChange={onChange} placeholder="Organization Name" name="organization_name" />
);

export default AuthOrgSignUp;
