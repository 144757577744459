import React, { useState, Fragment, SyntheticEvent, ReactElement, useEffect } from "react";
import { Box, Tab, Tabs as MuiTabs } from "@mui/material";

import store from "store";
import { lightDarkBG } from "theme/commonVariables";
import { UI_TABS } from "store/types";

export type TabProp = {
  render?: () => ReactElement;
  title: string;
  component: ReactElement | string;
};

export const DO_NOT_REMOVE_TAB = "needToRemove";

export const Tabs = ({
  tabs,
  type,
  currentValue,
  additionalComponent,
  marginTop = 0,
  paddingX = 0,
  checkForRemove,
  onChange,
}: {
  tabs: TabProp[];
  additionalComponent?: ReactElement;
  marginTop?: number;
  paddingX?: number;
  type?: UI_TABS;
  currentValue?: number;
  checkForRemove?: boolean;
  onChange?: (val: number) => void;
}) => {
  const [value, setValue] = useState(currentValue ?? (type ? store.ui.selectedTabs[type] || 0 : 0));

  useEffect(() => {
    if (currentValue === undefined) return;

    setValue(currentValue);
  }, [currentValue]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    if (checkForRemove && (event.target as HTMLElement)?.dataset?.type !== DO_NOT_REMOVE_TAB) {
      return;
    }

    setValue(newValue);
    onChange?.(newValue);

    if (type) {
      store.ui.setSelectedTabs(type, newValue);
    }
  };

  return (
    <Box display="flex" flexDirection="column" width={1}>
      <Box
        paddingX={paddingX}
        display="flex"
        justifyContent="space-between"
        alignItems="self-start"
        gap={3}
        flexWrap="nowrap"
      >
        <MuiTabs
          sx={{
            overflow: "unset",
            borderBottom: `2px solid ${lightDarkBG} !important`,
            flexGrow: 1,
            position: "relative",
            top: -1,

            "& .MuiTabs-scroller": {
              overflow: "unset !important",
            },
          }}
          data-testid="tabs"
          value={value}
          onChange={handleChange}
        >
          {tabs.map((tab) => (
            <Tab key={tab.title} label={tab.render?.() || tab.title} />
          ))}
        </MuiTabs>

        {additionalComponent}
      </Box>

      <Box marginTop={marginTop}>
        {tabs.map((tab, index) => value === index && <Fragment key={tab.title}>{tab.component}</Fragment>)}
      </Box>
    </Box>
  );
};
