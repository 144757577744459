import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

import { getOrgs } from "libs/api/methods";
import { AuthOrg, DROPDOWNS } from "store/types";
import { Select } from "components/ui";
import { RootStore } from "store/root.store";
import { LOGIN_ROUTE } from "consts";

type AuthOrgSignInProps = { store?: RootStore; id: string; setId: (id: string) => void };

const AuthOrgSignIn = ({ store, id, setId }: AuthOrgSignInProps) => {
  const { selectedDropdownValue } = store!.ui;

  const navigate = useNavigate();

  const [orgs, setOrgs] = useState<AuthOrg[]>([]);

  useEffect(() => {
    (async () => {
      const data = await getOrgs().catch(() => null);

      if (!data?.length) return navigate(LOGIN_ROUTE);

      setOrgs(data);

      const selectedPreviouslyId = selectedDropdownValue?.[DROPDOWNS.ORG_LOGIN];
      const orgId = data.find((o) => o.id === selectedPreviouslyId)?.id || data[0].id;

      setId(orgId);
    })();
  }, [selectedDropdownValue, setId, navigate]);

  if (!orgs.length) return null;

  return <Select type={DROPDOWNS.ORG_LOGIN} fullWidth value={id} list={orgs} onChange={setId} />;
};

export default inject("store")(observer(AuthOrgSignIn));
