import { makeAutoObservable } from "mobx";

import { RootStore } from "store/root.store";
import {
  DashboardWidgetsSettings,
  FEATURE,
  FILTER_PAGES,
  MultipleDashboardSavedFilter,
  SavedWidgetSettings,
  WidgetsSettings,
} from "store/types";

export class MultipleDashboardsStore {
  dashboards: MultipleDashboardSavedFilter[] = [];

  constructor(readonly rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);

    this.getCurrentDashboard = this.getCurrentDashboard.bind(this);
  }

  getCurrentDashboard(dashboardId?: string) {
    if (!this.rootStore.auth.isFeatureEnabled(FEATURE.MULTIPLE_DASHBOARDS)) return;

    return this.dashboards.find((d) => (dashboardId ? d.id === dashboardId : d.filter.isMain));
  }

  sort() {
    return this.dashboards.sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  async fetch() {
    if (!this.rootStore.auth.isFeatureEnabled(FEATURE.MULTIPLE_DASHBOARDS)) return;

    try {
      this.dashboards = (await this.rootStore.filter.getSavedFilters(
        FILTER_PAGES.DASHBOARD,
      )) as MultipleDashboardSavedFilter[];

      this.sort();
    } catch {}
  }

  mapDashboardSettings(settings: WidgetsSettings[]) {
    return settings
      .filter((s) => !s.disabled)
      .map((s) => ({
        id: s.id,
        order: s.order,
      }));
  }

  async createDashboard(name: string, duplicate?: boolean) {
    if (!this.rootStore.auth.isFeatureEnabled(FEATURE.MULTIPLE_DASHBOARDS)) return;

    let settings;

    if (duplicate) {
      if (
        !this.rootStore.widgets.currentWidgetFilter ||
        this.rootStore.widgets.currentWidgetFilter?.type.trim() === FILTER_PAGES.WIDGET_SETTINGS
      ) {
        const defaultWidgetFilter = (await this.rootStore.filter.getSavedFilters(FILTER_PAGES.WIDGET_SETTINGS))?.[0];
        settings = (defaultWidgetFilter?.filter as SavedWidgetSettings).settings;
      } else {
        const dashboards = await this.rootStore.filter.getSavedFilters(FILTER_PAGES.DASHBOARD);
        const dashboard = dashboards.find((d) => d.id === this.rootStore.widgets.currentWidgetFilter?.id);

        settings = (dashboard?.filter as SavedWidgetSettings).settings;
      }
    }

    const dashboard = (await this.rootStore.filter.saveFilter(
      {
        name,
        type: FILTER_PAGES.DASHBOARD,
        filter: { isMain: !this.dashboards.length, settings: this.mapDashboardSettings(settings || []) },
      },
      true,
    )) as MultipleDashboardSavedFilter;

    this.dashboards.push(dashboard);
    this.sort();

    return dashboard.id;
  }

  async makeMain(id: string, deleteOld?: boolean) {
    const oldMain = this.dashboards.find((d) => d.filter.isMain) as MultipleDashboardSavedFilter;

    this.dashboards = this.dashboards.map((d) => ({ ...d, filter: { ...d.filter, isMain: d.id === id } }));

    const newMain = this.dashboards.find((d) => d.filter.isMain) as MultipleDashboardSavedFilter;

    return Promise.all([
      !deleteOld &&
        this.rootStore.filter.saveFilter(
          {
            ...oldMain,
            filter: { ...oldMain.filter, isMain: false },
          },
          true,
        ),
      this.rootStore.filter.saveFilter(
        {
          ...newMain,
          filter: { ...newMain.filter, isMain: true },
        },
        true,
      ),
    ]);
  }

  async changeName(id: string, name: string) {
    this.dashboards = this.dashboards.map((d) => {
      if (d.id !== id) return d;

      return { ...d, name };
    });

    const dashboard = this.dashboards.find((d) => d.id === id) as MultipleDashboardSavedFilter;

    await this.rootStore.filter.saveFilter(
      {
        ...dashboard,
        name,
      },
      true,
    );

    this.sort();
  }

  async changeWidgets(id: string, settings: DashboardWidgetsSettings[]) {
    const dashboard = this.dashboards.find((d) => d.id === id) as MultipleDashboardSavedFilter;

    const filter = await this.rootStore.filter.saveFilter(
      {
        ...dashboard,
        filter: { ...dashboard.filter, settings },
      },
      true,
    );

    await this.fetch();

    return filter;
  }

  async remove(id: string) {
    if (!this.rootStore.auth.isFeatureEnabled(FEATURE.MULTIPLE_DASHBOARDS)) return;

    const dashboard = this.dashboards.find((d) => d.id === id);

    if (dashboard?.filter.isMain) {
      const notMainDashboard = this.dashboards.find((d) => d.id !== id);

      if (notMainDashboard) {
        await this.makeMain(notMainDashboard.id, true);
      }
    }

    await this.rootStore.filter.removeFilterAction(id);

    this.dashboards = this.dashboards.filter((d) => d.id !== id);
  }
}
