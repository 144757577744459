import { makeAutoObservable } from "mobx";

import { RootStore } from "store/root.store";
import {
  addNotificationChannel,
  getNotificationChannels,
  removeNotificationChannel,
  updateNotificationChannel,
} from "libs/api/methods";
import { MESSAGE_VARIANT, NotificationChannel } from "store/types";

export class NotificationsStore {
  isError = false;
  isFetched = false;
  channels: NotificationChannel[] = [];

  constructor(readonly rootStore: RootStore) {
    this.rootStore = rootStore;

    this.fetch = this.fetch.bind(this);

    makeAutoObservable(this);
  }

  async fetch() {
    try {
      this.channels = await getNotificationChannels(this.isFetched);
      this.isFetched = true;
    } catch {
      this.isError = true;
    }
  }

  async upsertNotificationChannel({ id, ...data }: Partial<NotificationChannel>, messageText?: string) {
    this.rootStore.ui.setShowModalLoading(true);

    try {
      if (id) {
        await updateNotificationChannel(id, data);
      } else {
        await addNotificationChannel(data);
      }

      await this.fetch();

      if (messageText) {
        this.rootStore.ui.showMessage({ variant: MESSAGE_VARIANT.SUCCESS, title: messageText });
      }

      this.rootStore.ui.closeModal();
    } catch {}

    this.rootStore.ui.setShowModalLoading(false);
  }

  async removeNotificationChannel(id: string) {
    await removeNotificationChannel(id);

    this.channels = this.channels.filter((u) => u.id !== id);
  }
}
