// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const without = (obj: Record<string, any>, skip: string[] = []) =>
  Object.entries(obj).reduce((acc, [k, v]) => ({ ...acc, ...(!skip.includes(k) && { [k]: v }) }), {});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const pick = (obj: Record<string, any>, take: string[] = []) =>
  Object.entries(obj).reduce((acc, [k, v]) => ({ ...acc, ...(take.includes(k) && { [k]: v }) }), {});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEqual = (a: any, b: any) => JSON.stringify(a) === JSON.stringify(b);

export const clone = <K>(o: K) => JSON.parse(JSON.stringify(o));

export const getRandomElementFromArray = <K>(arr: K[]): K => arr[Math.floor(Math.random() * arr.length)] || arr[0];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isPrimitiveValue = (value: any) => value !== Object(value);

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const isAndroid = () => navigator.userAgent.match(/Android/i);

export const isBlackBerry = () => navigator.userAgent.match(/BlackBerry/i);

export const isiOS = () => navigator.userAgent.match(/iPhone|iPad|iPod/i);

export const isOpera = () => navigator.userAgent.match(/Opera Mini/i);

export const isWindows = () => navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);

export const isMobile = () => isAndroid() || isBlackBerry() || isiOS() || isOpera() || isWindows();
