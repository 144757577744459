import React, { SyntheticEvent } from "react";
import { Snackbar, Alert, AlertColor, AlertTitle, useTheme, useMediaQuery } from "@mui/material";

import { Message, MESSAGE_VARIANT } from "store/types";
import { ErrorSvg, InfoSvg, SuccessSvg, WarningSvg } from "components/ui/Toaster/components";

type ToasterProps = {
  message: Message;
  onClose: () => void;
};

export const Toaster = ({ message: { duration, title, message, variant }, onClose }: ToasterProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  let severity: AlertColor;

  switch (variant) {
    case MESSAGE_VARIANT.ERROR:
      severity = "error";
      break;

    case MESSAGE_VARIANT.SUCCESS:
      severity = "success";
      break;

    case MESSAGE_VARIANT.WARNING:
      severity = "warning";
      break;

    default:
      severity = "info";
      break;
  }

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") return;

    onClose();
  };

  return (
    <Snackbar
      sx={{ minWidth: { xs: "none", sm: 450 }, marginTop: { xs: 20, laptop: 0 }, zIndex: 2000 }}
      data-testid="toaster"
      autoHideDuration={duration}
      open
      onClick={(e) => e.stopPropagation()}
      onClose={handleClose}
      anchorOrigin={isMobile ? { vertical: "top", horizontal: "center" } : { vertical: "bottom", horizontal: "right" }}
    >
      <Alert
        sx={{
          "& .MuiAlert-icon": {
            marginRight: 2,
            svg: {
              width: 40,
            },
          },
          "& .MuiAlert-action": {
            display: { xs: "none", laptop: "inherit" },
            marginTop: -1,
            marginRight: -1.5,
            marginLeft: 1,
          },
          "& .MuiAlert-message": { display: "flex", justifyContent: "center", flexDirection: "column" },
          padding: 3,
          maxWidth: 600,
        }}
        onClose={onClose}
        severity={severity}
        iconMapping={{
          error: <ErrorSvg />,
          warning: <WarningSvg />,
          info: <InfoSvg />,
          success: <SuccessSvg />,
        }}
      >
        {!!title && <AlertTitle sx={{ marginBottom: message ? 1 : 0 }}>{title}</AlertTitle>}
        {message || ""}
      </Alert>
    </Snackbar>
  );
};
