import React from "react";
import { Typography, Dialog, Box, Paper, useTheme, useMediaQuery } from "@mui/material";
import { Close } from "@mui/icons-material";

import { UIDialog } from "store/types";
import { ModalLoading } from "components/ui/ModalLoading/modalLoading";

export const Modal = ({
  title,
  text,
  component,
  children,
  onConfirm, // eslint-disable-line @typescript-eslint/no-unused-vars
  confirmText, // eslint-disable-line @typescript-eslint/no-unused-vars
  closeText, // eslint-disable-line @typescript-eslint/no-unused-vars
  onCloseButtonClick, // eslint-disable-line @typescript-eslint/no-unused-vars
  minWidth,
  showLoading,
  forbidFullScreen,
  ...props
}: UIDialog & { showLoading?: boolean }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));
  const fullScreen = isMobile && !forbidFullScreen;

  return (
    <Dialog
      {...props}
      fullScreen={fullScreen}
      PaperProps={{
        style: {
          borderRadius: fullScreen ? 0 : 10,
          ...(isMobile &&
            forbidFullScreen && { margin: 16, maxHeight: "calc(100% - 32px)", width: "calc(100% - 32px)" }),
        },
      }}
      componentsProps={{ backdrop: { style: { backgroundColor: "rgba(82, 81, 113, 0.9)" } } }}
    >
      <Box
        bgcolor="background.modalBG"
        data-testid="modal"
        component={Paper}
        padding={{ xs: 3, laptop: 5 }}
        minWidth={minWidth && !fullScreen ? theme.breakpoints.values[minWidth] : "auto"}
        sx={{ ...(fullScreen && { boxShadow: "none" }) }}
      >
        {!!title && (
          <Box marginBottom={4}>
            <Box
              marginBottom={text ? 2 : 3}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Typography variant="h2">{title}</Typography>
              <Close onClick={props.onClose} sx={{ cursor: "pointer" }} />
            </Box>

            {!!text && <Typography variant="table">{text}</Typography>}
          </Box>
        )}

        <Box position="relative">
          {!!showLoading && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              zIndex={10}
              bgcolor="#fff"
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
            >
              <ModalLoading />
            </Box>
          )}

          <Typography variant="main">{component || children}</Typography>
        </Box>
      </Box>
    </Dialog>
  );
};
