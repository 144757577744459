import React, { useEffect, useState } from "react";

import { CLOUD_PLATFORM } from "store/types";
import { StepsProgress } from "components/ui";
import { INITIALIZING_STEP } from "consts";
import { ORG_ONBOARDING_STEPS } from "components/modals/AddOrganizationModal/components/consts";

type AddAccountProgressListProps = {
  currentStep: number;
  provider?: CLOUD_PLATFORM;
  stepId?: string;
};

const gspSteps = [
  [INITIALIZING_STEP],
  [ORG_ONBOARDING_STEPS.DEPLOYMENT, ORG_ONBOARDING_STEPS.ONBOARDING, ORG_ONBOARDING_STEPS.ONBOARDING],
];

const awsSteps = [
  [INITIALIZING_STEP],
  [ORG_ONBOARDING_STEPS.DEPLOYMENT],
  [ORG_ONBOARDING_STEPS.PERMISSIONS],
  [ORG_ONBOARDING_STEPS.ONBOARDING],
];

const AddOrganizationProgressList = ({ currentStep, stepId, provider }: AddAccountProgressListProps) => {
  const [items, setItems] = useState<
    Array<{
      title: string;
      subItems?: string[];
    }>
  >([]);

  useEffect(() => {
    setItems([
      {
        title: "Getting Started",
      },
      {
        title: "Connect Organization",
        subItems:
          provider === CLOUD_PLATFORM.AWS
            ? [
                "Launch CloudFormation Stack",
                "CloudFormation Stack Deployment",
                "Needed Permissions Granted",
                "Individual account onboarding started",
              ]
            : ["Authorize OpsHelm", "Configure Log Sink"],
      },
    ]);
  }, [provider]);

  return (
    <StepsProgress
      items={items}
      currentStep={currentStep}
      stepId={stepId}
      steps={provider === CLOUD_PLATFORM.AWS ? awsSteps : gspSteps}
    />
  );
};

export default AddOrganizationProgressList;
