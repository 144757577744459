import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { inject, observer } from "mobx-react";
import { Add } from "@mui/icons-material";

import { TableFields } from "components/ui/Table/table";
import { DATA_STATS, DataStatsItem } from "store/types";
import { RootStore } from "store/root.store";
import { Button, Select, Input, MultiSelect } from "components/ui";
import { FilterTrashSvg } from "components/icons";

type DataStatsProps = {
  store?: RootStore;
  fields?: TableFields[];
  title?: string;
  setCurrentItems?: (val: DataStatsItem[]) => void;
  currentItems?: DataStatsItem[];
};

const DataStats = ({ setCurrentItems, currentItems, store, fields, title }: DataStatsProps) => {
  const theme = useTheme();

  const [items, setItems] = useState<DataStatsItem[]>([]);

  useEffect(() => {
    setItems(currentItems || [store!.filter.getDefaultDataStatsItem()]);
  }, [store, currentItems]);

  const setItemsAction = (data: DataStatsItem[]) => {
    setItems(data);
    setCurrentItems?.(data);
  };

  const setItemsHandler = (index: number, field: string, value: string) =>
    setItemsAction([
      ...items.slice(0, index),
      {
        ...items[index],
        [field]: value,
      },
      ...items.slice(index + 1),
    ]);

  const removeElement = (index: number) => {
    if (items.length === 1) {
      setItemsAction([store!.filter.getDefaultDataStatsItem()]);

      return;
    }

    setItemsAction([...items.slice(0, index), ...items.slice(index + 1)]);
  };

  const addElement = () => {
    setItemsAction([...items, store!.filter.getDefaultDataStatsItem()]);
  };

  return (
    <Box display="flex" gap={2} flexDirection="column">
      {!!title && (
        <Typography marginBottom={2} variant="main" fontWeight={700} fontSize={16}>
          {title}
        </Typography>
      )}

      {items.map((item, index) => (
        <Box
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          padding={2}
          borderRadius={1}
          bgcolor="background.default"
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={2}
          flexWrap={{ xs: "wrap", laptopLg: "nowrap" }}
        >
          <Typography>=</Typography>
          <Box width={0.5} flexGrow={1}>
            {fields?.length ? (
              <MultiSelect
                fullWidth
                placeholder="Select field"
                list={fields.map(({ name, property }) => ({ id: property, name: name as string }))}
                value={item.field}
                onChange={(val) => setItemsHandler(index, "field", val)}
              />
            ) : (
              <Input
                placeholder="Report field"
                value={item.field}
                onChange={(val) => setItemsHandler(index, "field", val)}
              />
            )}
          </Box>
          <Box flexShrink={0}>
            <Select
              inTable
              darkBg
              withBorder
              list={Object.entries(DATA_STATS).map(([name, property]) => ({ id: property, name }))}
              value={item.operator}
              onChange={(val: string) => setItemsHandler(index, "operator", val)}
            />
          </Box>
          <Box width={0.5} flexGrow={1}>
            <Input
              placeholder="Display Name"
              value={item.name}
              onChange={(val) => setItemsHandler(index, "name", val)}
            />
          </Box>
          <Button
            buttonSx={{
              svg: { path: { fill: theme.palette.mode === "light" ? "#2F3259" : "#198eef" } },
            }}
            inTable
            smallWidth
            customIcon={<FilterTrashSvg />}
            variant="outlined"
            onlyIcon
            disabled={items.length === 1 && !item.field && !item.name}
            onClick={() => removeElement(index)}
          />
          {index === items.length - 1 && <Button smallWidth inTable icon={Add} onlyIcon onClick={addElement} />}
        </Box>
      ))}
    </Box>
  );
};

export default inject("store")(observer(DataStats));
