import { api } from "libs";
import { AddApiKey, ApiKey, UpdateApiKey } from "store/types";
import { API_KEY, API_KEY_BY_ID, API_KEY_ROTATE } from "libs/api/endpoints";

export const fetchApiKeys = async (): Promise<ApiKey[]> =>
  api.get(API_KEY, {
    errorMessage: {
      title: "Failed to fetch Api Keys",
    },
  });

export const addApiKey = async (
  data: AddApiKey,
): Promise<{
  key: string;
  details: ApiKey;
}> =>
  api.post(API_KEY, data, {
    errorMessage: {
      title: "Failed to generate Api Key",
    },
  });

export const updateApiKey = (key: UpdateApiKey) =>
  api.put(API_KEY_BY_ID(key.id), key, {
    errorMessage: {
      title: "Failed to update the Api Key",
    },
  });

export const rotateApiKey = (key: UpdateApiKey) =>
  api.put(API_KEY_ROTATE(key.id), key, {
    errorMessage: {
      title: "Failed to rotate the Api Key",
    },
  });

export const removeApiKey = (key: ApiKey) =>
  api.delete(API_KEY_BY_ID(key.id), {
    errorMessage: {
      title: "Failed to remove the Api Key",
    },
  });
