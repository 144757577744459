import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { inject, observer } from "mobx-react";
import { Remove, Check } from "@mui/icons-material";

import { FilterDataItem, SavedFilter } from "store/types";
import { RootStore } from "store/root.store";
import { TableActions } from "components/ui/Table/components";
import { AddWidgetModal } from "components/modals";
import { WidgetSvg } from "components/icons";
import { isFilterWithDataAsset } from "tools";

type SavedFiltersProps = {
  store?: RootStore;
  useSavedFilter: (savedFilter: SavedFilter) => void;
  setSavedFilters: (savedFilters: SavedFilter[]) => void;
  savedFilters: SavedFilter[];
};

const SavedFilters = ({ store, savedFilters, setSavedFilters, useSavedFilter }: SavedFiltersProps) => {
  const theme = useTheme();

  if (!savedFilters.length) {
    return <Typography variant="main">You haven’t saved any filters yet</Typography>;
  }

  const removeFilter = async (id: string) => {
    await store!.filter.removeFilter(id);

    setSavedFilters(savedFilters.filter((c) => c.id !== id));
  };

  const actions = [
    {
      key: "use",
      onClick: useSavedFilter,
      getButtonProps: () => ({ icon: Check, text: "Use" }),
    },
    {
      key: "remove",
      onClick: (savedFilter: SavedFilter) =>
        store?.ui.openConfirmModal({
          title: "Are you sure you want to remove this filter?",
          confirmText: "Remove",
          onConfirm: () => removeFilter(savedFilter.id),
        }),
      getButtonProps: () => ({ icon: Remove, text: "Remove", variant: "text" }),
    },
    {
      key: "widget",
      onClick: (savedFilter: SavedFilter) =>
        store?.ui.openModal({
          title: "Create widget",
          component: (
            <AddWidgetModal
              widget={{
                isDataAsset: isFilterWithDataAsset(savedFilter?.filter as FilterDataItem),
                filterId: savedFilter.id,
                type: savedFilter.type,
              }}
            />
          ),
        }),
      getButtonProps: () => ({ customIcon: <WidgetSvg />, text: "Create widget", variant: "text" }),
    },
  ];

  return (
    <Box display="flex" gap={4} flexDirection={{ xs: "column", laptop: "row" }} flexWrap="wrap">
      {savedFilters.map((filter) => (
        <Box
          width={{ sx: 1, laptop: `calc(50% - ${theme.spacing(2)})` }}
          onClick={() => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useSavedFilter(filter);
          }}
          bgcolor="background.tableRow"
          borderRadius={1}
          key={filter.id}
          paddingY={2}
          paddingLeft={{ xs: 3, laptop: 5 }}
          paddingRight={{ xs: 2, laptop: 4 }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            cursor: "pointer",
            "&:hover": {
              borderColor: "background.infoItemTableRow",
              backgroundColor: "background.infoItemTableRow",
            },
          }}
        >
          <Typography variant="main">{filter.name}</Typography>

          <Box marginRight={-1} onClick={(e) => e.stopPropagation()}>
            <TableActions notInTable item={filter} actions={actions} />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default inject("store")(observer(SavedFilters));
