import React, { ElementType, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Box, Divider } from "@mui/material";

import { RootStore } from "store/root.store";
import { FILTER_PAGES, FilterData, FilterDataItem, FilterEntity, FilterItem, SavedFilter } from "store/types";
import { ButtonGroup, Loading, TableFields } from "components/ui";
import { getDatesFromSavedFilter } from "tools";

import { FilterActions, FilterBlock, SavedFilters } from "./components";

type FilterProps = {
  store?: RootStore;
  type: FILTER_PAGES;
  fields?: TableFields[];
  currentFilter: FilterDataItem;
  setCurrentFilter: (filter: FilterDataItem, savedFilterName?: string) => void;
  entities?: FilterEntity;
  onlyAnd?: boolean;
  fieldOnlyOnce?: boolean;
  maxNestedElements?: number;
  selectedFilterName?: string;
  setSelectedFilterName?: (val: string) => void;
  fetchDataOnOpen?: () => Promise<void>;
  customElement?: {
    component: ElementType;
    data?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  };
  nested?: boolean;
  clearCallback?: () => void;
};

enum FILTER_BUTTON_GROUPS {
  CREATE = "CREATE",
  SAVED = "SAVED",
}

const buttonGroupItems = [
  {
    value: FILTER_BUTTON_GROUPS.CREATE,
    component: "Create",
  },
  {
    value: FILTER_BUTTON_GROUPS.SAVED,
    component: "Use saved",
  },
];

const Filter = ({
  store,
  type,
  fields = [],
  currentFilter,
  setCurrentFilter,
  selectedFilterName,
  setSelectedFilterName,
  onlyAnd,
  fetchDataOnOpen,
  fieldOnlyOnce,
  maxNestedElements,
  entities,
  customElement,
  nested,
  clearCallback,
}: FilterProps) => {
  const [loading, setLoading] = useState(!!fetchDataOnOpen);
  const [savedFilters, setSavedFilters] = useState<SavedFilter[]>([]);
  const [filterType, setFilterType] = useState(buttonGroupItems[0].value as string);
  const [filterName, setFilterName] = useState(selectedFilterName || "");
  const [saveFilter, setSaveFilter] = useState(false);
  const [filter, setFilter] = useState<FilterDataItem>(currentFilter);

  useEffect(() => {
    setFilter(currentFilter);
  }, [currentFilter]);

  const filterConditions = store!.filter.getFilterConditions(type);

  useEffect(() => {
    if (nested) return;

    store?.filter.getSavedFilters(type).then(setSavedFilters);
  }, [store?.filter, type, nested]);

  useEffect(() => {
    if (!nested) return;

    setCurrentFilter(filter);
  }, [nested, filter, setCurrentFilter]);

  useEffect(() => {
    if (!fetchDataOnOpen) return;

    setLoading(true);

    fetchDataOnOpen().then(() => setLoading(false));
  }, [fetchDataOnOpen]);

  const useSavedFilter = (savedFilter: SavedFilter) => {
    const filterForSet: FilterDataItem = {
      ...(savedFilter.filter as FilterDataItem),
      type: (savedFilter.type || (savedFilter.filter as FilterData).type).trim() as FILTER_PAGES,
      dates: getDatesFromSavedFilter(savedFilter, type),
      items: (savedFilter.filter as FilterData).items?.filter(
        (s) => type !== FILTER_PAGES.INVENTORY || (s as FilterItem).field !== "dates",
      ),
    };

    setCurrentFilter(filterForSet, savedFilter.name);
    setFilter(filterForSet);
    setSelectedFilterName?.(savedFilter.name);
    setFilterName(savedFilter.name);
    setSaveFilter(false);
  };

  const clear = () => {
    setFilter(store!.filter.getDefaultFilter(type));
    setCurrentFilter(store!.filter.getDefaultFilter(type));
    setSelectedFilterName?.("");
    setFilterName("");
    setSaveFilter(false);
    clearCallback?.();
  };

  if (loading) {
    return (
      <Box display="flex" gap={5} flexDirection="column">
        {!nested && <Divider />}

        <Loading marginTop={5} marginBottom={5} />
      </Box>
    );
  }

  return (
    <Box display="flex" gap={5} flexDirection="column">
      {!nested && <Divider />}

      {!nested && <ButtonGroup exclusive items={buttonGroupItems} value={filterType} onChange={setFilterType} />}

      {filterType === FILTER_BUTTON_GROUPS.CREATE && (
        <>
          {customElement ? (
            <customElement.component {...customElement.data} filter={filter} setFilter={setFilter} />
          ) : (
            <FilterBlock
              filterConditions={filterConditions}
              changeFilter={setFilter}
              removeFilterElement={setFilter}
              fields={fields}
              parentFilter={filter as FilterData}
              filterElement={filter}
              type={type}
              filter={filter as FilterData}
              entities={entities}
              onlyAnd={onlyAnd}
              fieldOnlyOnce={fieldOnlyOnce}
              maxNestedElements={maxNestedElements}
            />
          )}

          {!nested && (
            <FilterActions
              filterName={filterName}
              setFilterName={setFilterName}
              saveFilter={saveFilter}
              setSaveFilter={setSaveFilter}
              clear={clear}
              type={type}
              filter={filter}
              setCurrentFilter={setCurrentFilter}
              selectedFilterName={selectedFilterName}
              setSelectedFilterName={setSelectedFilterName}
              savedFilters={savedFilters}
              setSavedFilters={setSavedFilters}
            />
          )}
        </>
      )}

      {filterType === FILTER_BUTTON_GROUPS.SAVED && (
        <SavedFilters savedFilters={savedFilters} useSavedFilter={useSavedFilter} setSavedFilters={setSavedFilters} />
      )}
    </Box>
  );
};

export default inject("store")(observer(Filter));
