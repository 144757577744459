import React from "react";
import { inject, observer } from "mobx-react";
import { Box, Typography, useTheme } from "@mui/material";

import { getIconTypeByResource } from "tools";
import { ProviderServiceIcon } from "components/ui/ProviderServiceTypeIcon/components/providerServiceIcon";

import DashboardHistoricalStats, {
  DashboardHistoricalStatsData,
} from "../DashboardHistoricalStats/dashboardHistoricalStats";

type DashboardHistoricalItemProps = {
  type: string;
  stats: DashboardHistoricalStatsData;
};

const DashboardHistoricalItem = ({ type, stats }: DashboardHistoricalItemProps) => {
  const theme = useTheme();

  return (
    <Box
      width={1}
      display="flex"
      flexDirection="column"
      padding={5}
      borderRadius={2}
      gap={2}
      bgcolor={theme.palette.mode === "light" ? "#fbfafe" : "background.default"}
    >
      <Box display="flex" justifyContent="flex-end">
        <DashboardHistoricalStats stats={stats} smallView />
      </Box>
      <Box display="flex" alignItems="center" gap={3}>
        <Box
          bgcolor="#e6ebf9"
          padding={2}
          borderRadius={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            svg: {
              width: 30,
              height: 30,
            },
          }}
        >
          <ProviderServiceIcon withoutDefaultStyles type={getIconTypeByResource(type)} />
        </Box>

        <Typography variant="h3">{type}</Typography>
      </Box>
    </Box>
  );
};

export default inject("store")(observer(DashboardHistoricalItem));
