import React from "react";
import { inject, observer } from "mobx-react";
import { Box } from "@mui/material";

import { RootStore } from "store/root.store";
import { ProviderIcon, AdditionalInfoGroupContent } from "components/ui";
import { DashboardIssue } from "store/types";
import { getDashboardFields } from "routes/DashboardRoute/dashboard.utils";

// eslint-disable-next-line max-len
import { DashboardIssueRemediationProgress } from "../DashboardIssueRemediationProgress/dashboardIssueRemediationProgress";

type DashboardIssueAdditionalInfoProps = {
  store?: RootStore;
  issue?: DashboardIssue;
  groupedBy?: string[];
};

const DashboardIssueAdditionalInfo = ({ store, issue, groupedBy }: DashboardIssueAdditionalInfoProps) => {
  const item = issue || store?.ui.infoItem?.activeItem;

  const fields = store?.dashboard.infoItemIssue?.resolved
    ? getDashboardFields({ resolved: true, withActions: true })
    : store?.dashboard.infoItemIssue?.justified
    ? getDashboardFields({ justified: true, withActions: true })
    : getDashboardFields({ withActions: true });

  return (
    <>
      <Box marginBottom={3}>
        <ProviderIcon variant="h3" groupedOfCount={item?.grouped?.length} name={item?.name} provider={item?.provider} />
      </Box>

      <AdditionalInfoGroupContent
        groupComponent={DashboardIssueRemediationProgress}
        item={item}
        fields={fields}
        groupedBy={groupedBy}
      />
    </>
  );
};

export default inject("store")(observer(DashboardIssueAdditionalInfo));
