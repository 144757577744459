import React from "react";
import { inject, observer } from "mobx-react";
import { Box, Typography } from "@mui/material";

import { TableFields, TableItemProps } from "components/ui";
import { getFieldValue } from "tools";

type TableWithPaddingProps = {
  data: TableItemProps[];
  fields: TableFields[];
};

const TableWithPadding = ({ data, fields }: TableWithPaddingProps) => (
  <Box display="flex" flexDirection="column" gap={2.5}>
    <Box display="flex">
      {fields.map((field, index) => (
        <Typography
          component="div"
          variant="main"
          fontWeight={700}
          marginBottom={1}
          paddingLeft={index ? 3 : 0}
          sx={{
            ...(field.width && { width: field.width }),
          }}
        >
          {field.name}
        </Typography>
      ))}
    </Box>
    {data.map((item, index) => (
      <Box
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        paddingY={4}
        display="flex"
        alignItems="center"
        bgcolor="background.tableRow"
        borderRadius={2}
      >
        {fields.map((field) => (
          <Box
            paddingX={3}
            flexShrink={field.width ? 0 : 1}
            key={field.property}
            sx={{
              ...(field.width && { width: field.width }),
              ...(field.breakWordsLine && { wordBreak: "break-word" }),
            }}
          >
            {getFieldValue({ field, item, inTable: true })}
          </Box>
        ))}
      </Box>
    ))}
  </Box>
);

export default inject("store")(observer(TableWithPadding));
