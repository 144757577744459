import { ReactElement } from "react";
import { Box, Typography } from "@mui/material";

export type InfoLabelProps = {
  title: string;
  value: ReactElement | string;
  inColumn?: boolean;
};

export const InfoLabel = ({ title, value, inColumn }: InfoLabelProps) => (
  <Typography
    data-testid="infoLabel"
    bgcolor="background.tableRow"
    variant="table"
    paddingY={3}
    paddingX={4}
    display="flex"
    borderRadius={1}
    alignItems={inColumn ? "flex-start" : "center"}
    flexDirection={inColumn ? "column" : "row"}
    gap={inColumn ? 2 : 3}
  >
    {!!title && <Box fontWeight={700}>{title}</Box>}
    {!!value && <Box>{value}</Box>}
  </Typography>
);
