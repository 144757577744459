import React from "react";
import { Box, Typography } from "@mui/material";

export enum SEVERITY {
  CRITICAL = "Critical",
  HIGH = "High",
  MEDIUM = "Medium",
  LOW = "Low",
}

type SeverityProps = {
  severity?: SEVERITY;
  score?: number;
  smallView?: boolean;
  center?: boolean;
};

const getText = (severity?: SEVERITY) => {
  const value = Object.values(SEVERITY).find((s) => s.toLowerCase() === severity?.toLowerCase());

  return value || SEVERITY.CRITICAL;
};

const getBgColor = (severity: SEVERITY, line: number) => {
  switch (true) {
    case severity === SEVERITY.CRITICAL:
      return "error.dark";

    case line === 0:
      return "pending.light";

    case severity === SEVERITY.HIGH:
      return "error.main";

    case line === 1:
      return "pending.light";

    case severity === SEVERITY.MEDIUM:
      return "warning.main";

    case line === 2:
      return "pending.light";

    default:
      return "success.main";
  }
};

export const Severity = ({ severity, score, smallView, center }: SeverityProps) => {
  const lines = new Array(4).fill(0).map((_, index) => index);
  const text = getText(severity);

  return (
    <Box data-testid="severity" display="flex" alignItems="baseline" justifyContent={center ? "center" : "flex-start"}>
      <Box display="flex" alignItems="flex-end">
        {lines.map((l) => (
          <Box
            key={l}
            bgcolor={getBgColor(text, l)}
            marginRight="2px"
            borderRadius="1.5px"
            width={3}
            height={(lines.length - l) * 5}
            sx={{
              "&:last-child": {
                marginRight: 0,
              },
            }}
          />
        ))}
      </Box>
      {(!smallView || !!score) && (
        <Typography variant="main" marginLeft={2}>
          {!smallView && text}
          {score ? ` (${score})` : ""}
        </Typography>
      )}
    </Box>
  );
};
