export enum PROVIDER_ICON_BY_SERVICE_TYPE {
  NOT_FOUND,
  AWS_DEFAULT,
  AWS_KEY,
  AWS_ROLE,
  AWS_LAMBDA,
  AWS_ECR,
  AWS_ECS,
  AWS_CACHE,
  AWS_S3_BUCKET,
  AWS_SSM,
  AWS_LAUNCH_TEMPLATE,
  AWS_CLOUDWATCH,
  AWS_VPC,
  AWS_GLUE,
  AWS_CLOUDFRONT,
  AWS_POLICY,
  AWS_SUBNET,
  AWS_NETWORK,
  AWS_VOLUME,
  AWS_SNAPSHOT,
  AWS_EIP,
  AWS_AMI,
  AWS_INSTANCE,
  AWS_ROUTE,
  AWS_USER,
  AWS_CLUSTER,
  AWS_GROUP,
  AWS_CREDENTIAL,
  AWS_CONFIG,
  AWS_FILE,
  AWS_SECRET,
  AWS_LOGS,
  AWS_TABLE,
  AWS_EVENTS,
  AWS_REGION,
  AWS_VPC_ENDPOINT,
  GCP_DEFAULT,
  GCP_SUBNETWORK,
  GCP_ROUTE,
  GRP_NETWORK,
  GCP_LOG,
  GCP_BUCKET,
  GCP_INSTANCE,
  GCP_SERVICE,
  GCP_TAG,
  GCP_FIREWALL,
  GCP_ADDRESS,
  GCP_DISK,
  GCP_PROJECT,
  GCP_HEALTH_CHECK,
  GCP_KEY,
  GCP_POLICY,
  GCP_TOPIC,
  GCP_NOTIFICATION_CHANNEL,
  GCP_KUBERNETES,
  GCP_TABLE,
  GCP_SECRET,
  GCP_ENDPOINT,
  GCP_CRON,
  GCP_BACKUP,
  GCP_DEPLOY,
  GCP_FUNCTION,
  GCP_CONFIGURATION,
  GCP_PROXY,
  GCP_TEMPLATE,
  GCP_APP,
  GCP_RULE,
  GCP_REPORT,
  GCP_SUBSCRIPTION,
}

export const getIconTypeByResource = (resource: string): PROVIDER_ICON_BY_SERVICE_TYPE => {
  switch (resource) {
    case "AWS::IAM::AccessKey":
    case "AWS::EC2::KeyPair":
    case "AWS::KMS::Key":
    case "AWS::SecretsManager::Secret":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_KEY;
    case "AWS::IAM::Role":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_ROLE;
    case "AWS::Lambda::Function":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_LAMBDA;
    case "AWS::S3::Bucket":
    case "s3_bucket":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_S3_BUCKET;
    case "AWS::EC2::LaunchTemplateVersion":
    case "AWS::EC2::LaunchTemplate":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_LAUNCH_TEMPLATE;
    case "AWS::EC2::VPC":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_VPC;
    case "AWS::IAM::Policy":
    case "AWS::IAM::ManagedPolicy":
    case "AWS::IAM::AccountPasswordPolicy":
    case "AWS::KMS::Alias":
    case "AWS::ECS::Task":
    case "AWS::ECS::TaskDefinition":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_POLICY;
    case "AWS::EC2::Subnet":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_SUBNET;
    case "AWS::EC2::NetworkInterface":
    case "AWS::EC2::NetworkAcl":
    case "AWS::EC2::InternetGateway":
    case "AWS::EC2::EgressOnlyInternetGateway":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_NETWORK;
    case "AWS::EC2::Volume":
    case "ebs_volume":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_VOLUME;
    case "AWS::EC2::Snapshot":
    case "AWS::RDS::DBSnapshot":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_SNAPSHOT;
    case "AWS::Logs::LogGroup":
    case "AWS::CloudTrail::Trail":
    case "AWS::CloudFormation::Stack":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_LOGS;
    case "AWS::DynamoDB::Table":
    case "AWS::RDS::DBInstance":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_TABLE;
    case "AWS::Events::Rule":
    case "AWS::Events::EventBus":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_EVENTS;
    case "AWS::EC2::EIP":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_EIP;
    case "AWS::EC2::AMI":
    case "AWS::AppRunner::ObservabilityConfiguration":
    case "AWS::AppRunner::AutoScalingConfiguration":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_AMI;
    case "AWS::EC2::Instance":
    case "ec2_instance":
    case "AWS::ElasticLoadBalancingV2::TargetGroup":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_INSTANCE;
    case "AWS::EC2::RouteTable":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_ROUTE;
    case "AWS::IAM::User":
    case "OH::IAM::RootUser":
    case "iam_user":
    case "AWS::Organizations::Account":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_USER;
    case "AWS::EC2::SecurityGroup":
    case "AWS::RDS::DBSecurityGroup":
    case "AWS::IAM::Group":
    case "AWS::RDS::DBParameterGroup":
    case "AWS::Athena::WorkGroup":
    case "security_group":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_GROUP;
    case "AWS::ECS::Cluster":
    case "AWS::RDS::DBCluster":
    case "AWS::RDS::DBClusterSnapshot":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_CLUSTER;
    case "AWS::ECS::Secret":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_SECRET;
    case "AWS::EC2::Region":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_REGION;
    case "AWS::IAM::Credential":
    case "AWS::IAM::CredentialReport":
    case "AWS::CertificateManager::Certificate":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_CREDENTIAL;
    case "AWS::Region:Config":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_CONFIG;
    case "AWS::EFS::FileSystem":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_FILE;
    case "AWS::EC2::VPCEndpoint":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_VPC_ENDPOINT;
    case "AWS::CloudWatch::Alarm":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_CLOUDWATCH;
    case "AWS::CloudFront::Distribution":
    case "AWS::Kinesis::Stream":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_CLOUDFRONT;
    case "AWS::ECR::Image":
    case "AWS::ECR::Repository":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_ECR;
    case "AWS::ECS::Service":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_ECS;
    case "AWS::Glue::Catalog":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_GLUE;
    case "AWS::ElastiCache::CacheCluster":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_CACHE;
    case "AWS::SSM::Document":
      return PROVIDER_ICON_BY_SERVICE_TYPE.AWS_SSM;

    case "GCP::Address":
    case "Address":
    case "GlobalAddress":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_ADDRESS;
    case "GCP::Instance":
    case "Instance":
    case "GCP::InstanceGroup":
    case "GCP::InstanceGroupManager":
    case "GCP::InstanceTemplate":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_INSTANCE;
    case "GCP::Route":
    case "Route":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_ROUTE;
    case "GCP::Firewall":
    case "Firewall":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_FIREWALL;
    case "GCP::Subnetwork":
    case "Subnetwork":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_SUBNETWORK;
    case "GCP::Network":
    case "Network":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GRP_NETWORK;
    case "GCP::HealthCheck":
    case "HealthCheck":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_HEALTH_CHECK;
    case "GCP::Service":
    case "Service":
    case "GCP::ServiceAccountKey":
    case "ServiceAccountKey":
    case "GCP::ServiceAccount":
    case "ServiceAccount":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_SERVICE;
    case "GCP::Disk":
    case "Disk":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_DISK;
    case "GCP::LogSink":
    case "LogSink":
    case "GCP::LogBucket":
    case "LogBucket":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_LOG;
    case "GCP::TagBinding":
    case "TagBinding":
    case "GCP::TagKey":
    case "TagKey":
    case "GCP::TagValue":
    case "TagValue":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_TAG;
    case "GCP::Bucket":
    case "Bucket":
    case "BackendBucket":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_BUCKET;
    case "GCP::Project":
    case "Project":
    case "GCP::ProjectBillingInfo":
    case "ProjectBillingInfo":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_PROJECT;
    case "GCP::AlertPolicy":
    case "AlertPolicy":
    case "GCP::ResourcePolicy":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_POLICY;
    case "GCP::Role":
    case "Role":
    case "GCP::Key":
    case "Key":
    case "GCP::RoleBinding":
    case "GCP::ClusterRoleBinding":
    case "GCP::ClusterRole":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_KEY;
    case "GCP::Topic":
    case "Topic":
    case "AWS::SNS::Topic":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_TOPIC;
    case "GCP::NotificationChannel":
    case "NotificationChannel":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_NOTIFICATION_CHANNEL;
    case "GCP::DaemonSet":
    case "GCP::Autoscaler":
    case "GCP::HorizontalPodAutoscaler":
    case "GCP::Namespace":
    case "GCP::Node":
    case "GCP::Pod":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_KUBERNETES;
    case "GCP::Table":
    case "GCP::Dataset":
    case "GCP::Database":
    case "GCP::StorageClass":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_TABLE;
    case "GCP::Secret":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_SECRET;
    case "GCP::Endpoint":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_ENDPOINT;
    case "GCP::CronJob":
    case "GCP::Job":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_CRON;
    case "GCP::Backup":
    case "GCP::BackupRun":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_BACKUP;
    case "GCP::Deployment":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_DEPLOY;
    case "GCP::Function":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_FUNCTION;
    case "GCP::MutatingWebhookConfiguration":
    case "GCP::ValidatingWebhookConfiguration":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_CONFIGURATION;
    case "GCP::TargetHttpProxy":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_PROXY;
    case "GCP::NodePool":
    case "GCP::NodeTemplate":
    case "GCP::NotebookRuntimeTemplate":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_TEMPLATE;
    case "GCP::Application":
    case "GCP::DockerImage":
    case "GCP::MachineImage":
    case "GCP::Image":
    case "GCP::Snapshot":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_APP;
    case "GCP::GlobalForwardingRule":
    case "GCP::Revision":
    case "GCP::StatefulSet":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_RULE;
    case "GCP::UrlMap":
    case "GCP::VulnerabilityReport":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_REPORT;
    case "GCP::Subscription":
      return PROVIDER_ICON_BY_SERVICE_TYPE.GCP_SUBSCRIPTION;
    default:
      return PROVIDER_ICON_BY_SERVICE_TYPE.NOT_FOUND;
  }
};
