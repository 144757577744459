import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Box, Typography } from "@mui/material";
import { CopyBlock, nord, dracula } from "react-code-blocks";

import { TabProp, Tabs, UnderlineLink } from "components/ui";
import { CLOUD_PLATFORM } from "store/types";
import { RootStore } from "store/root.store";

export type AWSInstructionParams = {
  accountId?: string;
  store?: RootStore;
  roleName: string;
  opshelmRoleArn: string;
  externalId: string;
};

export type GCloudInstructionParams = {
  store?: RootStore;
  projectId: string;
  serviceAccount: string;
};

export const codeInstructionsStyles = {
  fontFamily: "monospace",
  minHeight: "50px",
  maxHeight: "50vh",
  overflow: "scroll",
};

type ManualDocsParams = {
  accountId?: string;
  opshelmRoleArn?: string;
  externalId?: string;
  provider: string;
};

const Manual = ({ provider, accountId, opshelmRoleArn, externalId }: ManualDocsParams) => {
  const docsUrl = process.env.REACT_APP_DOCS_URL || "https://www.opshelm.com/docs";
  const queryParams =
    provider.toLowerCase() === "aws"
      ? `/#?${new URLSearchParams({
          AccountID: accountId || "",
          RoleARN: opshelmRoleArn || "",
          ExternalID: externalId || "",
        }).toString()}`
      : "";
  const dest = `${docsUrl}/guides/onboarding/${provider.toLowerCase()}${queryParams}`;

  return (
    <Box marginBottom={-2}>
      See our <UnderlineLink separateSite to={dest} text="documentation" /> for instructions for manually connecting
      your account.
    </Box>
  );
};

const TerraformAWS = ({ store, roleName, opshelmRoleArn, externalId, accountId }: AWSInstructionParams) => {
  const eventBridgeServiceLinkedRoleArn =
    "arn:aws:iam::$" +
    "{data.aws_caller_identity.current.account_id}" +
    ":role/aws-service-role/apidestinations.events.amazonaws.com/" +
    "AWSServiceRoleForAmazonEventBridgeApiDestinations";

  const text = `
data "aws_iam_policy_document" "assume" {
  statement {
    effect  = "Allow"
    actions = ["sts:AssumeRole"]

    principals {
      type = "AWS"

      identifiers = [
        "${opshelmRoleArn}",
      ]
    }

    condition {
      test     = "StringEquals"
      variable = "sts:ExternalId"

      values = ["${externalId}"]
    }
  }
}

resource "aws_iam_role" "opshelm-integration" {
  name               = "${roleName}"
  assume_role_policy = data.aws_iam_policy_document.assume.json

  tags = {
    Name = "${roleName}"
  }
}

resource "aws_iam_role_policy_attachment" "opshelm-audit-access" {
  role       = aws_iam_role.opshelm-integration.id
  policy_arn = "arn:aws:iam::aws:policy/SecurityAudit"
}

resource "aws_iam_role_policy_attachment" "opshelm-cloudtrail-access" {
  role       = aws_iam_role.opshelm-integration.id
  policy_arn = "arn:aws:iam::aws:policy/AWSCloudTrail_FullAccess"
}

resource "aws_iam_role_policy_attachment" "opshelm-eventbridge-access" {
  role       = aws_iam_role.opshelm-integration.id
  policy_arn = "arn:aws:iam::aws:policy/AmazonEventBridgeFullAccess"
}

resource "aws_iam_role_policy_attachment" "opshelm-firehose-access" {
  role       = aws_iam_role.opshelm-integration.id
  policy_arn = "arn:aws:iam::aws:policy/AmazonKinesisFirehoseFullAccess"
}

data "aws_caller_identity" "current" {}

data "aws_iam_policy_document" "eventbridge_iam_access" {
  statement {
    effect = "Allow"

    actions = [
      "iam:CreateRole",
      "iam:PutRolePolicy",
      "iam:TagRole",
      "iam:UpdateAssumeRolePolicy",
      "iam:PassRole",
      "iam:DetachRolePolicy",
      "iam:DeleteRolePolicy",
      "iam:DeleteRole"
    ]

    resources = [
      "arn:aws:iam::\${data.aws_caller_identity.current.account_id}:role/EventBridge-opshelm-ingest",
      "arn:aws:iam::\${data.aws_caller_identity.current.account_id}:role/OpshelmIntegration_${accountId}",
      "arn:aws:iam::\${data.aws_caller_identity.current.account_id}:role/OpshelmFirehoseIntegration_${accountId}"
    ]
  }

  statement {
    effect    = "Allow"
    actions   = ["iam:CreateServiceLinkedRole"]
    resources = ["${eventBridgeServiceLinkedRoleArn}"]
  }
}

resource "aws_iam_role_policy" "eventbridge_iam_access" {
  role   = aws_iam_role.opshelm-integration.name
  name   = "EventBridgeIAMAccess"
  policy = data.aws_iam_policy_document.eventbridge_iam_access.json
}
`;

  return (
    <>
      <Box marginBottom={2}>Use the following Terraform configuration to connect your account to OpsHelm:</Box>
      <Typography variant="table">
        <CopyBlock
          customStyle={codeInstructionsStyles}
          language="aws"
          text={text.trim()}
          theme={store?.ui?.isLightTheme ? nord : dracula}
          wrapLines
          codeBlock
        />
      </Typography>
    </>
  );
};

const TerraformGCP = ({ store, projectId, serviceAccount }: GCloudInstructionParams) => {
  const text = `
variable "opshelm_service_account" {
  default = "${serviceAccount}"
}

resource "google_project_iam_custom_role" "opshelm-service-role" {
  role_id     = "OpsHelmServiceRole"
  title       = "OpsHelm Service Role"
  description = "Allows OpsHelm to manage the connection between this project and the OpsHelm project."
  project     = "${projectId}"
 stage       = "GA"

  permissions = [
    "cloudasset.feeds.create",
    "cloudasset.feeds.delete",
    "cloudasset.feeds.get",
    "cloudasset.feeds.list",
    "cloudasset.feeds.update",
    "logging.sinks.create",
    "logging.sinks.delete",
    "logging.sinks.get",
    "logging.sinks.list",
    "logging.sinks.update",
    "pubsub.subscriptions.create",
    "pubsub.subscriptions.delete",
    "pubsub.subscriptions.get",
    "pubsub.subscriptions.list",
    "pubsub.subscriptions.update",
    "pubsub.topics.attachSubscription",
    "pubsub.topics.create",
    "pubsub.topics.delete",
    "pubsub.topics.get",
    "pubsub.topics.list",
    "resourcemanager.projects.get",
    "resourcemanager.projects.getIamPolicy",
  ]
}

resource "google_project_iam_member" "opshelm-service-account-service-role" {
  member  = "serviceAccount:\${var.opshelm_service_account}"
  project = "${projectId}"
  role    = google_project_iam_custom_role.opshelm-service-role.name
}

data "google_iam_role" "opshelm-asset-viewer" {
  name = "roles/cloudasset.viewer"
}

resource "google_project_iam_member" "opshelm-service-account-asset-viewer" {
  member  = "serviceAccount:\${var.opshelm_service_account}"
  project = "${projectId}"
  role    = data.google_iam_role.opshelm-asset-viewer.name
}
`;

  return (
    <>
      <Box marginBottom={2}>Use the following Terraform configuration to connect your project to OpsHelm:</Box>
      <Typography variant="table">
        <CopyBlock
          customStyle={codeInstructionsStyles}
          language="terraform"
          text={text.trim()}
          theme={store?.ui?.isLightTheme ? nord : dracula}
          wrapLines
          codeBlock
        />
      </Typography>
    </>
  );
};

const GCloudCLI = ({ store, projectId, serviceAccount }: GCloudInstructionParams) => {
  /* eslint-disable max-len */
  const text = `gcloud iam roles create OpsHelmServiceRole --project=${projectId} \\
    --title="OpsHelm Service Role" --description="Allows OpsHelm to manage the connection between this project and the OpsHelm project." \\
    --permissions="cloudasset.feeds.create,cloudasset.feeds.delete,cloudasset.feeds.get,cloudasset.feeds.list,cloudasset.feeds.update,logging.sinks.create,logging.sinks.delete,logging.sinks.get,logging.sinks.list,logging.sinks.update,pubsub.subscriptions.create,pubsub.subscriptions.delete,pubsub.subscriptions.get,pubsub.subscriptions.list,pubsub.subscriptions.update,pubsub.topics.attachSubscription,pubsub.topics.create,pubsub.topics.delete,pubsub.topics.get,pubsub.topics.list,resourcemanager.projects.get,resourcemanager.projects.getIamPolicy" --stage=GA
  gcloud projects add-iam-policy-binding \\
    ${projectId} \\
    --member serviceAccount:${serviceAccount} \\
    --role projects/${projectId}/roles/OpsHelmServiceRole
  gcloud projects add-iam-policy-binding \\
    ${projectId} \\
    --member serviceAccount:${serviceAccount} \\
    --role roles/cloudasset.viewer`;
  /* eslint-enable max-len */

  return (
    <>
      <Box marginBottom={2}>Use the following gcloud command to connect your project to OpsHelm:</Box>
      <Typography variant="table">
        <CopyBlock
          customStyle={codeInstructionsStyles}
          language="shell"
          text={text}
          theme={store?.ui?.isLightTheme ? nord : dracula}
          showLineNumbers={false}
          codeBlock
        />
      </Typography>
    </>
  );
};

export type OnboardingInstructionParams = {
  store?: RootStore;
  provider: string;
  projectId: string;
  serviceAccount?: string;
  roleName?: string;
  accountId?: string;
  opshelmRoleArn?: string;
  externalId?: string;
};

const OnboardingInstructions = ({
  store,
  provider,
  projectId,
  accountId,
  serviceAccount,
  roleName,
  opshelmRoleArn,
  externalId,
}: OnboardingInstructionParams) => {
  const [tabs, setTabs] = useState<TabProp[]>([]);

  useEffect(() => {
    const GCloudCLITab = {
      title: "CLI",
      component: <GCloudCLI store={store} projectId={projectId} serviceAccount={serviceAccount!} />,
    };

    const defaultTabs = [
      {
        title: "Terraform",
        component:
          provider?.toUpperCase() === CLOUD_PLATFORM.GCP ? (
            <TerraformGCP store={store} projectId={projectId} serviceAccount={serviceAccount!} />
          ) : (
            <TerraformAWS
              accountId={accountId}
              store={store}
              roleName={roleName!}
              opshelmRoleArn={opshelmRoleArn!}
              externalId={externalId!}
            />
          ),
      },
      {
        title: "Manual Authorization",
        component: (
          <Manual accountId={projectId} opshelmRoleArn={opshelmRoleArn} externalId={externalId} provider={provider} />
        ),
      },
    ];

    setTabs(provider?.toUpperCase() === CLOUD_PLATFORM.GCP ? [GCloudCLITab, ...defaultTabs] : defaultTabs);
  }, [provider, projectId, serviceAccount, store, roleName, opshelmRoleArn, externalId, accountId]);

  return (
    <Box data-testid="onboardingInstructions" marginTop={4}>
      <Tabs tabs={tabs} marginTop={4} />
    </Box>
  );
};

export default inject("store")(observer(OnboardingInstructions));
