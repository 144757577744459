import { makeAutoObservable } from "mobx";

import { RootStore } from "store/root.store";
import {
  AuthOrganization,
  AuthUser,
  FEATURE,
  MESSAGE_VARIANT,
  PERMISSION_TYPES,
  PermissionsWithRole,
} from "store/types";
import { joinWaitlist } from "libs/api/methods";

export class AuthStore {
  user: AuthUser | null = null;
  organization: AuthOrganization | null = null;
  permissions: PermissionsWithRole | null = null;
  features: FEATURE[] = [];
  isLoading = true;
  joinedToWaitlist = false;

  constructor(readonly rootStore: RootStore) {
    this.rootStore = rootStore;

    this.isFeatureEnabled = this.isFeatureEnabled.bind(this);
    this.isPermissionAllowed = this.isPermissionAllowed.bind(this);
    this.joinWaitlist = this.joinWaitlist.bind(this);

    makeAutoObservable(this);
  }

  isFeatureEnabled(feature: FEATURE) {
    return this.features.includes(feature);
  }

  isPermissionAllowed(permission: PERMISSION_TYPES) {
    return !!this.rootStore.auth.permissions?.[permission];
  }

  async setUserData(
    data: {
      user?: AuthUser;
      organization?: AuthOrganization;
      permissions?: PermissionsWithRole;
      features?: FEATURE[];
    } | null,
  ) {
    const user = data?.user || null;
    const organization = data?.organization || null;
    const permissions = data?.permissions || null;
    const features = data?.features || [];

    this.user = user;
    this.organization = organization;
    this.permissions = permissions;
    this.features = features;

    if (user) await this.rootStore.init();

    this.isLoading = false;
  }

  async joinWaitlist() {
    this.joinedToWaitlist = true;

    this.rootStore.ui.showMessage({
      title: "You were added to the Waitlist!",
      variant: MESSAGE_VARIANT.SUCCESS,
    });

    return joinWaitlist().catch(() => null);
  }
}
