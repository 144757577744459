import axios from "axios";
import axiosRetry from "axios-retry";
import { IAxiosCacheAdapterOptions, setupCache } from "axios-cache-adapter";

import store from "store";
import { API_BASE_URL } from "libs/api/endpoints";
import { LOGIN_ROUTE, SIGNUP_ROUTE } from "consts";

interface IAxiosCacheAdapterOptionsExtended extends IAxiosCacheAdapterOptions {
  uuid?: string;
  store?: {
    store: Record<string, string>;
    removeItem: (url: string) => void;
  };
}

export const axiosCache = setupCache({
  maxAge: 15 * 60 * 1000,
  exclude: {
    query: false,
  },
  invalidate: async (config: IAxiosCacheAdapterOptionsExtended, request) => {
    if (request.method?.toLowerCase() === "get" || !config.store?.store) return;

    const path = request.url?.slice(request.url?.startsWith("/") ? 1 : 0).split("/")[0];

    await Promise.all(
      Object.keys(config.store.store)
        .filter((url) => url.startsWith(`${API_BASE_URL}/${path}`))
        .map((key) => config.store?.removeItem(key)),
    );
  },
});

const client = axios.create({
  ...(process.env.REACT_APP_ENV !== "prod" && {
    adapter: axiosCache.adapter,
  }),
  baseURL: API_BASE_URL,
  timeout: 15000,
  withCredentials: true,
});

if (process.env.NODE_ENV !== "test") {
  const defaultRetryCount = 1;

  axiosRetry(client, {
    retries: defaultRetryCount,
    onRetry: (retryCount, error, config) => {
      const axiosRetryConfig = {
        ...config["axios-retry"],
        retries: config["axios-retry"]?.retries || defaultRetryCount,
      };

      if (axiosRetryConfig.retries === retryCount) {
        store.ui.showServerErrorMessage(error, config);
      }
    },
  });
}

client.interceptors.response.use(
  (res) => res.data,
  (error) => {
    if (error.config["axios-retry"].retryCount === 0) {
      store.ui.showServerErrorMessage(error, error.config);
    }

    if (error.response?.status === 401) {
      if (
        !store?.ui?.urlAfterSignIn &&
        ![LOGIN_ROUTE, SIGNUP_ROUTE].find((r) => window.location.pathname?.startsWith(r))
      ) {
        store?.ui.setUrlAfterSignIn(`${window.location.pathname}${window.location.search}`);
      }

      store.auth.setUserData(null);
      store.ui.closeAllUIElement();
    }

    return Promise.reject(error);
  },
);

client.defaults.xsrfHeaderName = "X-CSRF-Token";
client.defaults.xsrfCookieName = "csrf-token";

export default client;
