import React from "react";
import { Box } from "@mui/material";

import { TagsCount, JsonViewerType } from "components/ui";

export const TagsCountList = ({ tags }: { tags: JsonViewerType }) => {
  if (!tags) return null;

  return (
    <Box display="flex" gap={1.5} flexWrap="wrap">
      <TagsCount tags={tags} />
      <TagsCount tags={tags} withoutValues />
    </Box>
  );
};
