/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { inject, observer } from "mobx-react";

import { Button, InfoLabels, Select, SelectList, Tabs } from "components/ui";
import {
  calculateHighlights,
  calculateSimilarities,
  getHidedPropertiesByNumbers,
} from "components/ui/JsonViewer/jsonViewer.utils";
import { getInventoryLastUpdateData, isDateNewer } from "tools";
import { OPENING_ROWS_COUNT } from "components/ui/JsonViewer/consts";
import { RootStore } from "store/root.store";
import { INVENTORY_LATEST_VERSION_NAME, InventoryAttribution } from "store/types";

import { JsonView, JsonTableView } from "./components";

export enum JSON_VIEWER_VARIANTS {
  JSON = "JSON",
  TABLE = "TABLE",
}

export type PossibleJsonValue =
  | number
  | string
  | boolean
  | string[]
  | Record<string, number | string | boolean | string[]>;

export type JsonViewerType = Record<string, PossibleJsonValue>;

export type JsonSearchType = Record<string, { include: string[]; exclude: string[] }>;

export type SetJsonSearchType = (curr: JsonSearchType) => void;

export type JsonRow = {
  searchProperty?: string;
  property: string;
  collapsible: boolean;
  number?: number;
  onlyJson?: boolean;
  value?: string | number | boolean;
};

export type JsonSearchRowItem = {
  value: string | number | boolean;
  property: string;
};

export type JsonVersion = {
  id: string;
  json: JsonViewerType;
  attribution?: InventoryAttribution;
};

export type JsonHighlight = {
  property: string;
  full?: boolean;
};

export type JsonHighlights = {
  list: JsonHighlight[];
  added?: boolean;
};

export type SetHidedPropertiesHandler = (number: number) => void;

export type JsonHidedProperties = Record<string, number>;

type JsonViewerProps = {
  store?: RootStore;
  jsonSearch?: JsonSearchType;
  setJsonSearch?: SetJsonSearchType;
  variants: JSON_VIEWER_VARIANTS[];
  unsearchableProperties?: string[];
  json: JsonViewerType;
  compare?: boolean;
  offset?: number;
  copy?: boolean;
  setVersionHandler?: (version: string, handler: (val: JsonVersion) => void) => void;
  versions?: SelectList;
  maxLines?: number;
  attribution?: InventoryAttribution;
};

const Compare = ({
  versions,
  selectedVersion,
  setSelectedVersion,
  compareView,
  toggleCompareView,
}: {
  versions?: SelectList;
  compareView: boolean;
  selectedVersion?: JsonVersion;
  setSelectedVersion?: (version: string) => void;
  toggleCompareView?: () => void;
}) => {
  if (!versions || versions.length < 2) return null;

  return (
    <Box display="flex" gap={3} marginLeft="auto" flexWrap="wrap">
      {!compareView && !!setSelectedVersion && (
        <Select extraSmall inTable list={versions} value={selectedVersion!.id} onChange={setSelectedVersion!} />
      )}
      {!!toggleCompareView && (
        <Button
          inTable
          onClick={toggleCompareView}
          variant="text"
          extraSmallWithText
          text={compareView ? "Default view" : "Compare versions"}
        />
      )}
    </Box>
  );
};

const getTitle = (version: string, versions: SelectList) => versions?.find((v) => v.id === version)?.name || version;

const JsonViewer = ({
  store,
  variants,
  json,
  compare,
  offset,
  jsonSearch,
  setJsonSearch,
  unsearchableProperties,
  copy,
  versions,
  setVersionHandler,
  maxLines,
  attribution,
}: JsonViewerProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const [compareView, setCompareView] = useState<boolean>(false);
  const [selectedVersion, setSelectedVersion] = useState<JsonVersion>({
    id: INVENTORY_LATEST_VERSION_NAME,
    json,
    attribution,
  });
  const [compareVersion, setCompareVersion] = useState<JsonVersion | null>();
  const [jsons, setJsons] = useState<JsonVersion[]>([]);
  const [highlightsList, setHighlightsList] = useState<JsonHighlight[]>([]);
  const [hidedProperties, setHidedProperties] = useState<Array<JsonHidedProperties>>([{}, {}]);

  useEffect(() => {
    setJsons([selectedVersion, compareVersion as JsonVersion].filter((i) => i?.id));
  }, [selectedVersion, compareVersion]);

  useEffect(() => {
    if (!compareView) {
      setCompareVersion(null);

      return;
    }

    setVersionHandler?.(INVENTORY_LATEST_VERSION_NAME, setCompareVersion);
  }, [setVersionHandler, compareView, json]);

  useEffect(() => {
    if (!compareView) {
      setHighlightsList([]);
      setHidedProperties([{}, {}]);

      return;
    }

    const highlights = calculateHighlights(selectedVersion.json, compareVersion?.json);

    setHighlightsList(highlights);
    setHidedProperties([
      calculateSimilarities({ firstJson: selectedVersion.json, secondJson: compareVersion?.json, highlights }),
      calculateSimilarities({ firstJson: compareVersion?.json, secondJson: selectedVersion.json, highlights }),
    ]);
  }, [compareView, selectedVersion, compareVersion]);

  const setHidedPropertiesHandler = (index: number) => (number: number) => {
    const hidedPropertiesByNumbers = getHidedPropertiesByNumbers(hidedProperties[index]);
    const forRemove: string[] = [];

    [1, -1].forEach((pathIndex) => {
      for (let i = 0; i < OPENING_ROWS_COUNT; i += 1) {
        const prop = hidedPropertiesByNumbers[number + i * pathIndex];

        if (!prop) break;

        forRemove.push(prop);
      }
    });

    setHidedProperties(([left, right]) => {
      forRemove.forEach((prop) => {
        // eslint-disable-next-line no-param-reassign
        delete left[prop];
        // eslint-disable-next-line no-param-reassign
        delete right[prop];
      });

      return [left, right];
    });
  };

  const tabs = variants.map((variant) => ({
    title: variant === JSON_VIEWER_VARIANTS.JSON ? "JSON" : "Table",
    component: (
      <Box>
        <Box display="flex" gap={5} flexDirection={{ xs: "column", laptopLgx: "row" }}>
          {jsons.map((jsonData, index) => (
            <Box
              key={index}
              width={{ xs: 1, laptopLgx: 1 / jsons.length }}
              sx={{
                maxWidth: {
                  xs: "unset",
                  laptopLgx: compareVersion
                    ? `calc((100vw - ${
                        store!.ui.getMenuWidth(isMobile) + parseInt(theme.spacing(18), 10)
                      }px - ${parseInt(theme.spacing(3 * (jsons.length - 1)), 10)}px) / ${jsons.length})`
                    : "unset",
                },
              }}
            >
              {jsons.length > 1 && (
                <Box marginBottom={3} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography fontWeight={600}>{getTitle(jsonData!.id, versions!)}</Typography>
                  <Select
                    inTable
                    list={versions!}
                    value={jsonData!.id}
                    onChange={(val) => setVersionHandler?.(val, index ? setCompareVersion : setSelectedVersion)}
                  />
                </Box>
              )}

              {index === 1 && !highlightsList.length ? (
                <Typography variant="main">Contents are identical</Typography>
              ) : variant === JSON_VIEWER_VARIANTS.JSON ? (
                <JsonView
                  copy={copy}
                  unsearchableProperties={index ? undefined : unsearchableProperties}
                  jsonSearch={index ? undefined : jsonSearch}
                  setJsonSearch={index ? undefined : setJsonSearch}
                  json={jsonData.json}
                  offset={offset}
                  maxLines={compareVersion ? undefined : maxLines}
                  expanded={!!compareVersion}
                  hidedProperties={hidedProperties[index]}
                  setHidedProperties={setHidedPropertiesHandler(index)}
                  highlights={
                    compareView
                      ? {
                          list: highlightsList,
                          added: isDateNewer(selectedVersion.id, compareVersion?.id) ? !index : !!index,
                        }
                      : undefined
                  }
                />
              ) : (
                <JsonTableView
                  copy={copy}
                  unsearchableProperties={index ? undefined : unsearchableProperties}
                  jsonSearch={index ? undefined : jsonSearch}
                  setJsonSearch={index ? undefined : setJsonSearch}
                  json={jsonData.json}
                  maxLines={compareVersion ? undefined : maxLines}
                  highlights={
                    compareView
                      ? {
                          list: highlightsList,
                          added: !index,
                        }
                      : undefined
                  }
                />
              )}

              {(jsonData.id !== INVENTORY_LATEST_VERSION_NAME || jsons.length > 1) &&
                (getInventoryLastUpdateData(jsonData.attribution).lastUpdatedBy ||
                  getInventoryLastUpdateData(jsonData.attribution).lastUpdatedBySource) &&
                (index !== 1 || !!highlightsList.length) && (
                  <Box marginTop={3}>
                    <InfoLabels
                      labels={[
                        {
                          title: "Updated by",
                          value: getInventoryLastUpdateData(jsonData.attribution).lastUpdatedBy,
                        },
                        {
                          title: "Update Source",
                          value: getInventoryLastUpdateData(jsonData.attribution).lastUpdatedBySource,
                        },
                      ].filter((i) => i.value)}
                    />
                  </Box>
                )}
            </Box>
          ))}
        </Box>
      </Box>
    ),
  }));

  if (!variants.length || !json) return null;

  if (variants.length === 1)
    return (
      <Box flexDirection="column" display="flex" gap={{ xs: 3, laptop: 4 }}>
        <Compare
          versions={versions}
          selectedVersion={selectedVersion}
          setSelectedVersion={(val: string) => setVersionHandler?.(val, setSelectedVersion)}
          toggleCompareView={compare ? () => setCompareView(!compareView) : undefined}
          compareView={compareView}
        />

        <Box width={1}>{tabs[0].component}</Box>
      </Box>
    );

  return (
    <Tabs
      marginTop={4}
      tabs={tabs}
      additionalComponent={
        <Compare
          versions={versions}
          selectedVersion={selectedVersion}
          setSelectedVersion={(val: string) => setVersionHandler?.(val, setSelectedVersion)}
          toggleCompareView={compare ? () => setCompareView(!compareView) : undefined}
          compareView={compareView}
        />
      }
    />
  );
};

export default inject("store")(observer(JsonViewer));
