import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Box, Typography } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { useFormik } from "formik";
import * as yup from "yup";
import { addDays } from "date-fns";

import { Button, ButtonWrapper, FormFieldsBuilder } from "components/ui";
import { RootStore } from "store/root.store";
import { AddApiKey, API_KEY_PERMISSIONS, ApiKey } from "store/types";
import CongratulationScreen from "components/modals/AddIntegrationModal/components/CongratulationScreen";
import { copyToClipboard, getDaysDiff } from "tools";

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  expiration: yup
    .number()
    .required("Expiration is required")
    .moreThan(0, "Expiration should be more than 0")
    .lessThan(181, "Expiration should not be more than 180"),
});

const EXPIRATION_PROPERTY = "expiration";

const getDefaultPermissions = () =>
  Object.values(API_KEY_PERMISSIONS).reduce((acc, i) => ({ ...acc, [i]: i === API_KEY_PERMISSIONS.Read }), {});

const AddApiKeyIntegrationModal = ({
  store,
  keyForEdit,
  rotate,
}: {
  store?: RootStore;
  keyForEdit?: ApiKey;
  rotate?: boolean;
}) => {
  const [step, setStep] = useState(1);
  const [generatedKey, setGeneratedKey] = useState("");

  const expiration = keyForEdit ? getDaysDiff(new Date(), keyForEdit!.expires_at) : 30;

  const initialValues: AddApiKey = {
    name: keyForEdit?.name || "",
    permissions: keyForEdit?.permissions || getDefaultPermissions(),
    expiration,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      // eslint-disable-next-line camelcase
      const expires_at = addDays(new Date(), values.expiration);

      if (keyForEdit) {
        // eslint-disable-next-line camelcase
        await store?.apiKeys.updateApiKey({ ...keyForEdit, ...values, expires_at }, rotate);

        store?.ui.closeModal();

        return;
      }

      // eslint-disable-next-line camelcase
      const key = await store?.apiKeys.addApiKey({ ...values, expires_at });

      copyToClipboard({ value: key });
      setGeneratedKey(key!);

      return setStep(2);
    },
  });

  const items = [
    {
      property: "name",
      label: "Name",
    },
    {
      property: "permissions",
      label: "Permissions",
      type: "checkboxes",
      checkboxesList: Object.entries(API_KEY_PERMISSIONS).reduce((acc, [p, i]) => ({ ...acc, [p]: [i] }), {}),
    },
    {
      property: EXPIRATION_PROPERTY,
      label: "Expiration (in days)",
      fieldType: "number",
    },
  ].filter((i) => {
    if (rotate) return i.property === EXPIRATION_PROPERTY;

    return !keyForEdit || i.property !== EXPIRATION_PROPERTY;
  });

  if (step === 2) {
    return (
      <CongratulationScreen
        component={
          <Box textAlign="center">
            <Typography variant="main" fontSize={16}>
              Please save your api key:
            </Typography>
            <Box
              sx={{
                cursor: "pointer",
              }}
              marginTop={2}
              onClick={() => copyToClipboard({ value: generatedKey })}
            >
              <Typography
                sx={{ wordBreak: "break-word" }}
                display="flex"
                justifyContent="center"
                gap={1}
                variant="table"
                fontSize={16}
              >
                {generatedKey}
                <ContentCopy />
              </Typography>
            </Box>
          </Box>
        }
      />
    );
  }

  return (
    <Box
      onSubmit={formik.handleSubmit}
      component="form"
      gap={5}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <FormFieldsBuilder formik={formik} items={items} />

      <ButtonWrapper marginTop={0}>
        <>
          <Button onClick={store?.ui.closeModal} text="Cancel" variant="text" />
          <Button type="submit" text={rotate ? "Rotate" : keyForEdit ? "Save" : "Add"} />
        </>
      </ButtonWrapper>
    </Box>
  );
};

export default inject("store")(observer(AddApiKeyIntegrationModal));
