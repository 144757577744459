import React from "react";
import { Box } from "@mui/material";

import { Loading } from "components/ui/Loading/loading";

export const ModalLoading = () => (
  <Box data-testid="modalLoading" paddingY={5}>
    <Loading />
  </Box>
);
