import React, { MouseEvent } from "react";
import { inject, observer } from "mobx-react";
import { EditOutlined, RemoveCircleOutline } from "@mui/icons-material";
import { Box } from "@mui/material";

import { AwsCostIntegration, NotificationChannel, UI_TABLES } from "store/types";
import {
  AccountDetailsOnHover,
  ActionButtonsWrapper,
  Status,
  SUPPORTED_STATUSES_TYPE,
  Table,
  TableFieldOptions,
} from "components/ui";
import { formattedDate } from "tools";
import mainStore from "store";
import { AddNotificationChannelModal } from "components/modals";
import { ACTION_FIELD_PROPERTY } from "consts";

type NotificationChannelsTableProps = {
  data: NotificationChannel[];
};

const editChannelModal = (channel: NotificationChannel, integrationPage?: boolean) =>
  mainStore?.ui.openModal({
    title: "Edit Notification Channel",
    fullWidth: false,
    maxWidth: "lg",
    minWidth: "sm",
    component: (
      <AddNotificationChannelModal
        predefinedIntegration={integrationPage ? channel.channel_type : undefined}
        channel={channel}
      />
    ),
  });

const removeChannelModal = (channel: NotificationChannel) =>
  mainStore?.ui.openConfirmModal({
    title: "Are you sure you want to remove this channel?",
    component: <>This can not be undone.</>,
    confirmText: "Remove",
    onConfirm: () => {
      mainStore?.notifications.removeNotificationChannel(channel.id);
    },
  });

const actions = [
  {
    key: "edit",
    onClick: editChannelModal,
    getButtonProps: () => ({ icon: EditOutlined, text: "Edit" }),
  },
  {
    key: "remove",
    onClick: removeChannelModal,
    getButtonProps: () => ({ icon: RemoveCircleOutline, text: "Remove", variant: "text" }),
  },
];

export const getNotificationFields = (integrationPage?: boolean) => [
  {
    name: "Name",
    property: "name",
  },
  {
    name: "Account",
    property: "cloud_account_id",
    render: (channel: NotificationChannel, options?: TableFieldOptions) => {
      const account = mainStore.accounts.activeAccounts?.find((a) => a.id === channel.cloud_account_id);

      if (!account) return "All accounts";

      return <AccountDetailsOnHover inTable={options?.inTable} id={account.id} component={<Box>{account.name}</Box>} />;
    },
    textValue: (channel: NotificationChannel) => {
      const account = mainStore.accounts.activeAccounts?.find((a) => a.id === channel.cloud_account_id);

      return account?.name || "All accounts";
    },
  },
  ...(integrationPage
    ? []
    : [
        {
          name: "Type",
          property: "channel_type",
        },
      ]),
  {
    name: "Destination",
    property: "destination",
  },
  {
    name: "Enabled",
    property: "enabled",
    textValue: (channel: NotificationChannel) => (channel.enabled ? "enabled" : "disabled"),
    render: (channel: NotificationChannel) => (
      <Status status={channel.enabled ? "enabled" : "disabled"} statusType={SUPPORTED_STATUSES_TYPE.NOTIFICATION} />
    ),
  },
  {
    name: "Created At",
    property: "created_at",
    render: (channel: NotificationChannel) => formattedDate(channel.CreatedAt),
  },
  ...(integrationPage
    ? [
        {
          property: ACTION_FIELD_PROPERTY,
          render: (item: AwsCostIntegration) => (
            <ActionButtonsWrapper
              item={item}
              inTable
              buttons={actions.map((a) => ({
                ...a.getButtonProps(),
                onClick: (e: MouseEvent<HTMLElement>, channel: NotificationChannel) =>
                  a.onClick(channel, integrationPage),
              }))}
            />
          ),
        },
      ]
    : []),
];

const NotificationChannelsTable = ({ data, ...props }: NotificationChannelsTableProps) => (
  <Table
    {...props}
    type={UI_TABLES.NOTIFICATION_CHANNELS}
    noDataText="You haven’t added any notification channels yet"
    actions={actions}
    loadMoreCount={4}
    removePadding
    fields={getNotificationFields()}
    data={data}
  />
);

export default inject("store")(observer(NotificationChannelsTable));
