import { MouseEvent, ReactElement } from "react";
import { inject, observer } from "mobx-react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { NightlightOutlined, WbSunnyOutlined } from "@mui/icons-material";

import { RootStore } from "store/root.store";
import { FEATURE, UI_THEME } from "store/types";

type ElementType = { theme: UI_THEME; oppositeTheme: UI_THEME; icon: ReactElement; activeColor: string; text: string };

const ThemeSwitcher = ({ store }: { store?: RootStore }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const elements: ElementType[] = [
    {
      theme: UI_THEME.LIGHT,
      oppositeTheme: UI_THEME.DARK,
      icon: <WbSunnyOutlined sx={{ color: store?.ui.isLightTheme ? "#5A5FA0" : "text.primary" }} />,
      text: "Light",
      activeColor: "#fff",
    },
    {
      theme: UI_THEME.DARK,
      oppositeTheme: UI_THEME.LIGHT,
      icon: (
        <NightlightOutlined
          sx={{
            transform: "rotate(-30deg)",
            color:
              store?.ui.isLightTheme || (!isMobile && store?.ui.smallView)
                ? store?.ui.isLightTheme
                  ? "#5A5FA0"
                  : "text.primary"
                : "text.primary",
          }}
        />
      ),
      text: "Dark",
      activeColor: "#6F75C6",
    },
  ];

  const changeTheme = (e: MouseEvent<HTMLElement>, element: ElementType) => {
    e.stopPropagation();

    store?.ui.changeTheme(!isMobile && store?.ui.smallView ? element.oppositeTheme : element.theme);
  };

  if (!store?.auth.isFeatureEnabled(FEATURE.THEME_MODE)) return null;

  return (
    <Box
      data-testid={isMobile ? "themeSwitcherMobile" : "themeSwitcher"}
      display={{ xs: isMobile ? "flex" : "none", laptop: isMobile ? "none" : "flex" }}
      marginTop={2}
      padding={1}
      borderRadius={5}
      bgcolor={{ xs: "various.navBG", laptop: store?.ui.smallView ? "transparent" : "various.navBG" }}
    >
      {elements
        .filter((element) => isMobile || !store?.ui.smallView || store?.ui.theme === element.theme)
        .map((element) => (
          <Box
            data-testid={`themeSwitcher-${element.theme}`}
            key={element.theme}
            onClick={(e) => changeTheme(e, element)}
            bgcolor={
              store?.ui.theme !== element.theme || (!isMobile && store?.ui.smallView)
                ? "transparent"
                : element.activeColor
            }
            alignItems="center"
            justifyContent="center"
            display="flex"
            width={!isMobile && store?.ui.smallView ? 1 : 0.5}
            height="30px"
            borderRadius={5}
            sx={{
              cursor: (!isMobile && store?.ui.smallView) || store?.ui.theme !== element.theme ? "pointer" : "default",
            }}
          >
            {element.icon}
            {(isMobile || !store?.ui.smallView) && <Typography variant="main">&nbsp;&nbsp;{element.text}</Typography>}
          </Box>
        ))}
    </Box>
  );
};

export default inject("store")(observer(ThemeSwitcher));
