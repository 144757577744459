import React from "react";

import { EventView, UI_TABLES } from "store/types";
import { ProviderServiceTypeIcon, Table, TableFields } from "components/ui";
import { EventDetailsAdditionalInfo } from "routes/EventsRoute/components";
import { formattedDate, getIconTypeByResource } from "tools";

type EventDetailsTableProps = {
  widgetId?: string;
  filterId?: string;
  data: EventView[];
};

export const eventFields: TableFields[] = [
  {
    name: "Processed At",
    property: "processed_at",
    render: (item: EventView) => formattedDate(item.processed_at),
  },
  {
    name: "Event ID",
    property: "id",
    additionalInfo: "table",
    donNotShowInMainTable: true,
    notSortable: true,
  },
  {
    name: "Principal",
    property: "principal",
    additionalInfo: "table",
    breakWordsLine: true,
    notSortable: true,
  },
  {
    name: "Service",
    property: "service",
    notSortable: true,
  },
  {
    name: "Action",
    property: "action",
    notSortable: true,
  },
  {
    name: "Resource Type",
    property: "resource_type",
    additionalInfo: "table",
    textValue: (item: EventView) => item.resource_type,
    render: (item: EventView) => (
      <ProviderServiceTypeIcon type={getIconTypeByResource(item.resource_type)} text={item.resource_type} />
    ),
    donNotShowInMainTable: true,
    notSortable: true,
  },
  {
    name: "Resource ID",
    property: "resource_id",
    additionalInfo: "table",
    donNotShowInMainTable: true,
    notSortable: true,
  },
];

const EventDetailsTable = ({ data, widgetId, filterId, ...props }: EventDetailsTableProps) => (
  <Table
    {...props}
    type={widgetId ? `${UI_TABLES.EVENTS}_${filterId}` : UI_TABLES.EVENTS}
    noDataText="No events found"
    loadMoreCount={3}
    removePadding
    fields={eventFields.filter((f) => !widgetId || ["processed_at", "service", "action"].includes(f.property))}
    data={data}
    additionalInfo={<EventDetailsAdditionalInfo />}
  />
);

export default EventDetailsTable;
