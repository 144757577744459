import React from "react";
import { inject, observer } from "mobx-react";
import { Box, Typography } from "@mui/material";

import {
  ElementWrapper,
  ElementWrapperWithActions,
  TableItemProps,
  TableWithPadding,
  Tabs,
  TaleWrapper,
} from "components/ui";
import { getIconTypeByResource } from "tools";
import { LampSvg } from "routes/DashboardRoute/components";
import { Inventory, UI_TABS } from "store/types";
import { InventoryTable } from "routes/InventoryRoute/components";
import { ProviderServiceIcon } from "components/ui/ProviderServiceTypeIcon/components/providerServiceIcon";

import DashboardHistoricalStats from "../DashboardHistoricalStats/dashboardHistoricalStats";
import DashboardHistoricalItem from "../DashboardHistoricalItem/dashboardHistoricalItem";

const temp = [
  {
    type: "Address",
    name: "Lorem ipsum Address",
    change:
      "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum " +
      "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum ",
    id: "0.15952036331590325",
  },
  {
    type: "Disk",
    name: "Lorem ipsum Disk",
    change:
      "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum " +
      "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum " +
      "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum " +
      "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum " +
      "Lorem ipsum Lorem ipsum Lorem ipsum ",
    id: "0.9141646529426048",
  },
  {
    type: "HealthCheck",
    name: "Lorem ipsum HealthCheck",
    change:
      "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum " +
      "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum " +
      "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum " +
      "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum " +
      "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum " +
      "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum ",
    id: "0.42952672014692705",
  },
  {
    type: "Bucket",
    name: "Lorem ipsum Instance",
    change:
      "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum " +
      "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum " +
      "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum " +
      "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum " +
      "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum " +
      "Lorem ipsum ",
    id: "0.7746376139324205",
  },
];

const tempFields = [
  {
    name: "Name",
    property: "name",
    width: 250,
    textValue: (item: TableItemProps) => `${item.type} ${item.name}`,
    render: (item: TableItemProps) => (
      <Box display="flex" alignItems="center" gap={2}>
        <Box
          padding={1}
          borderRadius={1}
          bgcolor="#fff"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            svg: {
              width: 24,
              height: 24,
            },
          }}
        >
          <ProviderServiceIcon withoutDefaultStyles type={getIconTypeByResource(item.type)} />
        </Box>
        <Box display="flex" gap={1} flexDirection="column">
          <Typography variant="main" fontWeight={700}>
            {item.name}
          </Typography>
          <Typography color="#8BA2E0" variant="main" fontSize={12}>
            {item.type}
          </Typography>
        </Box>
      </Box>
    ),
  },
  {
    name: "Type of change",
    property: "change",
    doNotTruncate: true,
    breakWordsLine: true,
  },
];

const tempData: Inventory[] = [
  {
    id: "01HVQ194QW5V52Q8M0CYM1FBRA",
    provider_account_id: "211125613263",
    provider_id: "arn:aws:kms:us-west-2:211125613263:key/b4c3f99d-6952-45bc-8bd2-34a882fd5cd4",
    display_id: "key/b4c3f99d-6952-45bc-8bd2-34a882fd5cd4",
    type: "AWS::KMS::Key",
    name: "key/b4c3f99d-6952-45bc-8bd2-34a882fd5cd4",
    location: "us-west-2",
    details: {
      AWSAccountId: "211125613263",
      Arn: "arn:aws:kms:us-west-2:211125613263:key/b4c3f99d-6952-45bc-8bd2-34a882fd5cd4",
      CloudHsmClusterId: null,
      CreationDate: "2024-04-17T21:30:39.498Z",
      CustomKeyStoreId: null,
      CustomerMasterKeySpec: "SYMMETRIC_DEFAULT",
      DeletionDate: null,
      Description: "",
      Enabled: true,
      EncryptionAlgorithms: ["SYMMETRIC_DEFAULT"],
      ExpirationModel: "",
      KeyId: "b4c3f99d-6952-45bc-8bd2-34a882fd5cd4",
      KeyManager: "CUSTOMER",
      KeySpec: "SYMMETRIC_DEFAULT",
      KeyState: "Enabled",
      KeyUsage: "ENCRYPT_DECRYPT",
      MacAlgorithms: null,
      MultiRegion: false,
      MultiRegionConfiguration: null,
      OH__Meta: {
        arn: "arn:aws:kms:us-west-2:211125613263:key/b4c3f99d-6952-45bc-8bd2-34a882fd5cd4",
        availability_zone: "",
        region: "us-west-2",
        resource_type: "",
        type: "github.com/aws/aws-sdk-go-v2/service/kms/types.KeyMetadata",
      },
      Origin: "AWS_KMS",
      PendingDeletionWindowInDays: null,
      SigningAlgorithms: null,
      ValidTo: null,
      XksKeyConfiguration: null,
    },
    tags: {},
    last_updated_by: "",
    last_updated_by_source: "",
    provider_created_at: "0001-01-01T00:00:00Z",
    provider_updated_at: "0001-01-01T00:00:00Z",
    cost: null,
    month_to_date_cost: "0",
    created_at: "2024-04-17T22:01:49.565067Z",
    updated_at: "2024-04-17T22:01:49.552443Z",
    deleted_at: "0001-01-01T00:00:00Z",
  },
  {
    id: "01HVQ194H59S4QQ041NXJ7DY6V",
    provider_account_id: "211125613263",
    provider_id: "arn:aws:kms:us-west-2:211125613263:alias/kys",
    display_id: "alias/kys",
    type: "AWS::KMS::Alias",
    name: "alias/kys",
    location: "us-west-2",
    details: {
      AliasArn: "arn:aws:kms:us-west-2:211125613263:alias/kys",
      AliasName: "alias/kys",
      CreationDate: "2024-04-17T21:30:39.809Z",
      LastUpdatedDate: "2024-04-17T21:30:39.809Z",
      OH__Meta: {
        arn: "arn:aws:kms:us-west-2:211125613263:alias/kys",
        availability_zone: "",
        region: "us-west-2",
        resource_type: "",
        type: "github.com/aws/aws-sdk-go-v2/service/kms/types.AliasListEntry",
      },
      TargetKeyId: "b4c3f99d-6952-45bc-8bd2-34a882fd5cd4",
    },
    tags: {},
    last_updated_by: "",
    last_updated_by_source: "",
    provider_created_at: "0001-01-01T00:00:00Z",
    provider_updated_at: "0001-01-01T00:00:00Z",
    cost: null,
    month_to_date_cost: "0",
    created_at: "2024-04-17T22:01:49.351268Z",
    updated_at: "2024-04-17T22:01:49.241743Z",
    deleted_at: "0001-01-01T00:00:00Z",
  },
  {
    id: "01HVQ192BWJNBA8CFZ9T4M7HD7",
    provider_account_id: "211125613263",
    provider_id: "arn:aws:cloudtrail:us-west-2:211125613263:trail/management-events",
    display_id: "trail/management-events",
    type: "AWS::CloudTrail::Trail",
    name: "trail/management-events",
    location: "ap-south-1",
    details: {
      CloudWatchLogsLogGroupArn: null,
      CloudWatchLogsRoleArn: null,
      HasCustomEventSelectors: true,
      HasInsightSelectors: false,
      HomeRegion: "us-west-2",
      IncludeGlobalServiceEvents: true,
      IsMultiRegionTrail: true,
      IsOrganizationTrail: false,
      KmsKeyId: "arn:aws:kms:us-west-2:211125613263:key/b4c3f99d-6952-45bc-8bd2-34a882fd5cd4",
      LogFileValidationEnabled: true,
      Name: "management-events",
      OH__Meta: {
        arn: "arn:aws:cloudtrail:us-west-2:211125613263:trail/management-events",
        availability_zone: "",
        region: "ap-south-1",
        resource_type: "",
        type: "github.com/OpsHelmInc/cloudquery/resources/services/cloudtrail/models.CloudTrailWrapper",
      },
      S3BucketName: "aws-cloudtrail-logs-211125613263-2f75e024",
      S3KeyPrefix: null,
      SnsTopicARN: null,
      SnsTopicName: null,
      Tags: {},
      TrailARN: "arn:aws:cloudtrail:us-west-2:211125613263:trail/management-events",
    },
    tags: {},
    last_updated_by: "",
    last_updated_by_source: "",
    provider_created_at: "0001-01-01T00:00:00Z",
    provider_updated_at: "0001-01-01T00:00:00Z",
    cost: null,
    month_to_date_cost: "0",
    created_at: "2024-04-17T22:01:47.132525Z",
    updated_at: "2024-04-17T22:01:47.128808Z",
    deleted_at: "0001-01-01T00:00:00Z",
  },
  {
    id: "01HVPZG6403WDJ62VS8EYRHS8T",
    provider_account_id: "211125613263",
    provider_id: "arn:aws:s3:::aws-cloudtrail-logs-211125613263-2f75e024",
    display_id: "aws-cloudtrail-logs-211125613263-2f75e024",
    type: "AWS::S3::Bucket",
    name: "aws-cloudtrail-logs-211125613263-2f75e024",
    location: "us-west-2",
    details: {
      BlockPublicAcls: true,
      BlockPublicPolicy: true,
      CreationDate: "2024-04-17T21:30:39Z",
      IgnorePublicAcls: true,
      IntelligentTieringConfigurations: null,
      IsPublic: false,
      LifecycleRules: null,
      LoggingTargetBucket: null,
      LoggingTargetPrefix: null,
      Name: "aws-cloudtrail-logs-211125613263-2f75e024",
      OH__Meta: {
        arn: "arn:aws:s3:::aws-cloudtrail-logs-211125613263-2f75e024",
        availability_zone: "",
        region: "us-west-2",
        resource_type: "AWS::S3::Bucket",
        type: "github.com/OpsHelmInc/ohaws.WrappedBucket",
      },
      OwnershipControls: ["BucketOwnerEnforced"],
      Policy: {
        Statement: [
          {
            Action: "s3:GetBucketAcl",
            Condition: {
              StringEquals: {
                "AWS:SourceArn": "arn:aws:cloudtrail:us-west-2:211125613263:trail/management-events",
              },
            },
            Effect: "Allow",
            Principal: {
              Service: "cloudtrail.amazonaws.com",
            },
            Resource: "arn:aws:s3:::aws-cloudtrail-logs-211125613263-2f75e024",
            Sid: "AWSCloudTrailAclCheck20150319-a4c6f4bb-6f54-407a-9b32-5e340a4641e9",
          },
          {
            Action: "s3:PutObject",
            Condition: {
              StringEquals: {
                "AWS:SourceArn": "arn:aws:cloudtrail:us-west-2:211125613263:trail/management-events",
                "s3:x-amz-acl": "bucket-owner-full-control",
              },
            },
            Effect: "Allow",
            Principal: {
              Service: "cloudtrail.amazonaws.com",
            },
            Resource: "arn:aws:s3:::aws-cloudtrail-logs-211125613263-2f75e024/AWSLogs/211125613263/*",
            Sid: "AWSCloudTrailWrite20150319-968b6001-1c75-4d00-a6ca-2815126a7348",
          },
          {
            Action: "s3:*",
            Condition: {
              Bool: {
                "aws:SecureTransport": "false",
              },
            },
            Effect: "Deny",
            Principal: "*",
            Resource: "arn:aws:s3:::aws-cloudtrail-logs-211125613263-2f75e024/*",
            Sid: "OpsHelm_DenyHTTP",
          },
        ],
        Version: "2012-10-17",
      },
      Region: "us-west-2",
      ReplicationRole: null,
      ReplicationRules: null,
      RestrictPublicBuckets: true,
      SSEAlgorithm: "",
      Tags: null,
      VersioningMfaDelete: "",
      VersioningStatus: "",
    },
    tags: {},
    last_updated_by: "arn:aws:iam::211125613263:root",
    last_updated_by_source: "console",
    provider_created_at: "2024-04-17T21:30:38Z",
    provider_updated_at: "0001-01-01T00:00:00Z",
    cost: null,
    month_to_date_cost: "0",
    created_at: "2024-04-17T21:30:43.200819Z",
    updated_at: "2024-04-17T22:01:49.808513Z",
    deleted_at: "0001-01-01T00:00:00Z",
  },
  {
    id: "01HTTPX5NVJKANDJ22V6X697AQ",
    provider_account_id: "211125613263",
    provider_id: "arn:aws:iam::211125613263:role/aws-service-role/ecs.amazonaws.com/AWSServiceRoleForECS",
    display_id: "role/aws-service-role/ecs.amazonaws.com/AWSServiceRoleForECS",
    type: "AWS::IAM::Role",
    name: "role/aws-service-role/ecs.amazonaws.com/AWSServiceRoleForECS",
    location: "",
    details: {
      Arn: "arn:aws:iam::211125613263:role/aws-service-role/ecs.amazonaws.com/AWSServiceRoleForECS",
      CreateDate: "2024-04-04T22:08:12Z",
      Description: "Role to enable Amazon ECS to manage your cluster.",
      MaxSessionDuration: 3600,
      OH__Meta: {
        arn: "arn:aws:iam::211125613263:role/aws-service-role/ecs.amazonaws.com/AWSServiceRoleForECS",
        availability_zone: "",
        region: "",
        resource_type: "AWS::IAM::Role",
        type: "github.com/OpsHelmInc/ohaws.Role",
      },
      Path: "/aws-service-role/ecs.amazonaws.com/",
      PermissionsBoundary: null,
      Policies: {
        "arn:aws:iam::aws:policy/aws-service-role/AmazonECSServiceRolePolicy": "AmazonECSServiceRolePolicy",
      },
      RoleId: "AROATCKARGLH6YODXLQV7",
      RoleLastUsed: {
        LastUsedDate: "2024-04-05T00:32:01Z",
        Region: "us-west-2",
      },
      RoleName: "AWSServiceRoleForECS",
      Tags: null,
    },
    tags: {},
    last_updated_by: "",
    last_updated_by_source: "",
    provider_created_at: "0001-01-01T00:00:00Z",
    provider_updated_at: "0001-01-01T00:00:00Z",
    cost: null,
    month_to_date_cost: "0",
    created_at: "2024-04-06T22:01:47.451806Z",
    updated_at: "2024-04-06T22:01:47.446507Z",
    deleted_at: "0001-01-01T00:00:00Z",
  },
] as never;

const tempTabs = ["Disk", "Instance", "Address"];

const tempMain = ["Disk", "Instance", "Address", "Disk", "Instance", "Address", "Disk", "Instance"];

const InsightsTable = ({ data }: { data: TableItemProps[] }) => <TableWithPadding fields={tempFields} data={data} />;

const DashboardHistorical = () => (
  <Box display="flex" flexDirection="column" gap={5}>
    <ElementWrapperWithActions
      hideActions
      title={
        <Box display="flex" gap={2} alignItems="center" marginBottom={1}>
          <LampSvg /> Infrastructure Insights
        </Box>
      }
      component={InsightsTable}
      data={temp}
      componentProps={{
        hidePagination: true,
      }}
    />

    <ElementWrapper>
      <Box display="flex" flexWrap="wrap">
        {tempMain.map((type) => (
          <TaleWrapper key={type}>
            <DashboardHistoricalItem type={type} stats={{ added: 10, same: 1, removed: 5 }} />
          </TaleWrapper>
        ))}
      </Box>
    </ElementWrapper>

    <ElementWrapper customSx={{ paddingX: 0, paddingY: 0, paddingTop: 6 }}>
      <Tabs
        type={UI_TABS.HISTORICAL}
        paddingX={2}
        tabs={tempTabs.map((tab) => ({
          render: () => (
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                padding={1}
                borderRadius={1}
                bgcolor="#e6ebf9"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  svg: {
                    width: 14,
                    height: 14,
                  },
                }}
              >
                <ProviderServiceIcon withoutDefaultStyles type={getIconTypeByResource(tab)} />
              </Box>
              {tab}
            </Box>
          ),
          title: tab,
          component: (
            <ElementWrapperWithActions
              search
              component={InventoryTable}
              data={tempData}
              leftActions={<DashboardHistoricalStats stats={{ added: 10, same: 1, removed: 5 }} />}
              csvExport={{ handler: () => ({}) }}
            />
          ),
        }))}
      />
    </ElementWrapper>
  </Box>
);

export default inject("store")(observer(DashboardHistorical));
