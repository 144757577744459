import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Box, Typography } from "@mui/material";

import { RootStore } from "store/root.store";
import { Label, MultiSelect, Select } from "components/ui";
import { Account, DROPDOWNS } from "store/types";

type AccountDropdownProps = {
  store?: RootStore;
  selectedAccountId?: string;
  setSelectedAccountId?: (val: string) => void;
  selectedAccounts?: string[];
  setSelectedAccounts?: (val: string[]) => void;
  withoutTitle?: boolean;
  useAsLabel?: boolean;
  multiple?: boolean;
  inTable?: boolean;
  type: DROPDOWNS;
  fullWidth?: boolean;
};

const AccountDropdown = ({
  store,
  selectedAccountId,
  setSelectedAccountId,
  selectedAccounts,
  setSelectedAccounts,
  withoutTitle,
  useAsLabel,
  multiple,
  type,
  inTable,
  fullWidth,
}: AccountDropdownProps) => {
  const { activeAccounts } = store!.accounts;
  const { selectedDropdownValue } = store!.ui;

  useEffect(() => {
    if (!multiple || selectedAccounts?.length || !activeAccounts.length) return;

    const selectedPreviouslyAccounts = selectedDropdownValue?.[type];
    const accounts = (selectedPreviouslyAccounts as string[])?.filter?.((id: string) =>
      activeAccounts.find((a) => a.id === id),
    );

    setSelectedAccounts?.(accounts?.length ? accounts : [activeAccounts[0].id]);
  }, [multiple, type, activeAccounts, selectedAccounts, setSelectedAccounts, selectedDropdownValue]);

  useEffect(() => {
    if (multiple || selectedAccountId || !activeAccounts.length) return;

    const selectedPreviouslyId = selectedDropdownValue?.[type];
    const id = activeAccounts.find((a) => a.id === selectedPreviouslyId)?.id || activeAccounts[0].id;

    setSelectedAccountId?.(id);
  }, [multiple, type, selectedDropdownValue, activeAccounts, selectedAccountId, setSelectedAccountId]);

  if (multiple) {
    return (
      <Box
        display="flex"
        alignItems={useAsLabel ? "flex-start" : "center"}
        flexDirection={useAsLabel ? "column" : "row"}
        gap={useAsLabel ? 0 : 1.5}
      >
        {!!useAsLabel && <Label text="Accounts" />}

        {!withoutTitle && !useAsLabel && (
          <Typography variant="main" fontWeight={700}>
            Accounts
          </Typography>
        )}

        <Box minWidth={250} sx={{ ...(fullWidth && { width: 1 }) }}>
          <MultiSelect
            inTable={inTable}
            multiple
            fullWidth
            limitTags={2}
            type={type}
            placeholder="Select accounts"
            onChange={(accounts) => setSelectedAccounts?.(accounts.map((a: Account) => a.id))}
            value={selectedAccounts
              ?.map((id) => activeAccounts.find((a) => a.id === id))
              .filter((i) => i)
              .sort((a, b) => ((a?.name || "") > (b?.name || "") ? 1 : -1))}
            list={activeAccounts.map((a) => ({ id: a.id, name: a.name }))}
            dontRemoveLast
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems="center" gap={1.5} sx={{ ...(fullWidth && { width: 1 }) }}>
      {!withoutTitle && !useAsLabel && (
        <Typography variant="main" fontWeight={700}>
          Account
        </Typography>
      )}

      <Select
        label={useAsLabel ? "Account" : ""}
        inTable={inTable}
        type={type}
        value={selectedAccountId!}
        list={activeAccounts}
        onChange={setSelectedAccountId}
        fullWidth={fullWidth}
      />
    </Box>
  );
};

export default inject("store")(observer(AccountDropdown));
