import React from "react";
import { inject, observer } from "mobx-react";
import { Box } from "@mui/material";

import { RootStore } from "store/root.store";
import {
  JSON_VIEWER_VARIANTS,
  AdditionalInfoTable,
  InfoLabels,
  JsonViewer,
  ProviderIcon,
  JsonSearchType,
} from "components/ui";
import { formattedDate } from "tools";
import { CLOUD_PLATFORM, EventView } from "store/types";
import { UNSEARCHABLE_EVENT_PROPERTIES } from "routes/EventsRoute/eventsRoute";
import { eventFields } from "routes/EventsRoute/components/eventDetailsTable";

type EventDetailsAdditionalInfoProps = {
  store?: RootStore;
  event?: EventView;
  standalonePage?: boolean;
};

const EventDetailsAdditionalInfo = ({ store, event, standalonePage }: EventDetailsAdditionalInfoProps) => {
  const item = event || store?.ui.infoItem?.activeItem;

  const tableFields = eventFields.filter((f) => f.additionalInfo === "table");

  const setJsonSearch = (value: JsonSearchType) => store?.events.setJsonSearch(value);

  return (
    <>
      <InfoLabels
        labels={[
          {
            title: "Action",
            value: item?.action,
          },
          {
            title: "Service",
            value: item?.service?.includes("aws.com") ? <ProviderIcon provider={CLOUD_PLATFORM.AWS} /> : item?.service,
          },
          {
            title: "Processed At",
            value: formattedDate(item?.processed_at),
          },
        ]}
      />
      <Box marginY={5}>
        <AdditionalInfoTable item={item} fields={tableFields} />
      </Box>

      <JsonViewer
        copy
        unsearchableProperties={standalonePage ? undefined : UNSEARCHABLE_EVENT_PROPERTIES}
        setJsonSearch={standalonePage ? undefined : setJsonSearch}
        jsonSearch={standalonePage ? undefined : store?.events?.jsonSearch}
        variants={[JSON_VIEWER_VARIANTS.JSON, JSON_VIEWER_VARIANTS.TABLE]}
        json={item?.raw_event}
      />
    </>
  );
};

export default inject("store")(observer(EventDetailsAdditionalInfo));
