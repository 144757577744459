import {
  FILTER_LOGICAL_CONDITION,
  FILTER_PAGES,
  DataStatsItem,
  FilterData,
  FilterDataItem,
  FilterEntity,
  FilterItem,
  SavedFilter,
} from "store/types";
import {
  NONE_SELECT_TAG_VALUE,
  JsonSearchType,
  MultiSelectListItem,
  PossibleJsonValue,
  TableFields,
  TableItemProps,
} from "components/ui";
import { getDatesFromFilterValue, getDatesFromSavedFilter, getFieldFilterValue } from "tools";
import { inventoryFields } from "routes/InventoryRoute/components/inventoryTable";
import { getJsonSearchValue } from "components/ui/JsonViewer/jsonViewer.utils";

const buildSearchItem = ({
  fields,
  filter,
  useEqualSign,
}: {
  fields: TableFields[];
  filter: FilterItem;
  useEqualSign?: boolean;
}): string => {
  const field = (filter.field as MultiSelectListItem)?.id || filter.field;

  if (!field) return "";

  if (["IS_ONE_OF", "IS_NOT_ONE_OF"].includes(filter.condition)) {
    return `${
      useEqualSign
        ? ""
        : filter.condition === "IS_ONE_OF"
        ? FILTER_LOGICAL_CONDITION.POSITIVE
        : `${FILTER_LOGICAL_CONDITION.NEGATIVE} `
    }${field}${useEqualSign ? (filter.condition === "IS_ONE_OF" ? "=" : "!=") : ":"}IN [${filter.values
      .map((f) => `"${(f as MultiSelectListItem)?.id || f}"`)
      .join(" ")}]`;
  }

  const mainField = fields.find((f) => f.property === field);
  const quotes = mainField?.searchableObject ? "" : `"`; // eslint-disable-line quotes

  return `${
    useEqualSign
      ? ""
      : filter.condition === "IS"
      ? FILTER_LOGICAL_CONDITION.POSITIVE
      : `${FILTER_LOGICAL_CONDITION.NEGATIVE} `
  }${field}${useEqualSign ? (filter.condition === "IS" ? "=" : "!=") : ":"}${quotes}${
    (filter.value as MultiSelectListItem)?.id || filter.value
  }${quotes}`;
};

export const getSearchString = ({
  fields = [],
  filterItem,
  current = "",
  withoutBrackets,
  useEqualSign,
}: {
  fields?: TableFields[];
  filterItem: FilterDataItem;
  current?: string;
  withoutBrackets?: boolean;
  useEqualSign?: boolean;
}): string => {
  const { items } = filterItem as FilterData;

  if (items) {
    const filteredItems = items
      .map((item) => getSearchString({ fields, filterItem: item, current, withoutBrackets, useEqualSign }))
      .filter((i) => i);

    return `${filteredItems.length > 1 && !withoutBrackets ? "(" : ""}${filteredItems.join(
      ` ${(filterItem as FilterData).operator} `,
    )}${filteredItems.length > 1 && !withoutBrackets ? ")" : ""}`;
  }

  return buildSearchItem({ fields, filter: filterItem as FilterItem, useEqualSign });
};

export const sortFilterEntities = (entities: Record<string, string[]>) =>
  Object.entries(entities).reduce(
    (acc: FilterEntity, [prop, value]) => ({
      ...acc,
      [prop]: value.sort().map((v) => ({ id: v, name: v })),
    }),
    {},
  );

export const getCursorFilterEntities = (data: TableItemProps[], fields: TableFields[]) =>
  data?.reduce((acc: Record<string, string[]>, item: TableItemProps) => {
    fields.forEach((field) => {
      acc[field.property] = acc[field.property] || [];
      const value = getFieldFilterValue(item, field);

      if (!value) return;

      const values = Array.isArray(value)
        ? value
        : field.searchableObject
        ? Object.entries(item[field.property])
            .filter(([, v]) => !!v)
            .map(([key, v]) => JSON.stringify({ [key]: v }))
        : [value];

      values.forEach(
        (v: PossibleJsonValue) => !acc[field.property].includes(v.toString()) && acc[field.property].push(v.toString()),
      );
    });

    return acc;
  }, {});

export const getInventoryQueryData = (searchedFilter: FilterData, integrationTerraformEnabled: boolean) => {
  try {
    const res: Record<string, string | string[] | boolean> = (searchedFilter?.items || [])
      .filter((i) => (i as FilterItem).value || (i as FilterItem).values?.length)
      .reduce(
        (acc: { not: string[]; by_created_at: boolean }, i) => {
          if (["IS_NOT", "IS_NOT_ONE_OF"].includes((i as FilterItem).condition)) {
            acc.not.push((i as FilterItem).field as string);
          }

          if ((i as FilterItem).field === FILTER_PAGES.DATA_STATS) {
            try {
              const dataStats = JSON.parse((i as FilterItem).value as string).reduce(
                (values: Record<string, string[]>, item: DataStatsItem) => {
                  if (item.field && item.name) {
                    values.columns.push(item.field);
                    values.actions.push(item.operator);
                    values.renames.push(item.name);
                  }

                  return values;
                },
                {
                  columns: [],
                  actions: [],
                  renames: [],
                },
              );

              if (dataStats.columns.length) {
                return { ...acc, ...dataStats };
              }

              return acc;
            } catch {
              return acc;
            }
          }

          return {
            ...acc,
            [(i as FilterItem).field as string]: ["IS", "IS_NOT"].includes((i as FilterItem).condition)
              ? ((i as FilterItem).value as string).replace(NONE_SELECT_TAG_VALUE, "")
              : ((i as FilterItem).values as MultiSelectListItem[]).map((v) => v.id),
          };
        },
        {
          not: searchedFilter.dates?.condition === "IS_NOT" ? ["dates"] : [],
          by_created_at: searchedFilter.dates?.field === "created_at",
        },
      );

    const details = searchedFilter.items.find((f) => (f as FilterData).type === FILTER_PAGES.INVENTORY_DETAILS);

    if (details) {
      res.search = getSearchString({
        fields: inventoryFields(integrationTerraformEnabled),
        filterItem: details,
        withoutBrackets: true,
        useEqualSign: true,
      });
    }

    return res;
  } catch {
    return {};
  }
};

export const getInventoryQueryDataFromSavedFilter = ({
  filter,
  selectedAccountsIds,
  terraformFFEnabled,
}: {
  filter: SavedFilter;
  selectedAccountsIds?: string[];
  terraformFFEnabled: boolean;
}) => {
  const dates = getDatesFromFilterValue(
    getDatesFromSavedFilter(filter as SavedFilter, filter.type.trim() as FILTER_PAGES),
  );

  return {
    ...getInventoryQueryData(filter.filter as FilterData, terraformFFEnabled),
    accountId: selectedAccountsIds || [],
    start_time: dates[0],
    end_time: dates[1],
  };
};

const getFilterFields = (filterItem: FilterDataItem): FilterItem[] =>
  (filterItem as FilterData).items
    ? (filterItem as FilterData).items.flatMap(getFilterFields)
    : [filterItem as FilterItem];

export const getDataStatsError = (items: DataStatsItem[], required?: boolean) => {
  if ((required || items.length > 1 || items[0].name) && items.some((f) => !f.field)) {
    return 'Set "Report Field" for each Data Stat item'; // eslint-disable-line quotes
  }

  if ((required || items.length > 1 || items[0].field) && items.some((f) => !f.name)) {
    return 'Set "Display Name" for each Data Stat item'; // eslint-disable-line quotes
  }

  return "";
};

export const checkFilterFieldsError = (filterItem: FilterDataItem) => {
  const allFields = getFilterFields(filterItem);

  if (allFields.some((f) => !f.field)) {
    return 'Set "field" for each filter item'; // eslint-disable-line quotes
  }

  if (
    allFields.some(
      (f) =>
        (["IS", "IS_NOT"].includes(f.condition) && !f.value) ||
        (["IS_ONE_OF", "IS_NOT_ONE_OF"].includes(f.condition) && !f.values.length),
    )
  ) {
    return 'Set "value" for each filter item'; // eslint-disable-line quotes
  }

  return "";
};

export const isFilterWithDataAsset = (filterItem: FilterDataItem) => {
  try {
    const field = (filterItem as FilterData).items.find((i) => (i as FilterItem).field === FILTER_PAGES.DATA_STATS);

    if (!field) return false;

    const value = JSON.parse((field as FilterItem).value as string) as DataStatsItem[];

    return value.some((v) => v.field && v.name);
  } catch {
    return false;
  }
};

export const removeValueQuotes = (values: string[]) =>
  // eslint-disable-next-line quotes
  values.map((value) => (value.startsWith('"') && value.endsWith('"') ? value.slice(1, -1) : value));

export const extendFilterWithSearchProperties = (filter: FilterDataItem, jsonSearch: JsonSearchType) => {
  const order: Record<string, number> = {};

  const currentItems = [...(filter as FilterData).items].filter((item, index) => {
    if ((item as FilterData).items || !["IS", "IS_NOT"].includes((item as FilterItem).condition)) {
      return true;
    }

    order[((item as FilterItem).field as MultiSelectListItem)?.id] = index;

    return false;
  });

  Object.entries(jsonSearch).forEach(([prop, { exclude, include }]) => {
    removeValueQuotes([...exclude, ...include]).forEach((value, index) => {
      const item = {
        field: { id: prop, name: prop },
        value: { id: value, name: value },
        condition: index >= exclude.length ? "IS" : "IS_NOT",
        values: [],
      } as FilterItem;

      if (order[prop] === undefined) {
        currentItems.push(item);
      } else {
        currentItems.splice(order[prop], 0, item);
      }
    });
  });

  return currentItems;
};

export const getJsonSearchFromFilter = (filter: FilterDataItem) =>
  ((filter as FilterData).items || []).reduce((acc: JsonSearchType, i) => {
    const item = i as FilterItem;

    if (!["IS", "IS_NOT"].includes(item.condition) || !item.field) return acc;

    const fieldValue = (item.field as MultiSelectListItem)?.id || (item.field as string);
    const value = (item.value as MultiSelectListItem)?.id || (item.value as string);

    acc[fieldValue] = acc[fieldValue] || {
      exclude: [],
      include: [],
    };

    const typedValue = value === "true" ? true : value === "false" ? false : Number.isNaN(+value) ? value : +value;

    acc[fieldValue][item.condition === "IS" ? "include" : "exclude"].push(getJsonSearchValue(typedValue));

    return acc;
  }, {});

export const isJsonSearchEquals = (jsonSearchFirst: JsonSearchType, jsonSearchSecond: JsonSearchType) => {
  const firstItems = Object.entries(jsonSearchFirst)
    .flatMap(([prop, { exclude, include }]) =>
      [...exclude.map((v) => `-${v}`), ...include].map((v) => [prop, v].join()),
    )
    .sort();
  const secondItems = Object.entries(jsonSearchSecond)
    .flatMap(([prop, { exclude, include }]) =>
      [...exclude.map((v) => `-${v}`), ...include].map((v) => [prop, v].join()),
    )
    .sort();

  return JSON.stringify(firstItems) === JSON.stringify(secondItems);
};
