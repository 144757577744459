import { ReactElement } from "react";
import { inject, observer } from "mobx-react";

import { FEATURE, PERMISSION_TYPES } from "store/types";
import { RootStore } from "store/root.store";

export type RequirePermAccess = {
  feature?: FEATURE;
  permission?: PERMISSION_TYPES;
  fallback?: ReactElement | null;
};

type RequirePermProps = {
  store?: RootStore;
  element: ReactElement;
  access?: RequirePermAccess;
};

/**
 * RequirePerm checks if the current user has the specfied
 * permission. If so, it renders the specified node. Otherwise,
 * it renders the optional fallback node, if specified.
 */
const RequirePerm = ({ store, element, access = {} }: RequirePermProps) => {
  const { isPermissionAllowed, isFeatureEnabled } = store!.auth;
  const { permission, feature, fallback } = access;

  if ((!permission || isPermissionAllowed(permission)) && (!feature || isFeatureEnabled(feature))) return element;

  return fallback || null;
};

export default inject("store")(observer(RequirePerm));
