import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { inject, observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

import { DROPDOWNS, FILTER_PAGES, RefreshSavedWidgetsFilters, WIDGET_FILTER_TYPES, WidgetsSettings } from "store/types";
import { AccountDropdown, Button, ButtonWrapper, Input, Tooltip } from "components/ui";
import { RootStore } from "store/root.store";
import {
  CounterDarkPng,
  CounterLightPng,
  TableDarkPng,
  TableLightPng,
  PieChartLightPng,
  PieChartDarkPng,
} from "components/modals/AddWidgetModal/components";
import { DASHBOARD_ROUTE } from "consts";

type AddWidgetModalProps = {
  store?: RootStore;
  widget: Partial<WidgetsSettings>;
  refreshSavedWidgetsFilters?: RefreshSavedWidgetsFilters;
};

const widgets = [
  {
    widgetType: WIDGET_FILTER_TYPES.TABLE,
    srcLight: TableLightPng,
    srcDark: TableDarkPng,
  },
  {
    widgetType: WIDGET_FILTER_TYPES.COUNTER,
    srcLight: CounterLightPng,
    srcDark: CounterDarkPng,
  },
  {
    widgetType: WIDGET_FILTER_TYPES.PIE_CHART,
    srcLight: PieChartLightPng,
    srcDark: PieChartDarkPng,
  },
];

const AddWidgetModal = ({ store, widget, refreshSavedWidgetsFilters }: AddWidgetModalProps) => {
  const navigate = useNavigate();

  const [data, setData] = useState<{ name: string; widgetType: WIDGET_FILTER_TYPES; selectedAccountsIds: string[] }>({
    name: widget.name || "",
    widgetType: widget.widgetType || WIDGET_FILTER_TYPES.TABLE,
    selectedAccountsIds: widget.selectedAccountsIds || [],
  });

  const getAddButtonError = () => {
    if (!data.name) {
      return "Name is required";
    }

    if (
      store?.widgets.widgetSettings.find(
        (s) =>
          s.name === data.name &&
          s.widgetType === data.widgetType &&
          s.type === widget.type?.trim() &&
          s.id !== widget.id,
      )
    ) {
      return "Filter with this name already exist";
    }

    return "";
  };

  const create = async () => {
    const widgetData = { ...widget, ...data };

    await store!.widgets.addOrRemoveWidgetSettings({
      data: {
        ...widgetData,
        disabled: false,
      },
    });

    store?.ui.closeModal();

    if (refreshSavedWidgetsFilters) {
      await refreshSavedWidgetsFilters({ widgets: [widgetData] });
    } else {
      navigate(DASHBOARD_ROUTE);
    }
  };

  return (
    <Box display="flex" gap={{ xs: 3, laptop: 5 }} flexDirection="column">
      <Typography variant="table" color="text.secondary">
        Choose a widget view that will be displayed on the main dashboard.
      </Typography>

      <Box>
        <Input label="Widget name" value={data.name} onChange={(name) => setData((c) => ({ ...c, name }))} />
      </Box>

      <AccountDropdown
        useAsLabel
        fullWidth
        multiple={widget.type?.trim() === FILTER_PAGES.INVENTORY}
        type={widget.type?.trim() === FILTER_PAGES.INVENTORY ? DROPDOWNS.INVENTORY_ACCOUNT : DROPDOWNS.EVENTS_ACCOUNT}
        selectedAccountId={data.selectedAccountsIds[0] || ""}
        selectedAccounts={data.selectedAccountsIds}
        setSelectedAccountId={(accountId) => {
          setData((c) => ({ ...c, selectedAccountsIds: [accountId] }));
        }}
        setSelectedAccounts={(selectedAccountsIds) => {
          setData((c) => ({ ...c, selectedAccountsIds }));
        }}
      />

      <Box display="flex" gap={{ xs: 3, laptop: 5 }}>
        {widgets
          .filter((widgetItem) => {
            if ([WIDGET_FILTER_TYPES.COUNTER, WIDGET_FILTER_TYPES.PIE_CHART].includes(widgetItem.widgetType)) {
              const showPie = widget.type?.trim() === FILTER_PAGES.INVENTORY && widget.isDataAsset;

              return widgetItem.widgetType === WIDGET_FILTER_TYPES.PIE_CHART ? showPie : !showPie;
            }

            return true;
          })
          .map((widgetItem) => (
            <Box
              width={1}
              minHeight={{ xs: 50, sm: 100, laptop: 200 }}
              onClick={() => setData((c) => ({ ...c, widgetType: widgetItem.widgetType }))}
              key={widgetItem.widgetType}
              paddingTop={{ xs: 3, laptop: 5 }}
              paddingRight={{ xs: 3, laptop: 5 }}
              bgcolor="background.paper"
              borderRadius={1}
              overflow="hidden"
              border="1px solid"
              borderColor={widgetItem.widgetType === data.widgetType ? "primary.main" : "various.defaultBorder"}
              sx={{
                cursor: widgetItem.widgetType === data.widgetType ? "default" : "pointer",
                "&:hover": {
                  backgroundColor: store?.ui.isLightTheme ? "#F0F6FE" : "#1F3055",
                },
              }}
            >
              <Box
                width={1}
                display="flex"
                component="img"
                src={store?.ui.isLightTheme ? widgetItem.srcLight : widgetItem.srcDark}
                alt={widgetItem.widgetType}
              />
            </Box>
          ))}
      </Box>

      <ButtonWrapper marginTop={0}>
        <>
          <Button onClick={store?.ui.closeModal} text="Cancel" variant="text" buttonSx={{ marginRight: "auto" }} />
          <Tooltip title={getAddButtonError()}>
            <Button disabled={!!getAddButtonError()} onClick={create} text={widget ? "Save" : "Create"} />
          </Tooltip>
        </>
      </ButtonWrapper>
    </Box>
  );
};

export default inject("store")(observer(AddWidgetModal));
