import React, { ReactElement, useState, MouseEvent } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, SvgIcon, Typography, useMediaQuery, useTheme } from "@mui/material";
import { inject, observer } from "mobx-react";

import { RequirePerm } from "components/guards";
import { FEATURE, PERMISSION_TYPES } from "store/types";
import { RootStore } from "store/root.store";
import { Tooltip } from "components/ui";
import { RequirePermAccess } from "components/guards/RequirePerm/requirePerm";
import { CLOUD_MAP_ACCOUNTS_ROUTE, DASHBOARD_ROUTE, EVENTS_ROUTE, INVENTORY_ROUTE, RULES_ROUTE } from "consts";
import { ArrowSvg } from "components/icons";
import { darkTextPrimaryColor, mediumDark, primaryMainDark, primaryMainLight } from "theme/commonVariables";

type NavItemProps = {
  icon: typeof SvgIcon;
  url?: string;
  access?: RequirePermAccess;
  permission?: PERMISSION_TYPES;
  title: string;
  store?: RootStore;
  sectionTitle?: boolean;
  dropdownMenu?: ReactElement;
};

const NavItem = ({ icon: Icon, url, access, title, sectionTitle, store, dropdownMenu }: NavItemProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const { issueId, dashboardId, ruleId, resourceId, eventId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [dropdownOpened, setDropdownOpened] = useState(false);

  const isActive =
    issueId || dashboardId
      ? url === DASHBOARD_ROUTE
      : ruleId
      ? url === RULES_ROUTE
      : resourceId
      ? url === INVENTORY_ROUTE
      : eventId
      ? url === EVENTS_ROUTE
      : location.pathname.includes("cloudMap")
      ? url === CLOUD_MAP_ACCOUNTS_ROUTE
      : location.pathname.startsWith(url as string);

  const hasDashboardV1Feature = store?.auth?.features?.includes(FEATURE.DASHBOARD_V1);

  if (url === DASHBOARD_ROUTE && !hasDashboardV1Feature) {
    return null;
  }

  const menuClick = () => {
    if (!url) return;

    navigate(url);

    store?.ui.changeMobileMenuOpened(false);
  };

  const openDropdown = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setDropdownOpened((curr) => !curr);
  };

  return (
    <RequirePerm
      access={access}
      element={
        <Box>
          <Box
            height={40}
            data-testid="navItem"
            display="flex"
            position="relative"
            onClick={menuClick}
            marginY={2}
            paddingX={2.5}
            paddingY={1.5}
            borderRadius={1}
            component={url ? "div" : "button"}
            bgcolor={isActive ? "various.navBG" : "transparent"}
            sx={{ cursor: sectionTitle ? "default" : "pointer" }}
            justifyContent={{ xs: "flex-start", laptop: store?.ui.smallView ? "center" : "flex-start" }}
            {...(url ? {} : { type: "submit" })}
          >
            <Typography
              variant="main"
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              color={isActive ? "primary.main" : "text.primary"}
              width={1}
            >
              <Box
                display={{
                  xs: sectionTitle ? "none" : "inherit",
                  laptop: sectionTitle && !store?.ui?.smallView ? "none" : "inherit",
                }}
              >
                <Tooltip title={store?.ui.smallView && title}>
                  <Icon
                    sx={{
                      display: "flex",
                      color: isActive ? "primary.main" : store?.ui.isLightTheme ? "#5A5FA0" : "text.primary",
                    }}
                  />
                </Tooltip>{" "}
              </Box>
              <Box
                fontWeight={sectionTitle ? 700 : "inherit"}
                fontSize={sectionTitle ? 18 : "inherit"}
                display={{ xs: "inherit", laptop: store?.ui?.smallView ? "none" : "inherit" }}
                marginLeft={sectionTitle ? 0 : 3}
              >
                {title}
              </Box>
              {!!dropdownMenu && (!store?.ui.smallView || isMobile) && (
                <Box
                  onClick={openDropdown}
                  position="absolute"
                  width={dropdownOpened ? 37 : 31}
                  height={40}
                  top={0}
                  right={0}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    svg: {
                      transform: `rotate(${dropdownOpened ? 90 : 0}deg)`,
                      fill: `${
                        isActive
                          ? store?.ui.isLightTheme
                            ? primaryMainLight
                            : primaryMainDark
                          : store?.ui.isLightTheme
                          ? mediumDark
                          : darkTextPrimaryColor
                      } !important`,
                    },
                  }}
                >
                  <ArrowSvg />
                </Box>
              )}
            </Typography>
          </Box>
          {dropdownOpened && (!store?.ui.smallView || isMobile) && (
            <Box marginBottom={10} paddingY={2} borderRadius={1} bgcolor="background.tableRow">
              {dropdownMenu}
            </Box>
          )}
        </Box>
      }
    />
  );
};

export default inject("store")(observer(NavItem));
