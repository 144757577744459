import React from "react";
import { inject, observer } from "mobx-react";

import { FEATURE, FilterConditionKey, Inventory, INVENTORY_TERRAFORM_STATUS, UI_TABLES } from "store/types";
import {
  PossibleJsonValue,
  ProviderServiceTypeIcon,
  Status,
  SUPPORTED_STATUSES_TYPE,
  Table,
  TagsCountList,
  Price,
  TableFields,
} from "components/ui";
import { InventoryAdditionalInfo, InventoryAdditionalInfoHeader } from "routes/InventoryRoute/components";
import { formattedDate } from "tools";
import { getIconTypeByResource } from "tools/utils";
import { RootStore } from "store/root.store";

type InventoryTableProps = {
  store?: RootStore;
  widgetId?: string;
  relatedResources?: boolean;
  clickOnTag?: (property: string, value: PossibleJsonValue) => void;
  isDynamicFields?: boolean;
  data: Inventory[];
};

export const inventoryFields = (integrationTerraformEnabled: boolean): TableFields[] => [
  {
    name: "Type",
    property: "type",
    textValue: (item: Inventory) => item.type,
    render: (item: Inventory) => <ProviderServiceTypeIcon type={getIconTypeByResource(item.type)} text={item.type} />,
    filterConditions: ["IS"] as FilterConditionKey[],
  },
  {
    name: "Name",
    property: "name",
    breakWordsLine: true,
  },
  {
    name: "Location",
    property: "location",
    filterConditions: ["IS"] as FilterConditionKey[],
  },
  {
    name: "Tags",
    property: "tags",
    render: (item: Inventory) => <TagsCountList tags={item.tags} />,
    textValue: (item: Inventory) => (item.tags ? JSON.stringify(item.tags) : ""),
    notSortable: true,
    searchableObject: true,
    filterConditions: ["IS"] as FilterConditionKey[],
  },
  ...(integrationTerraformEnabled
    ? [
        {
          name: "IaC Status",
          property: "iacStatus",
          render: (item: Inventory) => (
            <Status
              status={item.terraform_status || INVENTORY_TERRAFORM_STATUS.NOT_CONNECTED}
              statusType={SUPPORTED_STATUSES_TYPE.TERRAFORM}
            />
          ),
          textValue: (item: Inventory) => item.terraform_status || INVENTORY_TERRAFORM_STATUS.NOT_CONNECTED,
          notSortable: true,
          additionalInfo: "table",
        },
      ]
    : []),
  {
    name: "Price (month to date)",
    property: "month_to_date_cost",
    render: (item: Inventory) => <Price price={item.month_to_date_cost} />,
    textValue: (item: Inventory) => item.month_to_date_cost?.toString() || "",
    additionalInfo: "table",
    sortByNumber: true,
  },
  {
    name: "Created At",
    property: "created_at",
    render: (item: Inventory) => formattedDate(item.created_at),
    additionalInfo: "table",
  },
  {
    name: "Updated At",
    property: "updated_at",
    render: (item: Inventory) => formattedDate(item.updated_at),
    additionalInfo: "table",
    defaultSort: -1,
  },
];

const InventoryTable = ({
  store,
  data,
  widgetId,
  relatedResources,
  clickOnTag,
  isDynamicFields,
  ...props
}: InventoryTableProps) => (
  <Table
    {...props}
    removePadding
    isDynamicFields={isDynamicFields}
    type={
      relatedResources
        ? UI_TABLES.INVENTORY_RELATED_RESOURCES
        : widgetId
        ? `${UI_TABLES.INVENTORY}_${widgetId}`
        : UI_TABLES.INVENTORY
    }
    noDataText={relatedResources ? "No related resources found" : "No resources found"}
    fields={inventoryFields(!!store?.auth.isFeatureEnabled(FEATURE.INTEGRATIONS_TERRAFORM)).filter(
      (f) => (!relatedResources && !widgetId) || ["name", "type", "location"].includes(f.property),
    )}
    data={data}
    additionalInfo={isDynamicFields ? undefined : <InventoryAdditionalInfo clickOnTag={clickOnTag} />}
    additionalInfoHeader={isDynamicFields ? undefined : <InventoryAdditionalInfoHeader />}
  />
);

export default inject("store")(observer(InventoryTable));
