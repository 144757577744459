import React from "react";
import { Box, useTheme } from "@mui/material";

import { PROVIDER_ICON_BY_SERVICE_TYPE } from "tools/utils";
import {
  AWSKeySvg,
  AWSRoleSvg,
  AWSLambdaSvg,
  AWSBucketSvg,
  AWSLaunchSvg,
  AWSVPCSvg,
  AWSPolicySvg,
  AWSSubnetSvg,
  AWSNetworkSvg,
  AWSVolumeSvg,
  AWSSnapshotSvg,
  AWSEIPSvg,
  AWSAMISvg,
  AWSInstanceSvg,
  AWSGroupSvg,
  AWSUserSvg,
  AWSClusterSvg,
  AWSRouteSvg,
  AWSCredentialSvg,
  AWSEventsSvg,
  AWSLogsSvg,
  AWSSsmSvg,
  AWSEcsSvg,
  AWSGlueSvg,
  AWSCacheSvg,
  AWSVpcEndpointSvg,
  AWSCloudfrontSvg,
  AWSCloudwatchSvg,
  AWSEcrSvg,
  AWSRegionSvg,
  AWSSecretSvg,
  AWSTableSvg,
  AWSConfigSvg,
  AWSFileSvg,
  GCPSubnetworkSvg,
  GCPRouteSvg,
  GCPNetworkSvg,
  GCPLogSvg,
  GCPBucketSvg,
  GCPInstanceSvg,
  GCPServiceSvg,
  GCPFirewallSvg,
  GCPTagSvg,
  GCPDiskSvg,
  GCPAddressSvg,
  GCPHealthSvg,
  GCPProjectSvg,
  GCPTopicSvg,
  GCPKeySvg,
  GCPPolicySvg,
  GCPNotificationChannelSvg,
  GCPDeploySvg,
  GCPEndpointSvg,
  GCPFunctionSvg,
  GCPAppSvg,
  GCPBackupSvg,
  GCPConfigurationSvg,
  GCPCronSvg,
  GCPSecretSvg,
  GCPDbSvg,
  GCPProxySvg,
  GCPReportSvg,
  GCPKubernetesSvg,
  GCPRuleSvg,
  GCPSubscriptionSvg,
  GCPTemplateSvg,
} from "components/ui/ProviderServiceTypeIcon/components";

export const ProviderServiceIcon = ({
  type,
  withoutDefaultStyles,
}: {
  type: PROVIDER_ICON_BY_SERVICE_TYPE;
  withoutDefaultStyles?: boolean;
}) => {
  const theme = useTheme();

  const defaultAWSStyles = withoutDefaultStyles
    ? {}
    : {
        transform: "scale(0.5)",
        marginLeft: -3,
        marginRight: -2,
        marginTop: -3,
        marginBottom: -3,
      };

  const defaultGCPStyles = withoutDefaultStyles
    ? {}
    : {
        marginLeft: -1,
        marginTop: -3,
        marginBottom: -3,
        svg: {
          width: 26,
          height: 26,
        },
      };

  const defaultAWSDarkStyles = withoutDefaultStyles
    ? {}
    : {
        ...defaultAWSStyles,
        ...(theme.palette.mode !== "light" && {
          svg: {
            path: { fill: "#5CC9FA" },
          },
        }),
      };

  switch (type) {
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_KEY:
      return (
        <Box display="flex" sx={defaultAWSStyles}>
          <AWSKeySvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_ROLE:
      return (
        <Box display="flex" sx={defaultAWSStyles}>
          <AWSRoleSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_LAMBDA:
      return (
        <Box display="flex" sx={defaultAWSStyles}>
          <AWSLambdaSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_S3_BUCKET:
      return (
        <Box display="flex" sx={defaultAWSStyles}>
          <AWSBucketSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_LAUNCH_TEMPLATE:
      return (
        <Box display="flex" sx={defaultAWSStyles}>
          <AWSLaunchSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_VPC:
      return (
        <Box display="flex" sx={defaultAWSStyles}>
          <AWSVPCSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_POLICY:
      return (
        <Box display="flex" sx={withoutDefaultStyles ? {} : { ...defaultAWSStyles, marginRight: -2 }}>
          <AWSPolicySvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_NETWORK:
      return (
        <Box display="flex" sx={defaultAWSDarkStyles}>
          <AWSNetworkSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_VOLUME:
      return (
        <Box display="flex" sx={defaultAWSStyles}>
          <AWSVolumeSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_SNAPSHOT:
      return (
        <Box display="flex" sx={defaultAWSStyles}>
          <AWSSnapshotSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_EIP:
      return (
        <Box display="flex" sx={defaultAWSStyles}>
          <AWSEIPSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_AMI:
      return (
        <Box display="flex" sx={defaultAWSStyles}>
          <AWSAMISvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_INSTANCE:
      return (
        <Box display="flex" sx={defaultAWSStyles}>
          <AWSInstanceSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_ROUTE:
      return (
        <Box display="flex" sx={defaultAWSDarkStyles}>
          <AWSRouteSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_USER:
      return (
        <Box
          display="flex"
          sx={withoutDefaultStyles ? {} : { ...defaultAWSDarkStyles, transform: "scale(0.45)", marginRight: -1.5 }}
        >
          <AWSUserSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_GROUP:
      return (
        <Box display="flex" sx={defaultAWSStyles}>
          <AWSGroupSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_CLUSTER:
      return (
        <Box display="flex" sx={defaultAWSDarkStyles}>
          <AWSClusterSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_CREDENTIAL:
      return (
        <Box display="flex" sx={defaultAWSDarkStyles}>
          <AWSCredentialSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_LOGS:
      return (
        <Box display="flex" sx={defaultAWSDarkStyles}>
          <AWSLogsSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_TABLE:
      return (
        <Box display="flex" sx={defaultAWSDarkStyles}>
          <AWSTableSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_EVENTS:
      return (
        <Box display="flex" sx={defaultAWSDarkStyles}>
          <AWSEventsSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_REGION:
      return (
        <Box display="flex" sx={defaultAWSDarkStyles}>
          <AWSRegionSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_SECRET:
      return (
        <Box display="flex" sx={defaultAWSDarkStyles}>
          <AWSSecretSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_CONFIG:
      return (
        <Box display="flex" sx={defaultAWSDarkStyles}>
          <AWSConfigSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_SSM:
      return (
        <Box display="flex" sx={defaultAWSDarkStyles}>
          <AWSSsmSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_VPC_ENDPOINT:
      return (
        <Box display="flex" sx={defaultAWSDarkStyles}>
          <AWSVpcEndpointSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_CLOUDWATCH:
      return (
        <Box display="flex" sx={defaultAWSDarkStyles}>
          <AWSCloudwatchSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_CLOUDFRONT:
      return (
        <Box display="flex" sx={defaultAWSDarkStyles}>
          <AWSCloudfrontSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_CACHE:
      return (
        <Box display="flex" sx={defaultAWSDarkStyles}>
          <AWSCacheSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_ECR:
      return (
        <Box display="flex" sx={defaultAWSDarkStyles}>
          <AWSEcrSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_ECS:
      return (
        <Box display="flex" sx={defaultAWSDarkStyles}>
          <AWSEcsSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_GLUE:
      return (
        <Box display="flex" sx={defaultAWSDarkStyles}>
          <AWSGlueSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_FILE:
      return (
        <Box display="flex" sx={defaultAWSDarkStyles}>
          <AWSFileSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_SUBNET:
    case PROVIDER_ICON_BY_SERVICE_TYPE.AWS_DEFAULT:
      return (
        <Box
          display="flex"
          sx={
            withoutDefaultStyles
              ? {}
              : {
                  ...defaultAWSStyles,
                  marginBottom: -3.5,
                  svg: {
                    path: { fill: theme.palette.mode === "light" ? "#4141F2" : "#5CC9FA" },
                  },
                }
          }
        >
          <AWSSubnetSvg />
        </Box>
      );

    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_SUBNETWORK:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPSubnetworkSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_ROUTE:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPRouteSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GRP_NETWORK:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPNetworkSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_LOG:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPLogSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_BUCKET:
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_DEFAULT:
      return (
        <Box
          display="flex"
          sx={
            withoutDefaultStyles ? {} : { ...defaultGCPStyles, svg: { ...defaultGCPStyles.svg, width: 36, height: 36 } }
          }
        >
          <GCPBucketSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_SERVICE:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPServiceSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_FIREWALL:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPFirewallSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_TAG:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPTagSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_INSTANCE:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPInstanceSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_DISK:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPDiskSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_PROJECT:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPProjectSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_ADDRESS:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPAddressSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_HEALTH_CHECK:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPHealthSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_POLICY:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPPolicySvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_NOTIFICATION_CHANNEL:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPNotificationChannelSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_KEY:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPKeySvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_TOPIC:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPTopicSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_KUBERNETES:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPKubernetesSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_TABLE:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPDbSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_SECRET:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPSecretSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_ENDPOINT:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPEndpointSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_CRON:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPCronSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_BACKUP:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPBackupSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_DEPLOY:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPDeploySvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_FUNCTION:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPFunctionSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_CONFIGURATION:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPConfigurationSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_PROXY:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPProxySvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_TEMPLATE:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPTemplateSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_APP:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPAppSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_RULE:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPRuleSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_REPORT:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPReportSvg />
        </Box>
      );
    case PROVIDER_ICON_BY_SERVICE_TYPE.GCP_SUBSCRIPTION:
      return (
        <Box display="flex" sx={defaultGCPStyles}>
          <GCPSubscriptionSvg />
        </Box>
      );

    case PROVIDER_ICON_BY_SERVICE_TYPE.NOT_FOUND:
    default:
      return <Box display="none" />;
  }
};
