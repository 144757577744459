import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

import { Loading, TableProps } from "components/ui";
import { TableMobileDataElement } from "components/ui/Table/components/TableMobile/components";

type TableMobileProps = TableProps & { isFilerEnabled?: boolean };

export const TableMobile = ({
  type,
  fields,
  data,
  noDataText,
  search,
  actions,
  isFilerEnabled,
  mobileTopSection,
  loadMoreCount,
  additionalInfo,
  additionalInfoHeader,
}: TableMobileProps) => {
  const [showNoDataText, setShowNoDataText] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowNoDataText(true), 1000);
  }, []);

  return (
    <Box data-testid="tableMobile">
      {!data?.length && noDataText && (
        <Box
          data-testid="tableMobileNoText"
          paddingY={2}
          paddingBottom={4}
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          {showNoDataText ? (
            <Typography variant="main">{search || isFilerEnabled ? "No data found" : noDataText}</Typography>
          ) : (
            <Loading marginBottom={5} />
          )}
        </Box>
      )}
      <Box marginTop={-3}>
        {data.map((item) => (
          <TableMobileDataElement
            type={type}
            key={item.id}
            data={data}
            fields={fields}
            item={item}
            actions={actions}
            mobileTopSection={mobileTopSection}
            loadMoreCount={loadMoreCount}
            additionalInfo={additionalInfo}
            additionalInfoHeader={additionalInfoHeader}
          />
        ))}
      </Box>
    </Box>
  );
};
