import React, { ReactElement } from "react";
import { inject, observer } from "mobx-react";
import { Box, Typography } from "@mui/material";

import { Button, ButtonWrapper } from "components/ui";
import { RootStore } from "store/root.store";
import { CongratulationsSvg } from "components/icons";

const CongratulationScreen = ({
  store,
  text,
  component,
}: {
  store?: RootStore;
  component?: ReactElement;
  text?: string;
}) => (
  <Box>
    <Box display="flex" gap={5} flexDirection="column" alignItems="center" paddingBottom={10} paddingTop={6}>
      <CongratulationsSvg />
      <Typography variant="h1">Congratulations!</Typography>
      {component || <Typography variant="table">{text}</Typography>}
    </Box>

    <ButtonWrapper marginTop={0}>
      <Button onClick={store?.ui.closeModal} text="Done" />
    </ButtonWrapper>
  </Box>
);

export default inject("store")(observer(CongratulationScreen));
