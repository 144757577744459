import ReactDOM from "react-dom/client";

import "styles/index.scss";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import App from "app";

import reportWebVitals from "./reportWebVitals";
import sentry from "./sentry";

sentry();

const root = ReactDOM.createRoot(document.getElementById("app-root")!);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
