import React from "react";
import { Box } from "@mui/material";

import { TagsItem, JsonViewerType, PossibleJsonValue } from "components/ui";

export const TagsItemList = ({
  tags,
  clickOnTag,
}: {
  tags: JsonViewerType;
  clickOnTag?: (property: string, value: PossibleJsonValue) => void;
}) => {
  if (!tags) return null;

  return (
    <Box display="flex" gap={2} flexWrap="wrap">
      {Object.entries(tags).map(([property, value], index) => (
        <Box
          sx={{ ...(!!clickOnTag && { cursor: "pointer" }) }}
          onClick={() => clickOnTag?.(property, value)}
          key={property}
        >
          <TagsItem index={index} property={property} value={value} />
        </Box>
      ))}
    </Box>
  );
};
