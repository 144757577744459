import React, { ReactElement } from "react";
import { Typography } from "@mui/material";

import { COMPLIANCE_STATUS, INVENTORY_TERRAFORM_STATUS } from "store/types";
import {
  TerraformNotConnectedSvg,
  TerraformManagedSvg,
  TerraformUnmanagedSvg,
  TerraformDriftedSvg,
} from "components/ui/Status/svg";

export enum SUPPORTED_STATUSES {
  ACTIVE = "active",
  FAILED = "failed",
  TIMED_OUT = "timed_out",
  FAULT = "fault",
  CANCELLED = "cancelled",
}

export enum SUPPORTED_STATUSES_TYPE {
  ACCOUNT = "account",
  NOTIFICATION = "notification",
  TERRAFORM = "terraform",
  COMPLIANCE = "compliance",
}

const getAccountStatusData = (status: string) => {
  switch (status) {
    case SUPPORTED_STATUSES.ACTIVE:
      return {
        type: "success",
        text: "Active",
      };
    case SUPPORTED_STATUSES.FAULT:
    case SUPPORTED_STATUSES.CANCELLED:
      return {
        type: "warning",
        text: "Stopped",
      };
    case SUPPORTED_STATUSES.FAILED:
    case SUPPORTED_STATUSES.TIMED_OUT:
      return {
        type: "error",
        text: "Failed",
      };
    default:
      return {
        type: "pending",
        text: "Pending",
      };
  }
};

const getComplianceStatusData = (status: string) => {
  switch (status) {
    case COMPLIANCE_STATUS.PASSED:
      return {
        type: "success",
        text: "Passed",
      };
    case COMPLIANCE_STATUS.NEEDS_REVIEW:
      return {
        type: "warning",
        text: "Needs review",
      };
    default:
      return {
        type: "secondarySuccess",
        text: "Info",
      };
  }
};

const getNotificationStatusData = (status: string) => {
  switch (status) {
    case "enabled":
      return {
        type: "success",
        text: "Active",
      };
    default:
      return {
        type: "pending",
        text: "Disabled",
      };
  }
};

const getTerraformStatusData = (status: string) => {
  switch (status) {
    case INVENTORY_TERRAFORM_STATUS.MANAGED:
      return {
        type: "success",
        icon: <TerraformManagedSvg />,
      };
    case INVENTORY_TERRAFORM_STATUS.UNMANAGED:
      return {
        type: "secondarySuccess",
        icon: <TerraformUnmanagedSvg />,
      };
    case INVENTORY_TERRAFORM_STATUS.DRIFTED:
      return {
        type: "warning",
        icon: <TerraformDriftedSvg />,
      };
    default:
      return {
        type: "pending",
        icon: <TerraformNotConnectedSvg />,
      };
  }
};

const getStatusData = ({
  status,
  statusType,
}: {
  status: string;
  statusType: SUPPORTED_STATUSES_TYPE;
}): { type: string; text: string; icon?: ReactElement } => {
  if (statusType === SUPPORTED_STATUSES_TYPE.COMPLIANCE) {
    return getComplianceStatusData(status);
  }

  if (statusType === SUPPORTED_STATUSES_TYPE.ACCOUNT) {
    return getAccountStatusData(status);
  }

  if (statusType === SUPPORTED_STATUSES_TYPE.NOTIFICATION) {
    return getNotificationStatusData(status);
  }

  const terraformData = getTerraformStatusData(status);

  return {
    ...terraformData,
    text: status,
  };
};

export const Status = (data: { status: string; statusType: SUPPORTED_STATUSES_TYPE }) => {
  const { statusType } = data;

  const { type, text, icon } = getStatusData(data);

  return (
    <Typography
      whiteSpace="nowrap"
      data-testid="status"
      color={`${type}.main`}
      borderRadius={statusType === SUPPORTED_STATUSES_TYPE.TERRAFORM ? 2 : 6}
      variant="main"
      bgcolor={`${type}.light`}
      paddingX={2}
      paddingY={1}
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      gap={1.5}
      sx={{
        svg: {
          width: 20,
          height: 20,
        },
      }}
    >
      {icon || ""}
      {text}
    </Typography>
  );
};
