import { ARN } from "link2aws";

import { CLOUD_PLATFORM, Inventory } from "store/types";
import { CLOUD_MAP_ASSETS_ROUTE, INVENTORY_BY_RESOURCE_ID_ROUTE } from "consts";

const getAwsInstanceLink = (arn: string, link: string) => {
  const { hostname } = new URL(link);
  const region = hostname.split(".")[0];
  const provider = arn.split("/").pop();

  return link.replace("/ec2/v2/home", `/ec2/home?region=${region}#InstanceDetails:instanceId=${provider}`);
};

const getAWSConsoleLinkByArn = (arn = "") => {
  try {
    const link = new ARN(arn).consoleLink || "";

    if (link.endsWith("/ec2/v2/home")) return getAwsInstanceLink(arn, link);

    return link;
  } catch {
    return "";
  }
};

export const getConsoleLinksFromInventory = ({ provider, item }: { provider?: CLOUD_PLATFORM; item?: Inventory }) => {
  if (provider?.toUpperCase() === CLOUD_PLATFORM.AWS) {
    return getAWSConsoleLinkByArn(item?.provider_id);
  }
};

export const getInventoryResourceLink = ({ accountId, resourceId }: { accountId: string; resourceId: string }) =>
  INVENTORY_BY_RESOURCE_ID_ROUTE.replace(":accountId", accountId).replace(
    ":resourceId",
    encodeURIComponent(resourceId),
  );

export const getCloudMapResourceLink = ({ accountId, resourceId }: { accountId: string; resourceId: string }) =>
  CLOUD_MAP_ASSETS_ROUTE.replace(":accountId", accountId).replace(":resourceId", encodeURIComponent(resourceId));
