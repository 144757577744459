import { api } from "libs";
import { EventAggregation, EventView } from "store/types";
import { API_EVENTS_VIEW_BY_ACCOUNT_ID } from "libs/api/endpoints";
import { buildSearchUrl } from "tools/cursorHandler/cursorHandler";
import { CursorFetchData, CursorFilters, CursorParameters } from "tools/cursorHandler/cursorHandler.types";

export const getEventsAggregatedData = async (params: CursorFilters): Promise<EventAggregation[]> => {
  if (!params?.accountId) return [];

  const res = await api.get(
    buildSearchUrl(API_EVENTS_VIEW_BY_ACCOUNT_ID(params.accountId as string), {
      ...params,
      accountId: "",
      aggregate: true,
    }),
    {
      errorMessage: {
        title: "Failed to load Events Aggregated data",
      },
    },
  );

  return res.data;
};

export const getEvents = async (
  params: CursorParameters,
  doNotShowErrorMessage?: boolean,
): Promise<CursorFetchData<EventView>> => {
  if (!params?.accountId) return { data: [] };

  return api.get(
    buildSearchUrl(API_EVENTS_VIEW_BY_ACCOUNT_ID(params.accountId as string), {
      ...params,
      accountId: "",
    }),
    {
      doNotShowErrorMessage,
      errorMessage: {
        title: "Failed to load Events",
      },
    },
  );
};
