import React from "react";
import { Box, Typography } from "@mui/material";

import { GoogleSvg, GithubSvg, MicrosoftSvg } from "components/icons";
import { API_BASE_URL, API_AUTH } from "libs/api/endpoints";
import { AUTH_PROVIDER, TYPE_AUTH } from "store/types";

export const AUTH_PROVIDERS = [
  {
    name: "Google",
    url: "google",
    type: AUTH_PROVIDER.GOOGLE,
    icon: <GoogleSvg />,
  },
  {
    name: "Github",
    url: "github",
    type: AUTH_PROVIDER.GITHUB,
    icon: <GithubSvg />,
  },
  {
    name: "Microsoft",
    url: "microsoft",
    type: AUTH_PROVIDER.MICROSOFT,
    icon: <MicrosoftSvg />,
  },
  // {
  //   name: "Okta",
  //   url: "okta",
  //   type: AUTH_PROVIDER.OKTA,
  //   icon: <OktaSvg />,
  // },
];

const SocialAuthForm = ({ type, onlyType }: { type: TYPE_AUTH; onlyType?: AUTH_PROVIDER }) => {
  const goTo = (url: string) => (window.location.href = `${API_BASE_URL}${API_AUTH(url, type)}`);

  return (
    <Box marginBottom={5} display="flex" flexDirection="column" alignItems="center">
      {AUTH_PROVIDERS.filter((system) => !onlyType || system.type === onlyType).map((system) => (
        <Box
          display="flex"
          onClick={() => goTo(system.url)}
          alignItems="center"
          justifyContent="center"
          paddingY={3}
          marginBottom={3}
          key={system.name}
          border="1px solid"
          borderRadius={1}
          borderColor="background.oppositePaperBG"
          width={300}
          sx={{
            cursor: "pointer",
            "&:hover": { borderColor: "primary.main" },
            backgroundColor: {
              xs: "background.inputBG",
              laptop: "transparent",
            },
          }}
        >
          {system.icon}
          <Typography marginLeft={3} fontSize={15} color="background.oppositePaperBG" variant="main">
            {type === TYPE_AUTH.LOGIN ? "Log in" : "Sign up"} with {system.name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default SocialAuthForm;
