import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { NoAccountDataLight, NoAccountDataDark } from "components/containters/NoAccountsData/components";
import { Button } from "components/ui";
import { RootStore } from "store/root.store";
import { ACCOUNTS_ROUTE } from "consts";

const NoAccountsData = ({ store }: { store?: RootStore }) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent={{ xs: "unset", laptop: "center" }}
    height={1}
    paddingTop={{ xs: 10, laptop: 0 }}
    width={{ xs: 1, laptop: "unset" }}
    maxWidth={{ xs: "500px", laptop: "none" }}
    margin={{ xs: "0 auto", laptop: 0 }}
    sx={{
      "& svg": { width: { xs: 1, laptop: "unset" } },
    }}
  >
    {store?.ui?.isLightTheme ? <NoAccountDataLight /> : <NoAccountDataDark />}
    <Typography
      marginTop={{ xs: 6, laptop: 8 }}
      marginBottom={{ xs: 8, laptop: 10 }}
      color={store?.ui?.isLightTheme ? "text.secondary" : "text.primary"}
      textAlign="center"
      sx={{ typography: { xs: "h3", laptop: "h2" }, fontWeight: `${600} !important` }}
    >
      You haven’t connected any cloud accounts yet
    </Typography>
    <Box width={{ xs: 1, laptop: "unset" }} paddingBottom={{ xs: 5, laptop: 10, lg: 20, xl: 25 }}>
      <Link to={ACCOUNTS_ROUTE}>
        <Button fullWidthOnMobile text="Connect an Account" />
      </Link>
    </Box>
  </Box>
);

export default inject("store")(observer(NoAccountsData));
