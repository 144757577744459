/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { inject, observer } from "mobx-react";
import { Box, Divider, Typography, Link as MuiLink } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { CopyBlock, dracula, nord } from "react-code-blocks";

import { Button, ButtonWrapper, FormFieldsBuilder, FormikItemType, Loading, Table, UnderlineLink } from "components/ui";
import { RootStore } from "store/root.store";
import {
  AddOrgRequest,
  AddOrgResponse,
  CLOUD_PLATFORM,
  FEATURE,
  ONBOARDING_STATUSES,
  OrgOnBoardingData,
  UI_TABLES,
} from "store/types";
import { AWSSvg, GCPSvg } from "components/icons";
import { AddOrganizationProgressList, CompleteSvg } from "components/modals/AddOrganizationModal/components";
import { INITIALIZING_STEP } from "consts";
import { ORG_ONBOARDING_STEPS } from "components/modals/AddOrganizationModal/components/consts";
// eslint-disable-next-line max-len
import { codeInstructionsStyles } from "components/modals/AddAccountModal/components/OnboardingInstructions/onboardingInstructions";

const defaultValidationSchema = {
  name: yup.string().required("Please provide the Display Name for Organization"),
};

const awsValidationSchema = {
  parent_account_id: yup.string().required("Please provide the Root Account ID"),
  aws_admin_account_id: yup.string().required("Please provide the Organization Unit ID"),
};

const gcpValidationSchema = {
  parent_account_id: yup.string().required("Please provide the Organization ID"),
};

const statusFields = [{ property: "property" }, { property: "value", breakWordsLine: true }];

const gcpPolicy = `gcloud organizations add-iam-policy-binding {ORG_ID} \\
  --member {OPSHELM_SERVICE_ACCOUNT_HERE} \\
  --role roles/cloudasset.viewer \\
  --include-children
gcloud organizations add-iam-policy-binding {ORG_ID} \\
  --member {OPSHELM_SERVICE_ACCOUNT_HERE} \\
  --role roles/browser \\
  --include-children`;

const gcpSecondPolicy = `gcloud logging sinks create opshelm-log-sink \\
pubsub.googleapis.com/projects/definitely-not-prod/topics/logsink-{ORG_CREATED_ID} \\
  --include-children \\
  --log-filter 'protoPayload.@type="type.googleapis.com/google.cloud.audit.AuditLog" AND SEVERITY>="NOTICE"' \\
  --organization={ORG_ID}`;

const gcpThirdPolicy = "https://console.cloud.google.com/iam-admin/audit/allservices?organizationId={ORG_ID}";

const getDocumentationLink = ({
  orgData,
  organizationAccountId,
}: {
  orgData?: AddOrgResponse;
  organizationAccountId: string;
}) => {
  if (orgData?.provider?.toUpperCase() === CLOUD_PLATFORM.AWS) {
    const url = "https://us-east-1.console.aws.amazon.com/cloudformation/home/";

    const queryParams = new URLSearchParams({
      templateURL: "https://opshelm-cfn-templates.s3.us-west-2.amazonaws.com/org-onboarding-stack.yaml",
      stackName: "OpsHelm-Integration-Stack",
      param_ExternalId: orgData.aws_external_id || "",
      param_ULID: orgData.id,
      param_OpsHelmAccountID: process.env.REACT_APP_OPSHELM_ACCOUNT_ID || "",
      param_OrganizationUnitID: organizationAccountId,
    }).toString();

    return `${url}?region=us-east-1#/stacks/create/review?${queryParams}`;
  }

  return "https://www.opshelm.com/docs/guides/onboarding/gcp";
};

const AddOrganizationModal = ({
  store,
  organization,
  fetchOrganizations,
}: {
  store?: RootStore;
  organization?: AddOrgResponse;
  fetchOrganizations?: () => void;
}) => {
  const wasFetchedRef = useRef<boolean>(false);

  const [currentStep, setCurrentStep] = useState(organization ? 2 : 1);
  const [stepId, setStepId] = useState(INITIALIZING_STEP);
  const [isOrgLoading, setIsOrgLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(!!organization);
  const [errorMsg, setErrorMsg] = useState("");
  const [onboardingData, setOnboardingData] = useState<OrgOnBoardingData>();
  const [orgData, setOrgData] = useState<AddOrgResponse | undefined>(organization);
  const [stateData, setStateData] = useState({
    validationSchema: yup.object(defaultValidationSchema),
    items: [] as FormikItemType[],
  });

  const initialValues = {
    provider: organization?.provider?.toUpperCase() || CLOUD_PLATFORM.AWS,
    name: organization?.name || "",
    parent_account_id: organization?.parent_account_id || "",
    aws_admin_account_id: organization?.parent_account_id || "",
  };

  const awsOrgOnboardingFFEnabled = !!store?.auth.isFeatureEnabled(FEATURE.AWS_ORG_ONBOARDING);
  const gcpOrgOnboardingFFEnabled = !!store?.auth.isFeatureEnabled(FEATURE.GCP_ORG_ONBOARDING);

  const defaultItems = useMemo(
    () => [
      {
        property: "provider",
        label: "Cloud Provider",
        exclusive: true,
        fullWidth: true,
        type: "buttonGroup",
        light: true,
        items: [
          ...(awsOrgOnboardingFFEnabled
            ? [
                {
                  value: CLOUD_PLATFORM.AWS,
                  component: <AWSSvg height={20} />,
                },
              ]
            : []),
          ...(gcpOrgOnboardingFFEnabled
            ? [
                {
                  value: CLOUD_PLATFORM.GCP,
                  component: <GCPSvg height={20} />,
                },
              ]
            : []),
        ],
      },
      {
        property: "name",
        label: "Display Name for Organization",
        placeholder: "Display name",
      },
    ],
    [awsOrgOnboardingFFEnabled, gcpOrgOnboardingFFEnabled],
  );

  const addOrg = async (values: AddOrgRequest) => {
    setIsOrgLoading(true);

    try {
      const data = await store?.accounts.createOrganization(values);

      setOrgData(data);

      setCurrentStep(2);

      fetchOrganizations?.();
    } catch {}

    setIsOrgLoading(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: stateData.validationSchema,
    onSubmit: async (values) => {
      if (currentStep === 1) {
        await addOrg(values);
      } else {
        if (!orgData) return;

        wasFetchedRef.current = false;

        setIsLoading(true);

        try {
          await store?.accounts.continueOrganizationOnboarding(orgData.id);

          setStepId(ORG_ONBOARDING_STEPS.DEPLOYMENT);
        } catch {
          setIsLoading(false);
        }
      }
    },
  });

  useEffect(() => {
    if (currentStep === 1) return;

    if (formik.values.provider?.toUpperCase() === CLOUD_PLATFORM.AWS) {
      store?.ui.changeModalData({
        title: "Launch CloudFormation stack",
      });
    }

    if (formik.values.provider?.toUpperCase() === CLOUD_PLATFORM.GCP) {
      store?.ui.changeModalData({
        title: "Authorize OpsHelm",
      });
    }
  }, [currentStep, store?.ui, formik.values.provider]);

  const launchStack = () =>
    window.open(
      getDocumentationLink({
        orgData,
        organizationAccountId: formik.values.aws_admin_account_id,
      }),
      "_blank",
    );

  const getStatus = useCallback(async () => {
    if (!orgData?.id) return;

    setIsLoading(true);

    let data;

    try {
      data = await store?.accounts.getOrganizationOnboardingStatus(orgData.id);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setErrorMsg(err?.message || "Something went wrong");
    }

    setOnboardingData(data);

    if (data?.status === ONBOARDING_STATUSES.AWAITING_AUTH) {
      if (formik.values.provider?.toUpperCase() === CLOUD_PLATFORM.AWS) {
        store?.ui.showErrorMessage({
          title: "Granting Required Permissions",
          message: data?.description || "Please authorize OpsHelm to connect to your AWS account",
        });
      }

      if (formik.values.provider?.toUpperCase() === CLOUD_PLATFORM.GCP) {
        if (orgData?.service_account_added) {
          store?.ui.showErrorMessage({
            title: "Configure Log Sink",
            message: "Please configure a log sink which forwards logs to OpsHelm’s",
          });
        } else {
          store?.ui.showErrorMessage({
            title: "Authorize OpsHelm",
            message: 'OpsHelm requires read-only access to the "Asset Inventory"', // eslint-disable-line quotes
          });
        }
      }

      setStepId(INITIALIZING_STEP);
    }

    if (data?.status === ONBOARDING_STATUSES.FAILED) {
      setErrorMsg(data?.description);
    }

    if (data?.status === ONBOARDING_STATUSES.RUNNING) {
      setStepId(ORG_ONBOARDING_STEPS.PERMISSIONS);
    }

    if (data?.status === ONBOARDING_STATUSES.COMPLETED) {
      store?.accounts.fetch();
      fetchOrganizations?.();

      setCurrentStep(3);
    }

    if (
      ![ONBOARDING_STATUSES.COMPLETED, ONBOARDING_STATUSES.AWAITING_AUTH].includes(
        data?.status as ONBOARDING_STATUSES,
      ) &&
      store?.ui?.modal
    ) {
      setTimeout(getStatus, 2000);
    } else {
      setTimeout(() => setIsLoading(false), 500);
    }
  }, [
    formik.values.provider,
    store?.accounts,
    store?.ui,
    orgData?.id,
    fetchOrganizations,
    orgData?.service_account_added,
  ]);

  useEffect(() => {
    if (wasFetchedRef.current || (!organization && (currentStep !== 2 || stepId !== ORG_ONBOARDING_STEPS.DEPLOYMENT))) {
      return;
    }

    wasFetchedRef.current = true;
    getStatus();
  }, [formik.values.provider, currentStep, stepId, getStatus, organization]);

  useEffect(() => {
    setStateData({
      validationSchema: yup.object({
        ...defaultValidationSchema,
        ...(formik.values.provider === CLOUD_PLATFORM.AWS ? awsValidationSchema : gcpValidationSchema),
      }),
      items: [
        ...defaultItems,
        ...(formik.values.provider === CLOUD_PLATFORM.AWS
          ? [
              {
                property: "parent_account_id",
                label: "Root Account ID",
              },
              {
                property: "aws_admin_account_id",
                label: "Organization Unit ID",
                placeholder: "something like: r-ccku",
              },
            ]
          : [
              {
                property: "parent_account_id",
                label: "Organization ID",
              },
            ]),
      ],
    });
  }, [formik.values.provider, defaultItems]);

  return (
    <Box data-testid="addAccountModalStepOne" display="flex">
      <AddOrganizationProgressList
        provider={formik.values.provider as CLOUD_PLATFORM}
        currentStep={currentStep}
        stepId={stepId}
      />

      <Box
        component="form"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        onSubmit={formik.handleSubmit}
        width={{
          xs: 1,
          laptop: "calc(100vw - 440px)",
        }}
        maxWidth={{
          xs: 1,
          laptop: 725,
        }}
      >
        {isOrgLoading ? (
          <Loading marginTop={12} />
        ) : (
          currentStep === 1 && <FormFieldsBuilder formik={formik} items={stateData.items} />
        )}

        {currentStep === 2 && stepId === INITIALIZING_STEP && (
          <>
            {formik.values.provider?.toUpperCase() === CLOUD_PLATFORM.AWS && (
              <Box display="flex" gap={2.5} flexDirection="column">
                <Typography variant="title">Granting Required Permissions</Typography>
                <Typography variant="table" marginBottom={2}>
                  To automatically deploy OpsHelm to new accounts, we use a CloudFormation StackSet. This is
                  automatically deployed via the CloudFormation Stack, through the button below. To review the content
                  of these actions and to learn more, please see our documentation.
                </Typography>
                <Typography variant="table" marginBottom={2}>
                  <span style={{ fontWeight: "bold" }}>Note:</span> To deploy StackSets successfully, you must first
                  enable Trusted Access for the CloudFormation StackSets service. This access must be enabled via the
                  StackSets console{" "}
                  <MuiLink
                    href="https://console.aws.amazon.com/cloudformation/home?#/stacksets"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="primary.main"
                  >
                    here
                  </MuiLink>{" "}
                  and not from the main Organizations Trusted Access management page, as StackSets must perform
                  additional setup steps when enabling Trusted Access.
                </Typography>
                <Typography variant="main">
                  <UnderlineLink
                    to="https://www.opshelm.com/docs/guides/onboarding/aws/organization"
                    separateSite
                    text={`OpsHelm ${formik.values.provider} Documentation`}
                  />
                </Typography>
                <Divider
                  sx={{
                    marginTop: 1,
                  }}
                />
                <Typography variant="title">Launch OpsHelm&apos;s CloudFormation Stack</Typography>
                <Typography variant="table" marginBottom={3}>
                  Please click the &quot;Launch Stack&quot; button below from a browser that is logged into your
                  organization&apos;s root account with appropriate permissions to create a CloudFormation Stack. This
                  link is unique to your account and it is essential that you apply the stack exactly as provided; the
                  pre-filled variables are required for the Stack to function correctly.
                </Typography>
                <Button
                  text="Launch Stack"
                  onClick={launchStack}
                  buttonSx={{
                    alignSelf: "center",
                  }}
                />
              </Box>
            )}

            {formik.values.provider?.toUpperCase() === CLOUD_PLATFORM.GCP &&
              (orgData?.service_account_added ? (
                <Box display="flex" flexDirection="column" gap={4}>
                  <Typography variant="main">Configure Log Sink</Typography>
                  <Typography variant="table">
                    In order for OpsHelm to receive the appropriate logging information for your environments, you need
                    to configure a log sink which forwards logs to OpsHelm’s pub/sub queue, you can use the following
                    gcloud command:
                  </Typography>

                  <Typography variant="table">
                    <CopyBlock
                      customStyle={codeInstructionsStyles}
                      language="ts"
                      text={gcpSecondPolicy
                        .replace(/{ORG_ID}/g, formik.values.parent_account_id)
                        .replace(/{ORG_CREATED_ID}/g, orgData?.id || "")}
                      theme={store?.ui?.isLightTheme ? nord : dracula}
                      wrapLines
                      codeBlock
                    />
                  </Typography>
                  <Typography variant="table">
                    The filter applied to this command ensures that logs are restricted to GCP generated logs only, not
                    logs that you generate from within your own applications, and that only those with a severity of{" "}
                    <Typography variant="main">NOTICE</Typography> or above are collected.
                  </Typography>

                  <Typography variant="table">
                    The --include-children flag ensures that this is applied to all projects within your organization,
                    to avoid the need to enable each environment individually, if you wish to add environments
                    individually this flag can be omitted.
                  </Typography>

                  <Typography variant="table">
                    If audit logs are not already enabled within your organization, you should enable them by visiting:
                  </Typography>

                  <Typography variant="table">
                    <CopyBlock
                      customStyle={codeInstructionsStyles}
                      language="ts"
                      text={gcpThirdPolicy.replace(/{ORG_ID}/g, formik.values.parent_account_id)}
                      theme={store?.ui?.isLightTheme ? nord : dracula}
                      wrapLines
                      codeBlock
                    />
                  </Typography>

                  <Typography variant="table">
                    Without <Typography variant="main">logging enabled</Typography>, OpsHelm will not be able to
                    receieve updates regarding your environment
                  </Typography>
                </Box>
              ) : (
                <Box display="flex" flexDirection="column" gap={4}>
                  <Typography variant="main">Authorize OpsHelm</Typography>
                  <Typography variant="table">
                    OpsHelm requires read-only access to the &quot;Asset Inventory&quot; in your environment via the
                    predefined <Typography variant="main">cloudasset.viewer</Typography> role, and read-only permission
                    to list projects within your organization via the predefined browser role.
                  </Typography>
                  <Typography variant="table">
                    To ease the process of onboarding we will generate a service account for this purpose. The easiest
                    way to achieve this is using the following gcloud command:
                  </Typography>

                  <Typography variant="table">
                    <CopyBlock
                      customStyle={codeInstructionsStyles}
                      language="ts"
                      text={gcpPolicy
                        .replace(/{ORG_ID}/g, formik.values.parent_account_id)
                        .replace(/{OPSHELM_SERVICE_ACCOUNT_HERE}/g, orgData?.gcp_service_account || "")}
                      theme={store?.ui?.isLightTheme ? nord : dracula}
                      wrapLines
                      codeBlock
                    />
                  </Typography>

                  <Typography variant="table">
                    Both of these role are read-only roles and do not provide OpsHelm any write permissions to your
                    environments.
                  </Typography>
                  <Typography variant="table">
                    The <Typography variant="main">--include-children</Typography> flag ensures that the roles and
                    relevant permissions are applied to all projects within your organization, to avoid the need to
                    enable each environment individually, if you wish to add environments individually this flag can be
                    omitted.
                  </Typography>
                </Box>
              ))}
          </>
        )}

        {currentStep !== 1 && stepId !== INITIALIZING_STEP && (
          <Box>
            <Table
              type={UI_TABLES.ORG_ONBOARDING}
              hidePagination
              desktopView
              leftHeader
              fields={statusFields}
              data={[
                ...stateData.items.map((i) => ({
                  id: i.property,
                  property: i.label,
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  value: (formik.values as any)[i.property],
                })),
                ...(onboardingData?.status === ONBOARDING_STATUSES.COMPLETED || !!errorMsg
                  ? [
                      {
                        id: "status",
                        property: "Status",
                        value: (
                          <Typography
                            variant="main"
                            fontWeight={700}
                            color={
                              onboardingData?.status === ONBOARDING_STATUSES.COMPLETED ? "success.main" : "error.main"
                            }
                          >
                            {onboardingData?.status === ONBOARDING_STATUSES.COMPLETED
                              ? formik.values.provider === CLOUD_PLATFORM.AWS
                                ? "Needed Permissions Granted, individual account onboarding started"
                                : "Successfully Reeving Logs"
                              : errorMsg}
                          </Typography>
                        ),
                      },
                    ]
                  : []),
              ]}
            />

            {isLoading && <Loading marginTop={8} />}

            {onboardingData?.status === ONBOARDING_STATUSES.COMPLETED && (
              <>
                <Typography component="div" paddingLeft={1} marginTop={3} variant="table">
                  {formik.values.provider === CLOUD_PLATFORM.AWS ? (
                    <>
                      All subsequent accounts in the organization have started onboarding and sending data to OpsHelm,
                      it can take up to 24hrs for data to begin to appear in dashbaords.
                    </>
                  ) : (
                    <>
                      This step is completely automatic and you don&apos;t have to stay on the page. However, if you
                      prefer to stay and monitor the process, it can take several minutes.
                    </>
                  )}
                </Typography>

                <Box display="block" marginX="auto" marginTop={10} marginBottom={5} component={CompleteSvg} />
              </>
            )}
          </Box>
        )}

        <ButtonWrapper>
          <>
            <Button
              onClick={store?.ui.closeModal}
              text={onboardingData?.status === ONBOARDING_STATUSES.COMPLETED ? "Close" : "Cancel"}
              variant={onboardingData?.status === ONBOARDING_STATUSES.COMPLETED ? "contained" : "text"}
            />
            {currentStep !== 3 && stepId === INITIALIZING_STEP && <Button text="Continue" type="submit" />}
          </>
        </ButtonWrapper>
      </Box>
    </Box>
  );
};

export default inject("store")(observer(AddOrganizationModal));
