import React from "react";
import { Box } from "@mui/material";

import {
  JsonHidedProperties,
  JsonHighlights,
  JsonRow,
  JsonSearchType,
  JsonViewerType,
  SetHidedPropertiesHandler,
  SetJsonSearchType,
} from "components/ui/JsonViewer/jsonViewer";
import { sortAndFilterJson } from "components/ui/JsonViewer/jsonViewer.utils";
import { RootStore } from "store/root.store";

import { JsonViewFieldValue } from "../JsonViewFieldValue/jsonViewFieldValue";

export type JsonValueCommonProps = {
  store?: RootStore;
  jsonSearch?: JsonSearchType;
  setJsonSearch?: SetJsonSearchType;
  offset: number;
  unsearchableProperties?: string[];
  selectedItem: string;
  setSelectedItem: (val: string) => void;
  copy?: boolean;
  highlights?: JsonHighlights;
  hidedProperties: JsonHidedProperties;
  collapsible: string[];
  setCollapsible: (val: string) => void;
  setHidedProperties: SetHidedPropertiesHandler;
  numbers: Record<string, JsonRow>;
  expanded: boolean;
};

type JsonValueProps = {
  rows: JsonRow[];
  currentKey?: string;
  offsetIndex?: number;
  json: JsonViewerType;
} & JsonValueCommonProps;

export const JsonViewJsonValue = ({ currentKey = "", json, offsetIndex = 1, ...props }: JsonValueProps) => {
  const sortedJson = sortAndFilterJson(json);

  return (
    <>
      {sortedJson.map(([key, value], index) => (
        <Box key={key}>
          <JsonViewFieldValue
            currentKey={currentKey}
            isNotLastIndex={index !== sortedJson.length - 1}
            keyValue={key}
            indexKeyValue={key}
            value={value}
            offsetIndex={offsetIndex}
            {...props}
          />
        </Box>
      ))}
    </>
  );
};
