import { makeAutoObservable } from "mobx";

import { RootStore } from "store/root.store";
import { getGithubInstallations, addGithubInstallation } from "libs/api/methods";
import { Installation, LOCAL_STORAGE_ITEM, Vulnerability } from "store/types";
import { CursorHandler, getDefaultCursorData } from "tools/cursorHandler/cursorHandler";
import { MultiSelectListItem, TableSortField } from "components/ui";
import * as githubMethods from "libs/api/methods/github-methods";
import { CursorParameters } from "tools/cursorHandler/cursorHandler.types";

const sortBy = (sortField: TableSortField) => ({
  sortBy: sortField?.field as string,
  sortDirection: sortField?.order === 1 ? "asc" : "desc",
});

export class GithubStore {
  appLink = `${process.env.REACT_APP_GITHUB_APP_LINK}/installations/select_target`;
  cursorData = getDefaultCursorData<Vulnerability>();
  isError = false;
  isFetched = false;
  installations: Installation[] = [];

  constructor(readonly rootStore: RootStore) {
    this.rootStore = rootStore;

    this.fetch = this.fetch.bind(this);

    makeAutoObservable(this);

    this.cursorData.handler = new CursorHandler(this.cursorData, githubMethods.getVulnerabilities, {
      apiSort: true,
      sortBy,
    });
  }

  async fetch() {
    try {
      this.installations = await getGithubInstallations(this.isFetched);
      this.isFetched = true;
    } catch {
      this.isError = true;
    }
  }

  getDependabotFilters() {
    if (localStorage.getItem(LOCAL_STORAGE_ITEM.GITHUB_DEPENDABOT_FILTERS)) {
      try {
        return JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEM.GITHUB_DEPENDABOT_FILTERS) as string);
      } catch {}
    }
  }

  saveDependabotFilters({ states, severities }: { states: MultiSelectListItem[]; severities: MultiSelectListItem[] }) {
    localStorage.setItem(LOCAL_STORAGE_ITEM.GITHUB_DEPENDABOT_FILTERS, JSON.stringify({ states, severities }));
  }

  async addGithubInstallation(installationId: number): Promise<Installation> {
    return addGithubInstallation(installationId);
  }

  getAllVulnerabilities(params: Omit<CursorParameters, "count">) {
    return githubMethods
      .getVulnerabilities({
        ...params,
        ...sortBy(this.cursorData.sortField),
        all: "true",
        count: 100,
      })
      .then((r) => r.data);
  }
}
