import { AUTH_PROVIDER, PermissionsWithRole } from "store/types";

export enum ROLES {
  OWNER = "owner",
  ADMIN = "admin",
  BILLING = "billing",
  USER = "user",
}

export type User = {
  id: string;
  name: string;
  email: string;
  created_at: string;
  auth_provider: AUTH_PROVIDER;
  role: PermissionsWithRole;
};

export type Invitation = {
  id: string;
  email: string;
  expires_at: string;
  created_at: string;
  role: PermissionsWithRole;
};
