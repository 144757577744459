import { CostReportStore } from "store/costReport.store";
import { UIStore } from "store/ui.store";
import { AuthStore } from "store/auth.store";
import { AccountsStore } from "store/accounts.store";
import { UsersStore } from "store/users.store";
import { DashboardStore } from "store/dashboard.store";
import { RulesStore } from "store/rules.store";
import { DataStore } from "store/data.store";
import { NotificationsStore } from "store/notifications.store";
import { IntegrationsStore } from "store/integrations.store";
import { GithubStore } from "store/github.store";
import { EventsStore } from "store/events.store";
import { InventoryStore } from "store/inventory.store";
import { FilterStore } from "store/filter.store";
import { WidgetsStore } from "store/widgets.store";
import { ApiKeysStore } from "store/apiKeys.store";
import { ComplianceStore } from "store/compliance.store";
import { MultipleDashboardsStore } from "store/multipleDashboards.store";

export class RootStore {
  ui: UIStore;
  auth: AuthStore;
  accounts: AccountsStore;
  dashboard: DashboardStore;
  users: UsersStore;
  rules: RulesStore;
  data: DataStore;
  notifications: NotificationsStore;
  integrations: IntegrationsStore;
  github: GithubStore;
  events: EventsStore;
  inventory: InventoryStore;
  filter: FilterStore;
  widgets: WidgetsStore;
  apiKeys: ApiKeysStore;
  compliance: ComplianceStore;
  costReport: CostReportStore;
  multipleDashboards: MultipleDashboardsStore;

  constructor() {
    this.ui = new UIStore(this);
    this.auth = new AuthStore(this);
    this.accounts = new AccountsStore(this);
    this.dashboard = new DashboardStore(this);
    this.users = new UsersStore(this);
    this.rules = new RulesStore(this);
    this.data = new DataStore(this);
    this.notifications = new NotificationsStore(this);
    this.integrations = new IntegrationsStore(this);
    this.github = new GithubStore(this);
    this.events = new EventsStore(this);
    this.inventory = new InventoryStore(this);
    this.filter = new FilterStore(this);
    this.widgets = new WidgetsStore(this);
    this.apiKeys = new ApiKeysStore(this);
    this.compliance = new ComplianceStore(this);
    this.costReport = new CostReportStore(this);
    this.multipleDashboards = new MultipleDashboardsStore(this);
  }

  init() {
    return Promise.all([
      this.data.init(),
      this.users.fetch(),
      this.accounts.fetch(),
      this.widgets.setWidgetSettings(),
      this.multipleDashboards.fetch(),
    ]);
  }
}
