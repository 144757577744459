import { api } from "libs";
import { ADD_TERRAFORM_BUCKET_TOKEN, ADD_TERRAFORM_CLOUD_TOKEN } from "libs/api/endpoints";
import { AddTerraformIntegration, INTEGRATION_TERRAFORM_TYPE } from "store/types";

export const addTerraformToken = async ({
  name,
  token,
  workspaceId,
  address,
  type,
  provider,
  bucketName,
  bucketPath,
}: AddTerraformIntegration): Promise<void> =>
  api.post(
    type === INTEGRATION_TERRAFORM_TYPE.CLOUD ? ADD_TERRAFORM_CLOUD_TOKEN : ADD_TERRAFORM_BUCKET_TOKEN,
    {
      name,
      ...(type === INTEGRATION_TERRAFORM_TYPE.CLOUD && {
        api_token: token,
        workspace_id: workspaceId,
        ...(address && { address }),
      }),
      ...(type === INTEGRATION_TERRAFORM_TYPE.BUCKET && {
        provider: provider.toLowerCase(),
        bucket_name: bucketName,
        bucket_path: bucketPath,
      }),
    },
    {
      errorMessage: {
        title: "Failed to add Terraform Token",
      },
    },
  );
