import { api } from "libs";
import { API_USERS, API_USERS_BY_ID, API_USERS_INVITATIONS, API_USERS_INVITATIONS_BY_ID } from "libs/api/endpoints";
import { Invitation, User } from "store/types";

export const getUsers = async (doNotShowErrorMessage: boolean): Promise<User[]> => {
  const res = await api.get(API_USERS, {
    doNotShowErrorMessage,
    errorMessage: {
      title: "Failed to load Users",
    },
  });

  return res.users;
};

export const getInvitations = async (doNotShowErrorMessage: boolean): Promise<Invitation[]> => {
  const res = await api.get(API_USERS_INVITATIONS, {
    doNotShowErrorMessage,
    errorMessage: {
      title: "Failed to load Invitations",
    },
  });

  return res.invitations;
};

export const inviteUser = async (email: string, roleId: number): Promise<User> =>
  api.post(
    API_USERS_INVITATIONS,
    { email, roleId },
    {
      errorMessage: {
        title: "Failed to invite the User",
      },
    },
  );

export const removeUser = async (id: string): Promise<void> =>
  api.delete(API_USERS_BY_ID(id), {
    errorMessage: {
      title: "Failed to remove the User",
    },
  });

export const removeInvitation = async (id: string): Promise<void> =>
  api.delete(API_USERS_INVITATIONS_BY_ID(id), {
    errorMessage: {
      title: "Failed to remove the Invitation",
    },
  });

export const updateUser = async (id: string, data: Partial<User>): Promise<void> =>
  api.put(API_USERS_BY_ID(id), data, {
    errorMessage: {
      title: "Failed to update the User",
    },
  });
