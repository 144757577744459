import React, { useEffect, useState } from "react";
import { DateRangePicker, DateRange, Calendar } from "react-date-range";
import { Box, Popover, Typography, useMediaQuery, useTheme } from "@mui/material";
import { CalendarToday } from "@mui/icons-material";
import { addHours, differenceInHours } from "date-fns";

import { formattedTime, formattedYearDate } from "tools";
import { Button, INPUT_MASK_TYPES, InputMask } from "components/ui";

export type DatePickerDates = Array<Date>;

type DateRangePickerProps = {
  dates: DatePickerDates;
  setDates: (val: DatePickerDates) => void;
  doNotShowDates?: boolean;
  range?: boolean;
  withTime?: boolean;
  placeholder?: string;
  setFullWidth?: boolean;
};

type Selection = {
  startDate: Date;
  endDate: Date;
  key: string;
};

const buttons = [
  {
    type: "cancel",
    text: "Cancel",
    color: "#DA535A",
  },
  {
    type: "set",
    text: "Set",
    color: "primary.tooltipLink",
  },
];

export const DatePicker = ({
  range,
  withTime,
  dates,
  setDates,
  doNotShowDates,
  placeholder,
  setFullWidth,
}: DateRangePickerProps) => {
  const theme = useTheme();
  const isSmallMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMDMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const now = new Date();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [dateRangePickerState, setDateRangePickerState] = useState<Selection[]>([]);
  const [date, setDate] = useState<Date>(dates[0]);
  const [time, setTime] = useState<string>(formattedTime(dates[0]));

  useEffect(() => {
    if (withTime) {
      setTime(formattedTime(dates[0]));
    }

    if (!range) {
      setDate(dates[0]);
    }

    if (range) {
      setDateRangePickerState([
        {
          startDate: dates[0],
          endDate: dates[1],
          key: "selection",
        },
      ]);
    }
  }, [range, withTime, dates, anchorEl]);

  const setDateRangePickerStateHandler = (item: { selection: Selection }) => setDateRangePickerState([item.selection]);

  const close = () => setAnchorEl(null);

  const click = (type: string) => {
    close();

    if (type === "set") {
      if (range) {
        setDates([dateRangePickerState[0].startDate, dateRangePickerState[0].endDate]);
      } else if (withTime) {
        const selectedDate = new Date(`${formattedYearDate(date)} ${time}`);

        setDates([+selectedDate ? selectedDate : new Date()]);
      } else {
        setDates([date]);
      }
    }
  };

  const value = range
    ? `${formattedYearDate(dates[0])} - ${formattedYearDate(dates[1])}`
    : `${formattedYearDate(dates[0])}${withTime ? ` ${formattedTime(dates[0])}` : ""}`;

  const Component = isSmallMobile ? DateRange : DateRangePicker;

  const inputRanges = [
    {
      label: "hours back",
      range(hours: number) {
        return {
          startDate: addHours(now, Math.min(-hours, 0)),
          endDate: now,
        };
      },
      getCurrentValue({ startDate, endDate }: { startDate: Date; endDate: Date }) {
        if (!startDate || !endDate) return 0;

        const hours = differenceInHours(now, startDate);

        return hours > 23 || hours < 0 ? 0 : hours;
      },
    },
  ];

  return (
    <>
      <Button
        fullWidth={setFullWidth}
        buttonSx={
          setFullWidth
            ? {
                justifyContent: "flex-start",
                paddingX: `${theme.spacing(2)} !important`,
              }
            : {}
        }
        onlyIcon={doNotShowDates}
        customIcon={
          <CalendarToday
            sx={{
              fontSize: 20,
            }}
          />
        }
        data-testid="datePicker"
        variant="text"
        text={value}
        inTable
        smallWidth
        onClick={(e) => setAnchorEl(e.currentTarget)}
      />

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={close}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            ".rdrDateDisplayWrapper": {
              ...(!range && {
                display: "none",
              }),
              ...(theme.palette.mode !== "light" && {
                background: "#5b6aa5",
              }),
            },

            ".rdrDefinedRangesWrapper": {
              ...(isMobile && {
                width: 200,
              }),
              ...(theme.palette.mode !== "light" && {
                background: "#5b6aa5",
              }),
            },

            ...(isMDMobile && {
              ".rdrCalendarWrapper": {
                width: 280,

                ".rdrMonth": {
                  width: 270,
                },
              },
            }),

            ...(theme.palette.mode !== "light" && {
              ".rdrMonthAndYearWrapper": {
                background: "#5b6aa5",
              },

              ".rdrMonths": {
                background: "#5b6aa5",
              },

              ".rdrDayNumber span": {
                color: "#fff",
              },

              ".rdrWeekDay": {
                color: "#fff",
              },

              ".rdrMonthName": {
                color: "#fff",
              },

              ".rdrYearPicker select": {
                color: "#fff",
                backgroundImage: "none",
              },

              ".rdrMonthPicker select": {
                color: "#fff",
                backgroundImage: "none",
              },

              ".rdrInputRangeInput": {
                color: "#000",
                background: "#dedede",
              },

              ".rdrDateInput": {
                overflow: "hidden",

                input: {
                  color: "#000",
                  background: "#dedede",
                },
              },

              ".rdrStaticRanges button": {
                background: "#5b6aa5",
                color: "#fff",

                "&.rdrStaticRangeSelected": {
                  color: "#fff !important",
                },

                "&:hover": {
                  ".rdrStaticRangeLabel": {
                    background: "#2B2753",
                  },
                },
              },
            }),
          }}
        >
          {range ? (
            <Component
              onChange={(item) => setDateRangePickerStateHandler(item as { selection: Selection })}
              ranges={dateRangePickerState}
              direction="horizontal"
              calendarFocus="backwards"
              // @ts-ignore
              inputRanges={isSmallMobile ? undefined : inputRanges}
            />
          ) : (
            <Calendar onChange={setDate} date={date} />
          )}
        </Box>

        <Box
          borderTop="solid 1px #eff2f7"
          bgcolor={theme.palette.mode === "light" ? "#eff2f7" : "#5b6aa5"}
          display="flex"
          justifyContent="space-between"
          gap={2}
          padding={2}
        >
          {!!withTime && (
            <Box width={100}>
              <InputMask
                placeholder={placeholder}
                type={INPUT_MASK_TYPES.TIME}
                smallView
                value={time}
                onChange={setTime}
              />
            </Box>
          )}

          <Box marginLeft="auto" gap={2} display="flex">
            {buttons.map((b) => (
              <Typography
                onClick={() => click(b.type)}
                key={b.type}
                bgcolor={b.color}
                color="#fff"
                width={55}
                justifyContent="center"
                alignItems="center"
                padding={1}
                borderRadius={1}
                variant="main"
                display="flex"
                sx={{
                  cursor: "pointer",

                  "&:hover": {
                    opacity: 0.9,
                  },
                }}
              >
                {b.text}
              </Typography>
            ))}
          </Box>
        </Box>
      </Popover>
    </>
  );
};
