import React, { useState } from "react";
import { Box } from "@mui/material";

import { AddAccountFormStepOne, AddAccountFormStepTwo } from "components/modals/AddAccountModal/components";
import { Account } from "store/types";

type AddAccountModalProps = {
  account?: Account | null;
};

const AddAccountModal = ({ account }: AddAccountModalProps) => {
  const [accountData, setAccountData] = useState(account);

  return (
    <Box data-testid="addAccountModal" display="flex" flexDirection="column">
      {!accountData && <AddAccountFormStepOne setAccountData={setAccountData} />}
      {accountData && <AddAccountFormStepTwo accountData={accountData} setAccountData={setAccountData} />}
    </Box>
  );
};

export default AddAccountModal;
