import React, { useCallback, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Box, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { CopyBlock, dracula, nord } from "react-code-blocks";

import {
  Button,
  ButtonWrapper,
  CheckboxListItem,
  FormFieldsBuilder,
  Loading,
  StepsProgress,
  Table,
  UnderlineLink,
} from "components/ui";
import { RootStore } from "store/root.store";
import {
  AddAwsCostIntegration,
  AddAwsCostIntegrationStatus,
  AwsCostIntegration,
  CLOUD_PLATFORM,
  UI_TABLES,
} from "store/types";
import CongratulationScreen from "components/modals/AddIntegrationModal/components/CongratulationScreen";
import { AWSSvg } from "components/icons";
import { ACTION_FIELD_PROPERTY } from "consts";
// eslint-disable-next-line max-len
import { codeInstructionsStyles } from "components/modals/AddAccountModal/components/OnboardingInstructions/onboardingInstructions";
import { awsCostFields } from "routes/IntegrationsRoute/components";

const firstStepValidationSchema = yup.object({
  provider: yup.string().required("Provider is required"),
  cloud_account_id: yup.string().required("Account ID is required"),
});

const secondStepValidationSchema = yup.object({
  bucket_name: yup.string().required("Bucket Name is required"),
  prefix: yup.string().required("Prefix is required"),
  report_name: yup.string().required("Report Name is required"),
});

const secondStepItems = [
  {
    property: "bucket_name",
    label: "Bucket Name",
  },
  {
    property: "prefix",
    label: "Prefix",
  },
  {
    property: "report_name",
    label: "Report Name",
  },
];

const step2Texts = ["Create an AWS CUR export", "Provide bucket and report info"];

const stepsItems = [
  {
    title: "Getting Started",
  },
  {
    title: "Creating AWS Cost Report",
    subItems: step2Texts,
  },
  {
    title: "Modifying OpsHelm role to allow access to AWS CUR report",
  },
];

const statusFields = [{ property: "property" }, { property: "value" }];

const instructionsLinkText =
  "Follow the following instructions for detailed steps and configuration to enable AWS CUR report";

const instructionsLink = "https://www.opshelm.com/docs/guides/features/cost-enrichment/aws";

const policyText = `{
    "Version": "2012-10-17",
    "Statement": [
        {
            "Sid": "ReadBucket",
            "Effect": "Allow",
            "Action": [
                "s3:ListBucket"
            ],
            "Resource": "arn:aws:s3:::{BucketName}"
        },
        {
            "Sid": "GetReport",
            "Effect": "Allow",
            "Action": [
                "s3:GetObject",
                "s3:GetObjectTagging",
                "s3:PutObject",
                "s3:PutObjectAcl",
                "s3:PutObjectTagging"
            ],
            "Resource": "arn:aws:s3:::{BucketName}/{Path}/*"
        },
        {
            "Sid": "PutReportToOpsHelm",
            "Effect": "Allow",
            "Action": [
                "s3:PutObject",
                "s3:PutObjectAcl"
            ],
            "Resource": [
                "arn:aws:s3:::{OHBucketName}",
                "arn:aws:s3:::{OHBucketName}/*"
            ],
            "Condition": {
                "StringEquals": {
                    "s3:x-amz-acl": "bucket-owner-full-control"
                }
            }
        }
    ]
}`;

const AddAWSCostIntegrationModal = ({
  store,
  awsIntegration,
}: {
  store?: RootStore;
  awsIntegration?: AwsCostIntegration;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [wasFetch, setWasFetch] = useState(false);
  const [connectedBucketStatus, setConnectedBucketStatus] = useState<AddAwsCostIntegrationStatus>({
    oh_s3_bucket_name: "",
    bucket: awsIntegration?.bucket,
    path: awsIntegration?.path,
  });
  const [step, setStep] = useState(awsIntegration ? 3 : 1);

  const { activeAccounts } = store!.accounts;
  const { notAddedAwsCostAccounts } = store!.costReport;

  const initialValues: AddAwsCostIntegration = {
    provider: awsIntegration?.provider || CLOUD_PLATFORM.AWS,
    cloud_account_id: awsIntegration?.cloud_account_id || notAddedAwsCostAccounts[0]?.id,
    bucket_name: awsIntegration?.bucket_name || "",
    prefix: awsIntegration?.prefix || "",
    report_name: awsIntegration?.report_name || "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: step === 1 ? firstStepValidationSchema : secondStepValidationSchema,
    onSubmit: async (values) => {
      if (awsIntegration) {
        await store?.costReport.updateIntegration(CLOUD_PLATFORM.AWS, { ...awsIntegration, ...values });

        store?.ui.closeModal();

        return;
      }

      if (step === 2) {
        setIsLoading(true);

        try {
          await store?.costReport.addCostIntegration(CLOUD_PLATFORM.AWS, values);

          setStep((curr) => curr + 1);
        } catch {
          setTimeout(() => setIsLoading(false), 500);
        }
      }
    },
  });

  const getStatus = useCallback(async () => {
    let status;

    setIsLoading(true);

    try {
      status = await store?.costReport.getAwsCostIntegrationStatus(formik.values.cloud_account_id);
    } catch {}

    setWasFetch(true);

    if (status) setConnectedBucketStatus(status);

    if (!status?.oh_s3_bucket_name && store?.ui?.modal) {
      setTimeout(getStatus, 2000);
    } else {
      setIsLoading(false);
    }
  }, [store?.costReport, store?.ui, formik.values.cloud_account_id]);

  useEffect(() => {
    if (step !== 3 || connectedBucketStatus?.oh_s3_bucket_name) return;

    getStatus();
  }, [getStatus, step, connectedBucketStatus?.oh_s3_bucket_name]);

  const firstStepItems = [
    {
      property: "provider",
      label: "Cloud Provider",
      exclusive: true,
      fullWidth: true,
      type: "buttonGroup",
      light: true,
      items: [
        {
          value: CLOUD_PLATFORM.AWS,
          component: <AWSSvg height={20} />,
        },
      ],
    },
    {
      property: "cloud_account_id",
      label: "AWS Account Id",
      type: "select",
      fullWidth: true,
      disabled: !!awsIntegration,
      list: awsIntegration
        ? activeAccounts.filter((a) => a.provider?.toUpperCase() === CLOUD_PLATFORM.AWS)
        : notAddedAwsCostAccounts,
    },
  ];

  if (step === 4) {
    return <CongratulationScreen text="You have successfully connected your AWS Cost Report" />;
  }

  return (
    <Box
      onSubmit={formik.handleSubmit}
      component="form"
      gap={5}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      flexGrow={1}
    >
      <Typography variant="table">Follow simple steps to connect Your AWS Cost Report</Typography>

      <Box display="flex" flexGrow={1}>
        <StepsProgress items={stepsItems} currentStep={step} stepId="stepId" steps={[]} />

        <Box width={1} display="flex" gap={5} flexDirection="column">
          {step === 1 && <FormFieldsBuilder formik={formik} items={firstStepItems} />}

          {step === 2 &&
            (isLoading ? (
              <Loading marginTop={10} />
            ) : (
              step2Texts.map((text, index) => (
                <Typography key={text} lineHeight={1.5} variant="table" display="flex" alignItems="flex-start" gap={2}>
                  <CheckboxListItem />

                  <Box flexGrow={1}>
                    {text}

                    <Box marginTop={2}>
                      {!index ? (
                        <Typography variant="table" lineHeight={1.5} color="#8F94C3" display="flex" gap={2}>
                          <span>&bull;</span>
                          <UnderlineLink to={instructionsLink} separateSite text={instructionsLinkText} />
                        </Typography>
                      ) : (
                        <FormFieldsBuilder formik={formik} items={secondStepItems} />
                      )}
                    </Box>
                  </Box>
                </Typography>
              ))
            ))}

          {step === 3 && (
            <Box>
              <Table
                type={UI_TABLES.AWS_COST_INTEGRATION}
                hidePagination
                desktopView
                leftHeader
                fields={statusFields}
                data={[
                  ...awsCostFields
                    .filter((i) => i.property !== ACTION_FIELD_PROPERTY)
                    .map((item) => ({
                      id: item.property,
                      property: item.name,
                      value:
                        item.render?.(formik.values as AwsCostIntegration) ||
                        connectedBucketStatus?.[item.property as keyof AddAwsCostIntegrationStatus] ||
                        (formik.values as AwsCostIntegration)[item.property as keyof AwsCostIntegration],
                    })),
                  {
                    id: "status",
                    property: "Status",
                    value: (
                      <Typography
                        variant="main"
                        fontWeight={700}
                        color={connectedBucketStatus?.oh_s3_bucket_name ? "success.main" : "text.secondaryDark"}
                      >
                        {connectedBucketStatus?.oh_s3_bucket_name
                          ? "Connected!"
                          : wasFetch
                          ? "Connecting..."
                          : "Fetching..."}
                      </Typography>
                    ),
                  },
                ]}
              />

              {isLoading && <Loading marginTop={10} />}

              {!isLoading && connectedBucketStatus?.oh_s3_bucket_name && (
                <Box marginTop={2} display="flex" gap={2} flexDirection="column">
                  <Typography variant="main" fontWeight={700} paddingX={2}>
                    OpsHelm role
                  </Typography>
                  <Typography variant="main" paddingX={2}>
                    Create and attach the following role to the already existing OpsHelm role. For more detailed
                    instructions refer to <UnderlineLink to={instructionsLink} separateSite text="documentation" />.
                  </Typography>
                  <CopyBlock
                    customStyle={codeInstructionsStyles}
                    language="ts"
                    text={policyText
                      .replace(/\{BucketName}/g, connectedBucketStatus?.bucket || "")
                      .replace(/\{Path}/g, connectedBucketStatus?.path || "")
                      .replace(/\{OHBucketName}/g, connectedBucketStatus?.oh_s3_bucket_name || "")}
                    theme={store?.ui?.isLightTheme ? nord : dracula}
                    wrapLines
                    codeBlock
                  />
                </Box>
              )}
            </Box>
          )}

          <ButtonWrapper marginTop="auto">
            <>
              <Button onClick={store?.ui.closeModal} text="Cancel" variant="text" />
              {step === 2 && <Button type="submit" text="Continue" />}
              {step !== 2 && (step !== 3 || connectedBucketStatus?.oh_s3_bucket_name) && (
                <Button onClick={() => setStep((c) => c + 1)} text="Continue" />
              )}
            </>
          </ButtonWrapper>
        </Box>
      </Box>
    </Box>
  );
};

export default inject("store")(observer(AddAWSCostIntegrationModal));
