import { makeAutoObservable } from "mobx";

import { RootStore } from "store/root.store";
import { addTerraformToken } from "libs/api/methods";
import { AddTerraformIntegration } from "store/types";

export class IntegrationsStore {
  constructor(readonly rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  async addTerraformToken(data: AddTerraformIntegration) {
    return addTerraformToken(data);
  }
}
