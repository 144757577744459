import React from "react";
import { Box } from "@mui/material";

import { AwsPng } from "./components";

const AWSAccountContent = () => (
  <Box>
    <Box marginBottom={4}>
      You can find your AWS Account ID in the{" "}
      <Box
        component="a"
        target="_blank"
        href="https://console.aws.amazon.com"
        sx={{ color: "primary.tooltipLink", "&:hover": { textDecoration: "underline" } }}
      >
        AWS Console
      </Box>
    </Box>
    <Box overflow="hidden" borderRadius={1}>
      <Box width={1} component="img" src={AwsPng} alt="aws" />
    </Box>
  </Box>
);

export default AWSAccountContent;
