import { makeAutoObservable } from "mobx";

import { RootStore } from "store/root.store";
import {
  FILTER_PAGES,
  Inventory,
  InventoryFilterDataTab,
  InventoryHistory,
  InventorySearchData,
  InventorySearchTagParams,
  SavedFilter,
  FEATURE,
  SESSION_STORAGE_ITEM,
  InventoryPriceHistoryItem,
  InventoryPriceHistoryRequest,
  InventorySearchDataTag,
} from "store/types";
import { CursorHandler, getDefaultCursorData } from "tools/cursorHandler/cursorHandler";
import {
  getInventorySearchData,
  getInventoryItemByResourceId,
  getHistoryVersions,
  getHistoryVersion,
  getRelatedResources,
  getInventoryTags,
  getPriceHistory,
} from "libs/api/methods";
import * as inventoryMethods from "libs/api/methods/inventory-methods";
import { CursorData, CursorParameters } from "tools/cursorHandler/cursorHandler.types";
import { getInventoryQueryDataFromSavedFilter } from "tools";
import { JsonSearchType, TableSortField } from "components/ui";

const sortBy = (sortField: TableSortField) => ({
  sort_by: sortField?.field as string,
  sort_order: sortField?.order === 1 ? "ASC" : "DESC",
});

export class InventoryStore {
  jsonSearch: JsonSearchType = {};
  lastUpdatedJsonSearch = Date.now();
  cursorData = getDefaultCursorData<Inventory>();
  secondCursorData = getDefaultCursorData<Inventory>();
  defaultSearchData = {
    types: [],
    locations: [],
    tags: [],
    details: [],
  };

  constructor(readonly rootStore: RootStore) {
    this.rootStore = rootStore;

    this.getSearchData = this.getSearchData.bind(this);
    this.getTabs = this.getTabs.bind(this);
    this.saveTabs = this.saveTabs.bind(this);

    this.setJsonSearch = this.setJsonSearch.bind(this);

    makeAutoObservable(this);

    this.cursorData.handler = this.getCursorHandler(this.cursorData);
    this.secondCursorData.handler = this.getCursorHandler(this.secondCursorData);
  }

  setLastUpdatedJsonSearch() {
    this.lastUpdatedJsonSearch = Date.now();
  }

  setJsonSearch(jsonSearch: JsonSearchType) {
    if (!jsonSearch) return;

    this.jsonSearch = jsonSearch;
  }

  getCursorHandler(cursorData: CursorData<Inventory>) {
    return new CursorHandler(cursorData, inventoryMethods.getInventory, {
      apiSort: true,
      sortBy,
    });
  }

  async getSearchData(accounts: string[]): Promise<InventorySearchData> {
    if (!accounts.length) return { ...this.defaultSearchData };

    try {
      return await getInventorySearchData(accounts);
    } catch {
      return { ...this.defaultSearchData };
    }
  }

  getInventoryTags(accounts: string[], params: InventorySearchTagParams) {
    try {
      return getInventoryTags(accounts, params);
    } catch {
      return [] as InventorySearchDataTag[];
    }
  }

  async getRelatedResources(accountId: string, assetId: string) {
    try {
      return await getRelatedResources(accountId, assetId);
    } catch {
      return [];
    }
  }

  getInventory(filter: SavedFilter, selectedAccountsIds?: string[]) {
    const query = getInventoryQueryDataFromSavedFilter({
      filter,
      selectedAccountsIds,
      terraformFFEnabled: this.rootStore.auth.isFeatureEnabled(FEATURE.INTEGRATIONS_TERRAFORM),
    });

    return inventoryMethods.getInventory({ ...query, count: 1 } as CursorParameters);
  }

  getInventoryItemByResourceId(accountId: string, resourceId: string) {
    return getInventoryItemByResourceId(accountId, resourceId);
  }

  getHistoryVersions(accountId: string, assetId: string) {
    return getHistoryVersions(accountId, assetId);
  }

  async getHistoryVersion(accountId: string, assetId: string, version: string): Promise<InventoryHistory> {
    try {
      return await getHistoryVersion(accountId, assetId, version);
    } catch {
      return {
        resource: {},
      } as InventoryHistory;
    }
  }

  getTabs() {
    const defaultTabs: InventoryFilterDataTab[] = [
      {
        title: "All Resources",
        filterData: {
          selectedAccounts: [],
          selectedFilterName: "",
          savedSearchData: {},
          filter: this.rootStore.filter.getDefaultFilter(FILTER_PAGES.INVENTORY),
        },
      },
    ];

    const sessionStorageItem = sessionStorage.getItem(
      this.rootStore.ui.getStorageNameWithUser(SESSION_STORAGE_ITEM.INVENTORY_TABS),
    );

    if (!sessionStorageItem) return defaultTabs;

    try {
      return JSON.parse(sessionStorageItem as string);
    } catch {
      return defaultTabs;
    }
  }

  saveTabs(tabs: InventoryFilterDataTab[]) {
    sessionStorage.setItem(
      this.rootStore.ui.getStorageNameWithUser(SESSION_STORAGE_ITEM.INVENTORY_TABS),
      JSON.stringify(tabs),
    );
  }

  getPriceHistory(requestData: InventoryPriceHistoryRequest): Promise<InventoryPriceHistoryItem[]> {
    return getPriceHistory(requestData);
  }
}
