import React from "react";
import { inject, observer } from "mobx-react";
import { Typography } from "@mui/material";

import { Button, ButtonWrapper } from "components/ui";
import { RootStore } from "store/root.store";

const AddRuleModal = ({ store }: { store?: RootStore }) => (
  <>
    <Typography variant="table" component="div">
      New custom rule
    </Typography>
    <ButtonWrapper>
      <Button onClick={store?.ui.closeModal} text="Cancel" variant="text" />
    </ButtonWrapper>
  </>
);

export default inject("store")(observer(AddRuleModal));
