import React, { ReactElement } from "react";
import { inject, observer } from "mobx-react";
import { Box } from "@mui/material";

import { RootStore } from "store/root.store";
import { DetailsOnHover } from "components/ui";
import { rulePackFields } from "routes/RulesRoute/components/rulesPackTable";

type AccountDetailsProps = {
  store?: RootStore;
  name: string;
  provider: string;
  component: ReactElement;
  inTable?: boolean;
};

const ResourceDetailsOnHover = ({ store, name, provider, component, inTable }: AccountDetailsProps) => {
  const { data } = store!.rules;
  const rule = data
    .filter((r) => r.provider === provider)
    .flatMap((r) => r.rules)
    .find((r) => r.name === name);
  const rulePack = data.find((r) => r.id === rule?.rulePackId);

  const fields = [
    {
      name: "Rule Pack",
      property: "rulePack",
      textValue: () => rulePack?.name || "",
      render: () => <Box fontWeight={600}>{rulePack?.name || ""}</Box>,
    },
    ...rulePackFields,
  ];

  return <DetailsOnHover item={rule} fields={fields} component={component} inTable={inTable} />;
};

export default inject("store")(observer(ResourceDetailsOnHover));
