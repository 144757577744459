import React, { forwardRef } from "react";
import { TextField } from "@mui/material";
import { IMaskInput } from "react-imask";

export enum INPUT_MASK_TYPES {
  TIME,
}

type InputMaskProps = {
  value: string;
  onChange: (val: string) => void;
  placeholder?: string;
  smallView?: boolean;
  type: INPUT_MASK_TYPES;
};

type CustomMaskProps = {
  onChange: ({ target: { value } }: { target: { value: string } }) => void;
};

const TimeMaskCustom = forwardRef<HTMLInputElement, CustomMaskProps>(({ onChange, ...other }, ref) => (
  <IMaskInput
    {...other}
    mask="ab:cd ef"
    definitions={{
      a: /[0-1]/,
      b: /[0-9]/,
      c: /[0-5]/,
      d: /[0-9]/,
      e: /[aApP]/,
      f: /[mM]/,
    }}
    inputRef={ref}
    onAccept={(value: string) => onChange({ target: { value: value.toUpperCase() } })}
    overwrite
  />
));

export const InputMask = ({ value, placeholder, onChange, smallView, type }: InputMaskProps) => (
  <TextField
    onChange={(e) => onChange(e.target.value)}
    value={value}
    placeholder={placeholder}
    InputProps={{
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      inputComponent: (type === INPUT_MASK_TYPES.TIME ? TimeMaskCustom : TimeMaskCustom) as any,
      sx: {
        borderRadius: 1,
        backgroundColor: "background.inputBG",
        "& input": smallView ? { paddingY: 1.7 } : { padding: 2.5 },
      },
    }}
  />
);
