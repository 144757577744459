import Cookies, { CookieAttributes } from "js-cookie";

type SetCookie = (value?: string, attributes?: CookieAttributes) => void;

export const COOKIE_INVITATION = "invitation";
export const COOKIE_MESSAGE = "ohMessage";

export const getCookie = (name: string) => Cookies.get(name);

export const setCookie = (name: string) => (value?: string, attributes?: CookieAttributes) => {
  if (value) {
    Cookies.set(name, value, attributes);
  } else {
    Cookies.remove(name);
  }
};

export default function useCookie(name: string): [string | undefined, SetCookie] {
  return [getCookie(name), setCookie(name)];
}
