import React, { useEffect, useMemo, useState } from "react";
import { inject, observer } from "mobx-react";
import { Box } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { addHours } from "date-fns";

import { AdditionalInfoTable, Button, ButtonWrapper, FormFieldsBuilder } from "components/ui";
import { RootStore } from "store/root.store";
import { DashboardActionsIssuesType, DashboardIssue } from "store/types";
import { getDashboardFields } from "routes/DashboardRoute/dashboard.utils";
// eslint-disable-next-line max-len
import { getIssueChangeStateSelect } from "routes/DashboardRoute/components/DashboardIssues/components/dashboardIssues.tools";

const validationSchema = yup.object({
  reason: yup.string().required("Justification is required").max(500, "Justification must be at most 500 characters"),
});

export enum UNDO_REMEDIATION_VARIANT {
  UNDO = "undo",
  SNOOZE = "snooze",
}

type undoRemediationModalModalProps = {
  store?: RootStore;
  issues: DashboardIssue[];
  variant?: UNDO_REMEDIATION_VARIANT;
  type: DashboardActionsIssuesType;
};

const UndoRemediationModal = ({ store, issues, variant, type }: undoRemediationModalModalProps) => {
  const { dashboard, ui } = store!;

  const defaultItems = useMemo(
    () => [
      ...getIssueChangeStateSelect(issues, type),
      {
        property: "variant",
        exclusive: true,
        fullWidth: true,
        type: "buttonGroup",
        items: [
          {
            value: UNDO_REMEDIATION_VARIANT.UNDO,
            component: "Undo",
          },
          {
            value: UNDO_REMEDIATION_VARIANT.SNOOZE,
            component: "Snooze",
          },
        ],
      },
      { property: "snoozeUntil", label: "Snooze Until", type: "date", withTime: true, placeholder: "00:00 AM" },
      {
        property: "reason",
        label: "Justification",
        type: "textarea",
        placeholder: "Why do you want to undo this remediation?",
      },
    ],
    [issues, type],
  );

  const fields = getDashboardFields({ inModal: true, notGrouped: true, resolved: true });

  const [items, setItems] = useState(defaultItems);

  const initialValues = {
    variant: variant || UNDO_REMEDIATION_VARIANT.UNDO,
    reason: "",
    snoozeUntil: [addHours(new Date(), 2)],
    type,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) =>
      dashboard.undoRemediations(
        issues,
        {
          reason: values.reason,
          ...(values.variant === UNDO_REMEDIATION_VARIANT.UNDO ? {} : { snoozeUntil: values.snoozeUntil[0] }),
        },
        values.type,
      ),
  });

  useEffect(() => {
    setItems(
      defaultItems
        .filter((i) => !variant || i.property !== "variant")
        .filter((i) => formik.values.variant === UNDO_REMEDIATION_VARIANT.SNOOZE || i.property !== "snoozeUntil"),
    );
  }, [defaultItems, variant, formik.values.variant]);

  useEffect(() => store?.dashboard.changeModalTitle(formik.values?.type), [store?.dashboard, formik.values?.type]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box marginBottom={4}>
        {formik.values.variant === UNDO_REMEDIATION_VARIANT.SNOOZE ? (
          <>
            If you click Snooze, OpsHelm will temporarily undo the applied remediation and restore the configuration to
            its previous state. After the selected snooze period, the remediation will be applied again.
          </>
        ) : (
          <>
            If you click Undo, OpsHelm will undo the applied remediation and restore the configuration to its previous
            state.
          </>
        )}
      </Box>

      {formik.values.type === DashboardActionsIssuesType.SINGLE && (
        <Box marginBottom={4}>
          <AdditionalInfoTable item={issues[0]} fields={fields} />
        </Box>
      )}

      <FormFieldsBuilder formik={formik} items={items} />

      <ButtonWrapper>
        <>
          <Button onClick={ui.closeModal} text="Cancel" variant="text" />
          <Button type="submit" text={formik.values.variant === UNDO_REMEDIATION_VARIANT.UNDO ? "Undo" : "Snooze"} />
        </>
      </ButtonWrapper>
    </form>
  );
};

export default inject("store")(observer(UndoRemediationModal));
