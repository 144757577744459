import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { HubOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

import { ElementWrapperWithActions } from "components/ui";
import { RootStore } from "store/root.store";
import { InventoryTable } from "routes/InventoryRoute/components";
import { Inventory } from "store/types";
import { getCloudMapResourceLink } from "tools/utils";

type InventoryRelatedResourcesProps = {
  store?: RootStore;
  accountId: string;
  asset: Inventory;
};

const InventoryRelatedResources = ({ store, accountId, asset }: InventoryRelatedResourcesProps) => {
  const { getRelatedResources } = store!.inventory;

  const [assets, setAssets] = useState<Inventory[]>([]);

  useEffect(() => {
    getRelatedResources(accountId, asset.id).then(setAssets);
  }, [accountId, asset.id, getRelatedResources]);

  return (
    <ElementWrapperWithActions
      search
      title={
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={5}>
          Related resources
          {!!assets.length && (
            <Box component={Link} to={getCloudMapResourceLink({ accountId, resourceId: asset.provider_id })}>
              <HubOutlined sx={{ cursor: "pointer", color: "text.primary" }} />
            </Box>
          )}
        </Box>
      }
      component={InventoryTable}
      data={assets}
      componentProps={{
        relatedResources: true,
      }}
    />
  );
};

export default inject("store")(observer(InventoryRelatedResources));
