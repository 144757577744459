import React, { MouseEvent, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { inject, observer } from "mobx-react";

import { TableActions, TableDesktopProps } from "components/ui/Table/components";
import { TableItemProps, Button } from "components/ui";
import { ArrowSvg } from "components/icons";
import { RootStore } from "store/root.store";
import { getFieldValue } from "tools";
import { ACTION_FIELD_PROPERTY } from "consts";

const TableMobileDataElement = ({
  store,
  type,
  data,
  fields,
  item,
  actions,
  loadMoreCount,
  mobileTopSection: TopSection,
  additionalInfo,
  additionalInfoHeader,
}: TableDesktopProps & { store?: RootStore; item: TableItemProps }) => {
  const theme = useTheme();
  const [allInfoButtonClicked, setAllInfoButtonClicked] = useState(false);

  const mobileFields = [...fields];
  if (mobileFields[mobileFields.length - 1]?.property === ACTION_FIELD_PROPERTY) {
    mobileFields.unshift({
      ...mobileFields.pop()!,
      name: "",
    });
  }

  const showAllInfoButton = loadMoreCount && loadMoreCount > 0 && mobileFields.length > loadMoreCount;
  const mobileTopSectionCount = TopSection ? mobileFields.filter((f) => f.mobileTopSection).length : 0;

  const showAllInfo = () => setAllInfoButtonClicked(!allInfoButtonClicked);

  const additionalInfoClick = (e: MouseEvent<HTMLElement>, activeItem: TableItemProps) => {
    if (!additionalInfo) return;

    e.stopPropagation();

    store?.ui?.openInfoItem({
      data,
      actions,
      component: additionalInfo,
      header: additionalInfoHeader,
      activeItem,
      type,
    });
  };

  return (
    <Box
      data-testid="tableMobileDataElement"
      position="relative"
      paddingTop={3}
      paddingBottom={1}
      sx={{
        "&:nth-of-type(even)": {
          backgroundColor: "background.tableRow",

          ".actions": {
            backgroundColor: "background.tableRow",
          },
        },
      }}
    >
      {!!TopSection && (
        <Box
          data-testid="tableMobileDataElementTopSection"
          onClick={(e) => additionalInfoClick(e, item)}
          key="topSection"
          paddingBottom={3}
          paddingLeft={2}
          paddingRight={2}
        >
          <TopSection item={item} />
        </Box>
      )}
      {mobileFields
        .filter((field) => !TopSection || !field.mobileTopSection)
        .filter(
          (field, fieldIndex) =>
            !showAllInfoButton || allInfoButtonClicked || fieldIndex + mobileTopSectionCount < loadMoreCount,
        )
        .map((field, index) => (
          <Box
            key={field.property}
            paddingBottom={3}
            paddingX={2}
            onClick={(e) => !TopSection && !index && additionalInfoClick(e, item)}
          >
            <Typography variant="main" component="div" marginBottom={1}>
              {field.name}
            </Typography>
            <Typography sx={field.breakWordsLine ? { wordBreak: "break-word" } : {}} variant="table" component="div">
              {getFieldValue({ field, item, inTable: true })}
            </Typography>
          </Box>
        ))}
      {(!!actions?.length || !!additionalInfo) && (
        <Box
          className="actions"
          bgcolor="background.paper"
          display="flex"
          alignItems="center"
          position="absolute"
          top={theme.spacing(3)}
          right={theme.spacing(1)}
        >
          {!!actions?.length && <TableActions item={item} actions={actions} />}

          {!!additionalInfo && !TopSection && (
            <Box
              data-testid="tableMobileDataElementAdditionalInfo"
              onClick={(e) => additionalInfoClick(e, item)}
              paddingRight={1}
              paddingLeft={2}
              top="1px"
              position="relative"
              sx={{
                svg: {
                  fill: "#8EA8EB",
                },
              }}
            >
              <ArrowSvg />
            </Box>
          )}
        </Box>
      )}
      {showAllInfoButton && (
        <Box data-testid="tableMobileDataElementAllInformation" key="allInformation" paddingBottom={3} paddingX={2}>
          <Button variant="text" fullWidthOnMobile onClick={showAllInfo}>
            {allInfoButtonClicked ? "Hide" : "All information"}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default inject("store")(observer(TableMobileDataElement));
