import { makeAutoObservable } from "mobx";

import { RootStore } from "store/root.store";
import {
  changeRulePackStatus,
  changeRuleStatus,
  changeRulePackAccountStatus,
  changeRuleAccountStatus,
  getRules,
} from "libs/api/methods";
import { Account, MESSAGE_VARIANT, Rule, RulesPack } from "store/types";

export class RulesStore {
  isError = false;
  isFetched = false;
  data: RulesPack[] = [];
  lastFetch = Date.now();

  constructor(readonly rootStore: RootStore) {
    this.rootStore = rootStore;

    this.fetch = this.fetch.bind(this);

    makeAutoObservable(this);
  }

  async fetch() {
    try {
      this.data = await getRules(this.isFetched);
      this.isFetched = true;

      this.lastFetch = Date.now();
    } catch {
      this.isError = true;
    }
  }

  changeRulePack = (item: RulesPack, data: Partial<RulesPack>) => {
    this.data = this.data.map((pack) => {
      if (pack.id !== item.id) return pack;

      return { ...pack, ...data };
    });
  };

  changeRule = (item: Rule, data: Partial<Rule>) => {
    this.data = this.data.map((pack) => {
      if (!pack.rules.find((r) => r.id === item.id)) return pack;

      return {
        ...pack,
        rules: pack.rules.map((rule) => {
          if (rule.id !== item.id) return rule;

          return { ...rule, ...data };
        }),
      };
    });
  };

  async changeRuleStatus(item: Rule, enabled: boolean) {
    this.changeRule(item, { enabled });

    try {
      await changeRuleStatus(item.id, enabled);

      this.rootStore.ui.showMessage({
        variant: enabled ? MESSAGE_VARIANT.SUCCESS : MESSAGE_VARIANT.WARNING,
        title: `Rule "${item.name}" is now ${enabled ? "activated" : "deactivated"}`,
      });
    } catch {}

    await this.fetch();
  }

  async changeRuleAccountStatus(item: Rule, account: Account, enabled: boolean): Promise<void> {
    try {
      await changeRuleAccountStatus(account.id, item.id, enabled);

      this.rootStore.ui.showMessage({
        variant: enabled ? MESSAGE_VARIANT.SUCCESS : MESSAGE_VARIANT.WARNING,
        title: `Rule "${item.name}" is now ${enabled ? "active" : "not active"} for "${account.name}" Environment`,
      });
    } catch {}

    await this.fetch();
  }

  async changeRulePackStatus(item: RulesPack, enabled: boolean) {
    this.changeRulePack(item, { enabled });

    try {
      item?.rules.forEach((rule) => this.changeRule(rule, { enabled }));

      if (!enabled) {
        const allEnabledAccountNames = [...new Set(item?.rules?.flatMap((r) => r.environments || []).filter((e) => e))];

        await Promise.all(
          allEnabledAccountNames.map((accountName) => {
            const account = this.rootStore.accounts.activeAccounts.find((a) => a.name === accountName);

            return account && changeRulePackAccountStatus(account.id, item.id, enabled);
          }),
        );
      }

      await changeRulePackStatus(item.id, enabled);

      this.rootStore.ui.showMessage({
        variant: enabled ? MESSAGE_VARIANT.SUCCESS : MESSAGE_VARIANT.WARNING,
        title: `Rule Pack "${item.name}" is now ${enabled ? "activated" : "deactivated"}`,
      });
    } catch {}

    await this.fetch();
  }

  async changeRulePackAccountStatus(item: RulesPack, account: Account, enabled: boolean): Promise<void> {
    try {
      await changeRulePackAccountStatus(account.id, item.id, enabled);

      this.rootStore.ui.showMessage({
        variant: enabled ? MESSAGE_VARIANT.SUCCESS : MESSAGE_VARIANT.WARNING,
        title: `Rule Pack "${item.name}" is now ${enabled ? "active" : "not active"} for "${account.name}" Environment`,
      });
    } catch {}

    await this.fetch();
  }
}
