import React, { ReactElement } from "react";

import { JsonRow, PossibleJsonValue } from "components/ui/JsonViewer/jsonViewer";
import { getKey, getParsedValue, isPrimitiveType, needToHideProperty } from "components/ui/JsonViewer/jsonViewer.utils";
import { JsonExpand } from "components/ui/JsonViewer/components/JsonExpand/jsonExpand";

import { JsonValueCommonProps } from "../JsonViewJsonValue/jsonViewJsonValue";
import { JsonViewPrimitiveValue, JsonViewObjectValue } from "./components";

export type FieldValueProps = {
  rows: JsonRow[];
  currentKey: string;
  keyValue?: string;
  indexKeyValue: string;
  offsetIndex: number;
  value: PossibleJsonValue;
  isNotLastIndex: boolean;
} & JsonValueCommonProps;

export const JsonViewFieldValue = ({ value, ...props }: FieldValueProps): ReactElement | null => {
  const property = getKey(props.currentKey, props.indexKeyValue);
  const parsedValue = getParsedValue(value);

  if (isPrimitiveType(parsedValue)) {
    const needToHide = needToHideProperty({ hidedProperties: props.hidedProperties, property, value: parsedValue });

    return needToHide ? (
      <JsonExpand
        setHidedProperties={props.setHidedProperties}
        hidedProperties={props.hidedProperties}
        number={needToHide}
      />
    ) : (
      <JsonViewPrimitiveValue value={parsedValue} {...props} />
    );
  }

  if (Array.isArray(parsedValue)) {
    return <JsonViewObjectValue isArray value={parsedValue} {...props} />;
  }

  return <JsonViewObjectValue value={parsedValue} {...props} />;
};
