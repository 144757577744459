import React from "react";
import { Outlet } from "react-router-dom";
import { Link, Box, useTheme, useMediaQuery, Typography } from "@mui/material";
import { inject, observer } from "mobx-react";

import { RequireAuth } from "components/guards";
import { BGLight, BGDark, LogoLight, LogoDark, LaptopBg } from "components/layouts/AuthFlow/components";
import { RootStore } from "store/root.store";
import { INVENTORY_ROUTE } from "consts";

const AuthFlow = ({ store }: { store?: RootStore }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  return (
    <RequireAuth unauthed redirectTo={INVENTORY_ROUTE}>
      <Box
        minHeight="100vh"
        display="flex"
        padding={store?.ui.isLightTheme ? 5 : 0}
        flexDirection={isMobile ? "column" : "row"}
        width={1}
        alignItems="stretch"
        sx={{
          ...(isMobile && {
            background: `url(${store?.ui?.isLightTheme ? BGLight : BGDark}) no-repeat center right fixed`,
            backgroundSize: "cover",
            alignItems: "center",
            justifyContent: "center",
          }),
        }}
      >
        {isMobile && (
          <Link href="https://www.opshelm.com">
            <Box sx={{ "& svg": { height: { xs: 34, laptop: "auto" } } }}>
              {store?.ui?.isLightTheme ? <LogoLight /> : <LogoDark />}
            </Box>
          </Link>
        )}

        <Box
          borderRadius={store?.ui.isLightTheme ? 2 : 0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexShrink={0}
          flexBasis={{
            xs: "100%",
            laptop: "50%",
          }}
          sx={{
            ...(!isMobile && {
              backgroundColor: "background.paper",
              paddingRight: store?.ui.isLightTheme ? 5 : 0,
              paddingLeft: store?.ui.isLightTheme ? 0 : 5,
            }),
            ...(isMobile && {
              marginY: 5,
              paddingX: store?.ui.isLightTheme ? 0 : 5,
            }),
          }}
        >
          <Outlet />
        </Box>

        {!isMobile && (
          <Box
            flexShrink={0}
            borderRadius={store?.ui.isLightTheme ? 2 : 0}
            bgcolor={store?.ui.isLightTheme ? "primary.main" : "background.paper"}
            flexGrow={1}
            flexBasis="50%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            padding={3}
            flexDirection="column"
            textAlign="center"
          >
            <Box width={1} marginBottom={4} component="img" src={LaptopBg} alt="bg" />

            <Typography
              marginTop={{
                lg: 10,
                lgXl: 15,
                xl: 20,
              }}
              color="#fff"
              variant="h1"
            >
              Begin your OpsHelm Journey
            </Typography>
            <Typography marginTop={5} color="#fff" variant="table">
              Quickly find and fix unmanaged assets, unexpected changes, unforeseen costs, and more — to ensure your
              cloud always looks as expected.
            </Typography>
          </Box>
        )}
      </Box>
    </RequireAuth>
  );
};

export default inject("store")(observer(AuthFlow));
