import React from "react";
import { inject, observer } from "mobx-react";
import { Box } from "@mui/material";

import { Tooltip } from "components/ui";
import { CopySvg } from "components/modals/AddAccountModal/components/AddAccountFormStepTwo/components";
import { copyToClipboard } from "tools";

type CopyToClipboardProps = { value: string; tooltip?: string };

export const CopyToClipboard = ({ value, tooltip }: CopyToClipboardProps) => (
  <Box
    onClick={() => copyToClipboard({ value })}
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    sx={{
      wordBreak: "break-word",
      cursor: "pointer",
      "& svg": { fill: (theme) => theme?.palette?.text?.secondary },
    }}
  >
    <Tooltip title={tooltip} autoPlacement>
      <Box>{value}</Box>
    </Tooltip>

    <Box marginLeft={2}>
      <Tooltip title="Copy">
        <Box
          display="flex"
          sx={{
            svg: {
              width: 15,
            },
          }}
        >
          <CopySvg />
        </Box>
      </Tooltip>
    </Box>
  </Box>
);

export default inject("store")(observer(CopyToClipboard));
