import React from "react";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";

import { DashboardIssue } from "store/types";
import { ErrorText } from "components/ui";

import { ApplyingSvg, PreparingSvg, ValidationSvg, SuccessSvg, ErrorSvg } from "./components";

type DashboardIssueRemediationProgressProps = {
  item: DashboardIssue;
};

const steps = [
  {
    title: "Pending",
  },
  {
    title: "Preparing",
    icon: <PreparingSvg />,
  },
  {
    title: "Applying",
    icon: <ApplyingSvg />,
  },
  {
    title: "Validating",
    icon: <ValidationSvg />,
  },
];

export const DashboardIssueRemediationProgress = ({ item }: DashboardIssueRemediationProgressProps) => {
  const theme = useTheme();

  if (!item?.remediationStatus) return null;

  const { step: currentStep, percentage, error } = item.remediationStatus;

  return (
    <Box display="flex" gap={4} flexDirection="column" paddingY={5}>
      <Typography variant="main">
        Remediation in progress
        {percentage !== undefined && (
          <Box component="span" color="text.secondary">
            {" "}
            ({percentage}% complete)
          </Box>
        )}
      </Typography>
      <Box display="flex" gap={1}>
        {steps.map((step, index) => {
          const isSuccess = index < currentStep;
          const isCurrent = index === currentStep;

          return (
            <Typography
              key={step.title}
              paddingY={1.5}
              paddingX={5}
              gap={1.5}
              display="flex"
              alignItems="center"
              justifyContent="center"
              variant="main"
              color={theme.palette.mode === "light" ? "#A5AAD8" : "#282454"}
              bgcolor="#E7E8F7"
              sx={{
                ...(!index && {
                  borderTopLeftRadius: 10,
                  borderBottomLeftRadius: 10,
                }),
                ...(index === steps.length - 1 && {
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                }),
                svg: {
                  path: {
                    fill: isSuccess
                      ? theme.palette.mode === "light"
                        ? "#00B081"
                        : "#62F3CD"
                      : isCurrent
                      ? "#fff"
                      : theme.palette.mode === "light"
                      ? "#A5AAD8"
                      : "#282454",
                  },
                },
                ...(isSuccess && {
                  backgroundColor: "success.light",
                  color: "success.main",
                }),
                ...(isCurrent && {
                  backgroundColor: error ? "error.main" : "#008AFF",
                  color: "#fff",
                }),
              }}
            >
              {isSuccess ? (
                <SuccessSvg />
              ) : isCurrent ? (
                error ? (
                  <ErrorSvg />
                ) : (
                  <CircularProgress sx={{ color: "#fff" }} size={16} thickness={4} />
                )
              ) : (
                !!step.icon && step.icon
              )}
              {step.title}
            </Typography>
          );
        })}
      </Box>
      {!!error && <ErrorText text={error} />}
    </Box>
  );
};
