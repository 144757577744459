import React from "react";

import { IconText } from "components/ui/IconText/iconText";
import { PROVIDER_ICON_BY_SERVICE_TYPE } from "tools/utils";
import { ProviderServiceIcon } from "components/ui/ProviderServiceTypeIcon/components/providerServiceIcon";

type ProviderServiceTypeIconProps = { type: PROVIDER_ICON_BY_SERVICE_TYPE; text?: string };

export const ProviderServiceTypeIcon = ({ type, text }: ProviderServiceTypeIconProps) => (
  <IconText icon={<ProviderServiceIcon type={type} />} text={text} />
);
