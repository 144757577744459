import { ReactElement } from "react";
import { Box } from "@mui/material";

export const ButtonWrapper = ({ children, marginTop = 5 }: { children: ReactElement; marginTop?: number | string }) => (
  <Box
    data-testid="buttonWrapper"
    marginTop={marginTop}
    display="flex"
    justifyContent={{ xs: "space-between", laptop: "flex-end" }}
    gap={5}
    sx={{
      "button:last-child": {
        marginLeft: "auto",
      },
    }}
  >
    {children}
  </Box>
);
