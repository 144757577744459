import React, { FormEvent, useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { inject, observer } from "mobx-react";

import {
  AccountDropdown,
  Button,
  DatePicker,
  DatePickerDates,
  ElementWrapper,
  ElementWrapperWithActions,
  Filter,
  Loading,
  TableFields,
} from "components/ui";
import { RootStore } from "store/root.store";
import { EventDetailsTable } from "routes/EventsRoute/components";
import { EventsBarChart, NoAccountsData } from "components/containters";
import { FILTER_PAGES, FilterDataItem, FilterEntity, DROPDOWNS, FilterData } from "store/types";
import { getRows, mapToFilterEntities } from "components/ui/JsonViewer/jsonViewer.utils";
import {
  extendFilterWithSearchProperties,
  getDatesFromFilterValue,
  getJsonSearchFromFilter,
  getSearchString,
  isJsonSearchEquals,
  setFilterValueByDates,
  sortFilterEntities,
} from "tools";
import { CursorFilters } from "tools/cursorHandler/cursorHandler.types";
import { Debounce } from "tools/debounce";
import { eventFields } from "routes/EventsRoute/components/eventDetailsTable";
import { FilterSvg } from "components/icons";

const debounce = Debounce();
const debounceJsonSearch = Debounce();

type EventsRouteProps = {
  store?: RootStore;
};

export const UNSEARCHABLE_EVENT_PROPERTIES = ["META.timestamp"];

const EventsRoute = ({ store }: EventsRouteProps) => {
  const { cursorData, jsonSearch, setJsonSearch } = store!.events;
  const { activeAccounts, isFetched } = store!.accounts;

  const [selectedAccountId, setSelectedAccountId] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [selectedFilterName, setSelectedFilterName] = useState("");
  const [filter, setFilter] = useState<FilterDataItem>(store!.filter.getDefaultFilter(FILTER_PAGES.EVENTS));
  const [filterEntities, setFilterEntities] = useState<FilterEntity>();
  const [filterFields, setFilterFields] = useState<TableFields[]>([]);
  const [eventFilters, setEventFilters] = useState<CursorFilters>();

  useEffect(() => () => cursorData?.handler?.resetCursorData(), [cursorData?.handler]);

  const searchAction = useCallback(
    (query: string, e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();

      if (!selectedAccountId) return;

      debounce(() => {
        const dates = getDatesFromFilterValue((filter as FilterData)?.dates);

        const newEventFilters = {
          query,
          accountId: selectedAccountId,
          startTime: dates[0],
          endTime: dates[1],
        };

        cursorData.handler?.setFilters(newEventFilters);
        cursorData.handler?.restart();
        setEventFilters(newEventFilters);
      });
    },
    [cursorData.handler, selectedAccountId, filter],
  );

  useEffect(() => {
    const extendedFilterItems = extendFilterWithSearchProperties(filter, jsonSearch);

    if (!extendedFilterItems.length) {
      extendedFilterItems.push(store!.filter.getDefaultFilterItem(FILTER_PAGES.EVENTS));
    }

    setFilter({
      ...filter,
      items: extendedFilterItems,
    });
  }, [store, filter, jsonSearch]);

  useEffect(
    () =>
      debounceJsonSearch(() => {
        const newJsonSearch = getJsonSearchFromFilter(filter);

        if (!isJsonSearchEquals(jsonSearch, newJsonSearch)) {
          setJsonSearch(newJsonSearch);
        }
      }),
    [filter, jsonSearch, setJsonSearch],
  );

  useEffect(
    () => () => {
      setJsonSearch({});
      cursorData?.handler?.resetData();
    },
    [setJsonSearch, cursorData?.handler],
  );

  useEffect(() => {
    if (filterFields.length) return;

    const entities = mapToFilterEntities(
      cursorData?.data.flatMap((c) => getRows(c.raw_event).filter((r) => !r.onlyJson)),
    );

    setFilterFields(
      Object.keys(entities)
        .filter((f) => !UNSEARCHABLE_EVENT_PROPERTIES.includes(f))
        .map((property) => ({ property, name: property })),
    );

    setFilterEntities(sortFilterEntities(entities));
  }, [cursorData?.data, filterFields.length]);

  const setFilterHandler = useCallback(
    (newFilter: FilterDataItem) => {
      const searchString = getSearchString({ fields: eventFields, filterItem: newFilter });

      setFilter(newFilter);
      searchAction(searchString);
    },
    [searchAction],
  );

  useEffect(() => {
    if (!cursorData.handler || !selectedAccountId) return;

    setFilterHandler(filter);
  }, [cursorData.handler, filter, setFilterHandler, selectedAccountId]);

  const setDates = (dates: DatePickerDates) =>
    setFilterHandler({
      ...filter,
      dates: setFilterValueByDates(dates),
    });

  if (!isFetched) return <Loading marginTop={25} />;

  if (!activeAccounts.length) return <NoAccountsData />;

  return (
    <Box display="flex" gap={5} flexDirection="column">
      <Typography variant="h1">Events</Typography>

      <ElementWrapper customSx={{ paddingX: 4 }}>
        <>
          <Typography variant="h2">Filter</Typography>

          <Box display="flex" gap={3} flexWrap="wrap">
            <AccountDropdown
              inTable
              type={DROPDOWNS.EVENTS_ACCOUNT}
              selectedAccountId={selectedAccountId}
              setSelectedAccountId={setSelectedAccountId}
            />

            <DatePicker range dates={getDatesFromFilterValue((filter as FilterData)?.dates)} setDates={setDates} />

            <Button
              buttonSx={{
                marginLeft: "auto",
              }}
              onClick={() => setShowFilter(!showFilter)}
              customIcon={<FilterSvg open={showFilter} />}
              inTable
              text="Filter"
              variant={showFilter ? "text" : "contained"}
            />
          </Box>

          {showFilter && (
            <Filter
              type={FILTER_PAGES.EVENTS}
              fields={filterFields}
              currentFilter={filter}
              setCurrentFilter={setFilterHandler}
              entities={filterEntities}
              selectedFilterName={selectedFilterName}
              setSelectedFilterName={setSelectedFilterName}
            />
          )}
        </>
      </ElementWrapper>

      <EventsBarChart search={eventFilters} eventRoute />

      <ElementWrapperWithActions
        title="Event Logs"
        component={EventDetailsTable}
        data={cursorData?.data}
        cursorData={cursorData}
        apiSort={cursorData?.handler?.apiSort}
        isLoading={cursorData?.isFetching}
        variant="table"
        hideActions
      />
    </Box>
  );
};

export default inject("store")(observer(EventsRoute));
