import { Box } from "@mui/material";

import { InfoLabelProps, InfoLabel } from "components/ui";

export const InfoLabels = ({ labels, inColumn }: { labels: InfoLabelProps[]; inColumn?: boolean }) => (
  <Box data-testid="infoLabels" display="flex" flexWrap="wrap" gap={3}>
    {labels.map(({ title, value }) => (
      <Box key={title}>
        <InfoLabel inColumn={inColumn} title={title} value={value} />
      </Box>
    ))}
  </Box>
);
