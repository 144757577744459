import { createTheme } from "@mui/material";

import {
  body2Typography,
  darkBG,
  darkBorder,
  darkPaginationBorder,
  darkPaperBG,
  darkPaperBGSecond,
  lightTextSecondaryDarkColor,
  lightTextSecondaryColor,
  darkTextSecondaryDarkColor,
  darkTextSecondaryColor,
  darkTextPrimaryColor,
  defaultBorderRadius,
  defaultButtonFont,
  defaultFontFamily,
  defaultSpacing,
  h2Typography,
  h3Typography,
  h4Typography,
  primaryLight,
  lightBG,
  lightBorder,
  lightPaperBG,
  lightTextPrimaryColor,
  linearButtonBG,
  mainTypography,
  tableTypography,
  titleTypography,
  windowTitleTypography,
  darkModalBG,
  h1Typography,
  primaryMainDark,
  primaryMainLight,
  lightDarkBG,
  lightInputBorder,
  darkInputBorder,
  mediumDark,
  lightTextErrorMain,
  darkTextErrorMain,
  darkTextErrorBg,
  lightTextErrorBg,
} from "theme/commonVariables";
import { UI_THEME } from "store/types";

const laptop = 900;

const breakpoints = {
  values: {
    xs: 0,
    sm: 550,
    md: laptop - 100,
    laptop,
    laptopLg: 1100,
    laptopLgx: 1250,
    lg: 1400,
    lgXl: 1600,
    xl: 1800,
    extraXl: 2200,
  },
};

const themeBreakpoints = createTheme({ breakpoints });

const defaultTheme = (theme: UI_THEME) =>
  createTheme({
    spacing: defaultSpacing,

    breakpoints,

    shape: {
      borderRadius: defaultBorderRadius,
    },

    typography: {
      allVariants: {
        fontFamily: defaultFontFamily,
      },

      main: mainTypography,
      table: tableTypography,
      body2: body2Typography,
      title: titleTypography,
      windowTitle: windowTitleTypography,
      h1: h1Typography,
      h2: h2Typography,
      h3: h3Typography,
      h4: h4Typography,
    },

    palette: {
      mode: theme === UI_THEME.LIGHT ? "light" : "dark",

      background: {
        default: theme === UI_THEME.LIGHT ? lightBG : darkBG,
        oppositePaperBG: theme === UI_THEME.LIGHT ? darkPaperBG : lightPaperBG,
        paper: theme === UI_THEME.LIGHT ? lightPaperBG : darkPaperBG,
        paperBGSecond: theme === UI_THEME.LIGHT ? lightPaperBG : darkPaperBGSecond,
        tableHead: theme === UI_THEME.LIGHT ? lightBG : mediumDark,
        tableRow: theme === UI_THEME.LIGHT ? "#F4F7FE" : "#3a396c",
        tableDarkerRow: theme === UI_THEME.LIGHT ? "#EAF0FF" : "#5f5d9f",
        inputBG: theme === UI_THEME.LIGHT ? "#fff" : "#2F2B5B",
        selectBG: theme === UI_THEME.LIGHT ? lightBG : "#2F2B5B",
        inputInTable: theme === UI_THEME.LIGHT ? lightBG : "#2F2B5B",
        modalBG: theme === UI_THEME.LIGHT ? lightPaperBG : darkModalBG,
        toaster: theme === UI_THEME.LIGHT ? "rgba(229, 229, 229, 0.7)" : "rgba(16, 14, 49, 0.7)",
        infoItemTableRow: theme === UI_THEME.LIGHT ? "#E4F3FF" : "#757CD4",
      },

      text: {
        primary: theme === UI_THEME.LIGHT ? lightTextPrimaryColor : darkTextPrimaryColor,
        secondary: theme === UI_THEME.LIGHT ? lightTextSecondaryColor : darkTextSecondaryColor,
        secondaryDark: theme === UI_THEME.LIGHT ? lightTextSecondaryDarkColor : darkTextSecondaryDarkColor,
      },

      various: {
        defaultBorder: theme === UI_THEME.LIGHT ? lightBorder : darkBorder,
        inputBorder: theme === UI_THEME.LIGHT ? lightInputBorder : darkInputBorder,
        navBG: theme === UI_THEME.LIGHT ? "#EFF6FF" : "#282553",
        tablePagination: theme === UI_THEME.LIGHT ? lightTextPrimaryColor : darkTextPrimaryColor,
        tablePaginationBorder: theme === UI_THEME.LIGHT ? lightBorder : darkPaginationBorder,
        inputColor: theme === UI_THEME.LIGHT ? lightTextPrimaryColor : darkTextPrimaryColor,
        tableTitleColor: theme === UI_THEME.LIGHT ? lightBorder : "#A5AAD8",
        inactiveColor: "#A5AAD8",
        jsonTableButton: theme === UI_THEME.LIGHT ? mediumDark : "#282553",
        jsonViewButtons: theme === UI_THEME.LIGHT ? "#fff" : mediumDark,
        tagInactive: {
          border: "#D3DAEB",
          bg: "#F3F7FF",
          color: theme === UI_THEME.LIGHT ? "#848696" : "#E9F2F9",
          bgColor: "#848696",
        },
        tagBlue: {
          border: "#C1DCF2",
          bg: "#E9F2F9",
          color: theme === UI_THEME.LIGHT ? "#007DE7" : "#C1DCF2",
          bgColor: theme === UI_THEME.LIGHT ? "#007DE7" : "#83b3da",
        },
        tagGreen: {
          border: "#BBECDE",
          bg: "#E3FDF6",
          color: "#68D7B9",
          bgColor: "#00B081",
        },
        tagYellow: {
          border: "#efd18a",
          bg: "#f5e2b6",
          color: "#FFD36B",
          bgColor: "#c99c32",
        },
      },

      primary: {
        main: theme === UI_THEME.LIGHT ? primaryMainLight : primaryMainDark,
        tooltipLink: theme === UI_THEME.LIGHT ? "#0277df" : "#319eff",
        light: primaryLight,
      },

      success: {
        main: theme === UI_THEME.LIGHT ? "#00B081" : "#62F3CD",
        light: theme === UI_THEME.LIGHT ? "#BBECDE" : "#20425F",
      },

      error: {
        main: theme === UI_THEME.LIGHT ? lightTextErrorMain : darkTextErrorMain,
        light: theme === UI_THEME.LIGHT ? lightTextErrorBg : darkTextErrorBg,
      },

      warning: {
        main: theme === UI_THEME.LIGHT ? "#D59B16" : "#FFD36B",
        light: theme === UI_THEME.LIGHT ? "#FFF2D5" : "#504149",
      },

      pending: {
        main: theme === UI_THEME.LIGHT ? "#a5a9da" : "#ccced0",
        light: theme === UI_THEME.LIGHT ? "#f0f1fa" : "#3a3939",
      },

      secondarySuccess: {
        main: "#7D41FD",
        light: "#E2DFFF",
      },
    },

    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            "& .MuiPickersArrowSwitcher-button": {
              color: theme === UI_THEME.LIGHT ? "#2F3259" : "#A5AAD8",
            },

            "& .MuiAutocomplete-option": {
              fontSize: 14,
              paddingLeft: defaultSpacing * 2,
              paddingRight: defaultSpacing * 2,
            },

            "& .MuiAutocomplete-noOptions": {
              fontSize: 14,
            },
          },
        },
      },

      MuiTypography: {
        styleOverrides: {
          root: {
            color: theme === UI_THEME.LIGHT ? lightTextPrimaryColor : darkTextPrimaryColor,
          },
        },
      },

      MuiTooltip: {
        styleOverrides: {
          arrow: {
            color: theme === UI_THEME.LIGHT ? darkPaperBGSecond : lightPaperBG,
          },

          tooltip: {
            color: theme === UI_THEME.LIGHT ? lightPaperBG : darkPaperBGSecond,
            maxWidth: "none",
            fontSize: 14,
            background: theme === UI_THEME.LIGHT ? darkPaperBGSecond : lightPaperBG,
            padding: defaultSpacing * 3,
            borderRadius: defaultBorderRadius,
          },
        },
      },

      MuiPopover: {
        styleOverrides: {
          root: {
            "& .MuiPaper-root": {
              borderRadius: defaultBorderRadius,
            },
          },
        },
      },

      MuiCheckbox: {
        defaultProps: {
          disableRipple: true,
        },
      },

      MuiTabs: {
        styleOverrides: {
          indicator: {
            height: 2,
            bottom: -2,
            borderRadius: theme === UI_THEME.LIGHT ? defaultBorderRadius : 0,
          },

          root: {
            minHeight: "auto",

            ".MuiTabs-flexContainer": {
              flexWrap: "wrap",
            },
          },
        },
      },

      MuiTab: {
        defaultProps: {
          disableRipple: true,
        },

        styleOverrides: {
          root: {
            ...defaultButtonFont,
            color: theme === UI_THEME.LIGHT ? primaryMainLight : primaryMainDark,
            textTransform: "none",
            marginRight: "0 !important",
            padding: `${defaultSpacing}px ${defaultSpacing * 4}px`,
            minHeight: "auto",
          },
        },
      },

      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            margin: 0,
          },
        },
      },

      MuiDialog: {
        styleOverrides: {
          root: {
            "& .MuiDateTimePickerToolbar-root": {
              button: {
                boxShadow: "none !important",
              },
            },
          },
        },
      },

      MuiToggleButton: {
        defaultProps: {
          disableRipple: true,
        },

        styleOverrides: {
          root: {
            ...mainTypography,
            color: theme === UI_THEME.LIGHT ? lightTextSecondaryDarkColor : darkTextSecondaryDarkColor,
            borderRadius: defaultBorderRadius,
            textTransform: "none",
            border: "none",
            paddingLeft: defaultSpacing * 6,
            paddingRight: defaultSpacing * 6,

            "&.Mui-selected": {
              pointerEvents: "none",
              color: "#fff",
              background: linearButtonBG,
              borderRadius: `${defaultBorderRadius}px !important`,

              "&:hover": {
                background: linearButtonBG,
              },
            },
          },
        },
      },

      MuiDivider: {
        styleOverrides: {
          root: {
            borderWidth: 1,
            borderColor: lightDarkBG,
          },
        },
      },

      MuiIconButton: {
        defaultProps: {
          disableRipple: true,
        },
      },

      MuiSwitch: {
        defaultProps: {
          disableRipple: true,
        },

        styleOverrides: {
          root: {
            padding: 0,
            height: 29,
            width: 49,
            marginBottom: -2,
            marginRight: defaultSpacing * -2,
            cursor: "pointer",

            "& .MuiSwitch-thumb": {
              width: 17,
              height: 17,
              boxShadow: "none",
              background: theme === UI_THEME.LIGHT ? lightBorder : lightBG,
            },

            "& .MuiSwitch-switchBase": {
              padding: 0,
              top: 4,
              left: 4,

              "&+.MuiSwitch-track": {
                border: "1px solid",
                borderColor: theme === UI_THEME.LIGHT ? lightBorder : lightBG,
                borderRadius: 20,
                height: 25,
                width: 40,
                background: theme === UI_THEME.LIGHT ? "#fff" : "#2B2753",
                opacity: 1,
              },

              "&.Mui-checked": {
                transform: "none",
                left: "auto",
                right: 13,

                "&+.MuiSwitch-track": {
                  opacity: 1,
                  borderColor: primaryLight,
                  background: primaryLight,
                },

                "& .MuiSwitch-thumb": {
                  background: "#fff",
                },
              },
            },
          },
        },
      },

      MuiRadio: {
        defaultProps: {
          disableRipple: true,
        },

        styleOverrides: {
          root: {
            padding: 0,
            marginRight: `${2 * defaultSpacing}px`,
            color: theme === UI_THEME.LIGHT ? lightInputBorder : darkInputBorder,
          },
        },
      },

      MuiButton: {
        defaultProps: {
          disableRipple: true,
        },

        styleOverrides: {
          root: {
            ...defaultButtonFont,
            textTransform: "none",
            padding: "10px 36px",
            borderRadius: defaultSpacing,
            boxShadow: "none !important",
            color: "#fff",

            [themeBreakpoints.breakpoints.down("sm")]: {
              padding: "12px 24px",
            },

            "&.Mui-disabled": {
              color: theme === UI_THEME.LIGHT ? "rgba(0, 0, 0, 0.25)" : "rgba(255, 255, 255, 0.25)",
              background: theme === UI_THEME.LIGHT ? "rgba(0, 0, 0, 0.12)" : "rgba(255, 255, 255, 0.12)",
              boxShadow: "none !important",
            },
          },

          containedPrimary: {
            background: linearButtonBG,
          },

          text: {
            background: theme === UI_THEME.LIGHT ? lightBG : lightBorder,
            color: theme === UI_THEME.LIGHT ? lightTextSecondaryDarkColor : darkTextPrimaryColor,
          },

          textError: {
            background: theme === UI_THEME.LIGHT ? lightTextErrorBg : darkTextErrorBg,
            color: theme === UI_THEME.LIGHT ? lightTextErrorMain : darkTextErrorMain,

            "&:hover": {
              background: theme === UI_THEME.LIGHT ? lightTextErrorBg : darkTextErrorBg,
            },
          },

          outlined: {
            border: "none",
            background: "none",
            color: theme === UI_THEME.LIGHT ? darkPaperBGSecond : darkTextPrimaryColor,

            "&:hover": {
              border: "none",
              background: "none",
            },

            "&[disabled]": {
              border: "none",
              background: "none",
              opacity: 0.5,
            },
          },
        },
      },

      MuiMenuItem: {
        defaultProps: {
          disableRipple: true,
        },

        styleOverrides: {
          root: {
            fontSize: 14,
            paddingLeft: defaultSpacing * 2,
            paddingRight: defaultSpacing * 2,

            "&:hover": {
              background: "rgba(0, 0, 0, 0.05)",
            },
          },
        },
      },

      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: defaultSpacing * 3,
            borderBottom: "none",

            "&.MuiTableCell-head": {
              whiteSpace: "nowrap",
            },

            "&.MuiTableCell-body": {
              "&:first-of-type": {
                borderLeft: "none",
              },
            },
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            color: theme === UI_THEME.LIGHT ? lightTextPrimaryColor : darkTextPrimaryColor,

            svg: {
              fill: theme === UI_THEME.LIGHT ? lightTextPrimaryColor : darkTextPrimaryColor,
            },
          },
        },
      },

      MuiAlert: {
        styleOverrides: {
          message: {
            padding: 0,
          },

          icon: {
            padding: 0,
          },

          action: {
            padding: 0,

            button: {
              padding: 0,
            },
          },

          root: {
            ...mainTypography,
            color: darkPaperBGSecond,
            border: "1px solid",
            paddingBottom: defaultSpacing * 4,
            borderRadius: defaultBorderRadius,

            svg: {
              fill: darkPaperBGSecond,
            },

            "&.MuiAlert-standardError": {
              background: "#FFDADA",
              borderColor: "#F56264",
            },

            "&.MuiAlert-standardInfo": {
              background: "#BADFFF",
              borderColor: "#008AFF",
            },

            "&.MuiAlert-standardSuccess": {
              background: "#A0FAE2",
              borderColor: "#00B081",
            },

            "&.MuiAlert-standardWarning": {
              background: "#FFEAB8",
              borderColor: "#FFC641",
            },
          },
        },
      },

      MuiAlertTitle: {
        styleOverrides: {
          root: {
            ...titleTypography,
            color: darkPaperBGSecond,
          },
        },
      },

      MuiSnackbar: {
        styleOverrides: {
          root: {
            ".MuiAlert-message": {
              width: "100%",

              ".MuiAlertTitle-root": {
                width: "100%",
              },
            },
          },
        },
      },
    },
  });

export default defaultTheme;
