import React from "react";
import { inject, observer } from "mobx-react";
import { PersonRemoveAlt1Outlined, SettingsOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";

import { SUPPORTED_STATUSES_TYPE, ProviderIcon, Status, Table } from "components/ui";
import { formattedDate } from "tools";
import { RootStore } from "store/root.store";
import { Account, ACCOUNT_STATUSES, AddOrgResponse, UI_TABLES } from "store/types";
import { AddAccountModal } from "components/modals";

export const accountFields = [
  {
    name: "Account",
    property: "account",
    textValue: (account: Account) => `${account.provider} ${account.name}`,
    render: (account: Account) => <ProviderIcon name={account.name} provider={account.provider} />,
    mobileTopSection: true,
  },
  {
    name: "Account ID",
    property: "provider_account_id",
  },
  {
    name: "Date Added",
    property: "created_at",
    render: (account: Account) => formattedDate(account.created_at),
  },
  {
    name: "Status",
    property: "status",
    textValue: (account: Account) => account.status,
    render: (account: Account) => <Status status={account.status} statusType={SUPPORTED_STATUSES_TYPE.ACCOUNT} />,
    mobileTopSection: true,
  },
];

const AccountsTable = ({
  store,
  data,
  organization,
  ...props
}: {
  store?: RootStore;
  organization?: AddOrgResponse;
  data: Account[];
}) => {
  const openAccountStatus = (account: Account) => store?.ui.openAccountModal(<AddAccountModal account={account} />);

  const removeAccount = (account: Account) => {
    store?.ui.openConfirmModal({
      title: "Are you sure you want to remove this account?",
      component: (
        <>
          This can not be undone.
          <br />
          The account will be removed from OpsHelm, audit log delivery will be disabled and associated data will be
          deleted.
        </>
      ),
      confirmText: "Remove",
      onConfirm: () => store!.accounts.removeAccount(account),
    });
  };

  const actions = [
    {
      key: "status",
      onClick: (account: Account) => openAccountStatus(account),
      getButtonProps: () => ({ icon: SettingsOutlined, text: "Status" }),
      hide: (account: Account) => account.status === ACCOUNT_STATUSES.ACTIVE,
    },
    ...(organization
      ? []
      : [
          {
            key: "remove",
            onClick: removeAccount,
            getButtonProps: () => ({ icon: PersonRemoveAlt1Outlined, text: "Remove", variant: "text" }),
          },
        ]),
  ];

  const mobileTopSection = ({ item }: { item: Account }) => (
    <Box display="flex" alignItems="center">
      <ProviderIcon name={item.name} variant="title" provider={item.provider} />
      <Box marginLeft="auto" marginRight={actions.filter((action) => !action.hide?.(item)).length ? 5 : 0}>
        <Status status={item.status} statusType={SUPPORTED_STATUSES_TYPE.ACCOUNT} />
      </Box>
    </Box>
  );

  return (
    <Table
      {...props}
      type={organization ? `${UI_TABLES.ACCOUNTS}_${organization.id}` : UI_TABLES.ACCOUNTS}
      mobileTopSection={mobileTopSection}
      actions={actions}
      removePadding
      fields={accountFields}
      data={data}
      noDataText="You haven’t connected any accounts yet"
    />
  );
};

export default inject("store")(observer(AccountsTable));
