import React, { ReactElement } from "react";
import { inject, observer } from "mobx-react";

import { RootStore } from "store/root.store";
import { accountFields } from "routes/AccountsRoute/components/acccountsTable/accountsTable";
import { DetailsOnHover } from "components/ui";

type AccountDetailsProps = {
  store?: RootStore;
  id: string;
  component: ReactElement;
  inTable?: boolean;
};

const AccountDetailsOnHover = ({ store, id, component, inTable }: AccountDetailsProps) => {
  const { activeAccounts } = store!.accounts;

  const account = activeAccounts.find((a) => a.id === id);

  return (
    <DetailsOnHover
      item={account}
      fields={accountFields.filter((a) => a.property !== "status")}
      component={component}
      inTable={inTable}
    />
  );
};

export default inject("store")(observer(AccountDetailsOnHover));
