export const defaultSpacing = 5;
export const defaultBorderRadius = 5;
export const defaultFontFamily = "'Open sans', sans-serif";

export const defaultButtonFont = {
  fontSize: 14,
  lineHeight: 1.25,
  fontWeight: 600,
  fontFamily: defaultFontFamily,
};

export const mainTypography = {
  fontSize: 14,
  lineHeight: 1.25,
  fontWeight: 600,
  fontFamily: defaultFontFamily,
};

export const tableTypography = {
  fontSize: 14,
  lineHeight: 1.25,
  fontWeight: 400,
  fontFamily: defaultFontFamily,
};

export const body2Typography = {
  fontSize: 20,
  lineHeight: 1.2,
  fontWeight: 400,
  fontFamily: defaultFontFamily,
};

export const titleTypography = {
  fontSize: 20,
  lineHeight: 1.2,
  fontWeight: 600,
  fontFamily: defaultFontFamily,
};

export const windowTitleTypography = {
  fontSize: 40,
  lineHeight: 1.17,
  fontWeight: 600,
  fontFamily: defaultFontFamily,
};

export const h1Typography = {
  fontSize: 32,
  lineHeight: 1.2,
  fontWeight: 700,
  fontFamily: defaultFontFamily,
};

export const h2Typography = {
  fontSize: 24,
  lineHeight: 1.2,
  fontWeight: 700,
  fontFamily: defaultFontFamily,
};

export const h3Typography = {
  fontSize: 20,
  lineHeight: 1.2,
  fontWeight: 700,
  fontFamily: defaultFontFamily,
};

export const h4Typography = {
  fontSize: 16,
  lineHeight: 1.2,
  fontWeight: 700,
  fontFamily: defaultFontFamily,
};

export const primaryLight = "#597BF7";

export const primaryMainLight = "#4141F2";
export const lightBG = "#F4F7FE";
export const lightInputBorder = "#A0BBFF";
export const lightDarkBG = "#E0E8FB";
export const lightBorder = "#8F94C3";
export const lightPaperBG = "#fff";
export const lightTextPrimaryColor = "#2F3259";
export const lightTextSecondaryColor = "#87A0DD";
export const lightTextSecondaryDarkColor = "#4267C7";
export const lightTextErrorMain = "#DA535A";
export const lightTextErrorBg = "#FDE8E8";

export const mediumDark = "#5A5FA0";

export const primaryMainDark = "#5CC9FA";
export const darkBG = "#1b1743";
export const darkInputBorder = "#9DA2D1";
export const darkPaperBGSecond = "#100E31";
export const darkPaperBG = "#282454";
export const darkTextPrimaryColor = "#F9F9F9";
export const darkTextSecondaryColor = "#A5AAF5";
export const darkTextSecondaryDarkColor = "#A5AAF5";
export const darkBorder = "#5b6aa5";
export const darkPaginationBorder = "#9DA2D1";
export const darkModalBG = "#2B2753";
export const darkTextErrorMain = "#F4898B";
export const darkTextErrorBg = "#3A2B57";

export const linearButtonBG = `linear-gradient(100deg, ${primaryLight} -2%, #4365E5 100%)`;
