import { api } from "libs";
import {
  API_CLOUD_ACCOUNTS,
  API_CLOUD_ACCOUNTS_ON_BOARDING,
  API_CLOUD_ACCOUNTS_ON_BOARDING_STATUS,
  API_CLOUD_ACCOUNTS_CANCEL_ON_BOARDING,
  API_CLOUD_ACCOUNTS_BY_ID,
  API_ORG_ON_BOARDING_STATUS,
  API_ORG,
  API_ORG_BY_ID,
  API_ORG_ON_BOARDING_CONTINUE,
} from "libs/api/endpoints";
import {
  Account,
  AddCloudRequest,
  AddOrgRequest,
  AddOrgResponse,
  OnBoardingData,
  OrgOnBoardingData,
  UpdateAccountRequest,
} from "store/types";

export const getCloudAccountsList = async (doNotShowErrorMessage: boolean): Promise<Account[]> => {
  const res = await api.get<{ accounts: Account[] }>(API_CLOUD_ACCOUNTS, {
    doNotShowErrorMessage,
    cache: {
      maxAge: 0,
    },
    errorMessage: {
      id: "err-list-cloud-accounts",
      title: "Failed to load Account list",
    },
  });

  return res.accounts;
};

export const addCloudAccount = async (data: AddCloudRequest, doNotShowErrorMessage?: boolean): Promise<Account> =>
  api.post(API_CLOUD_ACCOUNTS, data, {
    doNotShowErrorMessage,
    errorMessage: {
      title: "Failed to add Account",
    },
  });

export const getOnboardingStatus = async (accountId: string): Promise<OnBoardingData> =>
  api.get(API_CLOUD_ACCOUNTS_ON_BOARDING_STATUS(accountId), {
    cache: {
      maxAge: 0,
    },
    errorMessage: {
      title: "Failed to get Account status",
    },
  });

export const onBoardAccount = async (accountId: string): Promise<void> =>
  api.post(API_CLOUD_ACCOUNTS_ON_BOARDING(accountId), null, {
    errorMessage: {
      title: "Failed to onboard Account",
    },
  });

export const removeAccount = async (accountId: string, doNotShowErrorMessage?: boolean): Promise<void> =>
  api.delete(API_CLOUD_ACCOUNTS_BY_ID(accountId), {
    doNotShowErrorMessage,
    errorMessage: {
      title: "Failed to remove Account",
    },
  });

export const updateAccount = async (accountId: string, data: UpdateAccountRequest): Promise<Account> =>
  api.put(API_CLOUD_ACCOUNTS_BY_ID(accountId), data, {
    errorMessage: {
      title: "Failed to update account",
    },
  });

export const cancelOnboarding = async (accountId: string): Promise<void> =>
  api.post(API_CLOUD_ACCOUNTS_CANCEL_ON_BOARDING(accountId), null, {
    errorMessage: {
      title: "Failed to cancel onboarding for the Account",
    },
  });

export const getOrganizations = async (): Promise<AddOrgResponse[]> => {
  const res = await api.get(API_ORG, {
    cache: {
      maxAge: 0,
    },
    errorMessage: {
      title: "Failed to get Organizations",
    },
  });

  return res || [];
};

export const createOrganization = async (data: AddOrgRequest): Promise<AddOrgResponse> =>
  api.post(API_ORG, data, {
    errorMessage: {
      title: "Failed to create Organization",
    },
  });

export const deleteOrganization = async (id: string): Promise<void> =>
  api.delete(API_ORG_BY_ID(id), {
    errorMessage: {
      title: "Failed to delete Organization",
    },
  });

export const continueOrganizationOnboarding = async (id: string): Promise<void> =>
  api.post(API_ORG_ON_BOARDING_CONTINUE(id), {
    errorMessage: {
      title: "Failed to get Continue Organization onboarding",
    },
  });

export const getOrganizationOnboardingStatus = async (id: string): Promise<OrgOnBoardingData> =>
  api.get(API_ORG_ON_BOARDING_STATUS(id), {
    doNotShowErrorMessage: true,
    cache: {
      maxAge: 0,
    },
    errorMessage: {
      title: "Failed to get Organization status",
    },
  });
