import { makeAutoObservable } from "mobx";

import { RootStore } from "store/root.store";
import { COMPLIANCE_ITEM, ComplianceItem } from "store/types";

export class ComplianceStore {
  isError = false;
  isFetched = false;
  data: ComplianceItem[] = [];

  constructor(readonly rootStore: RootStore) {
    this.rootStore = rootStore;

    this.fetch = this.fetch.bind(this);

    makeAutoObservable(this);
  }

  async fetch() {
    try {
      this.data = [
        {
          id: "1",
          type: COMPLIANCE_ITEM.HITRUST,
          name: "Hitrust",
          controlled: 5,
          total: 40,
          account_id: "01GMQQ2X3F8AS875H251QS02YX",
          description: "sdfds sd fsd fdsf sdf sdf ",
          items: [
            {
              id: "11",
              title: "Identity and Access Management",
              analysedItems: [
                {
                  id: "111",
                  title: "Identity and Access Management",
                  passed: 3,
                  needsReview: 1,
                },
                {
                  id: "222",
                  title: "Identity and Access Management",
                  passed: 31,
                  needsReview: 21,
                  info: 30,
                },
                {
                  id: "333",
                  title: "Identity and Access Management",
                  needsReview: 11,
                },
                {
                  id: "444",
                  title: "Identity and Access Management",
                  needsReview: 1,
                  info: 11,
                },
              ],
            },
            {
              id: "22",
              title: "Identity and Access Management2",
              analysedItems: [
                {
                  id: "111",
                  title: "Identity and Access Management",
                  passed: 13,
                  needsReview: 11,
                  info: 30,
                },
                {
                  id: "222",
                  title: "Identity and Access Management",
                  needsReview: 121,
                  info: 30,
                },
                {
                  id: "444",
                  title: "Identity and Access Management",
                  needsReview: 11,
                },
                {
                  id: "333",
                  title: "Identity and Access Management",
                  needsReview: 11,
                },
              ],
            },
            {
              id: "33",
              title: "Identity and Access Management3",
              analysedItems: [
                {
                  id: "111",
                  title: "Identity and Access Management",
                  passed: 3,
                  needsReview: 51,
                },
                {
                  id: "222",
                  title: "Identity and Access Management",
                  passed: 31,
                  needsReview: 21,
                  info: 301,
                },
                {
                  id: "333",
                  title: "Identity and Access Management",
                  needsReview: 11,
                },
                {
                  id: "444",
                  title: "Identity and Access Management",
                  needsReview: 12,
                  info: 11,
                },
              ],
            },
          ],
        },
        {
          id: "2",
          type: COMPLIANCE_ITEM.AWS,
          name: "AWS Foundational Security Best Practices",
          controlled: 5,
          total: 45,
          account_id: "01GMQQ2X3F8AS875H251QS02YX",
          description: "sdfds sd fsd fdsf sdf sdf ",
          items: [
            {
              id: "11",
              title: "Identity and Access Management",
              analysedItems: [
                {
                  id: "111",
                  title: "Identity and Access Management",
                  passed: 3,
                  needsReview: 1,
                },
                {
                  id: "222",
                  title: "Identity and Access Management",
                  passed: 31,
                  needsReview: 21,
                  info: 30,
                },
                {
                  id: "333",
                  title: "Identity and Access Management",
                  needsReview: 11,
                },
                {
                  id: "444",
                  title: "Identity and Access Management",
                  needsReview: 1,
                  info: 11,
                },
              ],
            },
          ],
        },
        {
          id: "3",
          type: COMPLIANCE_ITEM.NIST,
          name: "AWS Well-Architected Framework",
          controlled: 15,
          total: 20,
          account_id: "01GMQQ2X3F8AS875H251QS02YX",
          description: "!!!sdfds sd fsd fdsf sdf sdf ",
          items: [
            {
              id: "11",
              title: "Identity and Access Management",
              analysedItems: [
                {
                  id: "111",
                  title: "Identity and Access Management",
                  passed: 3,
                  needsReview: 1,
                },
                {
                  id: "222",
                  title: "Identity and Access Management",
                  passed: 31,
                  needsReview: 21,
                  info: 30,
                },
                {
                  id: "333",
                  title: "Identity and Access Management",
                  needsReview: 11,
                },
                {
                  id: "444",
                  title: "Identity and Access Management",
                  needsReview: 1,
                  info: 11,
                },
              ],
            },
          ],
        },
        {
          id: "4",
          type: COMPLIANCE_ITEM.AWS,
          name: "Nist",
          controlled: 5,
          total: 30,
          account_id: "01GMQQ2X3F8AS875H251QS02YX",
          description: "sdfds sd fsd fdsf sdf sdf ",
          items: [
            {
              id: "11",
              title: "Identity and Access Management",
              analysedItems: [
                {
                  id: "111",
                  title: "Identity and Access Management",
                  passed: 3,
                  needsReview: 1,
                },
                {
                  id: "222",
                  title: "Identity and Access Management",
                  passed: 31,
                  needsReview: 21,
                  info: 30,
                },
                {
                  id: "333",
                  title: "Identity and Access Management",
                  needsReview: 11,
                },
                {
                  id: "444",
                  title: "Identity and Access Management",
                  needsReview: 1,
                  info: 11,
                },
              ],
            },
          ],
        },
        {
          id: "5",
          type: COMPLIANCE_ITEM.HITRUST,
          name: "Iso",
          controlled: 15,
          total: 40,
          account_id: "01GMQQ2X3F8AS875H251QS02YX",
          description: "sdfds sd fsd fdsf sdf sdf ",
          items: [
            {
              id: "11",
              title: "Identity and Access Management",
              analysedItems: [
                {
                  id: "111",
                  title: "Identity and Access Management",
                  passed: 3,
                  needsReview: 1,
                },
                {
                  id: "222",
                  title: "Identity and Access Management",
                  passed: 31,
                  needsReview: 21,
                  info: 30,
                },
                {
                  id: "333",
                  title: "Identity and Access Management",
                  needsReview: 11,
                },
                {
                  id: "444",
                  title: "Identity and Access Management",
                  needsReview: 1,
                  info: 11,
                },
              ],
            },
          ],
        },
        {
          id: "6",
          type: COMPLIANCE_ITEM.AWS,
          name: "Pci",
          controlled: 40,
          total: 40,
          account_id: "01GMQQ2X3F8AS875H251QS02YX",
          description: "sdfds sd fsd fdsf sdf sdf ",
          items: [
            {
              id: "11",
              title: "Identity and Access Management",
              analysedItems: [
                {
                  id: "111",
                  title: "Identity and Access Management",
                  passed: 3,
                  needsReview: 1,
                },
                {
                  id: "222",
                  title: "Identity and Access Management",
                  passed: 31,
                  needsReview: 21,
                  info: 30,
                },
                {
                  id: "333",
                  title: "Identity and Access Management",
                  needsReview: 11,
                },
                {
                  id: "444",
                  title: "Identity and Access Management",
                  needsReview: 1,
                  info: 11,
                },
              ],
            },
          ],
        },
      ];

      this.isFetched = true;
    } catch {
      this.isError = true;
    }
  }
}
