import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { addDays, format } from "date-fns";
import { Box, useMediaQuery, useTheme } from "@mui/material";

import { AccountDropdown, BarChart, DatePicker, DatePickerDates, ElementWrapperWithActions } from "components/ui";
import { RootStore } from "store/root.store";
import { CursorFilters } from "tools/cursorHandler/cursorHandler.types";
import { DROPDOWNS, EventAggregation } from "store/types";
import { formattedYearDate, getDatesRange } from "tools";

type EventsBarChartProps = {
  store?: RootStore;
  search?: CursorFilters;
  editModeCustomTitleElement?: ReactElement;
  eventRoute?: boolean;
  showFilters?: boolean;
  doNotShowDates?: boolean;
};

const EventsBarChart = ({
  store,
  search,
  editModeCustomTitleElement,
  showFilters,
  eventRoute,
  doNotShowDates,
}: EventsBarChartProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));
  const isLaptopLg = useMediaQuery(theme.breakpoints.down("laptopLgx"));

  const [selectedAccountId, setSelectedAccountId] = useState("");
  const [dates, setDates] = useState<DatePickerDates>([addDays(new Date(), -6), new Date()]);
  const [datesRange, setDatesRange] = useState<string[]>([]);
  const [aggregatedData, setAggregatedData] = useState<EventAggregation[]>([]);
  const [chartData, setChartData] = useState<Record<string, number>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isEqual = datesRange.length === 1;
  const properties = isEqual ? new Array(24).fill(0).map((_, index) => index) : datesRange;
  const labels = isEqual ? properties.map((p) => `${p > 9 ? "" : "0"}${p}:00`) : (properties as string[]);

  const getData = useCallback(
    (searchProperties: CursorFilters) => {
      setIsLoading(true);

      store?.events
        .getEventsAggregatedData(searchProperties)
        .then(setAggregatedData)
        .catch(() => null)
        .then(() => setIsLoading(false));
    },
    [store?.events, setAggregatedData],
  );

  useEffect(() => {
    setDatesRange(
      showFilters ? getDatesRange(dates) : getDatesRange([search?.startTime as string, search?.endTime as string]),
    );
  }, [showFilters, dates, search]);

  useEffect(() => {
    if (!search) return;

    getData(search);
  }, [getData, search]);

  useEffect(() => {
    if (eventRoute || !dates || !selectedAccountId) {
      return;
    }

    getData({
      accountId: selectedAccountId,
      startTime: dates[0],
      endTime: dates[1],
    });
  }, [getData, dates, selectedAccountId, eventRoute]);

  useEffect(() => {
    setChartData(
      aggregatedData
        .filter((i) => datesRange.includes(formattedYearDate(i.date)))
        .reduce((acc: Record<string, number>, i) => {
          const prop = isEqual ? format(new Date(i.date), "H") : formattedYearDate(i.date);

          return { ...acc, [prop]: (acc[prop] || 0) + i.count };
        }, {}),
    );
  }, [datesRange, aggregatedData, isEqual]);

  const customTitleElement = (
    <>
      <AccountDropdown
        inTable
        type={DROPDOWNS.DASHBOARD_ACCOUNT_EVENT_CHART}
        selectedAccountId={selectedAccountId}
        setSelectedAccountId={setSelectedAccountId}
        withoutTitle
      />

      <Box whiteSpace="nowrap">
        <DatePicker range doNotShowDates={doNotShowDates ? isLaptopLg : isMobile} dates={dates} setDates={setDates} />
      </Box>
    </>
  );

  return (
    <ElementWrapperWithActions
      component={BarChart}
      title={
        <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center" gap={5}>
          Events
          {(editModeCustomTitleElement || showFilters) && (
            <Box display="flex" gap={3}>
              {editModeCustomTitleElement || customTitleElement}
            </Box>
          )}
        </Box>
      }
      hideActions
      componentProps={{
        isLoading,
        isDate: true,
        isEqual,
        first: datesRange[0],
        last: datesRange[datesRange.length - 1],
        labels,
        data: properties.map((l) => chartData[l]),
      }}
    />
  );
};

export default inject("store")(observer(EventsBarChart));
