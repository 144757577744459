import React, { MouseEvent } from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { inject, observer } from "mobx-react";

import {
  LogoLightSvg,
  LogoDarkSvg,
  LogoLightSmallSvg,
  LogoDarkSmallSvg,
  MenuViewSvg,
} from "components/layouts/LoggedInFlow/components";
import { RootStore } from "store/root.store";
import { INDEX_ROUTE } from "consts";

const MenuLogo = ({ store }: { store?: RootStore }) => {
  const changeMenuView = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    store?.ui.changeMenuView(!store?.ui.smallView);
  };

  return (
    <Box
      display={{ xs: "none", laptop: "flex" }}
      data-testid="menuLogo"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      marginBottom={5}
    >
      <Link to={INDEX_ROUTE}>
        <Box paddingLeft={2} display="flex">
          {store?.ui.smallView &&
            (store?.ui.isLightTheme ? (
              <LogoLightSmallSvg data-testid="logoLightSmall" width={28} />
            ) : (
              <LogoDarkSmallSvg data-testid="logoDarkSmall" width={28} />
            ))}
          {!store?.ui.smallView &&
            (store?.ui.isLightTheme ? (
              <LogoLightSvg data-testid="logoLight" width={150} />
            ) : (
              <LogoDarkSvg data-testid="logoDark" width={150} />
            ))}
        </Box>
      </Link>
      <Box
        data-testid="changeMenuView"
        height={72}
        width={32}
        position="absolute"
        borderRadius={1}
        right={-31}
        boxShadow="1px 1px 4px rgba(90, 95, 160, 0.2), -1px -1px 4px rgba(90, 95, 160, 0.2)"
        onClick={changeMenuView}
        bgcolor={store?.ui?.isLightTheme ? "#fff" : "#494872"}
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          cursor: "pointer",
          transform: `rotate(${store?.ui.smallView ? "0deg" : "180deg"})`,
          svg: { fill: store?.ui?.isLightTheme ? "#4141F2" : "#5CC9FA" },
        }}
      >
        <MenuViewSvg width={8} height={16} />
      </Box>
    </Box>
  );
};

export default inject("store")(observer(MenuLogo));
