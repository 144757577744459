import React, { ReactElement } from "react";
import { Box, Typography } from "@mui/material";
import { KeyboardBackspace } from "@mui/icons-material";
import { Link } from "react-router-dom";

import { Button, ElementWrapper } from "components/ui";

export const SingleItem = ({
  children,
  backUrl,
  title,
}: {
  children: ReactElement;
  title?: string;
  backUrl?: string;
}) => (
  <ElementWrapper
    customSx={{
      padding: 0,
      gap: 0,
    }}
  >
    <>
      {(!!title || !!backUrl) && (
        <ElementWrapper
          customSx={{
            gap: 0,
            marginBottom: -4,
          }}
        >
          <Box
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="space-between"
            gap={{ xs: 3, laptop: 5 }}
          >
            {!!title && (
              <Typography sx={{ wordBreak: "break-word" }} variant="h2">
                {title}
              </Typography>
            )}
            {!!backUrl && (
              <Box marginLeft="auto" component={Link} to={backUrl}>
                <Button text="Go Back" icon={KeyboardBackspace} />
              </Box>
            )}
          </Box>
        </ElementWrapper>
      )}

      {children}
    </>
  </ElementWrapper>
);
