import React, { ChangeEvent, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Box, Switch, Typography } from "@mui/material";

import { RootStore } from "store/root.store";
import { AdditionalInfoGroupContent, ErrorText, InfoLabel, ProviderIcon, TableFields } from "components/ui";
import { Rule } from "store/types";
import RulesEnvironments from "routes/RulesRoute/components/rulesEnvironments";

type RulePackTableAdditionalInfoProps = {
  store?: RootStore;
  fields: TableFields[];
};

const RulePackTableAdditionalInfo = ({ store, fields }: RulePackTableAdditionalInfoProps) => {
  const { data, lastFetch } = store!.rules;
  const { updateInfoItem } = store!.ui;
  const activeItem = store?.ui.infoItem?.activeItem as Rule;
  const rulePack = data.find((r) => r.id === activeItem?.rulePackId);

  useEffect(() => {
    const rule = rulePack?.rules.find((r) => r.id === activeItem?.id);

    if (!rule) return;

    updateInfoItem({ activeItem: rule, data: rulePack?.rules });
  }, [activeItem, rulePack, lastFetch, updateInfoItem]);

  if (!activeItem) return <ErrorText text="Rule not found" />;

  if (!rulePack) return <ErrorText text="Rule Pack not found" />;

  return (
    <Box display="flex" flexDirection="column" gap={5}>
      <Box display="flex" alignItems="center" justifyContent="space-between" gap={4} marginBottom={1}>
        <InfoLabel title="Rules Pack" value={<ProviderIcon provider={rulePack.provider} name={rulePack.name} />} />

        <Box display="flex" alignItems="center" gap={2}>
          <Typography variant="table">{activeItem.enabled ? "Rule is Activated" : "Rule is deactivated"}</Typography>
          <Box marginLeft="auto">
            <Switch
              checked={activeItem.enabled}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                store?.rules.changeRuleStatus(activeItem, event.target.checked)
              }
            />
          </Box>
        </Box>
      </Box>

      <Typography component="div" variant="title" marginBottom={1}>
        {activeItem?.name}
      </Typography>

      <RulesEnvironments ruleItem={activeItem} />

      <Box>
        <AdditionalInfoGroupContent item={activeItem} fields={fields} />
      </Box>
    </Box>
  );
};

export default inject("store")(observer(RulePackTableAdditionalInfo));
