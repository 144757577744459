import React, { MouseEvent, ReactElement } from "react";
import { inject, observer } from "mobx-react";
import { Box, useTheme } from "@mui/material";

import { ISSUE_TYPE, ISSUE_TYPE_PROPS, UI_TABS, DashboardTableIssue, IssueList, DashboardIssue } from "store/types";
import {
  ElementWrapper,
  ElementWrapperWithActions,
  Select,
  TableItemProps,
  TableRelatedData,
  Tabs,
} from "components/ui";
import { DashboardStore } from "store/dashboard.store";
import { RootStore } from "store/root.store";
import { DashboardIssues } from "routes/DashboardRoute/components";
import { TableFiltersType, TableFilterValues } from "components/ui/Table/components";
import { getDashboardFields, getDashboardIssueGroupedData } from "routes/DashboardRoute/dashboard.utils";
import { exportCsv, transformWithTableRelatedData } from "tools";
import { dashboardGroupByList } from "routes/DashboardRoute/components/DashboardView/dashboardView";

export const dashboardIssues: DashboardTableIssue[] = [
  {
    title: "Open Issues",
    prop: "openIssuesCursorData",
    storeProp: ISSUE_TYPE_PROPS.OPEN,
    type: ISSUE_TYPE.OPEN,
  },
  {
    title: "Resolved Issues",
    prop: "resolvedIssuesCursorData",
    storeProp: ISSUE_TYPE_PROPS.RESOLVED,
    resolved: true,
    type: ISSUE_TYPE.RESOLVED,
  },
  {
    title: "Justified Issues",
    storeProp: ISSUE_TYPE_PROPS.JUSTIFIED,
    prop: "justifiedIssuesCursorData",
    justified: true,
    type: ISSUE_TYPE.JUSTIFIED,
  },
];

type DashboardIssuesTablesProps = {
  store?: RootStore;
  definedIssues?: IssueList;
  actions?: ReactElement;
};

const DashboardIssuesTables = ({ store, definedIssues, actions }: DashboardIssuesTablesProps) => {
  const theme = useTheme();

  const { dashboard } = store!;
  const { getAllIssues, groupedBy, groupedBySelectedProperty, allIssuesWereCalled, changeGroupByProperty } = dashboard;

  const getData = (issue: DashboardTableIssue): TableItemProps[] => {
    if (!definedIssues) {
      return dashboard[issue.storeProp as keyof DashboardStore] as TableItemProps[];
    }

    if (issue.resolved) return definedIssues.resolved || [];

    if (issue.justified) return definedIssues.justified || [];

    return definedIssues.open || [];
  };

  const download =
    (issue: DashboardTableIssue) =>
    async ({
      e,
      filters,
      filterValues,
      tableRelatedData,
    }: {
      e: MouseEvent<HTMLElement>;
      filters?: TableFiltersType[];
      filterValues?: TableFilterValues;
      tableRelatedData?: TableRelatedData;
    }) => {
      const grouped = e.detail === 1;
      const fullData = definedIssues ? (getData(issue) as DashboardIssue[]) : await getAllIssues(issue.type);
      const fields = getDashboardFields({
        resolved: issue.resolved,
        justified: issue.justified,
        notGrouped: !grouped,
      });
      const csvData = transformWithTableRelatedData({
        data: grouped ? getDashboardIssueGroupedData(fullData, groupedBy[issue.type]) : fullData,
        fields,
        filters,
        filterValues,
        tableRelatedData,
      });

      exportCsv({
        data: csvData,
        fields,
        fileName: `Dashboard_${grouped ? `Grouped_by_${groupedBySelectedProperty[issue.type]}` : "All"}_${
          issue.resolved ? "Resolved" : issue.justified ? "Justified" : "Open"
        }_issues`,
        withTitle: true,
      });
    };

  return (
    <ElementWrapper customSx={{ paddingX: 0, paddingY: 0, paddingTop: 6 }}>
      <>
        <Box zIndex={1} position="absolute" top={theme.spacing(3)} right={theme.spacing(3)}>
          {actions}
        </Box>

        <Tabs
          type={UI_TABS.ISSUES}
          paddingX={2}
          tabs={dashboardIssues.map((issue) => ({
            title: issue.title,
            component: (
              <ElementWrapperWithActions
                wrapperCustomSx={{ overflow: "scroll" }}
                isLoading={!definedIssues && !allIssuesWereCalled[issue.type]}
                // isLoading={
                //   (dashboard[issue.prop as keyof DashboardStore] as CursorData<DashboardIssue>)?.isFetching
                // }
                component={DashboardIssues}
                search={!actions}
                data={getData(issue)}
                csvExport={
                  !actions
                    ? {
                        tooltip: "Double click to export all",
                        handler: download(issue),
                      }
                    : undefined
                }
                hideActions={!!actions}
                leftActions={
                  !actions ? (
                    <Select
                      inTable
                      value={groupedBySelectedProperty[issue.type]}
                      onChange={(val) => changeGroupByProperty(issue.type, val as keyof DashboardIssue)}
                      list={dashboardGroupByList}
                    />
                  ) : undefined
                }
                componentProps={{
                  issue,
                  groupedBy: groupedBy[issue.type],
                  // ...(!definedIssues && {
                  //   cursorData: dashboard[issue.prop as keyof DashboardStore] as CursorData<DashboardIssue>,
                  //   apiSort: (dashboard[issue.prop as keyof DashboardStore] as CursorData<DashboardIssue>)?.handler
                  //     ?.apiSort,
                  // }),
                }}
              />
            ),
          }))}
        />
      </>
    </ElementWrapper>
  );
};

export default inject("store")(observer(DashboardIssuesTables));
