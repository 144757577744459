import { JsonViewerType, PossibleJsonValue } from "components/ui";
import { FilterDataItem } from "store/types/filter.store.types";

export const INVENTORY_LATEST_VERSION_NAME = "latest";

export enum INVENTORY_TERRAFORM_STATUS {
  MANAGED = "Managed",
  UNMANAGED = "Unmanaged",
  DRIFTED = "Drifted",
  NOT_CONNECTED = "Not Connected",
}

export type Inventory = {
  id: string;
  // provider_id is the full, provider-specified ID for the resource (e.g., the AWS ARN)
  provider_id: string;
  // display_id is the short, usually service-scoped, provider-specified ID (e.g., an instance ID like i-a1b2c3)
  display_id: string;
  provider_account_id: string;
  provider: string;
  location: string;
  name: string;
  type: string;
  last_updated_by: string;
  last_updated_by_source: string;
  month_to_date_cost: number;
  created_at: string;
  updated_at: string;
  tags: JsonViewerType;
  details: JsonViewerType;
  terraform_status?: INVENTORY_TERRAFORM_STATUS;
  openCount: number;
  resolvedCount: number;
  justifiedCount: number;
};

export type InventorySearch = {
  type: string;
  location: string;
  tags: Record<string, PossibleJsonValue>;
};

export type InventorySearchTagParams = {
  keyPrefix?: string;
  key?: string;
  valuePrefix?: string;
};

export type InventorySearchDataTag = { count?: number; keys: string; values?: string };

export type InventorySearchData = {
  types: string[];
  locations: string[];
  details: JsonViewerType[];
};

export type InventoryAttribution = JsonViewerType & {
  agent?: string;
  Agent?: string;
  principal?: {
    email?: string;
    id?: string;
    ID?: string;
    Email?: string;
    Id?: string;
  };
  Principal?: {
    Email?: string;
    Id?: string;
    ID?: string;
    email?: string;
    id?: string;
  };
};

export type InventoryHistory = {
  resource: JsonViewerType;
  attribution?: InventoryAttribution;
};

export type InventoryFilterData = {
  selectedAccounts: string[];
  selectedFilterName: string;
  savedSearchData: Record<string, InventorySearchData>;
  filter: FilterDataItem;
};

export type InventoryFilterDataTab = {
  title: string;
  filterData: InventoryFilterData;
};

export enum INVENTORY_PRICE_TYPE {
  DAY = "DAY",
  MONTH = "MONTH",
}

export type InventoryPriceHistoryRequest = {
  accountId: string;
  assetId: string;
  type: INVENTORY_PRICE_TYPE;
};

export type InventoryPriceHistoryItem = {
  price: number;
  date: string;
};

export type InventoryTagValue = { tag: string | null; value: string | null };
