import React, { MouseEvent } from "react";
import { TableCell, TableRow, Typography } from "@mui/material";
import { inject, observer } from "mobx-react";

import { TableActions, TableDesktopProps } from "components/ui/Table/components";
import { TableItemProps } from "components/ui";
import { RootStore } from "store/root.store";
import { ArrowSvg } from "components/icons";
import { getFieldValue } from "tools";
import { ACTION_FIELD_PROPERTY } from "consts";
import { darkTextSecondaryDarkColor, lightTextSecondaryDarkColor } from "theme/commonVariables";

const TableDesktopDataElement = ({
  store,
  type,
  leftHeader,
  fields,
  item,
  actions,
  additionalInfo,
  additionalInfoHeader,
  data,
  darkerTable,
}: TableDesktopProps & { store?: RootStore; item: TableItemProps }) => {
  const additionalInfoClick = (e: MouseEvent<HTMLElement>, activeItem: TableItemProps) => {
    if (!additionalInfo) return;

    e.stopPropagation();

    store?.ui.openInfoItem({
      data,
      actions,
      component: additionalInfo,
      header: additionalInfoHeader,
      activeItem,
      type,
    });
  };

  const isActive = item.id && store?.ui?.infoItem?.activeItem?.id === item.id;

  const hideAdditionalInfo = (e: MouseEvent<HTMLElement>) => {
    if (!isActive) return;

    e.stopPropagation();

    store?.ui.closeInfoItem();
  };

  return (
    <TableRow
      data-testid="tableDesktopDataElement"
      onClick={(e) => additionalInfoClick(e, item)}
      sx={{
        ...(isActive
          ? { backgroundColor: "background.infoItemTableRow" }
          : {
              [`&:nth-of-type(${leftHeader ? "odd" : "even"})`]: {
                backgroundColor: darkerTable ? "background.tableDarkerRow" : "background.tableRow",
              },
            }),
        "&:hover": {
          backgroundColor: "background.infoItemTableRow",
          td: {
            opacity: 1,
          },
          "& [data-testid='tableDesktopDataElementAdditionalInfo']": {
            svg: {
              fill: `${store?.ui.isLightTheme ? lightTextSecondaryDarkColor : darkTextSecondaryDarkColor} !important`,
            },
          },
        },
      }}
    >
      {fields.map((field, tableFieldIndex) => (
        <TableCell
          key={field.property}
          align="left"
          sx={{
            paddingY: 1,
            width: (leftHeader && !tableFieldIndex) || field.property === ACTION_FIELD_PROPERTY ? 0 : "auto",
            whiteSpace: leftHeader && !tableFieldIndex ? "nowrap" : "initial",
            wordBreak: field.breakWordsLine ? "break-word" : "initial",
            ...(leftHeader && {
              paddingX: 2,
            }),
            ...(field.showOnHover ? { opacity: 0 } : {}),
            ...field.fieldSx?.(item),
          }}
        >
          <Typography
            sx={{
              ...(leftHeader &&
                !tableFieldIndex && {
                  fontWeight: field.headerFontWeight || 700,
                }),
            }}
            variant={leftHeader && !tableFieldIndex ? "main" : "table"}
          >
            {getFieldValue({ field, item, inTable: true })}
          </Typography>
        </TableCell>
      ))}
      {!!additionalInfo && (
        <TableCell
          data-testid="tableDesktopDataElementAdditionalInfo"
          onClick={hideAdditionalInfo}
          sx={{
            textAlign: "left",
            padding: 0,
            paddingRight: actions?.length ? 0 : 3,
            svg: {
              transform: `rotate(${isActive ? "180" : 0}deg)`,
              fill: "#8EA8EB !important",
            },
            cursor: isActive ? "pointer" : "default",
          }}
        >
          <ArrowSvg />
        </TableCell>
      )}
      {!!actions?.length && (
        <TableCell data-testid="tableDesktopDataElementActions" sx={{ padding: 0 }}>
          <TableActions item={item} actions={actions} />
        </TableCell>
      )}
    </TableRow>
  );
};

export default inject("store")(observer(TableDesktopDataElement));
