import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Box, Switch, Typography } from "@mui/material";

import { PERMISSION_TYPES, RulesPack } from "store/types";
import { RootStore } from "store/root.store";
import { Tooltip } from "components/ui";
import { RequirePerm } from "components/guards";

type RulesPackTableToggleProps = {
  store?: RootStore;
  item: RulesPack;
};

const RulesPackTableToggle = ({ store, item }: RulesPackTableToggleProps) => {
  const [enabled, setEnabled] = useState(true);

  useEffect(() => {
    setEnabled(item?.rules?.some((r) => r.enabled));
  }, [item]);

  return (
    <Box display="flex" alignItems="center">
      <Typography whiteSpace="nowrap" marginRight={2} color="various.tableTitleColor" variant="main" lineHeight="18px">
        {enabled ? "Active for selected Environments" : "Inactive for all Environments"}
      </Typography>

      <RequirePerm
        access={{
          permission: PERMISSION_TYPES.ADMIN,
          fallback: (
            <Tooltip id={item.id} title="You don't have permission to edit this." byClick>
              <Switch disabled checked={enabled} />
            </Tooltip>
          ),
        }}
        element={
          <Switch
            checked={enabled}
            onClick={(event: MouseEvent<HTMLButtonElement>) => event.stopPropagation()}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setEnabled(event.target.checked);
              store?.rules.changeRulePackStatus(item, event.target.checked);
            }}
          />
        }
      />
    </Box>
  );
};

export default inject("store")(observer(RulesPackTableToggle));
