import React, { FormEvent, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import { API_AUTH_ORG, API_BASE_URL } from "libs/api/endpoints";
import { AuthOrgSignIn, AuthOrgSignUp } from "components/containters/AuthOrgPrompt/components";
import { Button, ButtonWrapper } from "components/ui";
import { TYPE_AUTH } from "store/types";

const AuthOrgPrompt = ({ type }: { type: TYPE_AUTH }) => {
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    if (name || type === TYPE_AUTH.LOGIN) return;

    setError("Organization Name is required");
    e.preventDefault();
  };

  const onChange = (newName: string) => {
    setError("");
    setName(newName);
  };

  return (
    <Box>
      <Typography sx={{ typography: { xs: "h1", laptop: "windowTitle" } }} component="div" marginBottom={3}>
        {type === TYPE_AUTH.LOGIN ? "Select an account" : "What's the name of your organization?"}
      </Typography>

      <form onSubmit={onSubmit} method="POST" action={`${API_BASE_URL}${API_AUTH_ORG(type, id)}`}>
        <Typography variant="main" component="div" marginBottom={1}>
          {type === TYPE_AUTH.LOGIN
            ? "You're a member of multiple accounts. Which one do you want to sign in to?"
            : "This will be displayed on the dashboard and during sign-in when an identity " +
              "is associated with multiple accounts."}
        </Typography>

        {type === TYPE_AUTH.LOGIN ? (
          <AuthOrgSignIn id={id} setId={setId} />
        ) : (
          <AuthOrgSignUp error={error} name={name} onChange={onChange} />
        )}

        <ButtonWrapper>
          <>
            <Box component={Link} to={type === TYPE_AUTH.LOGIN ? "/login" : "/signup"}>
              <Button variant="text" text="Back" />
            </Box>
            <Button text={type === TYPE_AUTH.LOGIN ? "Log In" : "Complete Sign Up"} type="submit" />
          </>
        </ButtonWrapper>
      </form>
    </Box>
  );
};

export default AuthOrgPrompt;
