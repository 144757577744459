import {
  JsonHidedProperties,
  JsonHighlight,
  JsonHighlights,
  JsonRow,
  JsonSearchRowItem,
  JsonSearchType,
  JsonViewerType,
  PossibleJsonValue,
} from "components/ui";
import { JSON_VIEW_ACTIONS } from "components/ui/JsonViewer/components";
import store from "store";
import {
  BASE_SEPARATOR,
  CUT_VALUE_LENGTH,
  HIDE_ROWS_COUNT,
  HIDE_ROWS_IDENTICAL_COUNT,
} from "components/ui/JsonViewer/consts";

export const getKey = (currentKey: string, key: string) => {
  if (!currentKey) return key;

  return `${currentKey}.${key}`;
};

export const getHighlight = (property: string, highlights?: JsonHighlights) =>
  highlights?.list?.find((h) => h.property === property);

export const getHighlightColor = (highlights?: JsonHighlights) => (highlights?.added ? "success.light" : "error.light");

export const getHighlightMainColor = (highlights?: JsonHighlights) =>
  highlights?.added ? (store.ui.isLightTheme ? "success.main" : "#68a897") : "error.main";

export const isPrimitiveType = (value: PossibleJsonValue) => ["string", "boolean", "number"].includes(typeof value);

export const getParsedValue = (value: PossibleJsonValue) => {
  if (Array.isArray(value)) return value;

  try {
    return JSON.parse(value as string);
  } catch {
    return value;
  }
};

const getSingleRow = (value: PossibleJsonValue, currentKey: string, key: string): JsonRow | JsonRow[] => {
  const parsedValue = getParsedValue(value);

  if (isPrimitiveType(parsedValue)) {
    return {
      property: getKey(currentKey, key),
      value: parsedValue as string | number | boolean,
      collapsible: parsedValue.toString().length > CUT_VALUE_LENGTH,
    };
  }

  if (Array.isArray(parsedValue)) {
    return parsedValue.length
      ? [
          {
            property: getKey(currentKey, key),
            collapsible: true,
            onlyJson: true,
            value: "[",
          },
          ...parsedValue.flatMap((v, index) => getSingleRow(v, currentKey, `${key}[${index}]`)),
          {
            property: getKey(currentKey, key),
            collapsible: false,
            onlyJson: true,
            value: "]",
          },
        ]
      : {
          property: getKey(currentKey, key),
          collapsible: false,
          onlyJson: true,
          value: "[]",
        };
  }

  return Object.keys(parsedValue).length
    ? [
        {
          property: getKey(currentKey, key),
          collapsible: true,
          onlyJson: true,
          value: "{",
        },
        // eslint-disable-next-line no-use-before-define
        ...getRows(parsedValue as JsonViewerType, getKey(currentKey, key)),
        {
          property: getKey(currentKey, key),
          collapsible: false,
          onlyJson: true,
          value: "}",
        },
      ]
    : {
        property: getKey(currentKey, key),
        collapsible: false,
        onlyJson: true,
        value: "{}",
      };
};

export const sortAndFilterJson = (json: JsonViewerType) =>
  Object.entries(json || {})
    .filter(([, value]) => (value ?? null) !== null)
    .sort(([keyA], [keyB]) => (keyA > keyB ? 1 : -1));

export const getRows = (json: JsonViewerType, currentKey = ""): JsonRow[] =>
  sortAndFilterJson(json).flatMap(([key, value]) => getSingleRow(value, currentKey, key));

export const getJsonSearchProperty = (property: string) => property.replace(/\[\d]/g, "");

export const getJsonSearchValue = (value?: string | number | boolean) =>
  typeof value === "string" ? `"${value}"` : String(value);

export const getJsonSearchFormattedValue = (property: string, values: string[], suffix = "") =>
  values.length ? `${suffix}${property}:${values.length > 1 ? `IN [${values.join(" ")}]` : values[0]}` : "";

export const setJsonSearchRows = (
  currentRows: JsonSearchType,
  item: JsonSearchRowItem,
  type: JSON_VIEW_ACTIONS,
): JsonSearchType => {
  const property = getJsonSearchProperty(item.property);
  const row = currentRows[property] || { include: [], exclude: [] };
  const value = getJsonSearchValue(item.value);

  if (type === JSON_VIEW_ACTIONS.INCLUDE) {
    row.include.push(value);
    row.exclude = row.exclude.filter((i) => i !== value);
  }
  if (type === JSON_VIEW_ACTIONS.EXCLUDE) {
    row.include = row.include.filter((i) => i !== value);
    row.exclude.push(value);
  }
  if (type === JSON_VIEW_ACTIONS.REMOVE) {
    row.include = row.include.filter((i) => i !== value);
    row.exclude = row.exclude.filter((i) => i !== value);
  }

  return {
    ...currentRows,
    [property]: row,
  };
};

export const isRowSelected = ({ item, jsonSearch }: { item: JsonSearchRowItem; jsonSearch?: JsonSearchType }) =>
  jsonSearch?.[getJsonSearchProperty(item.property)]?.include.includes(getJsonSearchValue(item.value)) ||
  jsonSearch?.[getJsonSearchProperty(item.property)]?.exclude.includes(getJsonSearchValue(item.value));

export const calculateSimilarities = ({
  firstJson,
  secondJson,
  highlights,
}: {
  firstJson?: JsonViewerType;
  secondJson?: JsonViewerType;
  highlights: JsonHighlight[];
}): JsonHidedProperties => {
  if (!firstJson || !secondJson) return {};

  const secondRows: Record<string, string> = getRows(secondJson).reduce(
    (acc, i) => ({ ...acc, [[i.property, i.value].join(BASE_SEPARATOR)]: true }),
    {},
  );

  const firstRows = getRows(firstJson).map((i) => [i.property, i.value].join(BASE_SEPARATOR));

  const propertiesWithIndexes: JsonHidedProperties = firstRows.reduce(
    (acc, i, index) => (secondRows[i] ? { ...acc, [i]: index + 2 } : acc),
    {
      "{": 1,
      "}": Object.keys(firstRows).length + 2,
    },
  );

  const sortedPropertiesIndexes = Object.entries(propertiesWithIndexes).sort(([, a], [, b]) => a - b);

  sortedPropertiesIndexes.forEach(([key, number], index) => {
    if (
      new Array(highlights?.length ? HIDE_ROWS_COUNT : HIDE_ROWS_IDENTICAL_COUNT)
        .fill(0)
        .flatMap((_, i) => [i + 1, -i - 1])
        .every((offset) => sortedPropertiesIndexes[index - offset]?.[1] === number - offset)
    ) {
      return;
    }

    delete propertiesWithIndexes[key];
  });

  return propertiesWithIndexes;
};

export const getHidedPropertiesByNumbers = (hidedProperties: JsonHidedProperties): Record<number, string> =>
  Object.entries(hidedProperties).reduce((acc, [prop, i]) => ({ ...acc, [i]: prop }), {});

export const needToHideProperty = ({
  hidedProperties,
  property,
  value,
}: {
  hidedProperties: JsonHidedProperties;
  property: string;
  value: string;
}) => hidedProperties[[property, value].join(BASE_SEPARATOR)];

export const calculateHighlights = (firstJson: JsonViewerType, secondJson?: JsonViewerType): JsonHighlight[] => {
  if (!firstJson || !secondJson) return [];

  const firstRows: Record<string, string> = getRows(firstJson).reduce(
    (acc, i) => ({ ...acc, [i.property]: i.value }),
    {},
  );

  const secondRows: Record<string, string> = getRows(secondJson).reduce(
    (acc, i) => ({ ...acc, [i.property]: i.value }),
    {},
  );

  const differentFields = [
    ...Object.keys(firstRows).filter((f) => !secondRows.hasOwnProperty(f)),
    ...Object.keys(secondRows).filter((f) => !firstRows.hasOwnProperty(f)),
  ].map((property) => ({ property, full: true }));

  const editedFields = Object.keys(firstRows)
    .filter((f) => secondRows.hasOwnProperty(f) && firstRows[f] !== secondRows[f])
    .map((property) => ({
      property,
      full:
        /\[\d+]/.test(property) ||
        !![firstRows[property], secondRows[property]].find((v) =>
          ["{", "}", "{}", "[", "]", "[]"].find((p) => p === v),
        ),
    }));

  return [...differentFields, ...editedFields];
};

export const mapToFilterEntities = (entities: JsonRow[]) =>
  entities.reduce((acc: Record<string, string[]>, { property, value }) => {
    const prop = getJsonSearchProperty(property);
    acc[prop] = acc[prop] || [];

    if (value && !acc[prop].includes(value.toString())) acc[prop].push(value.toString());

    return acc;
  }, {});
