import { api } from "libs";
import { Installation, Vulnerability } from "store/types";
import { API_GITHUB_DEPENDABOT_VULNERABILITIES_BY_ID, API_GITHUB_INSTALLATIONS } from "libs/api/endpoints";
import { CursorFetchData, CursorParameters } from "tools/cursorHandler/cursorHandler.types";
import { buildSearchUrl } from "tools/cursorHandler/cursorHandler";

export const getGithubInstallations = async (doNotShowErrorMessage: boolean): Promise<Installation[]> =>
  api.get(API_GITHUB_INSTALLATIONS, {
    doNotShowErrorMessage,
    errorMessage: {
      title: "Failed to load Github Installations",
    },
  });

export const addGithubInstallation = async (installationId: number): Promise<Installation> =>
  api.post(
    API_GITHUB_INSTALLATIONS,
    { installationId },
    {
      errorMessage: {
        title: "Failed to update Github Installations",
      },
    },
  );

export const getVulnerabilities = async (params: CursorParameters): Promise<CursorFetchData<Vulnerability>> => {
  if (!params?.installationId) return { data: [] };

  const res = await api.get(
    buildSearchUrl(API_GITHUB_DEPENDABOT_VULNERABILITIES_BY_ID(params.installationId as number), {
      ...params,
      installationId: "",
    }),
    {
      errorMessage: {
        title: "Failed to load Vulnerabilities",
      },
    },
  );

  return {
    ...res,
    data: res.data?.map((i: Vulnerability) => ({ ...i, id: Math.random() })),
  };
};
