import React, { ReactElement } from "react";
import { Box, Typography } from "@mui/material";

import { getHighlight, getHighlightColor, getKey, needToHideProperty } from "components/ui/JsonViewer/jsonViewer.utils";
import { JsonViewerType, PossibleJsonValue } from "components/ui";
import { JsonExpand } from "components/ui/JsonViewer/components/JsonExpand/jsonExpand";
import { BASE_SEPARATOR } from "components/ui/JsonViewer/consts";

import { JsonViewJsonValue } from "../../JsonViewJsonValue/jsonViewJsonValue";
import { FieldValueProps, JsonViewFieldValue } from "../jsonViewFieldValue";
import { JsonBorderWrapper } from "./jsonBorderWrapper";

export const JsonViewObjectValue = ({
  collapsible,
  currentKey,
  keyValue,
  indexKeyValue,
  value,
  offset,
  isNotLastIndex,
  offsetIndex,
  isArray,
  ...props
}: FieldValueProps & { isArray?: boolean }): ReactElement => {
  const property = getKey(currentKey, indexKeyValue);
  const highlight = getHighlight(property, props.highlights);
  const highlightColor = getHighlightColor(props.highlights);
  const isValueExist = isArray ? !!(value as PossibleJsonValue[])?.length : !!Object.keys(value).length;

  const needToHide = (hideValue: string) =>
    needToHideProperty({ hidedProperties: props.hidedProperties, property, value: hideValue });

  const topValue = isArray ? (isValueExist ? "[" : "[]") : isValueExist ? "{" : "{}";
  const bottomValue = isArray ? "]" : "}";
  const needToHideTop = needToHide(topValue);
  const needToHideBottom = needToHide(bottomValue);

  return (
    <Box sx={{ ...(highlight && { backgroundColor: highlightColor }) }}>
      {needToHideTop ? (
        <JsonExpand
          number={needToHideTop}
          hidedProperties={props.hidedProperties}
          setHidedProperties={props.setHidedProperties}
        />
      ) : (
        <Box component="span">
          <JsonBorderWrapper
            numbers={props.numbers}
            property={[property, topValue].join(BASE_SEPARATOR)}
            inline={collapsible.includes(property)}
            offset={offset}
            count={offsetIndex}
            highlights={props.highlights}
            expanded={props.expanded}
            collapsible={collapsible}
            setCollapsible={props.setCollapsible}
          >
            <>
              {!!keyValue && (
                <Typography display="inline" color="primary.main" variant="main" fontWeight={400}>
                  &quot;{keyValue}&quot;:{" "}
                </Typography>
              )}
              {isArray ? "[" : "{"}
              {isValueExist ? "" : `${isArray ? "]" : "}"}${isNotLastIndex ? ", " : ""}`}
            </>
          </JsonBorderWrapper>
        </Box>
      )}
      {isValueExist && (
        <>
          {collapsible.includes(property) ? (
            <Box component="span" sx={{ cursor: "pointer" }} onClick={() => props.setCollapsible(property)}>
              ...
            </Box>
          ) : isArray ? (
            (value as PossibleJsonValue[]).map((v, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box key={index}>
                <JsonViewFieldValue
                  collapsible={collapsible}
                  currentKey={currentKey}
                  isNotLastIndex={index !== (value as PossibleJsonValue[]).length - 1}
                  offset={offset}
                  value={v}
                  indexKeyValue={`${keyValue || property}[${index}]`}
                  offsetIndex={offsetIndex + 1}
                  {...props}
                />
              </Box>
            ))
          ) : (
            <Box>
              <JsonViewJsonValue
                collapsible={collapsible}
                currentKey={property}
                offset={offset}
                json={value as JsonViewerType}
                offsetIndex={offsetIndex + 1}
                {...props}
              />
            </Box>
          )}
          {needToHideBottom ? (
            <JsonExpand
              number={needToHideBottom}
              hidedProperties={props.hidedProperties}
              setHidedProperties={props.setHidedProperties}
            />
          ) : (
            <Box component="span">
              <JsonBorderWrapper
                numbers={props.numbers}
                property={[property, bottomValue].join(BASE_SEPARATOR)}
                offset={offset}
                count={collapsible.includes(property) ? 0 : offsetIndex}
                highlights={props.highlights}
                expanded={props.expanded}
                collapsible={collapsible}
                setCollapsible={props.setCollapsible}
              >
                <>
                  {bottomValue}
                  {isNotLastIndex ? ", " : ""}
                </>
              </JsonBorderWrapper>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
