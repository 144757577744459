import React from "react";
import { inject, observer } from "mobx-react";
import { useFormik } from "formik";
import * as yup from "yup";

import { Button, ButtonWrapper, FormFieldsBuilder, Loading, NONE_SELECT_VALUE } from "components/ui";
import { RootStore } from "store/root.store";
import { INTEGRATION, NotificationChannel } from "store/types";
import { integrationsList } from "routes/IntegrationsRoute/integrationsRoute";

type AddNotificationChannelModalType = {
  store?: RootStore;
  channel?: NotificationChannel;
  predefinedIntegration?: INTEGRATION;
};

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  destination: yup.string().required("Destination is required"),
});

const AddNotificationChannelModal = ({ store, channel, predefinedIntegration }: AddNotificationChannelModalType) => {
  const buttonText =
    predefinedIntegration === INTEGRATION.HTTP
      ? "HTTP Endpoint"
      : predefinedIntegration === INTEGRATION.SLACK
      ? "Slack Account"
      : predefinedIntegration === INTEGRATION.JIRA
      ? "Jira Account"
      : predefinedIntegration === INTEGRATION.EMAIL
      ? "Email"
      : "channel";

  const { isFetched, activeAccounts } = store!.accounts;

  const initialValues = {
    cloud_account_id: channel?.cloud_account_id || NONE_SELECT_VALUE,
    name: channel?.name || "",
    channel_type: channel?.channel_type || predefinedIntegration || INTEGRATION.EMAIL,
    destination: channel?.destination || "",
    enabled: (channel ? channel.enabled : true).toString(),
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) =>
      store?.notifications.upsertNotificationChannel(
        {
          ...values,
          id: channel?.id,
          enabled: values.enabled === "true",
          cloud_account_id: values.cloud_account_id === NONE_SELECT_VALUE ? null : values.cloud_account_id,
        },
        predefinedIntegration && `${buttonText} successfully ${channel ? "updated" : "added"}!`,
      ),
  });

  const items = [
    {
      property: "cloud_account_id",
      label: "Cloud Account",
      list: [{ name: "All accounts", id: NONE_SELECT_VALUE }, ...activeAccounts],
      type: "select",
      fullWidth: true,
    },
    {
      property: "name",
      label: "Name",
    },
    ...(predefinedIntegration
      ? []
      : [
          {
            property: "channel_type",
            label: "Type",
            list: integrationsList
              .filter((i) =>
                [INTEGRATION.SLACK, INTEGRATION.EMAIL, INTEGRATION.HTTP, INTEGRATION.JIRA].includes(i.type),
              )
              .map(({ type, title }) => ({ name: title, id: type })),
            type: "radio",
          },
        ]),
    {
      property: "destination",
      label: "Destination",
    },
    {
      property: "enabled",
      label: "Status",
      list: [
        { id: "true", name: "Enabled" },
        { id: "false", name: "Disabled" },
      ],
      type: "radio",
    },
  ];

  if (!isFetched) return <Loading />;

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormFieldsBuilder formik={formik} items={items} />

      <ButtonWrapper>
        <>
          <Button onClick={store?.ui.closeModal} text="Cancel" variant="text" />
          <Button type="submit" text={`${channel ? "Update" : "Add"} ${buttonText}`} />
        </>
      </ButtonWrapper>
    </form>
  );
};

export default inject("store")(observer(AddNotificationChannelModal));
