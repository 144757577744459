import React, { MouseEvent, ReactElement } from "react";
import { ToggleButton, ToggleButtonGroup, useTheme } from "@mui/material";

import { Label } from "components/ui/Label/label";
import { TABLE_HEIGHT } from "consts";
import { darkBG, darkInputBorder, darkPaperBG, lightBG, lightInputBorder, lightPaperBG } from "theme/commonVariables";

export type ButtonGroupItem = {
  value: string;
  render?: (value: string) => string | ReactElement;
  component?: string | ReactElement;
};

type ButtonGroupProps = {
  exclusive?: boolean;
  fullWidth?: boolean;
  value: string;
  onChange: (val: string) => void;
  items: ButtonGroupItem[];
  label?: string;
  light?: boolean;
  flex?: boolean;
  disabled?: boolean;
  inTable?: boolean;
  separate?: boolean;
};

export const ButtonGroup = ({
  value,
  onChange,
  items,
  exclusive,
  fullWidth,
  label,
  light,
  flex,
  disabled,
  inTable,
  separate,
}: ButtonGroupProps) => {
  const theme = useTheme();

  return (
    <>
      {!!label && <Label text={label} />}

      <ToggleButtonGroup
        disabled={disabled}
        data-testid="buttonGroup"
        value={value}
        onChange={(e: MouseEvent<HTMLElement>, val: string) => onChange(val)}
        exclusive={exclusive}
        fullWidth={fullWidth}
        sx={{
          height: TABLE_HEIGHT,
          alignSelf: "flex-start",
          ...(!separate && {
            background: theme.palette.mode === "light" ? lightBG : darkBG,
          }),
          ...(inTable && {
            padding: 1,
            background: theme.palette.mode === "light" ? lightPaperBG : darkPaperBG,
            border: `1px solid ${theme.palette.mode === "light" ? lightInputBorder : darkInputBorder} !important`,
          }),
          ...(flex && {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: 5,
          }),
          ...(light
            ? {
                ".Mui-selected": {
                  color: theme.palette.mode === "light" ? "#2F3259 !important" : "#F9F9F9 !important",
                  background: theme.palette.mode === "light" ? "#c0e3ff !important" : "#757CD4 !important",

                  "&:hover": {
                    background: theme.palette.mode === "light" ? "#c0e3ff !important" : "#757CD4 !important",
                  },
                },
              }
            : {
                ".Mui-selected": {
                  borderColor: "transparent !important",
                },
              }),
          ...(disabled && {
            opacity: 0.5,
            pointerEvents: "none",
          }),
        }}
      >
        {items.map((item) => (
          <ToggleButton
            sx={{
              ...(separate && {
                background: theme.palette.mode === "light" ? lightBG : darkBG,
                borderRadius: `${theme.spacing(1)} !important`,
              }),
              ...(light && {
                paddingY: 3,
              }),
              ...(inTable && {
                paddingX: 1.5,
              }),
              ...(!inTable && {
                height: TABLE_HEIGHT,
              }),
              border: "none !important",
            }}
            data-testid="buttonGroupToggleButton"
            key={item.value}
            value={item.value}
          >
            {item.render?.(value) || item.component || ""}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  );
};
