import React, { useState } from "react";
import { Box } from "@mui/material";
import { Search } from "@mui/icons-material";
import { inject, observer } from "mobx-react";

import {
  Button,
  Input,
  JSON_VIEWER_VARIANTS,
  JsonHighlights,
  JsonRow,
  JsonSearchRowItem,
  JsonSearchType,
  JsonViewerType,
  SetJsonSearchType,
  Table,
  Tooltip,
} from "components/ui";
import {
  getHighlight,
  getHighlightColor,
  getJsonSearchProperty,
  getRows,
  isRowSelected,
} from "components/ui/JsonViewer/jsonViewer.utils";
import {
  BooleanSvg,
  NumberSvg,
  StringSvg,
  TimestampSvg,
} from "components/ui/JsonViewer/components/JsonTableView/components";
import { JsonSearchActions } from "components/ui/JsonViewer/components";
import { UI_TABLES } from "store/types";
import { copyToClipboard } from "tools";
import { ACTION_FIELD_PROPERTY } from "consts";
import { CUT_VALUE_LENGTH } from "components/ui/JsonViewer/consts";
import { RootStore } from "store/root.store";

type JsonTableViewProps = {
  store?: RootStore;
  json: JsonViewerType;
  jsonSearch?: JsonSearchType;
  setJsonSearch?: SetJsonSearchType;
  unsearchableProperties?: string[];
  copy?: boolean;
  highlights?: JsonHighlights;
  maxLines?: number;
};

const getFieldIcon = (item: JsonRow) => {
  if (item.property.includes("timestamp")) return <TimestampSvg />;

  if (typeof item.value === "number") return <NumberSvg />;

  if (typeof item.value === "boolean") return <BooleanSvg />;

  return <StringSvg />;
};

const JsonTableView = ({
  store,
  json,
  jsonSearch,
  setJsonSearch,
  unsearchableProperties,
  copy,
  highlights,
  maxLines,
}: JsonTableViewProps) => {
  const [search, setSearch] = useState("");
  const [expanded, setExpanded] = useState<string[]>([]);
  const [showAll, setShowAll] = useState(false);

  if (!json) return null;

  const isPossibleToExpand = (value?: string) => value && value.toString().length > CUT_VALUE_LENGTH;

  const getFieldValue = (item: JsonRow) => {
    const value = item.value?.toString() || "";

    return expanded.includes(item.property) || !isPossibleToExpand(value)
      ? value
      : `${value.slice(0, CUT_VALUE_LENGTH)}...`;
  };

  const clickOnFieldValue = (item: JsonRow) => {
    if (!isPossibleToExpand(item.value?.toString())) {
      if (copy) copyToClipboard({ value: item.value?.toString() });

      return;
    }

    setExpanded((curr) =>
      expanded.includes(item.property) ? curr.filter((c) => c !== item.property) : [...curr, item.property],
    );
  };

  const fields = [
    ...(setJsonSearch
      ? [
          {
            property: ACTION_FIELD_PROPERTY,
            toLeft: true,
            showOnHover: true,
            fieldSx: () => ({ padding: 0 }),
            render: (item: JsonRow) =>
              unsearchableProperties?.includes(getJsonSearchProperty(item.property)) ? (
                ""
              ) : (
                <JsonSearchActions
                  type={JSON_VIEWER_VARIANTS.TABLE}
                  jsonSearch={jsonSearch}
                  setJsonSearch={setJsonSearch}
                  item={item as JsonSearchRowItem}
                />
              ),
          },
        ]
      : []),
    {
      property: "property",
      name: "Field",
      toLeft: true,
      breakWordsLine: true,
      fieldSx: (item: JsonRow) => {
        const highlight = getHighlight(item.property, highlights);

        return {
          paddingY: jsonSearch && !unsearchableProperties?.includes(getJsonSearchProperty(item.property)) ? 0.5 : 1.5,
          minWidth: 200,
          span: { fontWeight: isRowSelected({ item: item as JsonSearchRowItem, jsonSearch }) ? 700 : 600 },
          ...(highlight && highlight.full && { backgroundColor: getHighlightColor(highlights) }),
        };
      },
      render: (item: JsonRow) => <Box onClick={() => clickOnFieldValue(item)}>{item.property}</Box>,
      textValue: (item: JsonRow) => item.property,
    },
    {
      property: "value",
      name: "Value",
      toLeft: true,
      breakWordsLine: true,
      fieldSx: (item: JsonRow) => {
        const highlight = getHighlight(item.property, highlights);

        return {
          paddingY: jsonSearch && !unsearchableProperties?.includes(getJsonSearchProperty(item.property)) ? 0.5 : 1.5,
          minWidth: 300,
          span: isRowSelected({ item: item as JsonSearchRowItem, jsonSearch }) ? { fontWeight: 700 } : {},
          ...(highlight && { backgroundColor: getHighlightColor(highlights) }),
        };
      },
      render: (item: JsonRow) => (
        <Tooltip
          title={
            isPossibleToExpand(item.value?.toString()) ? (expanded.includes(item.property) ? "Collapse" : "Expand") : ""
          }
          placement="top"
        >
          <Box
            onClick={() => clickOnFieldValue(item)}
            display="flex"
            alignItems="center"
            sx={{ svg: { marginRight: 1.5, minWidth: 18 } }}
          >
            {getFieldIcon(item)}
            {getFieldValue(item)}
          </Box>
        </Tooltip>
      ),
      textValue: (item: JsonRow) => item.value?.toString() || "",
    },
  ];

  const allRows = getRows(json).filter((r) => !r.onlyJson);

  const tableRows = allRows
    .map((r) => ({ ...r, id: r.property }))
    .slice(0, maxLines && !showAll ? maxLines : allRows.length);

  return (
    <>
      <Box marginBottom={4}>
        <Input iconLeft icon={Search} inTable placeholder="Search..." value={search} onChange={setSearch} />
      </Box>

      <Table
        type={UI_TABLES.JSON_VIEW}
        search={search}
        smallView
        withoutBorder
        desktopView
        hidePagination
        fields={fields}
        data={tableRows}
      />

      {!!maxLines && allRows.length > maxLines && (
        <Box display="flex" justifyContent={store?.ui.infoItem ? "flex-start" : "flex-end"} marginTop={2}>
          <Button variant="text" onClick={() => setShowAll(!showAll)}>
            {showAll ? "Hide" : "Show all"}
          </Button>
        </Box>
      )}
    </>
  );
};

export default inject("store")(observer(JsonTableView));
