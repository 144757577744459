import { DashboardActionsIssuesType, DashboardIssue } from "store/types";

export const getIssueWord = (type: DashboardActionsIssuesType): string =>
  type === DashboardActionsIssuesType.SINGLE ? "this issue" : "all issues";

export const getIssueChangeStateSelect = (issues: DashboardIssue[], type: DashboardActionsIssuesType) =>
  type === DashboardActionsIssuesType.SINGLE && issues[0]?.grouped?.length && issues[0]?.grouped?.length > 1
    ? [
        {
          property: "type",
          exclusive: true,
          fullWidth: true,
          type: "buttonGroup",
          items: [
            {
              value: DashboardActionsIssuesType.SINGLE,
              component: "Most recent",
            },
            {
              value: DashboardActionsIssuesType.GROUPED,
              component: "All issues",
            },
          ],
        },
      ]
    : [];
