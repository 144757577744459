import React from "react";
import { Box, Typography } from "@mui/material";

import { capitalizeFirstLetter, getDaysDiff } from "tools";

type StateProps = {
  state?: string;
  date?: string;
  smallView?: boolean;
  center?: boolean;
  isFixed?: boolean;
};

export const State = ({ state = "", date, smallView, center, isFixed }: StateProps) => {
  const days = !isFixed && date && getDaysDiff(date, new Date()) + 1;

  return (
    <Box data-testid="state" display="flex" justifyContent={center ? "center" : "flex-start"}>
      {smallView ? (
        <Box width={10} height={10} borderRadius="100%" bgcolor={isFixed ? "pending.main" : "primary.main"} />
      ) : (
        <Typography
          color={isFixed ? "pending.main" : "primary.main"}
          borderRadius={1}
          variant="main"
          bgcolor="pending.light"
          paddingX={2.25}
          paddingY={1.25}
          position="relative"
          top={{ xs: "2px", laptop: 0 }}
          whiteSpace="nowrap"
        >
          {capitalizeFirstLetter(state)}
          {!!days && ` (${days}d)`}
        </Typography>
      )}
    </Box>
  );
};
