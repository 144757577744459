import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { inject, observer } from "mobx-react";

import {
  JsonViewerType,
  JsonSearchType,
  SetJsonSearchType,
  JsonHighlights,
  JsonRow,
  JsonHidedProperties,
  Button,
  SetHidedPropertiesHandler,
} from "components/ui";
import { BASE_SEPARATOR, SEARCH_ACTION_DATA_TYPE } from "components/ui/JsonViewer/consts";
import { copyToClipboard } from "tools";
import { getRows } from "components/ui/JsonViewer/jsonViewer.utils";
import { RootStore } from "store/root.store";

import { JsonViewJsonValue, JsonViewRowNumber } from "./components";

type JsonViewerProps = {
  store?: RootStore;
  json: JsonViewerType;
  jsonSearch?: JsonSearchType;
  setJsonSearch?: SetJsonSearchType;
  offset?: number;
  unsearchableProperties?: string[];
  copy?: boolean;
  highlights?: JsonHighlights;
  maxLines?: number;
  expanded?: boolean;
  hidedProperties: JsonHidedProperties;
  setHidedProperties: SetHidedPropertiesHandler;
};

const ONE_ROW_HEIGHT = 24;

const JsonView = ({
  store,
  jsonSearch,
  setJsonSearch,
  json,
  unsearchableProperties,
  copy,
  highlights,
  offset = 3,
  maxLines,
  expanded,
  hidedProperties,
  setHidedProperties,
}: JsonViewerProps) => {
  const theme = useTheme();

  const [showAll, setShowAll] = useState(false);
  const [collapsible, setCollapsible] = useState<string[]>([]);
  const [selectedItem, setSelectedItem] = useState<string>("");

  useEffect(() => {
    if (!setJsonSearch) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const removeSelectedItem = (e: any) => {
      if (e.target.dataset?.type === SEARCH_ACTION_DATA_TYPE || e.target.nodeName?.toLowerCase() === "path") return;

      setSelectedItem("");
    };

    document.addEventListener("click", removeSelectedItem, true);

    return () => document.removeEventListener("click", removeSelectedItem, true);
  }, [setJsonSearch]);

  useEffect(() => {
    setCollapsible([]);
  }, [expanded]);

  if (!json) return null;

  const setCollapsibleHandler = (val: string) =>
    setCollapsible((curr: string[]) => (curr.includes(val) ? curr.filter((c) => c !== val) : [...curr, val]));

  const jsonRows = getRows(json).map((r, index) => ({ ...r, number: index + 2 }));

  const numbers: JsonRow[] = jsonRows.length
    ? [
        {
          property: "{",
          collapsible: true,
          number: 1,
          onlyJson: true,
        },
        ...jsonRows,
        {
          property: "}",
          collapsible: false,
          number: jsonRows.length + 2,
          onlyJson: true,
        },
      ]
    : [
        {
          property: "{}",
          collapsible: false,
          number: 1,
          onlyJson: true,
        },
      ];

  const rowsByNumbers: Record<string, JsonRow> = numbers.reduce(
    (acc, n) => ({ ...acc, [[n.property, n.value].join(BASE_SEPARATOR)]: n }),
    {},
  );

  const collapsibleNumbers = numbers.filter((row) =>
    collapsible.includes("{")
      ? row.property === "{"
      : !collapsible.find(
          (c) =>
            (c === row.property && !row.collapsible) ||
            row.property.startsWith(`${c}.`) ||
            row.property.startsWith(`${c}[`),
        ),
  );

  return (
    <Box>
      <Typography
        display="block"
        sx={{
          overflow: "scroll",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": { display: "none" },
        }}
        variant="main"
        fontWeight={400}
        bgcolor="background.tableRow"
        borderRadius={1}
        paddingY={2.5}
        position="relative"
      >
        {!!copy && !!Object.keys(json).length && (
          <Button
            onClick={() => copyToClipboard({ value: JSON.stringify(json) })}
            icon={ContentCopy}
            extraSmall
            variant="text"
            darkBg
            buttonSx={{
              zIndex: 1,
              cursor: "pointer",
              position: "absolute",
              top: theme.spacing(2),
              right: theme.spacing(2),
            }}
          />
        )}

        <Box
          display="flex"
          maxHeight={maxLines && !showAll ? maxLines * ONE_ROW_HEIGHT : "none"}
          sx={{ overflowY: "hidden" }}
        >
          <Box flexGrow={1}>
            <Box display="flex">
              <JsonViewRowNumber
                highlights={highlights}
                rows={collapsible.includes("{") ? [numbers[0]] : Object.values(numbers)}
                row={
                  collapsible.includes("{") ? numbers[0] : rowsByNumbers[[numbers[0].property, ""].join(BASE_SEPARATOR)]
                }
                expanded={!!expanded}
                collapsible={collapsible}
                setCollapsible={setCollapsibleHandler}
              />
              {collapsible.includes("{") ? (
                <Box sx={{ cursor: "pointer" }} onClick={() => setCollapsibleHandler("{")}>
                  {"{...}"}
                </Box>
              ) : (
                numbers[0].property
              )}
            </Box>

            {!collapsible.includes("{") && (
              <>
                <JsonViewJsonValue
                  rows={collapsible.includes("{") ? [numbers[0]] : Object.values(numbers)}
                  jsonSearch={jsonSearch}
                  setJsonSearch={setJsonSearch}
                  collapsible={collapsible}
                  setCollapsible={setCollapsibleHandler}
                  json={json}
                  offset={offset}
                  unsearchableProperties={unsearchableProperties}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  copy={copy}
                  highlights={highlights}
                  hidedProperties={hidedProperties}
                  setHidedProperties={setHidedProperties}
                  numbers={rowsByNumbers}
                  expanded={!!expanded}
                />

                {numbers.length > 1 && (
                  <Box display="flex">
                    <JsonViewRowNumber
                      highlights={highlights}
                      rows={Object.values(numbers)}
                      row={rowsByNumbers[["}", ""].join(BASE_SEPARATOR)]}
                      expanded={!!expanded}
                      collapsible={collapsible}
                      setCollapsible={setCollapsibleHandler}
                    />
                    {"}"}
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
      </Typography>

      {!!maxLines && collapsibleNumbers.length > maxLines && (
        <Box display="flex" justifyContent={store?.ui.infoItem ? "flex-start" : "flex-end"} marginTop={2}>
          <Button variant="text" onClick={() => setShowAll(!showAll)}>
            {showAll ? "Hide" : "Show all"}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default inject("store")(observer(JsonView));
