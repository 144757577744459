import { makeAutoObservable } from "mobx";

import { RootStore } from "store/root.store";
import {
  EventView,
  FEATURE,
  FILTER_PAGES,
  FilterDataItem,
  Inventory,
  ISSUE_TYPE_PROPS,
  SavedFilter,
  SavedWidgetSettings,
  Widget,
  WIDGET_FILTER_TYPES,
  WidgetFilterRequest,
  WidgetsSettings,
} from "store/types";
import { CursorData } from "tools/cursorHandler/cursorHandler.types";
import {
  getDatesFromFilterValue,
  getDatesFromSavedFilter,
  getInventoryQueryDataFromSavedFilter,
  getSearchString,
} from "tools";
import { getEvents, getInventory } from "libs/api/methods";
import { getDefaultCursorData } from "tools/cursorHandler/cursorHandler";
import { eventFields } from "routes/EventsRoute/components/eventDetailsTable";

const defaultWidgetsSettings: WidgetsSettings[] = [
  {
    id: "open_issues",
    order: 1,
    widgetType: WIDGET_FILTER_TYPES.COUNTER,
  },
  {
    id: "resolved_issues",
    order: 2,
    widgetType: WIDGET_FILTER_TYPES.COUNTER,
  },
  {
    id: "accounts_connected",
    order: 3,
    widgetType: WIDGET_FILTER_TYPES.COUNTER,
  },
  {
    id: "rules_enabled",
    order: 4,
    widgetType: WIDGET_FILTER_TYPES.COUNTER,
  },
  {
    id: "events_bar",
    order: 5,
    widgetType: WIDGET_FILTER_TYPES.TABLE,
  },
  {
    id: ISSUE_TYPE_PROPS.OPEN as string,
    order: 6,
    widgetType: WIDGET_FILTER_TYPES.ISSUES_TABLE,
  },
  {
    id: ISSUE_TYPE_PROPS.RESOLVED as string,
    order: 7,
    widgetType: WIDGET_FILTER_TYPES.ISSUES_TABLE,
  },
  {
    id: ISSUE_TYPE_PROPS.JUSTIFIED as string,
    order: 8,
    widgetType: WIDGET_FILTER_TYPES.ISSUES_TABLE,
  },
];

export class WidgetsStore {
  cursors: Record<string, CursorData<Inventory | EventView>> = {};
  isFetched = false;
  currentWidgetFilter?: SavedFilter;
  defaultWidgetFilter?: SavedFilter;

  constructor(readonly rootStore: RootStore) {
    this.rootStore = rootStore;

    this.setWidgetValues = this.setWidgetValues.bind(this);
    this.setCurrentWidget = this.setCurrentWidget.bind(this);
    this.changeWidgetSettingsOrderAndVisibility = this.changeWidgetSettingsOrderAndVisibility.bind(this);

    makeAutoObservable(this);
  }

  get widgetSettings() {
    const currentSettings = (this.currentWidgetFilter?.filter as SavedWidgetSettings)?.settings;
    const defaultSettings = (this.defaultWidgetFilter?.filter as SavedWidgetSettings)?.settings;

    if (!this.rootStore.auth.isFeatureEnabled(FEATURE.MULTIPLE_DASHBOARDS)) {
      return this.filterSetting(defaultSettings || []);
    }

    return this.filterSetting(
      (defaultSettings || []).map((s) => {
        const current = currentSettings?.find((c) => c.id === s.id);

        return { ...s, order: current ? current.order : defaultSettings.length + s.order, disabled: !current };
      }),
    );
  }

  filterSetting(settings: WidgetsSettings[]) {
    return [...settings].filter(
      (w) => ![ISSUE_TYPE_PROPS.RESOLVED, ISSUE_TYPE_PROPS.JUSTIFIED].includes(w.id as ISSUE_TYPE_PROPS),
    );
  }

  setCurrentWidget(id?: string) {
    if (!this.rootStore.auth.isFeatureEnabled(FEATURE.MULTIPLE_DASHBOARDS)) return;

    this.currentWidgetFilter = this.rootStore.multipleDashboards.dashboards.find((d) => d.id === id);
  }

  async setWidgetSettings() {
    try {
      this.defaultWidgetFilter = (await this.rootStore.filter.getSavedFilters(FILTER_PAGES.WIDGET_SETTINGS))?.[0];

      if (!this.defaultWidgetFilter) {
        this.defaultWidgetFilter = (await this.rootStore.filter.saveFilter(
          {
            name: "Widget settings",
            type: FILTER_PAGES.WIDGET_SETTINGS,
            filter: {
              settings: defaultWidgetsSettings,
            },
          },
          true,
        )) as SavedFilter;
      }

      if (
        this.rootStore.auth.isFeatureEnabled(FEATURE.MULTIPLE_DASHBOARDS) &&
        !(this.defaultWidgetFilter.filter as SavedWidgetSettings).dashboardWasAdded
      ) {
        this.currentWidgetFilter = { ...this.defaultWidgetFilter };

        await Promise.all([
          this.rootStore.filter.saveFilter(
            {
              ...this.defaultWidgetFilter,
              filter: {
                ...this.defaultWidgetFilter.filter,
                dashboardWasAdded: true,
              },
            },
            true,
          ),
          this.rootStore.multipleDashboards.createDashboard("Default", true),
        ]);
      }

      this.isFetched = true;
    } catch {}
  }

  async updateDefaultFilter(settings: Widget[]) {
    if (!this.defaultWidgetFilter) return;

    this.defaultWidgetFilter = (await this.rootStore.filter.saveFilter(
      {
        ...this.defaultWidgetFilter,
        filter: {
          ...this.defaultWidgetFilter.filter,
          settings,
        },
      },
      true,
    )) as SavedFilter;
  }

  async updateMultipleDashboard(settings: Widget[]) {
    if (!this.currentWidgetFilter) return;

    this.currentWidgetFilter = (await this.rootStore.multipleDashboards.changeWidgets(
      this.currentWidgetFilter?.id,
      this.rootStore.multipleDashboards.mapDashboardSettings(settings),
    )) as SavedFilter;
  }

  async changeWidgetSettingsOrderAndVisibility(settings: Widget[]) {
    if (!this.currentWidgetFilter || this.currentWidgetFilter?.type.trim() === FILTER_PAGES.WIDGET_SETTINGS) {
      await this.updateDefaultFilter(settings);
    } else {
      await this.updateMultipleDashboard(settings);
    }
  }

  async updateWidgets({
    currentWidget,
    data,
    removeIds,
    id,
  }: {
    currentWidget: SavedFilter;
    data?: Partial<WidgetsSettings> & Pick<WidgetsSettings, "widgetType">;
    removeIds?: string[];
    id: string;
  }) {
    let settings = this.filterSetting((currentWidget?.filter as SavedWidgetSettings).settings);

    if (removeIds) {
      settings = settings.filter((s) => !removeIds.includes(s.id));
    } else if (data?.id) {
      settings = settings.map((s) => {
        if (s.id !== data.id) return s;

        return { ...s, ...data };
      });
    } else if (data) {
      const filteredSettings = settings.filter((s) => !s.disabled).sort((a, b) => b.order - a.order);

      const maxOrderWithCurrentType =
        filteredSettings.find((s) => s.widgetType === data.widgetType)?.order ?? filteredSettings[0]?.order ?? 0;

      settings = settings.map((s) => {
        if (maxOrderWithCurrentType >= s.order) return s;

        return { ...s, order: s.order + 1 };
      });

      settings.push({
        ...data,
        type: data.type?.trim() as FILTER_PAGES,
        id,
        order: maxOrderWithCurrentType + 1,
      });
    }

    if (currentWidget?.type.trim() === FILTER_PAGES.WIDGET_SETTINGS) {
      await this.updateDefaultFilter(settings);
    } else {
      await this.updateMultipleDashboard(settings);
    }
  }

  async addOrRemoveWidgetSettings(data: {
    data?: Partial<WidgetsSettings> & Pick<WidgetsSettings, "widgetType">;
    removeIds?: string[];
  }) {
    const id = Date.now().toString();

    return Promise.all([
      !data.removeIds &&
        this.currentWidgetFilter &&
        this.currentWidgetFilter.type.trim() !== FILTER_PAGES.WIDGET_SETTINGS &&
        this.updateWidgets({
          ...data,
          currentWidget: this.currentWidgetFilter,
          id,
        }),
      this.defaultWidgetFilter &&
        this.updateWidgets({
          ...data,
          currentWidget: this.defaultWidgetFilter,
          id,
        }),
    ]);
  }

  setWidgetCursor({ queryData, widget, filter }: WidgetFilterRequest) {
    if (widget.widgetType?.trim() !== WIDGET_FILTER_TYPES.TABLE) {
      delete this.cursors[widget.id!];

      return;
    }

    this.cursors[widget.id!] = getDefaultCursorData<Inventory | EventView>();

    this.cursors[widget.id!].handler =
      filter.type.trim() === FILTER_PAGES.INVENTORY
        ? this.rootStore.inventory.getCursorHandler(this.cursors[widget.id!] as CursorData<Inventory>)
        : this.rootStore.events.getCursorHandler(this.cursors[widget.id!] as CursorData<EventView>);

    this.cursors[widget.id!].handler?.setFilters(queryData);
  }

  async getFilterCount({ queryData, widget, filter }: WidgetFilterRequest) {
    if (widget.widgetType?.trim() !== WIDGET_FILTER_TYPES.COUNTER) {
      return 0;
    }

    const method = filter.type.trim() === FILTER_PAGES.INVENTORY ? getInventory : getEvents;

    try {
      const { total } = await method({ ...queryData, count: 1 });

      return total;
    } catch {
      return 0;
    }
  }

  async setWidgetValues(widgetSettings: Partial<WidgetsSettings>[]): Promise<Widget[]> {
    try {
      const types = [...new Set(widgetSettings.map((w) => w?.type as FILTER_PAGES).filter((t) => t))];

      const filters = (await Promise.all(types.map((type) => this.rootStore.filter.getSavedFilters(type)))).flat();

      return widgetSettings.map((widget) => {
        const filter = filters.find((f) => widget.filterId === f.id);

        if (!filter) return widget as Widget;

        try {
          const dates = getDatesFromFilterValue(
            getDatesFromSavedFilter(filter as SavedFilter, filter.type.trim() as FILTER_PAGES),
          );

          const request = {
            widget,
            filter,
            queryData:
              filter.type.trim() === FILTER_PAGES.INVENTORY
                ? getInventoryQueryDataFromSavedFilter({
                    filter,
                    selectedAccountsIds: widget.selectedAccountsIds,
                    terraformFFEnabled: this.rootStore.auth.isFeatureEnabled(FEATURE.INTEGRATIONS_TERRAFORM),
                  })
                : {
                    query: getSearchString({ fields: eventFields, filterItem: filter.filter as FilterDataItem }),
                    accountId: widget.selectedAccountsIds?.join(","),
                    startTime: dates[0],
                    endTime: dates[1],
                  },
          };

          this.setWidgetCursor(request);

          return {
            ...widget,
            countRequest: this.getFilterCount(request),
          } as Widget;
        } catch {
          return widget as Widget;
        }
      });
    } catch {
      return widgetSettings as Widget[];
    }
  }
}
