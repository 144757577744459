import React, { MouseEvent } from "react";
import { Edit, RemoveCircleOutline, Sync } from "@mui/icons-material";

import { API_KEY_PERMISSIONS, ApiKey } from "store/types";
import { formattedDate } from "tools";
import store from "store";
import { ACTION_FIELD_PROPERTY } from "consts";
import { ActionButtonsWrapper } from "components/ui";
import { AddApiKeyIntegrationModal } from "components/modals/AddIntegrationModal/components";

const rotateKeyModal = (key: ApiKey) =>
  store?.ui.openModal({
    title: "Rotate API Key",
    component: <AddApiKeyIntegrationModal rotate keyForEdit={key} />,
  });

const editKeyModal = (key: ApiKey) =>
  store?.ui.openModal({
    title: "Edit API Key",
    component: <AddApiKeyIntegrationModal keyForEdit={key} />,
  });

const removeKeyModal = (key: ApiKey) =>
  store?.ui.openConfirmModal({
    title: "Are you sure you want to remove this API Key?",
    confirmText: "Remove",
    onConfirm: () => store.apiKeys.removeKey(key),
  });

export const apiKeyFields = [
  { name: "Id", property: "id" },
  { name: "Name", property: "name" },
  {
    name: "Created date",
    property: "created_at",
    render: (apiKey: ApiKey) => formattedDate(apiKey.created_at),
  },
  {
    name: "Created by",
    property: "created_by_user_id",
    render: (apiKey: ApiKey) =>
      store?.users.getUserNameById(apiKey.created_by_user_id) || apiKey.created_by_user_id || "",
  },
  {
    name: "Permissions",
    property: "permissions",
    doNotTruncate: true,
    render: (apiKey: ApiKey) =>
      Object.entries(apiKey.permissions)
        .filter(([, value]) => value)
        .map(([prop]) => Object.entries(API_KEY_PERMISSIONS).find(([, property]) => property === prop)?.[0] || prop)
        .join(", "),
  },
  {
    name: "Expiry date",
    property: "expires_at",
    render: (apiKey: ApiKey) => formattedDate(apiKey.expires_at),
  },
  {
    property: ACTION_FIELD_PROPERTY,
    render: (item: ApiKey) => (
      <ActionButtonsWrapper
        item={item}
        inTable
        buttons={[
          {
            icon: Sync,
            text: "Rotate",
            onClick: (e: MouseEvent<HTMLElement>, key: ApiKey) => rotateKeyModal(key),
          },
          {
            icon: Edit,
            text: "Edit",
            variant: "text",
            onClick: (e: MouseEvent<HTMLElement>, key: ApiKey) => editKeyModal(key),
          },
          {
            icon: RemoveCircleOutline,
            text: "Remove",
            variant: "text",
            onClick: (e: MouseEvent<HTMLElement>, key: ApiKey) => removeKeyModal(key),
          },
        ]}
      />
    ),
  },
];
