import { api } from "libs";
import { API_FILTERS, API_FILTERS_BY_ID, API_FILTERS_BY_TYPE } from "libs/api/endpoints";
import { FILTER_PAGES, FilterSaveData, SavedFilter } from "store/types";

export const getFilters = async (type: FILTER_PAGES): Promise<SavedFilter[]> =>
  api.get(API_FILTERS_BY_TYPE(type), {
    errorMessage: {
      title: "Failed to load Filters",
    },
  });

export const saveFilter = async (data: FilterSaveData): Promise<SavedFilter> =>
  api.post(API_FILTERS, data, {
    errorMessage: {
      title: "Failed to save Filter settings",
    },
  });

export const updateFilter = async (id: string, data: FilterSaveData): Promise<SavedFilter> =>
  api.put(API_FILTERS_BY_ID(id), data, {
    errorMessage: {
      title: "Failed to update Filter",
    },
  });

export const removeFilter = async (id: string): Promise<void> =>
  api.delete(API_FILTERS_BY_ID(id), {
    errorMessage: {
      title: "Failed to remove Filter",
    },
  });
