import React, { useCallback, useEffect, useRef, useState } from "react";
import { inject, observer } from "mobx-react";
import { Box, Typography } from "@mui/material";

import { RootStore } from "store/root.store";
import { Button, ButtonWrapper, Loading, ModalLoading, Table } from "components/ui";
import { AddAccountProgressList, OnboardingInstructions } from "components/modals/AddAccountModal/components";
import {
  ACCOUNT_STATUSES,
  CLOUD_PLATFORM,
  ONBOARDING_STATUSES,
  UI_TABLES,
  Account,
  OnBoardingDataAWS,
} from "store/types";

import { useTableData } from "./addAccountFormStepTwo.hooks";

type AddAccountFormStepTwoProps = {
  store?: RootStore;
  accountData: Account;
  setAccountData: (account?: Account) => void;
};

const fields = [{ property: "property" }, { property: "value" }];

const AddAccountFormStepTwo = ({ store, accountData, setAccountData }: AddAccountFormStepTwoProps) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [onboardingData, setOnboardingData] = useState<any>();
  const [roleName, setRoleName] = useState(onboardingData?.role_name?.trim());
  const tableData = useTableData({
    onboardingData,
    setOnboardingData,
    accountData,
    roleName,
    setRoleName,
    accounts: store?.accounts,
  });

  const getOnboardingData = useCallback(
    async (firstTime: boolean) => {
      try {
        const data = await store?.accounts.getOnboardingStatus(accountData.id as string);

        if (accountData.provider?.toUpperCase() === CLOUD_PLATFORM.AWS && firstTime) {
          setRoleName((data as OnBoardingDataAWS)?.role_name?.trim());
        }

        setOnboardingData(data);

        if (data?.status === ONBOARDING_STATUSES.COMPLETED) {
          store?.accounts.fetch();
        }

        if (data?.status !== ONBOARDING_STATUSES.COMPLETED && store?.ui?.modal) {
          timeoutRef.current = setTimeout(() => getOnboardingData(false), 2000);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        setOnboardingData({
          status: ONBOARDING_STATUSES.FAILED,
          description: err.response?.data?.error || err.message,
        });
      }
    },
    [store?.ui?.modal, store?.accounts, accountData, setOnboardingData, setRoleName],
  );

  useEffect(() => {
    clearTimeout(timeoutRef.current);

    getOnboardingData(true);
  }, [getOnboardingData]);

  useEffect(() => clearTimeout(timeoutRef.current), [accountData.id]);

  useEffect(() => () => clearTimeout(timeoutRef.current), []);

  const showOnBoarding = () =>
    (onboardingData?.service_account_name || onboardingData?.opshelm_role_arn) &&
    (onboardingData?.status === ONBOARDING_STATUSES.AWAITING_AUTH ||
      onboardingData?.status === ONBOARDING_STATUSES.FAILED);

  const changeOnboardingStatus = (status: ONBOARDING_STATUSES) => {
    if (!onboardingData) return;

    setOnboardingData({ ...onboardingData, status });
  };

  const onBoardAccount = (account: Account) => {
    store?.accounts.onBoardAccount(account);

    changeOnboardingStatus(ONBOARDING_STATUSES.RUNNING);
  };

  const retry = async (account: Account) => {
    try {
      const newAccount = await store?.accounts.retry(account);

      setAccountData(newAccount);

      changeOnboardingStatus(ONBOARDING_STATUSES.AWAITING_AUTH);
    } catch {}
  };

  const closeActions = () => {
    store?.accounts.fetch();
    store?.ui.closeModal();
  };

  const onClose = () => {
    const showRemovalModal =
      accountData.status === ACCOUNT_STATUSES.PENDING &&
      (onboardingData?.status === ONBOARDING_STATUSES.AWAITING_AUTH ||
        onboardingData?.status === ONBOARDING_STATUSES.RUNNING);

    if (!showRemovalModal) return closeActions();

    store?.ui.openConfirmModal({
      title: "Are you sure you want to cancel?",
      component: "This will stop the onboarding process and remove the account.",
      confirmText: "Yes",
      closeText: "No",
      onCloseButtonClick: closeActions,
      onConfirm: () => store?.accounts.cancelOnboarding(accountData.id),
    });
  };

  if (store?.accounts.isModalLoading) return <ModalLoading />;

  return (
    <Box data-testid="addAccountModalStepTwo" display="flex">
      <AddAccountProgressList
        provider={accountData.provider?.toUpperCase() as CLOUD_PLATFORM}
        stepId={onboardingData?.step_id}
        currentStep={
          onboardingData?.status === ONBOARDING_STATUSES.COMPLETED
            ? accountData.provider?.toUpperCase() === CLOUD_PLATFORM.GCP
              ? 4
              : 3
            : accountData.provider?.toUpperCase() === CLOUD_PLATFORM.GCP
            ? 3
            : 2
        }
      />

      <Box
        width={{ xs: 1, laptop: "calc(100% - 310px)" }}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Table
          type={UI_TABLES.ACCOUNT_ONBOARDING}
          hidePagination
          desktopView
          leftHeader
          fields={fields}
          data={tableData}
        />

        {showOnBoarding() && (
          <OnboardingInstructions
            provider={accountData.provider}
            projectId={accountData.provider_account_id}
            serviceAccount={onboardingData!.service_account_name!}
            accountId={onboardingData!.cloud_account_id}
            roleName={(onboardingData!.role_name || "").trim()}
            opshelmRoleArn={onboardingData!.opshelm_role_arn!}
            externalId={onboardingData!.aws_external_id!}
          />
        )}

        {onboardingData?.status === ONBOARDING_STATUSES.RUNNING && (
          <Box marginTop={4}>
            <Typography variant="table" lineHeight={1.5}>
              This step is completely automatic and you don&apos;t have to stay on the page. However, if you prefer to
              stay and monitor the process, it can take up to 15 minutes. We&apos;ll let you know when the account is
              successfully connected. Thank you!
            </Typography>

            <Loading marginTop={10} marginBottom={10} />
          </Box>
        )}

        {onboardingData?.status === ONBOARDING_STATUSES.COMPLETED && (
          <Box marginTop={4}>
            <Typography variant="table" lineHeight={1.5}>
              Great news!
              <br />
              Your account is now connected! Simply click &quot;Close&quot; and you&apos;ll be taken back to the
              dashboard. Enjoy using our platform!
            </Typography>
          </Box>
        )}

        <ButtonWrapper>
          <>
            <Button
              variant={showOnBoarding() ? "text" : "contained"}
              onClick={onClose}
              text={onboardingData?.status === ONBOARDING_STATUSES.COMPLETED ? "Close" : "Cancel"}
            />
            {showOnBoarding() &&
              (onboardingData?.status === ONBOARDING_STATUSES.FAILED ? (
                <Button text="Retry" onClick={() => retry(accountData)} />
              ) : (
                <Button text="Continue" onClick={() => onBoardAccount(accountData)} />
              ))}
          </>
        </ButtonWrapper>
      </Box>
    </Box>
  );
};

export default inject("store")(observer(AddAccountFormStepTwo));
