import React, { ReactElement, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Box } from "@mui/material";

import { ElementWrapperWithActions, PieChart, PieChartItem } from "components/ui";
import { DataStatsResponseItem, FILTER_PAGES, Widget } from "store/types";
import { RootStore } from "store/root.store";
import { transformPieChartData } from "routes/DashboardRoute/dashboard.utils";

type DashboardPieChartItemType = {
  store?: RootStore;
  widget: Widget;
  editModeCustomTitleElement?: ReactElement;
};

const DashboardPieChartItemType = ({ store, editModeCustomTitleElement, widget }: DashboardPieChartItemType) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<PieChartItem[]>([]);

  useEffect(() => {
    (async () => {
      if (!widget?.filterId) {
        setIsLoading(false);

        return;
      }

      const filters = await store?.filter.getSavedFilters(widget.type?.trim() as FILTER_PAGES);

      const filter = filters?.find((f) => f.id === widget?.filterId);

      if (!filter?.filter) {
        setIsLoading(false);

        return;
      }

      const filterData = await store?.inventory.getInventory(filter, widget?.selectedAccountsIds);

      if (filterData?.data) {
        setData(transformPieChartData(filterData?.data as unknown as DataStatsResponseItem[]));
      }

      setIsLoading(false);
    })();
  }, [store?.inventory, store?.filter, widget?.selectedAccountsIds, widget?.type, widget?.filterId]);

  return (
    <ElementWrapperWithActions
      component={PieChart}
      isLoading={isLoading}
      title={
        <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center" gap={5}>
          {widget.name}
          {editModeCustomTitleElement && (
            <Box display="flex" gap={3}>
              {editModeCustomTitleElement}
            </Box>
          )}
        </Box>
      }
      componentProps={{
        items: data,
      }}
      hideActions
    />
  );
};

export default inject("store")(observer(DashboardPieChartItemType));
