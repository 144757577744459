import { FILTER_PAGES, SavedFilter } from "store/types/filter.store.types";

export enum WIDGET_MANAGER_VIEWS {
  GALLERY = "GALLERY",
  LIST = "LIST",
}

export enum WIDGET_FILTER_TYPES {
  COUNTER = "COUNTER",
  TABLE = "TABLE",
  PIE_CHART = "PIE_CHART",
  ISSUES_TABLE = "ISSUES_TABLE",
}

export type DashboardWidgetsSettings = {
  id: string;
  order: number;
};

export type WidgetsSettings = DashboardWidgetsSettings & {
  disabled?: boolean;
  widgetType: WIDGET_FILTER_TYPES;
  filterId?: string;
  name?: string;
  type?: FILTER_PAGES;
  selectedAccountsIds?: string[];
  isDataAsset?: boolean;
};

export type Widget = WidgetsSettings & {
  count?: number;
  countRequest?: Promise<number>;
  comment?: string;
};

export type SavedWidgetSettings = { settings: WidgetsSettings[]; dashboardWasAdded?: boolean };

export type WidgetFilterRequest = {
  queryData: Record<string, string | string[] | Date | undefined>;
  widget: Partial<WidgetsSettings>;
  filter: SavedFilter;
};
