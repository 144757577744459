import React from "react";
import { Autocomplete, Box, FormControl, TextField, useTheme } from "@mui/material";
import { HighlightOff, Add } from "@mui/icons-material";

import { Label } from "components/ui";
import { DROPDOWNS } from "store/types";
import store from "store";
import { SMALL_HEIGHT, TABLE_HEIGHT } from "consts";
import { darkInputBorder, lightDarkBG, lightInputBorder, lightTextSecondaryDarkColor } from "theme/commonVariables";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PossibleValue = any;

export type MultiSelectListItem = { id: string; name: string };

type MultiSelectProps = {
  label?: string;
  fullWidth?: boolean;
  value: PossibleValue;
  onChange: (val: PossibleValue) => void;
  disableOption?: (option: MultiSelectListItem | string) => boolean;
  list?: MultiSelectListItem[];
  placeholder?: string;
  multiple?: boolean;
  freeSolo?: boolean;
  disabled?: boolean;
  nowrap?: boolean;
  type?: DROPDOWNS;
  dontRemoveLast?: boolean;
  limitTags?: number;
  inTable?: boolean;
};

export const MultiSelect = ({
  type,
  fullWidth,
  label,
  value,
  onChange,
  disableOption,
  list,
  placeholder,
  multiple,
  freeSolo,
  disabled,
  nowrap,
  inTable,
  dontRemoveLast,
  limitTags = 5,
}: MultiSelectProps) => {
  const theme = useTheme();

  const handleChange = (v: PossibleValue) => {
    if (type) {
      store?.ui.setSelectedDropdownValue(
        type,
        v.map((pv: PossibleValue) => pv.id || pv),
      );
    }

    onChange(v);
  };

  return (
    <FormControl sx={{ "& .MuiInputBase-root": { minHeight: TABLE_HEIGHT } }} fullWidth={fullWidth}>
      {!!label && <Label text={label} />}

      <Autocomplete
        clearIcon={false}
        multiple={multiple}
        options={list || []}
        clearOnBlur
        clearOnEscape
        filterSelectedOptions
        blurOnSelect={!multiple}
        freeSolo={freeSolo}
        disabled={disabled}
        limitTags={limitTags}
        getOptionLabel={(option: MultiSelectListItem | string) =>
          (option as MultiSelectListItem)?.name || (option as string)
        }
        getOptionDisabled={(option) => !!disableOption && disableOption(option)}
        onChange={(_: unknown, newValue: PossibleValue) => handleChange(newValue)}
        value={value}
        isOptionEqualToValue={(i) =>
          multiple
            ? value
                .filter((v: MultiSelectListItem) => v)
                .find((v: MultiSelectListItem) => (typeof i === "string" ? v === i : v.id === i.id))
            : i.id === value?.id
        }
        disableCloseOnSelect={multiple}
        ChipProps={{ deleteIcon: <HighlightOff sx={{ width: 18 }} /> }}
        popupIcon={
          multiple ? (
            <Box
              height={SMALL_HEIGHT}
              width={SMALL_HEIGHT}
              borderRadius={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.palette.mode === "light" ? lightDarkBG : darkInputBorder}
            >
              <Add
                sx={{
                  width: 18,
                  height: 18,
                  ...(theme.palette.mode === "light" && {
                    fill: `${lightTextSecondaryDarkColor} !important`,
                  }),
                }}
              />
            </Box>
          ) : undefined
        }
        renderInput={(params) => <TextField {...params} placeholder={!value?.length ? placeholder : ""} />}
        sx={{
          input: {
            fontSize: 14,
            fontWeight: 600,
            paddingRight: "40px !important",
          },

          "& .MuiAutocomplete-popupIndicator": {
            svg: {
              ...(inTable && {
                fill: theme.palette.mode === "light" ? lightTextSecondaryDarkColor : darkInputBorder,
              }),
              ...(disabled && {
                fill: theme.palette.mode === "light" ? "#9e9e9e" : darkInputBorder,
              }),
            },
          },

          ...(multiple && {
            "& .MuiAutocomplete-endAdornment": {
              top: 3,
              right: "8px !important",
            },
          }),

          "& .MuiAutocomplete-inputRoot": {
            borderRadius: 1,
            paddingX: `${theme.spacing(1)} !important`,
            paddingY: "0 !important",
            ...(multiple &&
              value?.length && {
                paddingRight: "40px !important",
              }),

            backgroundColor: inTable ? "background.selectBG" : "background.inputBG",
            fieldset: inTable
              ? {
                  border: "none !important",
                }
              : {
                  border: `1px solid ${theme.palette.mode === "light" ? lightInputBorder : darkInputBorder} !important`,
                },

            "& .MuiChip-root": {
              borderRadius: 1,
              backgroundColor: theme.palette.mode === "light" ? lightDarkBG : darkInputBorder,

              ...(theme.palette.mode === "light" && {
                color: lightTextSecondaryDarkColor,
                svg: {
                  fill: lightTextSecondaryDarkColor,
                },
              }),

              "& .MuiSvgIcon-root": {
                ...(dontRemoveLast && value.length === 1 && { display: "none" }),
              },
            },

            ...(nowrap && {
              flexWrap: "nowrap",
            }),
          },
        }}
      />
    </FormControl>
  );
};
