import React from "react";
import { Box, Typography } from "@mui/material";
import { LocalOffer } from "@mui/icons-material";

import { JsonViewerType } from "components/ui/JsonViewer/jsonViewer";

export const TagsCount = ({ tags, withoutValues = false }: { tags: JsonViewerType; withoutValues?: boolean }) => {
  const count = Object.values(tags).filter((v) => !withoutValues || v === "").length;

  if (!count) return null;

  const themeVariable = withoutValues ? "tagInactive" : "tagBlue";

  return (
    <Box
      overflow="hidden"
      border="1px solid"
      borderColor={`various.${themeVariable}.border`}
      borderRadius={3}
      display="flex"
      height={30}
      color={`various.${themeVariable}.color`}
    >
      <Box width={30} height={1} display="flex" alignItems="center" justifyContent="center">
        <LocalOffer sx={{ display: "flex", transform: "rotate(90deg)", fontSize: 20 }} />
      </Box>
      <Typography
        variant="main"
        color={`various.${themeVariable}.bgColor`}
        bgcolor={`various.${themeVariable}.bg`}
        height={1}
        paddingX={1.5}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {count}
      </Typography>
    </Box>
  );
};
