import {
  GridViewOutlined,
  CloudOutlined,
  HubOutlined,
  PeopleOutline,
  NotificationsNone,
  LogoutRounded,
  SettingsOutlined,
  GppMaybeOutlined,
  RuleOutlined,
  ManageSearchOutlined,
  Inventory2Outlined,
  ExtensionOutlined,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import { inject, observer } from "mobx-react";

import { FEATURE, PERMISSION_TYPES } from "store/types";
import { API_BASE_URL, API_LOGOUT } from "libs/api/endpoints";
import { NavItem } from "components/layouts/LoggedInFlow/components/VerticalMenu/components/Nav/components";
import {
  ACCOUNTS_ROUTE,
  CLOUD_MAP_ACCOUNTS_ROUTE,
  DASHBOARD_ROUTE,
  NOTIFICATIONS_ROUTE,
  USERS_ROUTE,
  DEPENDABOT_ROUTE,
  RULES_ROUTE,
  EVENTS_ROUTE,
  INVENTORY_ROUTE,
  INTEGRATIONS_ROUTE,
} from "consts";
import { RequirePerm } from "components/guards";
import { RootStore } from "store/root.store";

import DashboardDropdownMenu from "./components/DashboardDropdownMenu/dashboardDropdownMenu";

const Nav = ({ store }: { store?: RootStore }) => (
  <Box component="nav" data-testid="nav" marginTop={{ xs: "-13px", laptop: 0 }}>
    <NavItem
      dropdownMenu={store?.auth.isFeatureEnabled(FEATURE.MULTIPLE_DASHBOARDS) ? <DashboardDropdownMenu /> : undefined}
      title="Dashboard"
      url={DASHBOARD_ROUTE}
      icon={GridViewOutlined}
    />
    <NavItem
      title="Inventory"
      url={INVENTORY_ROUTE}
      icon={Inventory2Outlined}
      access={{
        feature: FEATURE.INVENTORY_PAGE,
      }}
    />
    <NavItem
      title="Events"
      url={EVENTS_ROUTE}
      icon={ManageSearchOutlined}
      access={{
        feature: FEATURE.EVENTS_PAGE,
      }}
    />
    <NavItem
      title="Rules"
      url={RULES_ROUTE}
      icon={RuleOutlined}
      access={{
        feature: FEATURE.RULES_PAGE,
      }}
    />
    <NavItem title="Vulnerability Alerts" url={DEPENDABOT_ROUTE} icon={GppMaybeOutlined} />
    <NavItem
      title="Cloud Map"
      url={CLOUD_MAP_ACCOUNTS_ROUTE}
      icon={HubOutlined}
      access={{ feature: FEATURE.CLOUDMAP_PAGE }}
    />

    <RequirePerm
      access={{
        permission: PERMISSION_TYPES.ADMIN,
      }}
      element={
        <Box
          marginTop={4}
          paddingTop={2}
          border="1px solid"
          borderColor="background.default"
          borderLeft="none"
          borderRight="none"
          borderBottom="none"
        >
          <NavItem title="Settings" sectionTitle icon={SettingsOutlined} />
          <NavItem title="Accounts" url={ACCOUNTS_ROUTE} icon={CloudOutlined} />
          <NavItem title="Users" url={USERS_ROUTE} icon={PeopleOutline} />
          <NavItem title="Notifications" url={NOTIFICATIONS_ROUTE} icon={NotificationsNone} />
          <NavItem title="Integrations" url={INTEGRATIONS_ROUTE} icon={ExtensionOutlined} />
        </Box>
      }
    />

    <Box
      marginTop={4}
      paddingTop={2}
      border="1px solid"
      borderColor="background.default"
      borderLeft="none"
      borderRight="none"
      borderBottom="none"
    >
      <form method="POST" action={`${API_BASE_URL}${API_LOGOUT}`}>
        <NavItem icon={LogoutRounded} title="Log Out" />
      </form>
    </Box>
  </Box>
);

export default inject("store")(observer(Nav));
