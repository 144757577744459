import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { inject, observer } from "mobx-react";
import { HighlightOff } from "@mui/icons-material";

import { RootStore } from "store/root.store";
import { MultiSelect, NONE_SELECT_TAG_VALUE } from "components/ui";
import { InventorySearchDataTag, InventoryTagValue } from "store/types";

type InventoryTagProps = {
  store?: RootStore;
  defaultTags: InventorySearchDataTag[];
  selectedAccounts: string[];
  index: number;
  tags: InventoryTagValue[];
  setTags: Dispatch<SetStateAction<InventoryTagValue[]>>;
  setTagsValue: (val: InventoryTagValue[]) => void;
};

const InventoryTag = ({
  store,
  defaultTags,
  selectedAccounts,
  tags,
  setTags,
  index,
  setTagsValue,
}: InventoryTagProps) => {
  const lastFetchedTag = useRef<string>("");

  const [state, setState] = useState<{
    tags: InventorySearchDataTag[];
    values: InventorySearchDataTag[];
  }>({
    tags: defaultTags,
    values: [],
  });

  const getValues = useCallback(
    async (key: string) => {
      if (lastFetchedTag.current === key) return;

      const data = await store?.inventory.getInventoryTags(selectedAccounts, {
        key,
      });

      lastFetchedTag.current = key;

      setState((curr) => ({ ...curr, values: data as InventorySearchDataTag[] }));
    },
    [store?.inventory, selectedAccounts],
  );

  useEffect(() => {
    if (!tags[index]?.tag) {
      setState({
        tags: defaultTags,
        values: [],
      });

      return;
    }

    getValues(tags[index].tag as string);
  }, [setState, defaultTags, getValues, tags, index]);

  const removeTag = () => {
    if (tags.length === 1) {
      const newTags = [{ tag: null, value: null }];
      setTags(newTags);
      setTagsValue(newTags);

      return;
    }

    setTags((curr) => {
      const newTags = [...curr.slice(0, index), ...curr.slice(index + 1)];

      setTagsValue(newTags);

      return newTags;
    });
  };

  const changeTag = (tag: string | null, value: string | null) =>
    setTags((curr) => {
      const newTags = [
        ...curr.slice(0, index),
        {
          tag,
          value,
        },
        ...curr.slice(index + 1),
      ];

      if (value) {
        setTagsValue(newTags);
      }

      return newTags;
    });

  return (
    <Box
      display="flex"
      gap={2}
      borderRadius={1}
      minWidth={200}
      flexGrow={1}
      flexDirection="column"
      padding={1}
      border="1px solid"
      borderColor="various.inputBorder"
      position="relative"
    >
      <HighlightOff
        onClick={removeTag}
        sx={{
          position: "absolute",
          top: -10,
          right: -10,
          zIndex: 1,
          color: "text.secondary",
          backgroundColor: "background.paper",
          cursor: "pointer",
        }}
      />

      <MultiSelect
        inTable
        fullWidth
        placeholder="Search tag"
        onChange={(tagValue) => changeTag(tagValue.id, null)}
        value={tags[index]?.tag}
        list={state.tags
          .filter((t) => !tags.find((ct) => ct.tag === t.keys))
          .map((t) => ({
            id: t.keys,
            name: `${t.keys} (${t.count})`,
          }))}
      />

      <MultiSelect
        disabled={!state.values.length}
        inTable
        fullWidth
        placeholder="Search value"
        onChange={(newValue) => changeTag(tags[index]?.tag, newValue.id)}
        value={tags[index]?.value}
        list={state.values.map((t) => ({
          id: t.values || NONE_SELECT_TAG_VALUE,
          name: `${t.values || NONE_SELECT_TAG_VALUE} (${t.count})`,
        }))}
      />
    </Box>
  );
};

export default inject("store")(observer(InventoryTag));
