import { api } from "libs";
import {
  API_RULES,
  API_RULES_STATUS,
  API_RULES_PACK_STATUS,
  API_RULES_ACCOUNT_STATUS,
  API_RULES_PACK_ACCOUNT_STATUS,
} from "libs/api/endpoints";
import { RulesPack } from "store/types";

export const getRules = async (doNotShowErrorMessage: boolean): Promise<RulesPack[]> => {
  const { rules } = await api.get(API_RULES, {
    doNotShowErrorMessage,
    cache: {
      maxAge: 0,
    },
    errorMessage: {
      title: "Failed to load Rules",
    },
  });

  return (rules || []).map((rulePack: RulesPack) => ({
    ...rulePack,
    rules: rulePack.rules.map((r) => ({ ...r, rulePackId: rulePack.id })),
  }));
};

export const changeRulePackStatus = async (id: string, enabled: boolean): Promise<void> =>
  api.put(
    API_RULES_PACK_STATUS(id),
    { enabled },
    {
      errorMessage: {
        title: "Failed to change status for the Rule Pack",
      },
    },
  );

export const changeRuleStatus = async (id: string, enabled: boolean): Promise<void> =>
  api.put(
    API_RULES_STATUS(id),
    { enabled },
    {
      errorMessage: {
        title: "Failed to change status for the Rule",
      },
    },
  );

export const changeRulePackAccountStatus = async (
  accountId: string,
  rulePackId: string,
  enabled: boolean,
): Promise<void> =>
  api.put(
    API_RULES_PACK_ACCOUNT_STATUS(accountId, rulePackId),
    { enabled },
    {
      errorMessage: {
        title: "Failed to change Account status for the Rule Pack",
      },
    },
  );

export const changeRuleAccountStatus = async (accountId: string, ruleId: string, enabled: boolean): Promise<void> =>
  api.put(
    API_RULES_ACCOUNT_STATUS(accountId, ruleId),
    { enabled },
    {
      errorMessage: {
        title: "Failed to change Account status for the Rule",
      },
    },
  );
