import React, { ReactElement } from "react";
import { Box, FormControl, MenuItem, Select as MuiSelect, SelectChangeEvent, useTheme } from "@mui/material";
import { inject, observer } from "mobx-react";

import { Label } from "components/ui/Label/label";
import { DROPDOWNS } from "store/types";
import { RootStore } from "store/root.store";
import { SMALL_HEIGHT, TABLE_HEIGHT } from "consts";
import {
  darkBG,
  darkInputBorder,
  darkTextPrimaryColor,
  darkTextSecondaryDarkColor,
  lightDarkBG,
  lightInputBorder,
  lightTextPrimaryColor,
  lightTextSecondaryDarkColor,
} from "theme/commonVariables";

export const NONE_SELECT_VALUE = "__NONE__";

export const NONE_SELECT_TAG_VALUE = "__NO_VALUE__";

export const CURRENT_SELECT_VALUE = "__CURRENT__";

export const LAST_7_DAYS = {
  id: "last7Days",
  name: "Last 7 Days",
};

export const LAST_MONTHS = {
  id: "lastMonth",
  name: "Last Months",
};

export type SelectListId = string | number;

export type SelectList = { id: SelectListId; name: string | number }[];

type SelectProps = {
  store?: RootStore;
  darkBg?: boolean;
  extraSmall?: boolean;
  fullWidth?: boolean;
  inTable?: boolean;
  list: SelectList;
  label?: string;
  value: string | number;
  onChange?: (value: string) => void;
  eventRawChange?: (e: SelectChangeEvent) => void;
  type?: DROPDOWNS;
  smallWidth?: boolean;
  withBorder?: boolean;
  disabled?: boolean;
  icon?: ReactElement;
};

const Select = ({
  extraSmall,
  store,
  fullWidth,
  value,
  inTable,
  label,
  list,
  onChange,
  eventRawChange,
  darkBg,
  smallWidth,
  type,
  disabled,
  withBorder,
  icon,
}: SelectProps) => {
  const theme = useTheme();

  const handleChange = (e: SelectChangeEvent) => {
    if (eventRawChange) return eventRawChange(e);

    const selectedValue = e.target.value;

    if (type) {
      store?.ui.setSelectedDropdownValue(type, selectedValue);
    }

    onChange?.(selectedValue);
  };

  return (
    <FormControl fullWidth={fullWidth}>
      {!!label && <Label text={label} />}

      <Box
        bgcolor={inTable ? "background.selectBG" : "background.inputBG"}
        display="flex"
        alignItems="center"
        borderRadius={1}
        sx={{
          height: extraSmall ? SMALL_HEIGHT : TABLE_HEIGHT,
          ...((!inTable || withBorder) && {
            border: `1px solid ${
              disabled ? "#9d9e9e" : theme.palette.mode === "light" ? lightInputBorder : darkInputBorder
            }`,
          }),
        }}
      >
        {!!icon && (
          <Box
            display="flex"
            zIndex={1}
            marginLeft={2}
            marginRight={-1}
            sx={{
              svg: {
                path: {
                  fill: inTable
                    ? theme.palette.mode === "light"
                      ? lightTextSecondaryDarkColor
                      : darkTextSecondaryDarkColor
                    : theme.palette.mode === "light"
                    ? lightTextPrimaryColor
                    : darkTextPrimaryColor,
                },
              },
            }}
          >
            {icon}
          </Box>
        )}
        <MuiSelect
          fullWidth
          data-testid="select"
          disabled={disabled}
          value={value?.toString()}
          onChange={handleChange}
          sx={{
            height: 1,
            fontSize: 14,
            fontWeight: 600,
            color: inTable ? "text.secondaryDark" : "various.inputColor",
            backgroundColor: inTable ? "background.selectBG" : "background.inputBG",
            fieldset: {
              border: "none !important",
            },
            "& .MuiSelect-select": {
              paddingY: 0,
              ...(smallWidth && {
                paddingLeft: 2,
                paddingRight: `${theme.spacing(4)} !important`,
              }),
            },
            svg: {
              ...(inTable && {
                fill: theme.palette.mode === "light" ? lightTextSecondaryDarkColor : darkInputBorder,
              }),
              ...(smallWidth && { right: 0 }),
            },
            ...(darkBg && { background: theme.palette.mode === "light" ? lightDarkBG : darkBG }),
          }}
        >
          {list.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </MuiSelect>
      </Box>
    </FormControl>
  );
};

export default inject("store")(observer(Select));
