import React, { ReactElement } from "react";
import { Box } from "@mui/material";

// eslint-disable-next-line max-len
import { JsonViewRowNumber } from "components/ui/JsonViewer/components/JsonView/components/JsonViewRowNumber/jsonViewRowNumber";
import { JsonHighlights, JsonRow } from "components/ui/JsonViewer/jsonViewer";

export const JsonBorderWrapper = ({
  count,
  children,
  offset,
  inline,
  numbers,
  property,
  highlights,
  expanded,
  collapsible,
  setCollapsible,
}: {
  count: number;
  offset: number;
  numbers?: Record<string, JsonRow>;
  property?: string;
  inline?: boolean;
  children: ReactElement;
  highlights?: JsonHighlights;
  collapsible: string[];
  setCollapsible: (val: string) => void;
  expanded: boolean;
}) => {
  if (!count) return children;

  return (
    <Box display={inline ? "inline-flex" : "flex"}>
      {!!numbers?.[property!] && (
        <JsonViewRowNumber
          highlights={highlights}
          rows={Object.values(numbers)}
          row={numbers?.[property!]}
          expanded={expanded}
          collapsible={collapsible}
          setCollapsible={setCollapsible}
        />
      )}

      <Box paddingLeft={offset} borderLeft="1px solid #C5C7DF">
        <JsonBorderWrapper
          highlights={highlights}
          offset={offset}
          count={count - 1}
          expanded={expanded}
          collapsible={collapsible}
          setCollapsible={setCollapsible}
        >
          {children}
        </JsonBorderWrapper>
      </Box>
    </Box>
  );
};
