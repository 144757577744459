import React from "react";
import { inject, observer } from "mobx-react";
import { Box } from "@mui/material";

import { INTEGRATION, Integration, UI_TABLES } from "store/types";
import { Button, ButtonWrapper, Table, TableFields } from "components/ui";
import { RootStore } from "store/root.store";

const viewIntegrationModal = ({
  store,
  integration,
  fields,
  type,
}: {
  store?: RootStore;
  integration: Integration;
  fields?: TableFields[];
  type?: INTEGRATION;
}) => {
  const data =
    type === INTEGRATION.GITHUB
      ? store!.github.installations.map((item) => ({
          id: item.installationId.toString(),
          ...item,
        }))
      : type === INTEGRATION.API_KEY
      ? store!.apiKeys.apiKeys
      : type === INTEGRATION.AWS_COST
      ? store!.costReport.awsIntegrations
      : type === INTEGRATION.GCP_COST
      ? store!.costReport.gcpIntegrations
      : [INTEGRATION.SLACK, INTEGRATION.EMAIL, INTEGRATION.JIRA, INTEGRATION.HTTP].includes(type as INTEGRATION)
      ? store!.notifications.channels.filter((c) => c.channel_type === type)
      : [];

  return (
    <>
      <Box marginX={{ xs: -3, laptop: -5 }}>
        <Table
          type={`${UI_TABLES.INTEGRATION}_${integration.type}`}
          desktopView
          hidePagination
          fields={fields || []}
          data={data}
        />
      </Box>
      <ButtonWrapper>
        <Button onClick={store?.ui.closeModal} text="Cancel" variant="text" />
      </ButtonWrapper>
    </>
  );
};

export default inject("store")(observer(viewIntegrationModal));
