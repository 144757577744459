import React from "react";
import { inject, observer } from "mobx-react";
import { Box, Typography } from "@mui/material";

import { TABLE_HEIGHT } from "consts";

export type DashboardHistoricalStatsData = {
  added: number;
  same: number;
  removed: number;
};

type DashboardHistoricalStatsProps = {
  stats: DashboardHistoricalStatsData;
  smallView?: boolean;
};

enum HISTORICAL_STATS {
  ADDED = "added",
  SAME = "same",
  REMOVED = "removed",
}

const DashboardHistoricalStats = ({ stats, smallView }: DashboardHistoricalStatsProps) => (
  <Box display="flex" gap={1}>
    {Object.values(HISTORICAL_STATS).map((item) => (
      <Typography
        key={item}
        borderRadius={1}
        variant="main"
        fontWeight={700}
        color={item === HISTORICAL_STATS.ADDED ? "#00b081" : item === HISTORICAL_STATS.REMOVED ? "#E45D68" : "#f1b017"}
        height={smallView ? 28 : TABLE_HEIGHT}
        paddingX={3}
        bgcolor={
          item === HISTORICAL_STATS.ADDED ? "#DBFFF5" : item === HISTORICAL_STATS.REMOVED ? "#FFCED2" : "#FCEFD1"
        }
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {item === HISTORICAL_STATS.ADDED && "+"}
        {item === HISTORICAL_STATS.REMOVED && "-"}
        {stats[item]}
      </Typography>
    ))}
  </Box>
);

export default inject("store")(observer(DashboardHistoricalStats));
