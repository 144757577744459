import { ReactElement, useEffect } from "react";
import { inject, observer } from "mobx-react";

import { RootStore } from "store/root.store";
import { useCookie } from "hooks";
import { Message } from "store/types";
import { COOKIE_MESSAGE } from "hooks/cookie";

type Props = { store?: RootStore; children: ReactElement };

const MessageProvider = ({ store, children }: Props) => {
  const [msgCookie, setMsgCookie] = useCookie(COOKIE_MESSAGE);

  useEffect(() => {
    if (!msgCookie) return;

    let message: (Message & { severity?: string }) | null;

    try {
      message = JSON.parse(window.atob(msgCookie));
    } catch {
      message = null;
    }

    setMsgCookie();

    if (!message) return;

    if (message.severity === "error") {
      store?.ui.showErrorMessage(message);
    } else {
      store?.ui.showMessage(message);
    }
  }, [msgCookie, setMsgCookie, store?.ui, store?.ui.lastCookieCheck]);

  return children;
};

export default inject("store")(observer(MessageProvider));
