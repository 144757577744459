import React, { useCallback, useEffect, useMemo, useState } from "react";
import { inject, observer } from "mobx-react";
import { Box, Typography, useTheme } from "@mui/material";

import { Account, RulesPack, Rule } from "store/types";
import { Checkbox } from "components/ui";
import { RootStore } from "store/root.store";

type RulesEnvironmentsProps = {
  store?: RootStore;
  rulePackItem?: RulesPack;
  ruleItem?: Rule;
};

const RulesEnvironments = ({ store, rulePackItem, ruleItem }: RulesEnvironmentsProps) => {
  const item = rulePackItem || ruleItem;

  const theme = useTheme();
  const { activeAccounts } = store!.accounts;

  const [environments, setEnvironments] = useState<string[]>([]);

  const relatedAccounts = useMemo(
    () => activeAccounts.filter((a) => a.provider === item?.provider),
    [activeAccounts, item],
  );

  const rulesCountEnableForAccount = useCallback(
    (name: string) => rulePackItem?.rules?.filter((r) => r?.environments?.includes(name)).length,
    [rulePackItem],
  );

  useEffect(() => {
    if (ruleItem) {
      setEnvironments(item?.environments || []);

      return;
    }

    setEnvironments(relatedAccounts.map((a) => a.name).filter(rulesCountEnableForAccount));
  }, [item, relatedAccounts, ruleItem, rulesCountEnableForAccount]);

  const getComment = useCallback(
    (name: string) => {
      if (ruleItem || !environments.includes(name)) return "";

      const count = rulesCountEnableForAccount(name);

      if (!count) return "";

      return `(${count === rulePackItem?.rules?.length ? "All" : "Some"} rules are enabled)`;
    },
    [ruleItem, rulePackItem, rulesCountEnableForAccount, environments],
  );

  const changeStatus = async (account: Account, enabled: boolean) => {
    setEnvironments((curr) => {
      if (curr.includes(account.name)) return curr.filter((name) => name !== account.name);

      return [...curr, account.name];
    });

    if (rulePackItem) {
      return store!.rules.changeRulePackAccountStatus(item as RulesPack, account, enabled);
    }

    return store!.rules.changeRuleAccountStatus(item as Rule, account, enabled);
  };

  if (!item) return null;

  return (
    <Box>
      <Typography variant="main">Covered Environments</Typography>

      <Box display="flex" flexWrap="wrap" marginX={{ xs: -3, laptop: -5 }}>
        {relatedAccounts.map((a, index) => {
          const comment = getComment(a.name);

          return (
            <Checkbox
              key={a.id}
              label={
                <Typography variant="table" fontWeight={600}>
                  {a.name}
                  {comment && (
                    <>
                      {" "}
                      <Typography variant="table" component="span" fontWeight={400}>
                        {comment}
                      </Typography>
                    </>
                  )}
                </Typography>
              }
              checked={environments?.includes(a.name)}
              onChange={(val: boolean) => changeStatus(a, val)}
              formLabelSx={{
                boxSizing: "border-box",
                paddingY: 3,
                paddingX: { xs: 3, laptop: 5 },
                width: index === relatedAccounts.length - 1 && relatedAccounts.length % 2 ? 1 : 0.5,

                "&:nth-of-type(4n + 3)": {
                  background: theme.palette.mode === "light" ? "#F4F7FE" : "#1b1743",
                },

                "&:nth-of-type(4n + 4)": {
                  background: theme.palette.mode === "light" ? "#F4F7FE" : "#1b1743",
                },

                svg: {
                  marginRight: { xs: 2, laptop: 3 },
                },
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default inject("store")(observer(RulesEnvironments));
