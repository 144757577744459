import React, { ReactNode, Component } from "react";
import { Box } from "@mui/material";

type Props = { children: ReactNode };
type State = { error: { message: string } | null };

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: Error) {
    this.setState({ error });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (!error) return children;

    return (
      <Box textAlign="center" paddingY={10} color="#7c3626">
        Uncaught error: {error!.message}
      </Box>
    );
  }
}

export default ErrorBoundary;
