import React from "react";
import { Box, Typography } from "@mui/material";

import { PossibleJsonValue } from "components/ui/JsonViewer/jsonViewer";

type TagsItemProps = { property: string; value?: PossibleJsonValue; index: number; doNotShowEmptyValue?: boolean };

export const TagsItem = ({ property, value, index, doNotShowEmptyValue }: TagsItemProps) => {
  const colors = ["Blue", "Yellow", "Green"];
  const themeVariable = value === "" ? "tagInactive" : `tag${colors[index % colors.length]}`;

  return (
    <Box
      overflow="hidden"
      border="1px solid"
      borderColor={`various.${themeVariable}.border`}
      borderRadius={3}
      display="flex"
      color={`various.${themeVariable}.color`}
    >
      <Typography
        variant="table"
        color={!value ? `various.${themeVariable}.color` : `various.${themeVariable}.bgColor`}
        paddingY={1}
        paddingX={2}
        lineHeight={1.1}
      >
        {property}
      </Typography>

      {(!doNotShowEmptyValue || !!value) && (
        <Typography
          variant="main"
          color={`various.${themeVariable}.bgColor`}
          bgcolor={`various.${themeVariable}.bg`}
          padding={1}
          paddingX={2}
          lineHeight={1.1}
          minWidth={15}
          textAlign="center"
        >
          {value?.toString() || ""}
        </Typography>
      )}
    </Box>
  );
};
