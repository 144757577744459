import React, { ChangeEventHandler } from "react";
import { Box, Typography } from "@mui/material";

import {
  InputProps,
  Input,
  SelectList,
  Select,
  ButtonGroup,
  ButtonGroupItem,
  Textarea,
  DatePicker,
  Radio,
  Checkbox,
  Label,
} from "components/ui";

export type FormikInputValueTypes = {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

export type FormikItemType = InputProps & {
  property: string;
  fieldType?: string;
  list?: SelectList;
  items?: ButtonGroupItem[];
  checkboxesList?: Record<string, string>;
};

type FormFieldsBuilderType = {
  items: FormikItemType[];
  formik: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export const FormFieldsBuilder = ({ items, formik }: FormFieldsBuilderType) => (
  <Box data-testid="formFieldsBuilder">
    {items.map((item) => (
      <Box key={item.property} sx={{ marginBottom: 4, "&:last-child": { marginBottom: 0 } }}>
        {item.type === "select" && (
          <Select
            {...item}
            icon={undefined}
            type={undefined}
            list={item.list!}
            value={formik.values[item.property]}
            eventRawChange={formik.handleChange(item.property)}
          />
        )}

        {item.type === "date" && (
          <DatePicker
            {...item}
            dates={formik.values[item.property]}
            setDates={(date) => formik.setFieldValue(item.property, date)}
          />
        )}

        {item.type === "buttonGroup" && (
          <ButtonGroup
            {...item}
            items={item.items!}
            value={formik.values[item.property]}
            onChange={formik.handleChange(item.property)}
          />
        )}

        {item.type === "textarea" && (
          <Textarea
            {...item}
            value={formik.values[item.property]}
            onChange={formik.handleChange(item.property)}
            error={formik.touched[item.property] ? (formik.errors[item.property] as string) : ""}
          />
        )}

        {item.type === "checkboxes" && (
          <Box>
            {!!item.label && <Label text={item.label} />}

            <Box display="flex" flexWrap="wrap" gap={2.5}>
              {Object.entries(item.checkboxesList!).map(([name, prop]) => (
                <Checkbox
                  key={prop}
                  label={
                    <Typography variant="table" fontWeight={600}>
                      {name}
                    </Typography>
                  }
                  onChange={(val) =>
                    formik.setFieldValue(item.property, {
                      ...formik.values[item.property],
                      [prop]: val,
                    })
                  }
                  checked={!!formik.values[item.property][prop]}
                  formLabelSx={{
                    gap: 2,
                    width: `calc(${100 / 3}% - ${25 / 3}px)`,
                    span: {
                      fontWeight: 400,
                    },
                  }}
                />
              ))}
            </Box>
          </Box>
        )}

        {item.type === "radio" && (
          <Radio
            {...item}
            list={item.list!}
            value={formik.values[item.property]}
            onChange={formik.handleChange(item.property)}
            error={formik.touched[item.property] ? (formik.errors[item.property] as string) : ""}
          />
        )}

        {!["select", "buttonGroup", "textarea", "date", "radio", "checkboxes"].includes(item.type as string) && (
          <Input
            {...item}
            type={item.fieldType}
            value={formik.values[item.property]}
            onChange={formik.handleChange(item.property)}
            error={formik.touched[item.property] ? (formik.errors[item.property] as string) : ""}
          />
        )}
      </Box>
    ))}
  </Box>
);
