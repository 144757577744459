import React, { ElementType } from "react";
import { Box, Divider, Typography } from "@mui/material";

import { TableFields, AdditionalInfoTable, TableItemProps } from "components/ui";
import { getFieldValue } from "tools";
import { ACTION_FIELD_PROPERTY } from "consts";

type AdditionalInfoContentProps = {
  item?: TableItemProps;
  fields: TableFields[];
  groupedBy?: string[];
  groupComponent?: ElementType;
};

export const AdditionalInfoGroupContent = ({
  item,
  fields,
  groupedBy,
  groupComponent: GroupComponent,
}: AdditionalInfoContentProps) => {
  if (!item) return null;

  const tableFields = fields.filter(
    (f) => f.additionalInfo === "table" && (!f.ungrouped || !groupedBy || groupedBy.includes(f.property)),
  );
  const rowFields = fields.filter(
    (f) => f.additionalInfo === "row" && (!f.ungrouped || !groupedBy || groupedBy.includes(f.property)),
  );
  const ungroupedFields = fields.filter((f) => f.ungrouped && !groupedBy?.includes(f.property));
  const actionField = fields.find((f) => f.property === ACTION_FIELD_PROPERTY);

  return (
    <>
      {!!tableFields.length && (
        <Box marginBottom={3}>
          <AdditionalInfoTable item={item} fields={tableFields} />
        </Box>
      )}

      {rowFields.map((field) => (
        <Box marginBottom={3} key={field.property}>
          <Typography marginBottom={0.5} component="div" fontWeight={700} variant="main">
            {field.name}
          </Typography>
          <Typography component="div" variant="table">
            {getFieldValue({ field, item })}
          </Typography>
        </Box>
      ))}

      {!!item?.grouped?.length && (
        <Box marginTop={rowFields.length ? 5 : 0}>
          {item?.grouped?.map((groupedItem: TableItemProps) => (
            <Box key={groupedItem.id}>
              <Divider sx={{ marginY: 3 }} />
              <AdditionalInfoTable actionField={actionField} item={groupedItem} fields={ungroupedFields} />
              {!!GroupComponent && <GroupComponent item={groupedItem} />}
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};
