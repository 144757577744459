import React from "react";
import { Box, Typography } from "@mui/material";
import { inject, observer } from "mobx-react";

import { DashboardIssueBy, ISSUE_STATE_TYPE } from "store/types";
import { ByUserSvg, ByOpshelmSvg, ByExternalSvg } from "components/ui/IssueBy/components";
import { RootStore } from "store/root.store";

type IssueByProps = { store?: RootStore; state?: ISSUE_STATE_TYPE; userId?: string; type?: DashboardIssueBy };

const IssueByValue = ({ store, state, userId, type }: IssueByProps) => {
  if (state === ISSUE_STATE_TYPE.DELETED) {
    return <Box>Asset Deleted</Box>;
  }

  if (type === DashboardIssueBy.USER) {
    return (
      <>
        <ByUserSvg />
        {store?.users.getUserNameById(userId) || "User"}
      </>
    );
  }

  if (type === DashboardIssueBy.EXTERNALLY) {
    return (
      <>
        <ByExternalSvg />
        Externally
      </>
    );
  }

  return (
    <>
      <ByOpshelmSvg />
      Opshelm
    </>
  );
};

const IssueBy = ({ store, state, userId, type, justText }: IssueByProps & { justText?: boolean }) => {
  if (!userId && !type) return null;

  return (
    <Typography
      data-testid="issueBy"
      borderRadius={1}
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      variant="main"
      paddingY={justText ? 0.5 : 1}
      sx={{
        ...(!justText && {
          paddingX: 2.5,
          background: "#F2F3FA",
        }),
        svg: {
          marginRight: justText ? 1 : 1.5,
          ...(!store?.ui.isLightTheme && {
            path: {
              fill: "#F9F9F9",
            },
          }),
        },
      }}
    >
      <IssueByValue state={state} store={store} userId={userId} type={type} />
    </Typography>
  );
};

export default inject("store")(observer(IssueBy));
