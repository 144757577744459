import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Box, Typography } from "@mui/material";
import { useFormik } from "formik";

import { RootStore } from "store/root.store";
import { DashboardActionsIssuesType, DashboardIssue, IssueRemediation } from "store/types";
import { Button, ButtonWrapper, FormFieldsBuilder } from "components/ui";
// eslint-disable-next-line max-len
import { getIssueChangeStateSelect } from "routes/DashboardRoute/components/DashboardIssues/components/dashboardIssues.tools";

type FixIssueModalProps = { store?: RootStore; issues: DashboardIssue[]; type: DashboardActionsIssuesType };

const FixIssueModal = ({ store, issues, type }: FixIssueModalProps) => {
  const initialValues = { type };

  const [remediations, setRemediations] = useState<IssueRemediation[]>([]);

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => store?.dashboard.automaticallyFixIssues(issues, remediations[0].id, values.type),
  });

  useEffect(() => store?.dashboard.changeModalTitle(formik.values?.type), [store?.dashboard, formik.values?.type]);

  const items = getIssueChangeStateSelect(issues, type);

  useEffect(() => {
    store?.dashboard
      .getIssueRemediations(issues[0])
      .then(setRemediations)
      .catch(() => store?.ui.closeModal());
  }, [store?.dashboard, store?.ui, issues]);

  if (!remediations.length) {
    return (
      <>
        <Typography variant="main">OpsHelm is unable to automatically remediate this issue</Typography>

        <ButtonWrapper>
          <Button onClick={store?.ui.closeModal} text="Cancel" variant="text" />
        </ButtonWrapper>
      </>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box display="flex" gap={2} flexDirection="column">
        <Typography variant="title">{remediations[0].name}</Typography>

        <Typography variant="main">{remediations[0].description}</Typography>

        {!!items.length && <FormFieldsBuilder formik={formik} items={items} />}
      </Box>

      <ButtonWrapper>
        <>
          <Button onClick={store?.ui.closeModal} text="Cancel" variant="text" />
          <Button
            type="submit"
            text={`Fix issue${formik.values.type === DashboardActionsIssuesType.SINGLE ? "" : "s"}`}
          />
        </>
      </ButtonWrapper>
    </form>
  );
};

export default inject("store")(observer(FixIssueModal));
