import React, { MouseEvent } from "react";
import { Link } from "react-router-dom";
import { Box, Link as MuiLink } from "@mui/material";
import { inject, observer } from "mobx-react";

import { RootStore } from "store/root.store";
import { truncateValue } from "tools";

type UnderlineLinkProps = {
  store?: RootStore;
  to: string;
  text: string;
  underline?: boolean;
  separateSite?: boolean;
  blank?: boolean;
  breakWordsLine?: boolean;
  truncate?: boolean;
};

const UnderlineLink = ({
  store,
  to,
  text,
  underline,
  separateSite,
  blank,
  breakWordsLine,
  truncate,
}: UnderlineLinkProps) => (
  <Box
    data-testid="underlineLink"
    component={separateSite ? MuiLink : Link}
    href={to}
    to={to}
    color="primary.main"
    onClick={(e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();

      if (!separateSite) store?.ui.closeInfoItem();
    }}
    target={separateSite || blank ? "_blank" : undefined}
    sx={{
      textDecoration: underline ? "underline" : "none",
      "&:hover": { textDecoration: underline ? "none" : "underline" },
      ...(breakWordsLine && { wordBreak: "break-word" }),
    }}
  >
    {truncate ? truncateValue(text, true) : text}
  </Box>
);

export default inject("store")(observer(UnderlineLink));
