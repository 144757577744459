import React from "react";
import { inject, observer } from "mobx-react";
import { Box, Typography } from "@mui/material";

import { RootStore } from "store/root.store";
import { Button, Loading } from "components/ui";
import { RulesPackTableToggle } from "routes/RulesRoute/components";
import RulesEnvironments from "routes/RulesRoute/components/rulesEnvironments";

type RulesPackModalProps = {
  store?: RootStore;
  id: string;
};

const RulesPackModal = ({ store, id }: RulesPackModalProps) => {
  const item = store?.rules.data.find((a) => a.id === id);

  if (!item) return <Loading />;

  return (
    <Box display="flex" flexDirection="column" gap={5}>
      <Typography variant="main">
        Disabling this rules pack will not be active across all environments.
        <br />
        By selecting a checkbox next to a specific environment, you can leave the rules pack activate solely for that
        environment.
      </Typography>

      <RulesEnvironments rulePackItem={item} />

      <Box display="flex" alignItems="center" justifyContent="space-between">
        <RulesPackTableToggle item={item} />
        <Button onClick={store?.ui.closeModal} text="Cancel" variant="text" />
      </Box>
    </Box>
  );
};

export default inject("store")(observer(RulesPackModal));
