import React, { FormEvent, MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Check, Edit } from "@mui/icons-material";

import { Input, ProviderIcon, TableItemProps, Tooltip } from "components/ui";
import { Account, CLOUD_PLATFORM, ONBOARDING_STATUSES, OnBoardingDataAWS } from "store/types";
import { AccountsStore } from "store/accounts.store";
import { copyToClipboard } from "tools";

import { CopySvg, CopyToClipboard } from "./components";

const roleNameTooltip = "This is name of the role that OpsHelm will assume in your AWS account";
const opshelmRoleArnTooltip = "This role is controlled by OpsHelm and is used to access your AWS account";
const externalIdTooltip = "This is the ExternalID OpsHelm will use when assuming the integration role in your account";

type useTableDataProps = {
  accounts?: AccountsStore;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onboardingData: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setOnboardingData: (val: any) => void;
  accountData: Account;
  roleName: string;
  setRoleName: (val: string) => void;
};

export const useTableData = ({
  accounts,
  onboardingData,
  setOnboardingData,
  accountData,
  roleName,
  setRoleName,
}: useTableDataProps) => {
  const inputRoleNameRef = useRef<HTMLInputElement>(null);
  const [editRoleNameEditing, setRoleNameEditing] = useState(false);
  const [tableData, setTableData] = useState<TableItemProps[]>([]);

  useEffect(() => {
    if (!editRoleNameEditing) return;

    setTimeout(() => inputRoleNameRef?.current?.focus());
  }, [editRoleNameEditing]);

  const openRoleEditing = useCallback(() => {
    setRoleName(onboardingData?.role_name?.trim());
    setRoleNameEditing(true);
  }, [setRoleName, setRoleNameEditing, onboardingData]);

  const editRoleName = useCallback(
    async (e: FormEvent<HTMLFormElement> | MouseEvent<SVGSVGElement>) => {
      e?.preventDefault();

      if (roleName) {
        await accounts?.updateAccount(accountData, { aws_role_name: roleName.trim() });
      }

      setOnboardingData((prev: OnBoardingDataAWS) => ({
        ...prev,
        role_name: (roleName || prev.role_name)?.trim(),
      }));

      setRoleNameEditing(false);
    },
    [roleName, setOnboardingData, setRoleNameEditing, accountData, accounts],
  );

  useEffect(() => {
    const data = [
      {
        id: "account",
        property: "Account",
        value: <ProviderIcon name={accountData.name} provider={accountData.provider} />,
      },
      onboardingData?.role_name &&
        accountData.provider?.toUpperCase() === CLOUD_PLATFORM.AWS && {
          id: "role_name",
          property: (
            <Tooltip title={roleNameTooltip}>
              <Typography variant="main" fontWeight={700}>
                Role name
              </Typography>
            </Tooltip>
          ),
          value: (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                "& svg": { fill: (theme) => theme?.palette?.text?.secondary },
              }}
            >
              {editRoleNameEditing ? (
                <Box
                  component="form"
                  onSubmit={editRoleName}
                  display="flex"
                  alignItems="center"
                  gap={2}
                  marginY={-1.1}
                  marginRight={-5}
                >
                  <Box width="calc(100% - 55px)">
                    <Input
                      extraSmall
                      inputRef={inputRoleNameRef}
                      placeholder="Role name"
                      value={roleName}
                      onChange={setRoleName}
                      closeIconClick={() => setRoleNameEditing(false)}
                    />
                  </Box>

                  <Check onClick={editRoleName} sx={{ cursor: "pointer", fontSize: 16 }} />
                </Box>
              ) : (
                <>
                  <Tooltip title={roleNameTooltip}>{onboardingData?.role_name}</Tooltip>

                  <Box display="flex" gap={2} marginLeft={2} alignItems="center">
                    <Edit onClick={openRoleEditing} sx={{ cursor: "pointer", fontSize: 16 }} />

                    <Box
                      sx={{
                        cursor: "pointer",
                        svg: {
                          width: 15,
                        },
                      }}
                      onClick={() => copyToClipboard({ value: onboardingData?.role_name })}
                    >
                      <Tooltip title="Copy">
                        <Box display="flex">
                          <CopySvg />
                        </Box>
                      </Tooltip>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          ),
        },
      onboardingData?.opshelm_role_arn &&
        accountData.provider?.toUpperCase() === CLOUD_PLATFORM.AWS && {
          id: "opshelm_role_arn",
          property: (
            <Tooltip title={opshelmRoleArnTooltip}>
              <Typography variant="main" fontWeight={700}>
                OpsHelm Role ARN
              </Typography>
            </Tooltip>
          ),
          value: <CopyToClipboard value={onboardingData?.opshelm_role_arn as string} tooltip={opshelmRoleArnTooltip} />,
        },
      onboardingData?.aws_external_id &&
        accountData.provider?.toUpperCase() === CLOUD_PLATFORM.AWS && {
          id: "aws_external_id",
          property: (
            <Tooltip title={externalIdTooltip}>
              <Typography variant="main" fontWeight={700}>
                External ID
              </Typography>
            </Tooltip>
          ),
          value: <CopyToClipboard value={onboardingData?.aws_external_id as string} tooltip={externalIdTooltip} />,
        },
      onboardingData?.service_account_name &&
        accountData.provider?.toUpperCase() === CLOUD_PLATFORM.GCP && {
          id: "service_account_name",
          property: "Service Account",
          value: <CopyToClipboard value={onboardingData?.service_account_name as string} />,
        },
      {
        id: "accountStatus",
        property: "Status",
        value: (
          <Typography
            variant="main"
            fontWeight={700}
            color={
              onboardingData?.status === ONBOARDING_STATUSES.COMPLETED
                ? "success.main"
                : onboardingData?.status === ONBOARDING_STATUSES.FAILED
                ? "error.main"
                : "text.secondaryDark"
            }
          >
            {onboardingData?.status === ONBOARDING_STATUSES.COMPLETED ? "Completed!" : onboardingData?.description}
          </Typography>
        ),
      },
    ];

    setTableData(data.filter((i) => i) as TableItemProps[]);
  }, [onboardingData, accountData, editRoleName, editRoleNameEditing, roleName, openRoleEditing, setRoleName]);

  return tableData;
};
