import React, { MouseEvent } from "react";
import { Box } from "@mui/material";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";

import { RootStore } from "store/root.store";
import { LogoLightMobileSvg, LogoDarkMobileSvg, MenuViewSvg } from "components/layouts/LoggedInFlow/components";

const MobileHeader = ({ store }: { store?: RootStore }) => {
  const changeMobileMenuOpened = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    store?.ui.changeMobileMenuOpened(!store?.ui.mobileMenuOpened);
  };

  return (
    <Box
      display={{ xs: "flex", laptop: "none" }}
      alignItems="center"
      zIndex={10}
      position="fixed"
      width={1}
      height={64}
      borderBottom="1px solid"
      borderColor="various.defaultBorder"
      bgcolor="background.paperBGSecond"
      paddingLeft={3}
    >
      <Link to="/">
        <Box paddingLeft={2} display="flex">
          {store?.ui.isLightTheme ? (
            <LogoLightMobileSvg data-testid="logoLightSmallMobile" width={28} />
          ) : (
            <LogoDarkMobileSvg data-testid="logoDarkSmallMobile" width={28} />
          )}
        </Box>
      </Link>
      <Box
        marginLeft={8}
        position="relative"
        top={store?.ui.mobileMenuOpened ? "-3px" : 0}
        onClick={changeMobileMenuOpened}
        sx={{
          cursor: "pointer",
          transform: `rotate(${store?.ui.mobileMenuOpened ? "180deg" : "0deg"})`,
          svg: { fill: store?.ui?.isLightTheme ? "#4141F2" : "#5CC9FA", width: 8, height: 16 },
        }}
      >
        <MenuViewSvg width={6} />
      </Box>
    </Box>
  );
};

export default inject("store")(observer(MobileHeader));
