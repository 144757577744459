import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Box, Typography, useTheme } from "@mui/material";
import { inject, observer } from "mobx-react";

import { formattedYearDate } from "tools";
import { RootStore } from "store/root.store";
import { Loading } from "components/ui/Loading/loading";
import { darkTextPrimaryColor, lightTextSecondaryColor } from "theme/commonVariables";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const getOptions = (isLight: boolean): ChartOptions<"bar"> => {
  const color = isLight ? lightTextSecondaryColor : darkTextPrimaryColor;
  const grid = isLight
    ? {}
    : {
        color: "#8F94C3",
      };

  return {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          beforeBody: ([{ raw }]) => `Count of records: ${raw}`,
          label: () => "",
        },
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          precision: 0,
          color,
        },
        grid: {
          display: false,
          ...grid,
        },
      },
      x: {
        ticks: {
          color,
        },
        grid: {
          display: false,
          ...grid,
        },
      },
    },
  };
};

export type BarChartProps = {
  store?: RootStore;
  labels: string[];
  data: number[];
  isDate?: boolean;
  isEqual?: boolean;
  first?: string;
  last?: string;
  isLoading?: boolean;
};

const BarChart = ({ store, labels, data, isDate, isEqual, first, last, isLoading }: BarChartProps) => {
  const theme = useTheme();
  const parentRef = useRef<HTMLInputElement>(null);
  const chartRef = useRef<ChartJS<"bar">>(null);

  const [resizeTime, setResizeTime] = useState(Date.now());

  useLayoutEffect(() => {
    const node = chartRef.current?.canvas?.parentNode as HTMLElement;

    if (!node?.style || !parentRef.current) return;

    node.style.width = "0";
    node.style.width = `${parentRef.current.clientWidth}px`;
  }, [store?.ui.infoItem, store?.ui.smallView, store?.widgets.cursors, resizeTime]);

  useEffect(() => {
    const updateSize = () => setTimeout(() => setResizeTime(Date.now()));

    window.addEventListener("resize", updateSize);

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const chartData = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: "#73B8FF",
        hoverBackgroundColor: "#1F90FF",
      },
    ],
  };

  if (isLoading) return <Loading marginTop={10} marginBottom={10} />;

  return (
    <Box ref={parentRef}>
      <Box position="relative">
        <Bar ref={chartRef} options={getOptions(theme.palette.mode === "light")} data={chartData} />
      </Box>

      {isDate && (
        <Typography marginTop={3} variant="main" fontWeight={700} component="div" textAlign="center">
          {isEqual ? formattedYearDate(first!) : `${formattedYearDate(first!)} - ${formattedYearDate(last!)}`}
        </Typography>
      )}
    </Box>
  );
};

export default inject("store")(observer(BarChart));
