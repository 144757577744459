import React from "react";
import { inject, observer } from "mobx-react";
import { Box } from "@mui/material";

import { RootStore } from "store/root.store";
import { FEATURE } from "store/types";
import { UnderlineLink } from "components/ui";
import { getInventoryResourceLink } from "tools/utils";

type InventoryByResourceIdLinkProps = {
  store?: RootStore;
  showLink: boolean;
  accountId: string;
  resourceId: string;
  uniqueResourceIdsLength?: number;
};

const InventoryByResourceIdLink = ({
  store,
  showLink,
  accountId,
  resourceId,
  uniqueResourceIdsLength = 0,
}: InventoryByResourceIdLinkProps) => {
  const isInventoryEnabled = store?.auth.isFeatureEnabled(FEATURE.INVENTORY_PAGE);
  const link = getInventoryResourceLink({ accountId, resourceId });

  if (showLink && isInventoryEnabled) {
    if (uniqueResourceIdsLength > 1) {
      return (
        <Box>
          <UnderlineLink truncate breakWordsLine underline to={link} text={resourceId} />{" "}
          <b>(+{uniqueResourceIdsLength - 1})</b>
        </Box>
      );
    }

    return <UnderlineLink truncate breakWordsLine underline to={link} text={resourceId} />;
  }

  if (uniqueResourceIdsLength > 1) {
    return (
      <Box sx={{ wordBreak: "break-word" }}>
        {resourceId} <b>(+{uniqueResourceIdsLength - 1})</b>
      </Box>
    );
  }

  return <Box sx={{ wordBreak: "break-word" }}>{resourceId}</Box>;
};

export default inject("store")(observer(InventoryByResourceIdLink));
