import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

export default () =>
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing({ tracingOrigins: ["*"] }), new Sentry.Replay()],
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
