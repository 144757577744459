import React, { ReactElement } from "react";
import { Box } from "@mui/material";

type IconTextProps = { icon: ReactElement; text?: string };

export const IconText = ({ icon, text }: IconTextProps) => (
  <Box data-testid="iconText" display="flex" alignItems="center" gap={1.5}>
    {icon || ""}
    {text || ""}
  </Box>
);
