import { MultiSelectListItem } from "components/ui";
import { SavedWidgetSettings, WidgetsSettings } from "store/types/widgets.store.types";
import { MultipleDashboard } from "store/types/multipleDashboards.store.types";

export enum FILTER_LOGICAL_CONDITION {
  POSITIVE = "",
  NEGATIVE = "NOT",
}

export enum FILTER_PAGES {
  EVENTS = "events",
  INVENTORY = "inventory",
  INVENTORY_DETAILS = "inventoryDetails",
  WIDGET_SETTINGS = "widgetSettings",
  DATA_STATS = "dataStats",
  DASHBOARD = "dashboard",
}

export enum FILTER_DATE_TYPES {
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  THIS_WEEK = "THIS_WEEK",
  LAST_WEEK = "LAST_WEEK",
  THIS_MONTH = "THIS_MONTH",
  LAST_MONTH = "LAST_MONTH",
  DAYS_RANGE = "DAYS_RANGE",
  HOURS_BACK = "HOURS_BACK",
}

export enum FILTER_OPERATOR {
  AND = "AND",
  OR = "OR",
}

export enum FILTER_CONDITION {
  IS = "is",
  IS_NOT = "is not",
  IS_ONE_OF = "is one of",
  IS_NOT_ONE_OF = "is not one of",
  EXISTS = "exists",
  DOES_NOT_EXIST = "does not exist",
}

export enum FILTER_CONDITION_TYPE {
  EQUAL,
  LIST,
  BOOLEAN,
}

export type FilterConditionKey = keyof typeof FILTER_CONDITION;

export type FilterConditionElement = {
  condition: FilterConditionKey;
  type: FILTER_CONDITION_TYPE;
};

export type FilterConditions = FilterConditionElement[];

export type FilterItem = {
  field: MultiSelectListItem | string;
  value: MultiSelectListItem | string;
  values: Array<MultiSelectListItem | string>;
  condition: FilterConditionKey;
};

// eslint-disable-next-line no-use-before-define
export type FilterDataItem = FilterData | FilterItem;

export type FilterDataDates = {
  type: FILTER_DATE_TYPES;
  values?: number[];
  field?: string;
  condition?: FilterConditionKey;
};

export type FilterData = {
  dates?: FilterDataDates;
  type?: FILTER_PAGES;
  operator: FILTER_OPERATOR;
  items: FilterDataItem[];
};

export type ChangeFilter = (value: FilterDataItem) => void;

export type FilterSaveData = {
  name: string;
  type: FILTER_PAGES;
  filter: FilterDataItem | SavedWidgetSettings | MultipleDashboard;
};

export type SavedFilter = FilterSaveData & {
  id: string;
  created_at: string;
};

export type FilterEntity = Record<string, MultiSelectListItem[]>;

export type RefreshSavedWidgetsFilters = (data: {
  widgets: Partial<WidgetsSettings>[];
  remove?: boolean;
  init?: boolean;
}) => void;

export enum DB_FILTER_CONDITION_TYPE {
  INCLUDE = "include",
  EXCLUDE = "exclude",
}

export enum DATA_STATS {
  Count = "COUNT",
  Sum = "SUM",
  Max = "MAX",
  Min = "MIN",
  "Group By" = "GROUPBY",
}

export type DataStatsItem = {
  field: string;
  operator: DATA_STATS;
  name: string;
};

export type DataStatsResponseItem = Record<string, string | number>;
