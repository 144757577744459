import React from "react";
import { Box, Typography } from "@mui/material";

import { CheckboxListItem } from "components/ui/CheckboxListItem/checkboxListItem";

export type StepProgressItem = {
  title: string;
  subItems?: string[];
};

type StepsProgressProps = {
  currentStep: number;
  items: StepProgressItem[];
  steps: string[][];
  stepId?: string;
};

export const StepsProgress = ({ items, steps, stepId, currentStep }: StepsProgressProps) => {
  const getSubItemIndex = () => {
    const index = steps.findIndex((el) => el.includes(stepId!));

    return index === -1 ? 0 : index;
  };

  return (
    <Box
      display={{ xs: "none", laptop: "flex" }}
      flexDirection="column"
      width={300}
      minWidth={300}
      marginRight={5}
      borderRadius={1}
      padding={3}
      bgcolor="background.tableRow"
    >
      {items.map((item, index) => (
        <Box key={item.title} marginBottom={4}>
          <Box display="flex" alignItems="center" gap={2}>
            <CheckboxListItem currentStep={currentStep} index={index} />

            <Typography
              variant="main"
              fontWeight={currentStep > index ? 700 : 600}
              color={currentStep > index ? "text.primary" : "various.inactiveColor"}
            >
              {item.title}
            </Typography>
          </Box>

          {item?.subItems?.map((subItem, subItemIndex) => (
            <Typography
              variant="main"
              key={subItem}
              marginLeft={7}
              marginTop={2}
              fontSize={12}
              display="flex"
              alignItems="center"
              color="various.inactiveColor"
              fontWeight={600}
              sx={{
                ...((currentStep > index + 1 || (currentStep === index + 1 && getSubItemIndex() >= subItemIndex)) && {
                  color: "text.primary",
                  fontWeight: 700,
                }),
              }}
            >
              <Box
                width={8}
                height={8}
                borderRadius="100%"
                marginRight="8px"
                bgcolor={
                  currentStep > index + 1 || (currentStep === index + 1 && getSubItemIndex() > subItemIndex)
                    ? "primary.main"
                    : "various.inactiveColor"
                }
              />
              {subItem}
            </Typography>
          ))}
        </Box>
      ))}
    </Box>
  );
};
