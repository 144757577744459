import React, { FormEvent, useState } from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Box, Typography } from "@mui/material";

import { API_ACCEPT_INVITE, API_BASE_URL } from "libs/api/endpoints";
import { Button, Input } from "components/ui";
import { SIGNUP_ROUTE } from "consts";
import { RootStore } from "store/root.store";

const InviteCodePrompt = ({ store }: { store?: RootStore }) => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    if (code) return;

    setError("Invitation Code is required");
    e.preventDefault();
  };

  const onChange = (newCode: string) => {
    setError("");
    setCode(newCode);
  };

  return (
    <Box>
      <Typography sx={{ typography: { xs: "h1", laptop: "windowTitle" } }} component="div" marginBottom={5}>
        Please enter your invitation code
      </Typography>

      <form onSubmit={onSubmit} method="POST" action={`${API_BASE_URL}${API_ACCEPT_INVITE}`}>
        <Input value={code} onChange={onChange} placeholder="Invitation Code" name="invite_code" error={error} />

        <Box display="flex" justifyContent="space-between" flexWrap="wrap" gap={5} marginTop={5}>
          <Button
            disabled={store?.auth.joinedToWaitlist}
            onClick={store?.auth.joinWaitlist}
            text="Join the Waitlist"
            buttonSx={{ width: { xs: 1, laptopLgx: "auto" } }}
          />

          <Box display="flex" gap={5} justifyContent="space-between" width={{ xs: 1, laptopLgx: "auto" }}>
            <Box component={Link} to={SIGNUP_ROUTE}>
              <Button variant="text" text="Back" />
            </Box>

            <Button text="Continue" type="submit" />
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default inject("store")(observer(InviteCodePrompt));
