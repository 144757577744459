import { makeAutoObservable } from "mobx";

import { RootStore } from "store/root.store";
import {
  fetchCostIntegrations,
  addAwsCostIntegration,
  updateAwsIntegration,
  removeCostIntegration,
  getAwsCostIntegrationStatus,
  updateGcpIntegration,
  addGcpCostIntegration,
} from "libs/api/methods";
import {
  CLOUD_PLATFORM,
  FEATURE,
  AddAwsCostIntegration,
  AwsCostIntegration,
  AddGcpCostIntegration,
  AddCostIntegration,
  CostIntegration,
} from "store/types";

export class CostReportStore {
  integrations: CostIntegration[] = [];

  constructor(readonly rootStore: RootStore) {
    this.rootStore = rootStore;

    this.fetchIntegrations = this.fetchIntegrations.bind(this);

    makeAutoObservable(this);
  }

  get awsIntegrations() {
    const awsAccountsIds = this.rootStore.accounts.activeAccounts
      .filter((i) => i.provider?.toUpperCase() === CLOUD_PLATFORM.AWS)
      .map((a) => a.id);

    return this.integrations.filter((i) => awsAccountsIds.includes(i.cloud_account_id));
  }

  get gcpIntegrations() {
    const gcpAccountIds = this.rootStore.accounts.activeAccounts
      .filter((i) => i.provider?.toUpperCase() === CLOUD_PLATFORM.GCP)
      .map((a) => a.id);

    return this.integrations.filter((i) => gcpAccountIds.includes(i.cloud_account_id));
  }

  get notAddedAwsCostAccounts() {
    return this.rootStore.accounts.activeAccounts.filter(
      (i) =>
        i.provider?.toUpperCase() === CLOUD_PLATFORM.AWS &&
        !this.awsIntegrations.find((a) => a.cloud_account_id === i.id),
    );
  }

  get notAddedGcpCostAccounts() {
    return this.rootStore.accounts.activeAccounts.filter(
      (i) =>
        i.provider?.toUpperCase() === CLOUD_PLATFORM.GCP &&
        !this.gcpIntegrations.find((a) => a.cloud_account_id === i.id),
    );
  }

  async fetchIntegrations() {
    try {
      if (
        this.rootStore.auth.isFeatureEnabled(FEATURE.INTEGRATIONS_AWS_COST) ||
        this.rootStore.auth.isFeatureEnabled(FEATURE.INTEGRATIONS_GCP_COST)
      ) {
        this.integrations = await fetchCostIntegrations();
      }
    } catch {}
  }

  async updateIntegration(platform: CLOUD_PLATFORM, data: AddCostIntegration) {
    if (platform === CLOUD_PLATFORM.AWS) {
      await updateAwsIntegration(data as AddAwsCostIntegration);
    } else {
      await updateGcpIntegration(data as AddGcpCostIntegration);
    }

    this.integrations = this.integrations.map((integration) => {
      if (integration.cloud_account_id !== data.cloud_account_id) return integration;

      return { ...integration, ...data };
    });
  }

  async addCostIntegration(platform: CLOUD_PLATFORM, data: AddCostIntegration) {
    if (platform === CLOUD_PLATFORM.AWS) {
      await addAwsCostIntegration(data as AddAwsCostIntegration);
    } else {
      await addGcpCostIntegration(data as AddGcpCostIntegration);
    }

    await this.fetchIntegrations();
  }

  async removeCostIntegration(integration: AddCostIntegration) {
    await removeCostIntegration(integration.cloud_account_id);

    this.integrations = this.integrations.filter((i) => i.cloud_account_id !== integration.cloud_account_id);
  }

  async getAwsCostIntegrationStatus(accountId: string): Promise<AwsCostIntegration> {
    return getAwsCostIntegrationStatus(accountId);
  }
}
