import React, { ReactElement } from "react";
import { Box } from "@mui/material";

import {
  JsonSearchActions,
  JsonSearchActionsProps,
} from "components/ui/JsonViewer/components/JsonSearchActions/jsonSearchActions";
import { SMALL_HEIGHT } from "consts";
import { JsonRow } from "components/ui/JsonViewer/jsonViewer";

export const JsonViewSearchActions = ({
  leftOffset,
  row,
  ...props
}: JsonSearchActionsProps & { leftOffset: number; row: JsonRow }): ReactElement => (
  <Box
    overflow="hidden"
    border="1px solid"
    borderColor="various.defaultBorder"
    borderRadius={1}
    bgcolor="various.jsonViewButtons"
    position="absolute"
    top={-SMALL_HEIGHT / (+(row?.number || 0) < 3 ? 2 : 1)}
    left={leftOffset}
    zIndex={1}
  >
    <JsonSearchActions {...props} />
  </Box>
);
