import React from "react";
import { inject, observer } from "mobx-react";
import { Box } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";

import { RootStore } from "store/root.store";
import { Button, ButtonWrapper, FormFieldsBuilder } from "components/ui";

const validationSchema = yup.object({
  email: yup.string().required("Email is required").email("Email is not valid"),
});

const InviteUserModal = ({ store }: { store?: RootStore }) => {
  const initialValues = {
    email: "",
    roleId: store?.data.roles[0]?.id || 1,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => store?.users.inviteUser({ ...values, roleId: +values.roleId }),
  });

  const items = [
    {
      property: "email",
      label: "User email",
    },
    {
      property: "roleId",
      label: "Role",
      list: store!.data.roles.map((item) => ({ id: +item.id, name: item.role })),
      type: "radio",
      fullWidth: true,
    },
  ];

  return (
    <Box data-testid="inviteUserModal">
      <form onSubmit={formik.handleSubmit}>
        <FormFieldsBuilder formik={formik} items={items} />

        <ButtonWrapper>
          <>
            <Button onClick={store?.ui.closeModal} text="Cancel" variant="text" />
            <Button type="submit" text="Invite" />
          </>
        </ButtonWrapper>
      </form>
    </Box>
  );
};

export default inject("store")(observer(InviteUserModal));
