import React, { ChangeEvent, ReactElement, useRef } from "react";
import { inject, observer } from "mobx-react";
import { Box, FormControlLabel, Switch, Typography, useTheme } from "@mui/material";
import { useDrag, useDrop } from "react-dnd";

import {
  FILTER_PAGES,
  WIDGET_FILTER_TYPES,
  WIDGET_MANAGER_VIEWS,
  DashboardStats,
  RefreshSavedWidgetsFilters,
  Widget,
} from "store/types";
import { EventsBarChart } from "components/containters";
import {
  AccountsConnectedSvg,
  EventsSvg,
  InventorySvg,
  OpenIssuesSvg,
  ResolvedIssuesSvg,
  RulesEnabledSvg,
} from "routes/DashboardRoute/components";
import { RootStore } from "store/root.store";
import DashboardIssuesTables from "routes/DashboardRoute/components/DashboardIssuesTables/dashboardIssuesTables";
import {
  DashboardCounterItem,
  DashboardViewItemCustomTitle,
} from "routes/DashboardRoute/components/DashboardView/components";
import { ElementWrapper, ElementWrapperWithActions, WidgetFilterActions } from "components/ui";
import { InventoryTable } from "routes/InventoryRoute/components";
import { EventDetailsTable } from "routes/EventsRoute/components";
import { getWidgetCounterWidth } from "routes/DashboardRoute/dashboard.utils";

import DashboardPieChartItem from "../DashboardPieChartItem/dashboardPieChartItem";

export type DashboardStatsProperties = {
  title: string;
  prop?: string;
  icon: ReactElement;
  svgSize?: number;
};

const statsProperties: Record<string, DashboardStatsProperties> = {
  open_issues: {
    title: "Open Issues",
    icon: <OpenIssuesSvg />,
  },
  resolved_issues: {
    title: "Resolved Issues",
    icon: <ResolvedIssuesSvg />,
  },
  accounts_connected: {
    title: "Accounts Connected",
    icon: <AccountsConnectedSvg />,
  },
  rules_enabled: {
    title: "Rules Enabled",
    icon: <RulesEnabledSvg />,
  },
};

const getCounterItem = (widget: Widget) =>
  statsProperties[widget.id] ||
  (widget.type?.trim() === FILTER_PAGES.EVENTS
    ? {
        title: widget.name!,
        svgSize: 28,
        icon: <EventsSvg />,
      }
    : {
        title: widget.name!,
        svgSize: 25,
        icon: <InventorySvg />,
      });

type DashboardViewItemType = {
  store?: RootStore;
  widget: Widget;
  refreshSavedWidgetsFilters: RefreshSavedWidgetsFilters;
  editMode?: WIDGET_MANAGER_VIEWS;
  changeWidgetStatus?: (id: string, disabled: boolean) => void;
  isTableWide: boolean;
  countAfter: number;
  reOrder: (a: Widget, b: Widget) => void;
  index: number;
};

const DashboardViewItem = ({
  store,
  widget,
  isTableWide,
  countAfter,
  refreshSavedWidgetsFilters,
  changeWidgetStatus,
  editMode,
  reOrder,
  index,
}: DashboardViewItemType) => {
  const theme = useTheme();

  const { dashboard, widgets } = store!;

  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: FILTER_PAGES.WIDGET_SETTINGS,
    item: widget,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [, drop] = useDrop({
    accept: FILTER_PAGES.WIDGET_SETTINGS,
    hover(item: Widget, monitor) {
      if (!ref.current || item.order === widget.order) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset ? clientOffset.y : 0) - hoverBoundingRect.top;

      if (item.order < widget.order && hoverClientY < hoverMiddleY) return;

      if (item.order > widget.order && hoverClientY > hoverMiddleY) return;

      reOrder(item, widget);

      // eslint-disable-next-line no-param-reassign
      item.order = widget.order;
    },
  });

  drag(drop(ref));

  const opacity = isDragging ? 0.5 : 1;

  if (editMode === WIDGET_MANAGER_VIEWS.LIST) {
    return (
      <Box
        ref={ref}
        width={{
          xs: 1,
          laptopLg: widget.widgetType === WIDGET_FILTER_TYPES.COUNTER ? getWidgetCounterWidth(theme, countAfter) : 1,
          opacity,
        }}
        display="flex"
      >
        <ElementWrapper>
          <Box display="flex" alignItems="center" justifyContent="space-between" width={1} height={1} gap={1}>
            <FormControlLabel
              control={
                <Switch
                  checked={!widget.disabled}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    changeWidgetStatus?.(widget.id, !event.target.checked)
                  }
                />
              }
              label={
                <Typography
                  marginLeft={2}
                  variant="main"
                  sx={{
                    wordBreak: "break-word",
                  }}
                >
                  {widget.name}
                  {!!widget.comment && (
                    <>
                      {" "}
                      <Typography component="div" variant="table" fontWeight={600} color="various.inactiveColor">
                        ({widget.comment})
                      </Typography>
                    </>
                  )}
                </Typography>
              }
            />

            <WidgetFilterActions refreshSavedWidgetsFilters={refreshSavedWidgetsFilters} widget={widget} />
          </Box>
        </ElementWrapper>
      </Box>
    );
  }

  if (widget.widgetType === WIDGET_FILTER_TYPES.COUNTER) {
    return (
      <Box
        ref={editMode ? ref : undefined}
        width={{
          xs: 1,
          laptopLg: getWidgetCounterWidth(theme, countAfter),
        }}
        display="flex"
      >
        <DashboardCounterItem
          actions={
            editMode ? (
              <DashboardViewItemCustomTitle
                refreshSavedWidgetsFilters={refreshSavedWidgetsFilters}
                widget={widget}
                editMode={editMode}
                changeWidgetStatus={changeWidgetStatus}
              />
            ) : undefined
          }
          index={index}
          editMode={editMode}
          request={widget.countRequest}
          item={getCounterItem(widget)}
          value={widget.type ? widget.count : dashboard.stats?.[widget.id as keyof DashboardStats]}
        />
      </Box>
    );
  }

  if (widget.widgetType === WIDGET_FILTER_TYPES.ISSUES_TABLE) {
    return (
      <Box ref={editMode ? ref : undefined} width={1} display="flex">
        <DashboardIssuesTables
          actions={
            editMode ? (
              <DashboardViewItemCustomTitle
                refreshSavedWidgetsFilters={refreshSavedWidgetsFilters}
                widget={widget}
                editMode={editMode}
                changeWidgetStatus={changeWidgetStatus}
              />
            ) : undefined
          }
        />
      </Box>
    );
  }

  if (widget.widgetType === WIDGET_FILTER_TYPES.PIE_CHART) {
    return (
      <Box ref={editMode ? ref : undefined} width={1} display="flex">
        <DashboardPieChartItem
          widget={widget}
          editModeCustomTitleElement={
            editMode ? (
              <DashboardViewItemCustomTitle
                refreshSavedWidgetsFilters={refreshSavedWidgetsFilters}
                widget={widget}
                editMode={editMode}
                changeWidgetStatus={changeWidgetStatus}
              />
            ) : undefined
          }
        />
      </Box>
    );
  }

  if (!widget.type) {
    return (
      <Box ref={editMode ? ref : undefined} width={1} display="flex">
        <EventsBarChart
          editModeCustomTitleElement={
            editMode ? (
              <DashboardViewItemCustomTitle
                refreshSavedWidgetsFilters={refreshSavedWidgetsFilters}
                widget={widget}
                editMode={editMode}
                changeWidgetStatus={changeWidgetStatus}
              />
            ) : undefined
          }
          doNotShowDates={!isTableWide}
          showFilters
        />
      </Box>
    );
  }

  return (
    <Box ref={editMode ? ref : undefined} width={1} display="flex">
      <ElementWrapperWithActions
        title={
          <Box display="flex" justifyContent="space-between" alignItems="center" gap={5}>
            {widget.name}

            {!!editMode && (
              <DashboardViewItemCustomTitle
                refreshSavedWidgetsFilters={refreshSavedWidgetsFilters}
                widget={widget}
                editMode={editMode}
                changeWidgetStatus={changeWidgetStatus}
              />
            )}
          </Box>
        }
        hideActions={!!editMode}
        search={!editMode}
        component={widget.type?.trim() === FILTER_PAGES.INVENTORY ? InventoryTable : EventDetailsTable}
        apiSort={widgets.cursors[widget.id]?.handler?.apiSort}
        data={widgets.cursors[widget.id]?.data}
        cursorData={widgets.cursors[widget.id]}
        isLoading={!widgets.cursors[widget.id] || widgets.cursors[widget.id]?.isFetching}
        componentProps={{
          widgetId: widget.id,
        }}
      />
    </Box>
  );
};

export default inject("store")(observer(DashboardViewItem));
