import React from "react";
import { Box } from "@mui/material";

import { JsonHidedProperties, SetHidedPropertiesHandler } from "components/ui";
import { getHidedPropertiesByNumbers } from "components/ui/JsonViewer/jsonViewer.utils";
import { OPENING_ROWS_COUNT } from "components/ui/JsonViewer/consts";
import { UpSvg, DownSvg, BothSvg } from "components/ui/JsonViewer/components/JsonExpand/svg";
import store from "store";

export type JsonExpandProps = {
  hidedProperties: JsonHidedProperties;
  number: number;
  setHidedProperties: SetHidedPropertiesHandler;
};

const Element = ({
  up,
  down,
  both,
  number,
  setHidedProperties,
}: {
  up?: boolean;
  down?: boolean;
  both?: boolean;
} & Omit<JsonExpandProps, "hidedProperties">) => (
  <Box
    onClick={() => setHidedProperties(number)}
    height={both ? 42 : 36}
    display="flex"
    alignItems="center"
    paddingLeft={2.5}
    sx={{
      cursor: "pointer",
      "&:hover": {
        backgroundColor: number ? "background.infoItemTableRow" : store.ui.isLightTheme ? "#F4F7FE" : "#1b1743",
      },
    }}
  >
    <Box
      bgcolor="background.paper"
      borderRadius={1}
      border="1px solid"
      borderColor="various.defaultBorder"
      display="flex"
      alignItems="center"
      justifyContent="center"
      paddingX={1}
      sx={{
        svg: {
          height: both ? 30 : 24,
          width: 20,
          ...(!store.ui.isLightTheme && {
            circle: {
              fill: "#fff",
            },
            path: {
              fill: "#fff",
            },
          }),
        },
      }}
    >
      {up && <UpSvg />}
      {down && <DownSvg />}
      {both && <BothSvg />}
    </Box>
  </Box>
);

export const JsonExpand = ({ hidedProperties, number, ...props }: JsonExpandProps) => {
  const hidedPropertiesByNumbers = getHidedPropertiesByNumbers(hidedProperties);

  if (!hidedPropertiesByNumbers[number]) return null;

  const rowsCount = new Array(OPENING_ROWS_COUNT).fill(0).flatMap((_, i) => i + 1);

  if (!hidedPropertiesByNumbers[number - 1]) {
    if (!rowsCount.every((i) => hidedPropertiesByNumbers[number + i])) {
      return <Element number={number} both {...props} />;
    }

    return <Element number={number} down {...props} />;
  }

  if (!hidedPropertiesByNumbers[number + 1] && rowsCount.every((i) => hidedPropertiesByNumbers[number - i])) {
    return <Element number={number} up {...props} />;
  }

  return null;
};
