import React from "react";
import { Box, Typography } from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export type PieChartItem = {
  title: string;
  value: number;
  color?: string;
};

type PieChartProps = {
  title: string;
  items: PieChartItem[];
};

const options: ChartOptions<"doughnut"> = {
  responsive: true,
  cutout: 70,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        beforeBody: ([{ raw }]) => raw?.toString(),
        label: () => "",
      },
    },
  },
};

export const PieChart = ({ title, items }: PieChartProps) => {
  if (!items.length) {
    return (
      <Typography marginBottom={5} textAlign="center" component="div" fontSize={18} variant="main">
        No data
      </Typography>
    );
  }

  const data = {
    datasets: [
      {
        data: items.map((i) => i.value),
        backgroundColor: items.map((i) => i.color),
        borderWidth: 0,
      },
    ],
  };

  const total = items.reduce((sum: number, i) => sum + i.value, 0);

  return (
    <Box display="flex" alignItems="center" gap={5}>
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height={175}
        width={175}
        marginRight={5}
        flexShrink={0}
      >
        <Box position="absolute" top={0} left={0} width={1} height={1}>
          <Doughnut data={data} options={options} />
        </Box>

        <Typography variant="h2">{total}</Typography>
        <Typography variant="main">{title}</Typography>
      </Box>

      <Box display="flex" gap={5} flexDirection="row" flexWrap="wrap">
        {items.map((item) => (
          <Box key={item.title} display="flex" gap={1.25} alignItems="center">
            <Box marginRight={1} borderRadius={1} bgcolor={item.color} height={10} width={10} />

            <Typography variant="main" fontWeight={700}>
              {item.value}
            </Typography>
            <Typography variant="main">{item.title}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
