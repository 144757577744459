import React, { useEffect, useState } from "react";

import { CLOUD_PLATFORM } from "store/types";
import { StepsProgress } from "components/ui";
import { INITIALIZING_STEP } from "consts";

type AddAccountProgressListProps = {
  currentStep: number;
  provider?: CLOUD_PLATFORM;
  stepId?: string;
};

const gspSteps = [
  [INITIALIZING_STEP],
  ["PROVISIONING_INGEST", "PROVISIONING_SERVICE_ACCOUNT"],
  ["AWAITING_AUTH"],
  ["LOADING_ACCOUNT_CONFIG", "ENABLE_LOGGING"],
];

const awsSteps = [
  [INITIALIZING_STEP],
  ["PROVISIONING_INGEST", "PROVISIONING_ROLE"],
  ["AWAITING_AUTH"],
  ["CONFIGURING_EVENTBRIDGE"],
  ["LOADING_ACCOUNT_CONFIG", "CONFIGURING_CLOUDTRAIL"],
];

const AddAccountProgressList = ({ currentStep, stepId, provider }: AddAccountProgressListProps) => {
  const [items, setItems] = useState<
    Array<{
      title: string;
      subItems?: string[];
    }>
  >([]);

  useEffect(() => {
    setItems([
      {
        title: "Getting Started",
      },
      ...(provider === CLOUD_PLATFORM.GCP
        ? [
            {
              title: "GCP Audit Logging Configuration",
            },
          ]
        : []),
      {
        title: "Connect Project",
        subItems: [
          "Connect Project",
          "Service Account authorization",
          "Configuring Service Account",
          ...(provider === CLOUD_PLATFORM.AWS
            ? ["Configuring EventBridge", "Configuring CloudTrail"]
            : ["Creating Service Account Bucket"]),
        ],
      },
    ]);
  }, [provider]);

  return (
    <StepsProgress
      items={items}
      currentStep={currentStep}
      stepId={stepId}
      steps={provider === CLOUD_PLATFORM.AWS ? awsSteps : gspSteps}
    />
  );
};

export default AddAccountProgressList;
