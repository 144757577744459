import { useTheme } from "@mui/material";

import { ReactComponent as TerraformLightSvg } from "./terraformLight.svg";
import { ReactComponent as TerraformDarkSvg } from "./terraformDark.svg";
import { ReactComponent as BucketLightSvg } from "./bucketLight.svg";
import { ReactComponent as BucketDarkSvg } from "./bucketDark.svg";

type SvgProps = { active?: boolean };

const TerraformSvg = ({ active }: SvgProps) => {
  const theme = useTheme();

  return theme.palette.mode === "light" && !active ? <TerraformDarkSvg /> : <TerraformLightSvg />;
};

const BucketSvg = ({ active }: SvgProps) => {
  const theme = useTheme();

  return theme.palette.mode === "light" && !active ? <BucketDarkSvg /> : <BucketLightSvg />;
};

export { TerraformSvg, BucketSvg };
