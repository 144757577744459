import React from "react";
import { Box, Typography } from "@mui/material";

import { AUTH_PROVIDERS } from "components/containters/SocialAuthForm/socialAuthForm";
import { AUTH_PROVIDER } from "store/types";

export enum AUTH_PROVIDER_VARIANT {
  TABLE,
  COMPONENT,
}

type AuthProviderIconProps = {
  provider: AUTH_PROVIDER;
  variant?: AUTH_PROVIDER_VARIANT;
};

export const AuthProviderIcon = ({ provider, variant = AUTH_PROVIDER_VARIANT.TABLE }: AuthProviderIconProps) => {
  const authProvider = AUTH_PROVIDERS.find((p) => p.type === provider);

  if (!authProvider) return <Box>{provider}</Box>;

  return (
    <Typography
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      variant="main"
      borderRadius={6}
      border="1px solid"
      color={variant === AUTH_PROVIDER_VARIANT.TABLE ? "#2F3259" : "text.primary"}
      borderColor={variant === AUTH_PROVIDER_VARIANT.TABLE ? "#F2F3FA" : "#5A5FA0"}
      bgcolor={variant === AUTH_PROVIDER_VARIANT.TABLE ? "#F2F3FA" : "transparent"}
      paddingX={variant === AUTH_PROVIDER_VARIANT.TABLE ? 3 : 4.5}
      paddingY={variant === AUTH_PROVIDER_VARIANT.TABLE ? 1 : 2.2}
      gap={variant === AUTH_PROVIDER_VARIANT.TABLE ? 1.5 : 2.5}
      sx={{
        svg: {
          width: variant === AUTH_PROVIDER_VARIANT.TABLE ? 20 : 24,
          height: variant === AUTH_PROVIDER_VARIANT.TABLE ? 20 : 24,
        },
      }}
    >
      {authProvider.icon}
      {authProvider.name}
    </Typography>
  );
};
