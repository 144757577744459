import { api } from "libs";
import {
  FEATURE,
  Inventory,
  INVENTORY_LATEST_VERSION_NAME,
  InventoryHistory,
  InventoryPriceHistoryItem,
  InventoryPriceHistoryRequest,
  InventorySearchData,
  InventorySearchDataTag,
  InventorySearchTagParams,
} from "store/types";
import {
  API_ASSET_HISTORY,
  API_ASSET_HISTORY_VERSION,
  API_ASSET_PRICE_HISTORY,
  API_INVENTORY_BY_ACCOUNT_ID,
  API_INVENTORY_ITEM_BY_RESOURCE_ID,
  API_INVENTORY_SEARCH_DATA_BY_ACCOUNT_ID,
  API_INVENTORY_SUMMARY_BY_ACCOUNT_ID,
  API_INVENTORY_TAGS_BY_ACCOUNT_ID,
  API_RELATED_RESOURCES_BY_ACCOUNT_ID,
} from "libs/api/endpoints";
import { CursorFetchData, CursorParameters } from "tools/cursorHandler/cursorHandler.types";
import { getIconTypeByResource } from "tools";
import store from "store";

export const getInventory = async (params: CursorParameters): Promise<CursorFetchData<Inventory>> => {
  if (!(params?.accountId as string[])?.length) return { data: [] };

  const isDataAsset = params.columns && (params.columns as string[]).length;

  const res: CursorFetchData<Inventory> | Inventory[] = await api.post(
    (isDataAsset ? API_INVENTORY_SUMMARY_BY_ACCOUNT_ID : API_INVENTORY_BY_ACCOUNT_ID)(
      (params.accountId as string[]).join(",") as string,
    ),
    {
      pagination: {
        count: isDataAsset ? 10000 : params.count,
        ...(params.cursor ? { cursor: params.cursor } : {}),
      },
      sort: {
        field: isDataAsset
          ? ""
          : params.sort_by === "asset_cur"
          ? "month_to_date_cost"
          : params.sort_by || "created_at",
        ...(params.sort_order ? { direction: params.sort_order } : {}),
        ...(params.by_created_at ? { by_created_at: params.by_created_at } : {}),
      },
      search: params.search || "",
      filters: {
        ...(params.types ? { types: params.types } : {}),
        ...(params.locations ? { locations: params.locations } : {}),
        ...(params.tags ? { tags: params.tags } : {}),
        ...(params.start_time ? { earliest_update: params.start_time } : {}),
        ...(params.end_time ? { latest_update: params.end_time } : {}),
        ...(params.not ? { negated_filter_types: params.not } : {}),
      },
      enrichments: {
        pricing: params.with_pricing ? params.with_pricing : true,
        ...(store.auth.isFeatureEnabled(FEATURE.INTEGRATIONS_TERRAFORM) ? { terraform: true } : {}),
      },
      ...(isDataAsset ? { columns: params.columns, actions: params.actions, renames: params.renames } : {}),
    },
    {
      timeout: 300000,
      errorMessage: {
        title: "Failed to load Resources",
      },
    },
  );

  if (isDataAsset) return { data: res, isFullResponse: true, isDynamicFields: true } as CursorFetchData<Inventory>;

  if (process.env.REACT_APP_ENV === "prod") return res as CursorFetchData<Inventory>;

  const types = [...new Set(((res as CursorFetchData<Inventory>)?.data || []).map((i) => i?.type).filter((i) => i))];

  types.forEach((type) => {
    if (getIconTypeByResource(type)) return;

    // eslint-disable-next-line no-console
    console.log("[ASSET ICON NOT FOUND]", type);
  });

  return (res || { data: [], total: 0 }) as CursorFetchData<Inventory>;
};

export const getRelatedResources = async (accountId: string, assetId: string): Promise<Inventory[]> => {
  if (!accountId || !assetId) return [];

  return api.get(API_RELATED_RESOURCES_BY_ACCOUNT_ID(accountId, assetId), {
    doNotShowErrorMessage: true,
    errorMessage: {
      title: "Failed to load Related Resources",
    },
  });
};

export const getInventorySearchData = async (accounts: string[]): Promise<InventorySearchData> => {
  if (!accounts.length)
    return {
      ...store.inventory.defaultSearchData,
    };

  const {
    types,
    locations,
    details,
  }: {
    types: string[];
    locations: string[];
    details: string[];
  } = await api.get(API_INVENTORY_SEARCH_DATA_BY_ACCOUNT_ID(accounts.join(",")), {
    timeout: 300000,
    errorMessage: {
      title: "Failed to load Resources Filter Data",
    },
  });

  return {
    types: [...new Set((types || []).filter((i: string) => i))].sort(),
    locations: [...new Set((locations || []).filter((i: string) => i))].sort(),
    details: (details || []).map((t) => {
      try {
        return JSON.parse(window.atob(t));
      } catch {
        return {};
      }
    }),
  };
};

export const getInventoryItemByResourceId = (accountId: string, resourceId: string): Promise<Inventory> =>
  api.get(API_INVENTORY_ITEM_BY_RESOURCE_ID(accountId, resourceId), {
    errorMessage: {
      title: "Failed to load Asset",
    },
  });

export const getHistoryVersions = async (accountId: string, assetId: string): Promise<string[]> => {
  const res: string[] = await api.get(API_ASSET_HISTORY(accountId, assetId), {
    doNotShowErrorMessage: true,
  });

  return res?.reverse() || [];
};

export const getHistoryVersion = (accountId: string, assetId: string, version: string): Promise<InventoryHistory> =>
  api.get(API_ASSET_HISTORY_VERSION(accountId, assetId, version), {
    doNotShowErrorMessage: version === INVENTORY_LATEST_VERSION_NAME,
    errorMessage: {
      title: "Failed to load Asset Version",
    },
  });

export const getInventoryTags = async (
  accounts: string[],
  params: InventorySearchTagParams,
): Promise<InventorySearchDataTag[]> => {
  const res = await api.post(API_INVENTORY_TAGS_BY_ACCOUNT_ID(accounts.join(",")), params, {
    doNotShowErrorMessage: true,
  });

  return res || [];
};

export const getPriceHistory = async (
  requestData: InventoryPriceHistoryRequest,
): Promise<InventoryPriceHistoryItem[]> => {
  const res: InventoryPriceHistoryItem[] = await api.get(API_ASSET_PRICE_HISTORY(requestData), {
    errorMessage: {
      title: "Failed to get Price History",
    },
  });

  return res?.sort((a, b) => (a.date > b.date ? 1 : -1)) || [];
};
