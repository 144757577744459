import React from "react";
import { Box, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";

import { CLOUD_PLATFORM } from "store/types";
import { AWSSvg, GCPSmallSvg } from "components/icons";
import { GroupedCount } from "components/ui";

type CloudPlatformStr = keyof typeof CLOUD_PLATFORM;

const rowTypes = {
  AWS: <AWSSvg data-testid="awsProviderIcon" height={20} />,
  GCP: <GCPSmallSvg data-testid="gcpProviderIcon" height={20} />,
  Azure: "Azure",
};

type ProviderIconProps = {
  provider: string;
  name?: string;
  center?: boolean;
  variant?: string;
  groupedCount?: number;
  groupedOfCount?: number;
  itemId?: string;
};

export const ProviderIcon = ({
  provider,
  name,
  center,
  groupedCount,
  groupedOfCount,
  itemId,
  variant = "table",
}: ProviderIconProps) => (
  <Box
    textAlign="left"
    display="flex"
    alignItems="center"
    flexDirection="row"
    justifyContent={center ? "center" : "inherit"}
    gap={1.5}
  >
    {!!groupedCount && <GroupedCount itemId={itemId} total={groupedCount} />}

    {!!provider && (
      <Box
        data-testid="providerIcon"
        display="flex"
        justifyContent={{ xs: "self-start", laptop: "center" }}
        alignItems="center"
      >
        {rowTypes[provider?.toUpperCase() as CloudPlatformStr] || provider?.toUpperCase()}
      </Box>
    )}

    {!!name && (
      <Typography variant={variant as Variant}>
        {name}
        {groupedOfCount! > 1 && (
          <Box component="span" color="text.secondary">
            {" "}
            (Group of {groupedOfCount})
          </Box>
        )}
      </Typography>
    )}

    {!name && groupedOfCount! > 1 && (
      <Typography variant={variant as Variant} color="text.secondary">
        (Group of {groupedOfCount})
      </Typography>
    )}
  </Box>
);
