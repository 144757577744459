import React, { MouseEvent } from "react";
import { inject, observer } from "mobx-react";
import { AddCircleOutline, BuildOutlined, HourglassEmpty, RemoveCircleOutline, Undo } from "@mui/icons-material";
import { Box, useMediaQuery, useTheme } from "@mui/material";

import { Button } from "components/ui";
import { RootStore } from "store/root.store";
import { DashboardActionsIssuesType, DashboardIssue } from "store/types";
import { closeIssues, fixIssues, reopenIssues, snoozeIssues, undoIssues } from "routes/DashboardRoute/dashboard.utils";

const DashboardIssueAdditionalInfoHeader = ({ store }: { store?: RootStore }) => {
  const theme = useTheme();
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));

  const activeItem = store?.ui.infoItem?.activeItem as DashboardIssue;

  const needToCutText = !isMobile && isLg;

  const closeAll = (e: MouseEvent<HTMLElement>) =>
    closeIssues(e, activeItem?.grouped || [], DashboardActionsIssuesType.ALL);

  const fixAll = (e: MouseEvent<HTMLElement>) =>
    fixIssues(e, activeItem?.grouped || [], DashboardActionsIssuesType.ALL);

  const undoAll = (e: MouseEvent<HTMLElement>) =>
    undoIssues(e, activeItem?.grouped || [], DashboardActionsIssuesType.ALL);

  const snoozeAll = (e: MouseEvent<HTMLElement>) =>
    snoozeIssues(e, activeItem?.grouped || [], DashboardActionsIssuesType.ALL);

  const reopenAll = (e: MouseEvent<HTMLElement>) =>
    reopenIssues(e, activeItem?.grouped || [], DashboardActionsIssuesType.ALL);

  if (store?.dashboard.infoItemIssue?.resolved) {
    return (
      <Box display="flex">
        <Button
          buttonSx={{ marginRight: 2 }}
          onClick={snoozeAll}
          text={needToCutText ? "All" : "Snooze All"}
          icon={HourglassEmpty}
          variant="text"
          onlyIcon={isMobileSmall}
        />
        <Button onClick={undoAll} text={needToCutText ? "All" : "Undo All"} icon={Undo} onlyIcon={isMobileSmall} />
      </Box>
    );
  }

  if (store?.dashboard.infoItemIssue?.justified) {
    return (
      <Button
        onClick={reopenAll}
        variant="text"
        text={needToCutText ? "All" : "Reopen All"}
        icon={AddCircleOutline}
        onlyIcon={isMobileSmall}
      />
    );
  }

  return (
    <Box display="flex">
      <Button
        buttonSx={{ marginRight: 2 }}
        onClick={closeAll}
        text={needToCutText ? "All" : "Justify All"}
        icon={RemoveCircleOutline}
        variant="text"
        onlyIcon={isMobileSmall}
      />
      <Button onClick={fixAll} text={needToCutText ? "All" : "Fix All"} icon={BuildOutlined} onlyIcon={isMobileSmall} />
    </Box>
  );
};

export default inject("store")(observer(DashboardIssueAdditionalInfoHeader));
