import React, { ReactElement, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Box, Typography, useTheme } from "@mui/material";

import { DashboardStatsProperties } from "routes/DashboardRoute/components/DashboardView/components";
import { WIDGET_MANAGER_VIEWS } from "store/types";
import { ElementWrapper } from "components/ui";

type DashboardCounterItemType = {
  item: DashboardStatsProperties;
  value?: number;
  actions?: ReactElement;
  editMode?: WIDGET_MANAGER_VIEWS.GALLERY;
  index: number;
  request?: Promise<number>;
};

const colors = ["#FCEFD1", "#DBFFF5", "#e3dfff", "#E4F3FF"];
const svgColors = ["#f1b017", "#00b081", "#6e5fff", "#3ec3ff"];

const DashboardCounterItem = ({ item, value, actions, editMode, index, request }: DashboardCounterItemType) => {
  const theme = useTheme();
  const [count, setCount] = useState(value);

  useEffect(() => {
    request?.then(setCount);
  }, [request]);

  useEffect(() => {
    if (value !== undefined) setCount(value);
  }, [value]);

  return (
    <ElementWrapper customSx={{ paddingY: 3, gap: 2 }}>
      <>
        {!!actions && (
          <Box
            sx={
              editMode
                ? {
                    width: 1,
                  }
                : {
                    position: "absolute",
                    top: theme.spacing(2),
                    right: theme.spacing(3),
                  }
            }
            bgcolor="background.paper"
          >
            {actions}
          </Box>
        )}

        <Box display="flex" gap={2.5}>
          <Box
            minWidth={60}
            width={60}
            height={60}
            minHeight={60}
            borderRadius={2}
            bgcolor={colors[index % colors.length]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              svg: {
                width: item.svgSize || 30,
                height: item.svgSize || 30,
                path: {
                  fill: svgColors[index % 4],
                },
              },
            }}
          >
            {item.icon}
          </Box>

          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start" gap={0.5}>
            <Typography variant="h2" color={theme.palette.mode === "light" ? "#2B3674" : "text.secondary"}>
              {count === undefined ? "..." : count || 0}
            </Typography>
            <Typography
              display="flex"
              flexDirection="column"
              gap={0.5}
              variant="main"
              lineHeight={1}
              color="text.secondary"
              sx={{
                wordBreak: "break-word",
              }}
            >
              {item.title}
            </Typography>
          </Box>
        </Box>
      </>
    </ElementWrapper>
  );
};

export default inject("store")(observer(DashboardCounterItem));
