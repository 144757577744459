import { Button as MuiButton, SvgIcon, SxProps, ButtonProps, useTheme, Box } from "@mui/material";
import { ReactElement } from "react";

import { Tooltip } from "components/ui";
import { SMALL_HEIGHT, TABLE_HEIGHT } from "consts";
import { darkInputBorder, lightDarkBG } from "theme/commonVariables";

type UIButtonProps = {
  fullWidthOnMobile?: boolean;
  icon?: typeof SvgIcon;
  customIcon?: ReactElement;
  text?: string;
  buttonSx?: SxProps;
  onlyIcon?: boolean;
  background?: string;
  smallWidth?: boolean;
  inTable?: boolean;
  tooltip?: string;
  extraSmall?: boolean;
  darkBg?: boolean;
  iconRight?: boolean;
  extraSmallWithText?: boolean;
} & ButtonProps;

export const Button = ({
  variant = "contained",
  color = "primary",
  children,
  icon: Icon,
  customIcon,
  text,
  fullWidth,
  fullWidthOnMobile,
  onlyIcon,
  buttonSx,
  background,
  inTable,
  smallWidth,
  tooltip,
  extraSmall,
  darkBg,
  iconRight,
  extraSmallWithText,
  ...props
}: UIButtonProps) => {
  const theme = useTheme();
  const content = !onlyIcon && (text || children);

  return (
    <Tooltip title={!props.disabled && ((onlyIcon && text) || tooltip)} placement="top">
      <MuiButton
        sx={{
          display: "flex",
          gap: 2,
          minWidth: 0,
          whiteSpace: "nowrap",
          width: extraSmall
            ? SMALL_HEIGHT
            : fullWidth
            ? 1
            : { xs: fullWidthOnMobile ? 1 : "fit-content", laptop: "fit-content" },
          height: 44,
          ...(inTable && { height: TABLE_HEIGHT }),
          ...((extraSmall || extraSmallWithText) && {
            ...(extraSmall ? { padding: "0 !important" } : { paddingX: 2 }),
            height: SMALL_HEIGHT,
          }),
          ...(smallWidth && {
            paddingX: `${theme.spacing(onlyIcon ? 2 : 4)} !important`,
          }),
          ...(background && { background, "&:hover": { background } }),
          ...(darkBg &&
            variant !== "contained" && {
              background: theme.palette.mode === "light" ? lightDarkBG : darkInputBorder,
              "&:hover": { background: theme.palette.mode === "light" ? lightDarkBG : darkInputBorder },
            }),
          ...(props.disabled && {
            opacity: 0.5,
            color: "#a5a9da !important",
            background: "#f0f1fa !important",
          }),
          ...buttonSx,
        }}
        data-testid="button"
        variant={variant}
        color={color}
        {...props}
      >
        {iconRight ? content : ""}
        {!!customIcon && <Box sx={{ svg: { display: "flex" } }}>{customIcon}</Box>}
        {!!Icon && <Icon data-testid="buttonIcon" sx={{ ...(extraSmall && { fontSize: 18 }) }} />}
        {!iconRight ? content : ""}
      </MuiButton>
    </Tooltip>
  );
};
