import { ROLES } from "store/types";

export enum AUTH_PROVIDER {
  GOOGLE = "google",
  GITHUB = "github",
  MICROSOFT = "microsoft",
  OKTA = "okta",
}

export enum FEATURE {
  RULES_PAGE = "RULES_PAGE",
  EVENTS_PAGE = "EVENTS_PAGE",
  INVENTORY_PAGE = "INVENTORY_PAGE",
  INTEGRATIONS_TERRAFORM = "INVENTORY_IAC_INTEGRATION",
  INTEGRATIONS_AWS_COST = "INTEGRATIONS_AWS_COST",
  INTEGRATIONS_GCP_COST = "INTEGRATIONS_GCP_COST",
  CLOUDMAP_PAGE = "CLOUDMAP_PAGE",
  THEME_MODE = "THEME_MODE",
  API_ACCESS = "API_ACCESS",
  COMPLIANCE_PAGE = "COMPLIANCE_PAGE",
  HISTORICAL_DASHBOARD = "HISTORICAL_DASHBOARD",
  MULTIPLE_DASHBOARDS = "MULTIPLE_DASHBOARDS",
  AWS_ORG_ONBOARDING = "AWS_ORG_ONBOARDING",
  GCP_ORG_ONBOARDING = "GCP_ORG_ONBOARDING",
  DEPENDABOT_INTEGRATION = "DEPENDABOT_INTEGRATION",
  NOTIFICATIONS = "NOTIFICATIONS",
  DASHBOARD_V1 = "DASHBOARD_V1",
  SLACK_INTEGRATION = "SLACK_INTEGRATION",
  EMAIL_ALERTS_INTEGRATION = "EMAIL",
  GITHUB_INTEGRATION = "GITHUB_INTEGRATION",
}

export enum TYPE_AUTH {
  LOGIN,
  SIGN_UP,
}

export type AuthOrg = {
  id: string;
  name: string;
};

export type AuthOrganization = {
  id: string;
  name: string;
  auth_provider: AUTH_PROVIDER;
};

export type AuthUser = {
  id: string;
  name: string;
  email: string;
  auth_provider: AUTH_PROVIDER;
  created_at: string;
  updated_at: string;
};

export enum PERMISSION_TYPES {
  OWNER = "owner_permission",
  ADMIN = "admin_permission",
  BILLING = "billing_permission",
  USER = "user_permission",
}

export type PermissionsWithRole = {
  id: number;
  admin_permission?: boolean;
  billing_permission?: boolean;
  owner_permission?: boolean;
  user_permission?: boolean;
  role: ROLES;
};
