import React from "react";
import { inject, observer } from "mobx-react";
import { Box } from "@mui/material";

import { INTEGRATION, Integration } from "store/types";
import { AddNotificationChannelModal } from "components/modals";

import {
  AddGithubIntegrationModal,
  AddTerraformIntegrationModal,
  AddApiKeyIntegrationModal,
  AddAWSCostIntegrationModal,
  AddGCPCostIntegrationModal,
} from "./components";

const AddIntegrationModal = ({ integration }: { integration: Integration }) => (
  <Box
    minHeight={{ xs: 200, laptop: 300 }}
    gap={5}
    display="flex"
    flexDirection="column"
    justifyContent="space-between"
  >
    {integration.type === INTEGRATION.SLACK && (
      <AddNotificationChannelModal predefinedIntegration={INTEGRATION.SLACK} />
    )}
    {integration.type === INTEGRATION.JIRA && <AddNotificationChannelModal predefinedIntegration={INTEGRATION.JIRA} />}
    {integration.type === INTEGRATION.HTTP && <AddNotificationChannelModal predefinedIntegration={INTEGRATION.HTTP} />}
    {integration.type === INTEGRATION.EMAIL && (
      <AddNotificationChannelModal predefinedIntegration={INTEGRATION.EMAIL} />
    )}
    {integration.type === INTEGRATION.TERRAFORM && <AddTerraformIntegrationModal />}
    {integration.type === INTEGRATION.GITHUB && <AddGithubIntegrationModal />}
    {integration.type === INTEGRATION.API_KEY && <AddApiKeyIntegrationModal />}
    {integration.type === INTEGRATION.AWS_COST && <AddAWSCostIntegrationModal />}
    {integration.type === INTEGRATION.GCP_COST && <AddGCPCostIntegrationModal />}
  </Box>
);

export default inject("store")(observer(AddIntegrationModal));
