import React from "react";
import { inject, observer } from "mobx-react";
import { Link, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

import { Button, ButtonWrapper } from "components/ui";
import { RootStore } from "store/root.store";

const AddGithubIntegrationModal = ({ store }: { store?: RootStore }) => (
  <>
    <Typography variant="table">Please click the button below to go to your account and connect it.</Typography>

    <Link
      href={store?.github.appLink}
      sx={{ textDecoration: "none" }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexGrow={1}
    >
      <Button text="Add Github account" icon={Add} />
    </Link>

    <ButtonWrapper marginTop={0}>
      <Button onClick={store?.ui.closeModal} text="Cancel" variant="text" />
    </ButtonWrapper>
  </>
);

export default inject("store")(observer(AddGithubIntegrationModal));
