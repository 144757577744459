import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Box } from "@mui/material";
import { useFormik } from "formik";

import { RootStore } from "store/root.store";
import { Button, ButtonWrapper, FormFieldsBuilder } from "components/ui";
import { DashboardActionsIssuesType, DashboardIssue } from "store/types";
// eslint-disable-next-line max-len
import { getIssueChangeStateSelect } from "routes/DashboardRoute/components/DashboardIssues/components/dashboardIssues.tools";

type ReopenIssueModalProps = { store?: RootStore; issues: DashboardIssue[]; type: DashboardActionsIssuesType };

const ReopenIssueModal = ({ store, issues, type }: ReopenIssueModalProps) => {
  const initialValues = { type };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => store?.dashboard.reopenIssues(issues, values.type),
  });

  useEffect(() => store?.dashboard.changeModalTitle(formik.values?.type), [store?.dashboard, formik.values?.type]);

  const items = getIssueChangeStateSelect(issues, type);

  return (
    <Box data-testid="reopenIssueModal">
      <form onSubmit={formik.handleSubmit}>
        <FormFieldsBuilder formik={formik} items={items} />

        <ButtonWrapper>
          <>
            <Button onClick={store?.ui.closeModal} text="Cancel" variant="text" />
            <Button
              type="submit"
              text={`Reopen issue${formik.values.type === DashboardActionsIssuesType.SINGLE ? "" : "s"}`}
            />
          </>
        </ButtonWrapper>
      </form>
    </Box>
  );
};

export default inject("store")(observer(ReopenIssueModal));
