import { makeAutoObservable } from "mobx";

import { RootStore } from "store/root.store";
import {
  addCloudAccount,
  cancelOnboarding,
  continueOrganizationOnboarding,
  createOrganization,
  deleteOrganization,
  getCloudAccountsList,
  getOnboardingStatus,
  getOrganizationOnboardingStatus,
  getOrganizations,
  onBoardAccount,
  removeAccount,
  updateAccount,
} from "libs/api/methods";
import { Account, ACCOUNT_STATUSES, AddCloudRequest, AddOrgRequest, FEATURE, UpdateAccountRequest } from "store/types";

export class AccountsStore {
  isError = false;
  isFetched = false;
  isModalLoading = false;
  data: Account[] = [];

  constructor(readonly rootStore: RootStore) {
    this.rootStore = rootStore;

    this.fetch = this.fetch.bind(this);
    this.onBoardAccount = this.onBoardAccount.bind(this);

    makeAutoObservable(this);
  }

  get activeAccounts() {
    return this.data.filter((a) => a.status === ACCOUNT_STATUSES.ACTIVE);
  }

  async fetch() {
    try {
      this.data = await getCloudAccountsList(this.isFetched);
      this.isFetched = true;
    } catch {
      this.isError = true;
    }
  }

  setIsModalLoading(value: boolean) {
    this.isModalLoading = value;
  }

  async addAccount(data: AddCloudRequest) {
    this.setIsModalLoading(true);

    let account;

    try {
      account = await addCloudAccount(data);

      this.rootStore.ui.showMessage({
        title: "Account Added",
        message: "Follow the steps above to continue onboarding your account.",
      });

      this.data = [...this.data, account];

      this.data.sort((a, b) => (a.name > b.name ? 1 : -1));
    } catch {}

    this.setIsModalLoading(false);

    return account;
  }

  async onBoardAccount(account: Account) {
    this.setIsModalLoading(true);

    await onBoardAccount(account.id).catch(() => null);

    this.setIsModalLoading(false);
  }

  async cancelOnboarding(id: string) {
    this.rootStore.ui.setShowConfirmModalLoading(true);

    await cancelOnboarding(id);
    await this.fetch();

    this.rootStore.ui.closeModal();
    this.rootStore.ui.setShowConfirmModalLoading(false);
  }

  async removeAccount(account: Account) {
    this.rootStore.ui.setShowConfirmModalLoading(true);

    if (account.status === ACCOUNT_STATUSES.PENDING) {
      await cancelOnboarding(account.id);
    } else {
      await removeAccount(account.id);
    }

    this.data = this.data.filter((a) => a.id !== account.id);

    this.rootStore.ui?.setShowConfirmModalLoading(false);
  }

  async updateAccount(account: Account, update: UpdateAccountRequest) {
    await updateAccount(account.id, update).catch(() => null);
  }

  async retry(account: Account) {
    this.rootStore.ui.setShowModalLoading(true);

    try {
      await removeAccount(account.id, true);

      this.data = this.data.filter((a) => a.id !== account.id);

      const newAccount = await addCloudAccount(
        {
          name: account.name,
          provider: account.provider?.toUpperCase(),
          account_id: account.provider_account_id,
        },
        true,
      );

      this.fetch();

      this.rootStore.ui.setShowModalLoading(false);

      return newAccount;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      this.rootStore.ui.showErrorMessage({
        title: "Failed to retry",
        message: err.message,
      });

      this.rootStore.ui.setShowModalLoading(false);

      throw err;
    }
  }

  async getOnboardingStatus(id: string) {
    return getOnboardingStatus(id);
  }

  async createOrganization(data: AddOrgRequest) {
    return createOrganization(data);
  }

  async getOrganizations() {
    if (
      !this.rootStore.auth.isFeatureEnabled(FEATURE.AWS_ORG_ONBOARDING) &&
      !this.rootStore.auth.isFeatureEnabled(FEATURE.GCP_ORG_ONBOARDING)
    ) {
      return [];
    }

    return getOrganizations();
  }

  async deleteOrganization(id: string) {
    return deleteOrganization(id);
  }

  async continueOrganizationOnboarding(id: string) {
    return continueOrganizationOnboarding(id);
  }

  async getOrganizationOnboardingStatus(id: string) {
    return getOrganizationOnboardingStatus(id);
  }
}
