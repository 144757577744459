import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";

import { TableProps, TableSortField } from "components/ui";
import { TableMobile, TableDesktop } from "components/ui/Table/components";

export const TableComponent = ({
  desktopView,
  ...props
}: TableProps & {
  isFilerEnabled?: boolean;
  sortField: TableSortField;
  setSortFieldHandler: (field: string) => void;
}) => {
  const theme = useTheme();
  const isLaptop = useMediaQuery(theme.breakpoints.up("laptop"));

  if (isLaptop || desktopView) return <TableDesktop {...props} />;

  return <TableMobile {...props} />;
};
