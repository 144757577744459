import React, { ChangeEvent, ReactElement } from "react";
import { Checkbox as MuiCheckbox, FormControlLabel, SxProps } from "@mui/material";

import { DisableCheckedSvg, CheckedSvg, NotCheckedSvg } from "components/ui/Checkbox/components";

type CheckboxComponentProps = {
  checked: boolean;
  onChange: (val: boolean) => void;
  disabled?: boolean;
};

type CheckboxProps = CheckboxComponentProps & {
  label?: ReactElement;
  formLabelSx?: SxProps;
  disabled?: boolean;
};

const CheckboxComponent = ({ checked, onChange, disabled }: CheckboxComponentProps) => (
  <MuiCheckbox
    data-testid="checkbox"
    checked={checked}
    onChange={(e: ChangeEvent<HTMLInputElement>, val: boolean) => onChange(val)}
    sx={{ padding: 0, cursor: disabled ? "default" : "pointer" }}
    icon={<NotCheckedSvg />}
    checkedIcon={disabled ? <DisableCheckedSvg /> : <CheckedSvg />}
    indeterminateIcon={<NotCheckedSvg />}
  />
);

export const Checkbox = ({ label, formLabelSx, ...props }: CheckboxProps) => {
  if (!label) return <CheckboxComponent {...props} />;

  return (
    <FormControlLabel
      sx={{
        display: "flex",
        alignItems: "center",
        ...formLabelSx,
      }}
      data-testid="checkboxFormControl"
      control={<CheckboxComponent {...props} />}
      label={label}
    />
  );
};
