import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Add, SwapHoriz } from "@mui/icons-material";
import { inject, observer } from "mobx-react";

import {
  ChangeFilter,
  FILTER_CONDITION,
  FILTER_CONDITION_TYPE,
  FILTER_OPERATOR,
  FILTER_PAGES,
  FilterConditionKey,
  FilterConditions,
  FilterData,
  FilterDataItem,
  FilterEntity,
  FilterItem,
} from "store/types";
import { Button, Input, MultiSelect, MultiSelectListItem, Select, TableFields } from "components/ui";
import { FilterTrashSvg } from "components/icons";
import { RootStore } from "store/root.store";

export type FilterElementProps = {
  store?: RootStore;
  filter: FilterData;
  fields: TableFields[];
  changeFilter: ChangeFilter;
  removeFilterElement: ChangeFilter;
  parentFilter: FilterData;
  filterConditions: FilterConditions;
  type: FILTER_PAGES;
  index: number;
  parentIndex: number;
  entities?: FilterEntity;
  onlyAnd?: boolean;
  fieldOnlyOnce?: boolean;
  maxNestedElements?: number;
};

const buttonSx = { paddingX: 2, gap: 1, flexShrink: 0 };

const FilterElement = ({
  store,
  item,
  fields,
  changeFilter,
  removeFilterElement,
  filterConditions,
  type,
  index,
  parentFilter,
  parentIndex,
  filter,
  entities,
  onlyAnd,
  fieldOnlyOnce,
  maxNestedElements = 5,
}: FilterElementProps & { item: FilterItem }) => {
  const theme = useTheme();
  const [acceptableConditions, setAcceptableConditions] = useState<FilterConditions>(filterConditions);

  const changeFilterElement = useCallback(
    (value: FilterDataItem) =>
      changeFilter({
        ...parentFilter,
        items: [...parentFilter.items.slice(0, index), value, ...parentFilter.items.slice(index + 1)],
      }),
    [changeFilter, parentFilter, index],
  );

  const addNewElement = (selectedOperator: FILTER_OPERATOR) => {
    if (parentFilter.operator === selectedOperator || parentFilter.items.length === 1) {
      changeFilter({
        ...parentFilter,
        operator: selectedOperator,
        items: [...parentFilter.items, ...store!.filter.getDefaultFilter(type).items],
      });
    } else {
      changeFilter({
        ...parentFilter,
        items: [
          ...parentFilter.items.slice(0, index),
          {
            operator: selectedOperator,
            items: [parentFilter.items[index], ...store!.filter.getDefaultFilter(type).items],
          },
          ...parentFilter.items.slice(index + 1),
        ],
      });
    }
  };

  const removeElement = () => {
    if (filter.items.length === 1) {
      removeFilterElement({
        ...filter,
        items: [
          {
            ...filter.items[0],
            field: "",
            value: "",
            values: [],
          },
        ],
      });

      return;
    }

    const items = [...parentFilter.items.slice(0, index), ...parentFilter.items.slice(index + 1)];

    if (items.length === 1) {
      removeFilterElement(items[0]);
    } else {
      removeFilterElement({ ...parentFilter, items });
    }
  };

  const disableFieldOption = (option: MultiSelectListItem | string) => {
    if (!fieldOnlyOnce || parentFilter.operator === FILTER_OPERATOR.OR) return false;

    return !!parentFilter.items.find((i) => {
      const filterFieldItem = (i as FilterItem).field;

      if (!filterFieldItem) return false;

      return (
        ((filterFieldItem as MultiSelectListItem)?.id || filterFieldItem) ===
        ((option as MultiSelectListItem)?.id || (option as string))
      );
    });
  };

  const isList = filterConditions.find((f) => f.condition === item.condition)?.type === FILTER_CONDITION_TYPE.LIST;
  const isBoolean =
    filterConditions.find((f) => f.condition === item.condition)?.type === FILTER_CONDITION_TYPE.BOOLEAN;

  useEffect(() => {
    const field = fields.find((f) => f.property === (item.field as MultiSelectListItem)?.id);

    const newConditions = filterConditions.filter(
      (f) => !field?.filterConditions?.length || field.filterConditions.includes(f.condition),
    );

    setAcceptableConditions(newConditions);

    if (newConditions.length && !newConditions.find((c) => c.condition === item.condition)) {
      changeFilterElement({ ...item, condition: newConditions[0].condition });
    }
  }, [changeFilterElement, fields, item, filterConditions]);

  return (
    <Box
      padding={2}
      marginBottom={2}
      borderRadius={1}
      bgcolor="background.default"
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap={2}
      flexWrap={{ xs: "wrap", laptopLg: "nowrap" }}
    >
      <Typography>=</Typography>
      <Box width={0.5} flexGrow={1}>
        {fields?.length ? (
          <MultiSelect
            fullWidth
            disableOption={disableFieldOption}
            placeholder="Select field"
            list={fields.map(({ name, property }) => ({ id: property, name: name as string }))}
            value={item.field}
            onChange={(val) => changeFilterElement({ ...item, field: val, value: "", values: [] })}
          />
        ) : (
          <Input
            placeholder="Enter field"
            value={(item.field as MultiSelectListItem)?.id || ""}
            onChange={(val) => changeFilterElement({ ...item, field: val ? { id: val, name: val } : "" })}
          />
        )}
      </Box>
      <Box flexShrink={0}>
        <Select
          list={acceptableConditions.map(({ condition }) => ({ id: condition, name: FILTER_CONDITION[condition] }))}
          value={item.condition}
          onChange={(val) => changeFilterElement({ ...item, condition: val as FilterConditionKey })}
        />
      </Box>
      <Box width={0.5} flexGrow={1} sx={{ ...(isBoolean && { display: "none" }) }}>
        <Box sx={{ ...(!isList && { display: "none" }) }}>
          <MultiSelect
            disabled={!item.field}
            freeSolo
            fullWidth
            multiple
            limitTags={3}
            placeholder={!item.field ? "Please select a field first" : "Select values"}
            list={entities?.[(item.field as MultiSelectListItem)?.id]}
            value={item.values}
            onChange={(values) => changeFilterElement({ ...item, values })}
          />
        </Box>
        <Box sx={{ ...(isList && { display: "none" }) }}>
          {entities?.[(item.field as MultiSelectListItem)?.id]?.length || isBoolean ? (
            <MultiSelect
              freeSolo
              fullWidth
              placeholder={!item.field ? "Please select a field first" : "Select value"}
              list={entities?.[(item.field as MultiSelectListItem)?.id]}
              disabled={!item.field || isBoolean}
              value={isBoolean ? "" : item.value}
              onChange={(value) => changeFilterElement({ ...item, value })}
            />
          ) : (
            <Input
              placeholder={fields.length ? "Please select a field first" : "Enter value"}
              disabled={!!fields.length && !item.field}
              value={(item.value as MultiSelectListItem)?.id || (item.value as string)}
              onChange={(value) => changeFilterElement({ ...item, value })}
            />
          )}
        </Box>
      </Box>

      <Button
        buttonSx={{
          ...buttonSx,
          svg: { path: { fill: theme.palette.mode === "light" ? "#2F3259" : "#198eef" } },
        }}
        inTable
        smallWidth
        customIcon={<FilterTrashSvg />}
        variant="outlined"
        onlyIcon
        disabled={filter.items.length === 1 && !item.field && !item.value}
        onClick={removeElement}
      />

      <Box display="flex" alignItems="center" gap={2}>
        {(!fieldOnlyOnce || fields.length > parentFilter.items.length || !fields.length) && (
          <>
            {(parentIndex < maxNestedElements ||
              (parentIndex === maxNestedElements &&
                (parentFilter.operator === FILTER_OPERATOR.AND ||
                  (maxNestedElements === 1 && parentFilter.items.length === 1)))) && (
              <Button
                onClick={() => addNewElement(FILTER_OPERATOR.AND)}
                background="#00B081"
                buttonSx={buttonSx}
                icon={Add}
                text="AND"
                inTable
              />
            )}

            {!onlyAnd &&
              (parentIndex < maxNestedElements ||
                (parentIndex === maxNestedElements &&
                  (parentFilter.operator === FILTER_OPERATOR.OR ||
                    (maxNestedElements === 1 && parentFilter.items.length === 1)))) && (
                <Button
                  onClick={() => addNewElement(FILTER_OPERATOR.OR)}
                  background="#15A6E8"
                  buttonSx={buttonSx}
                  icon={SwapHoriz}
                  text="OR"
                  inTable
                />
              )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default inject("store")(observer(FilterElement));
