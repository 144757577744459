import React, { ReactElement, useRef } from "react";
import { Box } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";

import { AdditionalInfoTable } from "components/ui/AdditionalInfoTable/additionalInfoTable";
import { copyToClipboard, getFieldTextValue } from "tools";
import { Account } from "store/types";
import { TableFields, TableItemProps } from "components/ui/Table/table";

type AccountDetailsProps = {
  component: ReactElement;
  fields: TableFields[];
  item?: TableItemProps;
  inTable?: boolean;
};

const CustomActionComponent = ({ item, field }: { item: Account; field: TableFields }) => (
  <Box
    onClick={() => copyToClipboard({ value: getFieldTextValue(item, field) })}
    sx={{ cursor: "pointer", svg: { width: 15 } }}
  >
    <ContentCopy />
  </Box>
);

export const DetailsOnHover = ({ component, fields, item, inTable }: AccountDetailsProps) => {
  const timeoutEnterRef = useRef<ReturnType<typeof setTimeout>>();
  const timeoutLeaveRef = useRef<ReturnType<typeof setTimeout>>();

  const elRef = useRef<null | HTMLElement>(null);

  if (!item || !inTable) return component;

  const toggleEl = (show: boolean) => {
    if (!elRef.current?.style) return;

    elRef.current.style.display = show ? "flex" : "none";
  };

  const mouseEnter = () => {
    clearTimeout(timeoutLeaveRef.current);

    timeoutEnterRef.current = setTimeout(() => toggleEl(true), 100);
  };

  const mouseLeave = () => {
    clearTimeout(timeoutEnterRef.current);

    timeoutLeaveRef.current = setTimeout(() => toggleEl(false), 100);
  };

  return (
    <Box position="relative" onClick={(e) => e.stopPropagation()} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
      {component}

      <Box
        ref={elRef}
        top={{ xs: 0, laptop: -15 }}
        left={0}
        width={{ xs: 1, laptop: "max-content" }}
        maxWidth={{ xs: "none", laptop: 500, laptopLg: 600, lg: 700 }}
        position="absolute"
        display="none"
        padding={3}
        bgcolor="background.paper"
        borderRadius={1}
        border="2px solid"
        borderColor="background.default"
        zIndex={10}
      >
        <AdditionalInfoTable
          fields={fields}
          item={item}
          customActionComponent={CustomActionComponent}
          eachFieldAction
        />
      </Box>
    </Box>
  );
};
