import { makeAutoObservable } from "mobx";

import { RootStore } from "store/root.store";
import { getCloudMapData, getRoles } from "libs/api/methods";
import {
  AccountCloudMapItem,
  CLOUD_MAP_TYPES,
  CloudMapSupportedTypes,
  PERMISSION_TYPES,
  PermissionsWithRole,
} from "store/types";
import { transformDataToCloudMap } from "tools";

export class DataStore {
  roles: PermissionsWithRole[] = [];

  constructor(readonly rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  async init() {
    if (!this.rootStore.auth.isPermissionAllowed(PERMISSION_TYPES.ADMIN)) return;

    await this.getRoles();
  }

  async getRoles() {
    this.roles = await getRoles().catch(() => []);
  }

  async getCloudMapData({
    type,
    accountId,
    item,
  }: {
    type: CLOUD_MAP_TYPES;
    accountId: string;
    item?: CloudMapSupportedTypes;
  }): Promise<AccountCloudMapItem[][]> {
    if (type === CLOUD_MAP_TYPES.ACCOUNTS) {
      return getCloudMapData(accountId);
    }

    if (type === CLOUD_MAP_TYPES.ASSETS) {
      if (!item) return [];

      const assets = await this.rootStore.inventory.getRelatedResources(accountId, item.id);

      return transformDataToCloudMap(item, assets);
    }

    return [];
  }
}
