import { ReactElement } from "react";
import { Box } from "@mui/material";
import { inject, observer } from "mobx-react";

import { RootStore } from "store/root.store";

type TaleWrapperProps = {
  store?: RootStore;
  children: ReactElement;
};

const TaleWrapper = ({ store, children }: TaleWrapperProps) => {
  const infoItemCoefficient = store?.ui.infoItem ? 1 : 0;

  return (
    <Box
      display="flex"
      width={{
        xs: 1,
        laptop: store?.ui.smallView ? 1 / (2 - infoItemCoefficient) : 1,
        laptopLg: 1 / (2 - infoItemCoefficient),
        lg: 1 / (3 - infoItemCoefficient),
        xl: 1 / (4 - infoItemCoefficient),
      }}
      padding={2.5}
    >
      {children}
    </Box>
  );
};

export default inject("store")(observer(TaleWrapper));
