import React, { MouseEvent } from "react";
import { RemoveCircleOutline } from "@mui/icons-material";

import store from "store";
import { ACTION_FIELD_PROPERTY } from "consts";
import { GcpCostIntegration } from "store/types";
import { ActionButtonsWrapper } from "components/ui";

const removeGcpIntegrationModal = (integration: GcpCostIntegration) =>
  store?.ui.openConfirmModal({
    title: "Are you sure you want to remove this GCP Integration?",
    confirmText: "Remove",
    onConfirm: () => store.costReport.removeCostIntegration(integration),
  });

export const gcpCostFields = [
  {
    name: "Account",
    property: "cloud_account_id",
    render: (item: GcpCostIntegration) =>
      store?.accounts.data.find((a) => a.id === item.cloud_account_id)?.name || item.cloud_account_id,
  },
  { name: "Dataset Name", property: "dataset_name" },
  { name: "Billing Project Id", property: "billing_project_id" },
  {
    property: ACTION_FIELD_PROPERTY,
    render: (item: GcpCostIntegration) => (
      <ActionButtonsWrapper
        item={item}
        inTable
        buttons={[
          {
            icon: RemoveCircleOutline,
            text: "Remove",
            variant: "text",
            onClick: (e: MouseEvent<HTMLElement>, integration: GcpCostIntegration) => {
              removeGcpIntegrationModal(integration);
            },
          },
        ]}
      />
    ),
  },
];
