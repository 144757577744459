import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { inject, observer } from "mobx-react";

import { RootStore } from "store/root.store";
import { lightTextSecondaryDarkColor, darkTextSecondaryDarkColor } from "theme/commonVariables";

import { EmptySvg, WithValueSvg } from "./components";

type GroupedCountProps = { store?: RootStore; total: number; itemId?: string };

const GroupedCount = ({ store, total, itemId }: GroupedCountProps) => {
  const theme = useTheme();
  const isActive = itemId && store?.ui?.infoItem?.activeItem?.id === itemId;
  const value = Math.max(0, total);

  return (
    <Box
      data-testid="groupedCount"
      display="flex"
      alignItems="center"
      sx={{
        svg: {
          marginRight: 1,
          path: {
            fill: isActive
              ? theme.palette.mode === "light"
                ? "#4141F2"
                : "#5CC9FA"
              : !value
              ? "#A5AAD8"
              : theme.palette.mode === "light"
              ? lightTextSecondaryDarkColor
              : darkTextSecondaryDarkColor,
          },
        },
      }}
    >
      {value ? <WithValueSvg /> : <EmptySvg />}
      <Typography
        color={isActive ? "primary.main" : !value ? "various.inactiveColor" : "text.secondaryDark"}
        variant="main"
        sx={{
          ...(isActive && {
            fontWeight: 700,
          }),
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default inject("store")(observer(GroupedCount));
