import React, { ChangeEventHandler } from "react";
import { Box, RadioGroup, Radio as MuiRadio, FormControlLabel } from "@mui/material";

import { ErrorText, Label, SelectList } from "components/ui";

export type RadioProps = {
  error?: string;
  label?: string;
  name?: string;
  list: SelectList;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

export const Radio = ({ list, name, value, onChange, label, error }: RadioProps) => (
  <Box data-testid="textarea">
    {!!label && <Label text={label} />}

    <RadioGroup
      name={name}
      value={value}
      onChange={onChange}
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 5,
      }}
    >
      {list.map((i) => (
        <FormControlLabel key={i.id} control={<MuiRadio />} value={i.id} label={i.name} />
      ))}
    </RadioGroup>

    {!!error && <ErrorText text={error} marginTop={1} />}
  </Box>
);
