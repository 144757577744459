import React from "react";
import { inject, observer } from "mobx-react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

import { FullScreenSvg } from "components/icons";
import { getInventoryResourceLink } from "tools/utils";
import { RootStore } from "store/root.store";

const InventoryAdditionalInfoHeader = ({ store }: { store?: RootStore }) => {
  const activeItem = store?.ui.infoItem?.activeItem;

  const account = store?.accounts.activeAccounts.find((a) => a.provider_account_id === activeItem?.provider_account_id);

  const link = getInventoryResourceLink({
    accountId: account?.id as string,
    resourceId: activeItem?.provider_id as string,
  });

  return (
    <Box
      component={Link}
      to={link}
      onClick={() => store?.ui.closeInfoItem()}
      sx={{
        cursor: "pointer",
        svg: {
          width: 16,
          height: 16,
        },
      }}
    >
      <FullScreenSvg />
    </Box>
  );
};

export default inject("store")(observer(InventoryAdditionalInfoHeader));
