import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { inject, observer } from "mobx-react";
import { Add } from "@mui/icons-material";

import {
  ButtonGroup,
  DatePicker,
  DatePickerDates,
  Filter,
  Select,
  MultiSelect,
  TableFields,
  DataStats,
  Button,
} from "components/ui";
import { getDatesFromFilterValue, setFilterValueByDates, sortFilterEntities, without } from "tools";
import {
  DataStatsItem,
  DB_FILTER_CONDITION_TYPE,
  FILTER_PAGES,
  FilterConditionKey,
  FilterData,
  FilterDataDates,
  FilterDataItem,
  FilterEntity,
  FilterItem,
  InventorySearchData,
  InventorySearchDataTag,
  InventoryTagValue,
} from "store/types";
import { RootStore } from "store/root.store";
import { getRows, mapToFilterEntities } from "components/ui/JsonViewer/jsonViewer.utils";
import { TABLE_HEIGHT } from "consts";

import InventoryTag from "./inventoryTag";

type InventoryFilterProps = {
  store?: RootStore;
  selectedAccounts: string[];
  searchData: InventorySearchData;
  filter: FilterData;
  setFilter: Dispatch<SetStateAction<FilterData>>;
};

const fields = [
  {
    name: "Types",
    property: "types",
  },
  {
    name: "Locations",
    property: "locations",
  },
  {
    name: "Update at",
    property: "dates",
  },
];

const dateFields = [
  {
    id: "created_at",
    name: "Created at",
  },
  {
    id: "updated_at",
    name: "Update at",
  },
];

const ButtonGroupComponent = ({
  exclude,
  onChange,
  disabled,
}: {
  exclude: boolean;
  onChange: (exclude: boolean) => void;
  disabled?: boolean;
}) => (
  <ButtonGroup
    inTable
    exclusive
    disabled={disabled}
    items={[
      { value: DB_FILTER_CONDITION_TYPE.INCLUDE, component: "Include" },
      { value: DB_FILTER_CONDITION_TYPE.EXCLUDE, component: "Exclude" },
    ]}
    value={exclude ? DB_FILTER_CONDITION_TYPE.EXCLUDE : DB_FILTER_CONDITION_TYPE.INCLUDE}
    onChange={(val: string) => onChange(val === DB_FILTER_CONDITION_TYPE.EXCLUDE)}
  />
);

const InventoryFilter = ({ store, searchData, selectedAccounts, filter, setFilter }: InventoryFilterProps) => {
  const [detailsFilter, setDetailsFilter] = useState<FilterDataItem>(
    filter.items.find((f) => (f as FilterData).type === FILTER_PAGES.INVENTORY_DETAILS) ||
      store!.filter.getDefaultFilter(FILTER_PAGES.INVENTORY_DETAILS),
  );
  const [detailsFilterEntities, setDetailsFilterEntities] = useState<FilterEntity>();
  const [detailsFilterFields, setDetailsFilterFields] = useState<TableFields[]>([]);
  const [defaultTags, setDefaultTags] = useState<InventorySearchDataTag[]>([]);
  const [tags, setTags] = useState<InventoryTagValue[]>([{ tag: null, value: null }]);

  const getDefaultTags = useCallback(async () => {
    const data = await store?.inventory.getInventoryTags(selectedAccounts, {
      keyPrefix: "",
    });
    setDefaultTags(data as InventorySearchDataTag[]);
  }, [store?.inventory, selectedAccounts]);

  useEffect(() => {
    getDefaultTags();
  }, [getDefaultTags]);

  useEffect(() => {
    const entities = mapToFilterEntities(searchData.details.flatMap((c) => getRows(c).filter((r) => !r.onlyJson)));

    setDetailsFilterFields(Object.keys(entities).map((property) => ({ property, name: property })));

    setDetailsFilterEntities(sortFilterEntities(entities));
  }, [searchData.details]);

  const changeValue = useCallback(
    (field: string, value: string | string[], condition: FilterConditionKey) => {
      const currentItems = JSON.parse(JSON.stringify(filter.items));
      const currentItem = currentItems.find((f: FilterItem) => f.field === field);

      if (currentItem) {
        currentItem[Array.isArray(value) ? "values" : "value"] = value;
        currentItem.condition = condition;
      } else {
        currentItems.push({
          ...(Array.isArray(value)
            ? {
                value: "",
                values: value || [],
              }
            : {
                value: value || "",
                values: [],
              }),
          field,
          condition,
        } as FilterItem);
      }

      setFilter((s: FilterData) => ({
        ...s,
        items: currentItems.filter(
          (c: FilterDataItem) =>
            (c as FilterData).type === FILTER_PAGES.INVENTORY_DETAILS ||
            (c as FilterItem).value ||
            (c as FilterItem).values?.length,
        ),
      }));
    },
    [filter.items, setFilter],
  );

  const getField = useCallback(
    (field: string) => filter.items.find((f) => (f as FilterItem).field === field) as FilterItem,
    [filter.items],
  );

  const getTagsValue = useCallback(() => {
    try {
      return JSON.parse(getField("tags")?.value as string);
    } catch {
      return {};
    }
  }, [getField]);

  const getDataStatsValue = () => {
    try {
      return JSON.parse(getField(FILTER_PAGES.DATA_STATS)?.value as string);
    } catch {
      return null;
    }
  };

  const setDates = (dates: DatePickerDates) =>
    setFilter((s: FilterData) => ({
      ...s,
      dates: {
        ...s.dates,
        ...setFilterValueByDates(dates),
      },
    }));

  const setDatesByField = (field: string) =>
    setFilter((s: FilterData) => ({
      ...s,
      dates: {
        ...(s.dates as FilterDataDates),
        field,
      },
    }));

  const setDetailsFilterHandler = (newFilter: FilterDataItem) => {
    if (detailsFilter === newFilter) return;

    setDetailsFilter(newFilter);

    setFilter((s: FilterData) => {
      const items = s.items.filter((c: FilterDataItem) => (c as FilterData).type !== FILTER_PAGES.INVENTORY_DETAILS);

      items.push({
        ...(without(newFilter, ["dates"]) as FilterDataItem),
        type: FILTER_PAGES.INVENTORY_DETAILS,
      });

      return { ...s, items };
    });
  };

  const addTag = () => setTags((curr) => [...curr, { tag: null, value: null }]);

  const setTagsValue = (val: InventoryTagValue[]) => {
    const tagValue = val
      .filter((t) => t.tag && t.value)
      .reduce((acc, { tag, value }) => ({ ...acc, [tag as string]: value }), {});

    const tagField = getField("tags");

    const value = Object.keys(tagValue).length ? JSON.stringify(tagValue) : "";

    changeValue("tags", value, tagField?.condition || "IS");
  };

  useEffect(() => {
    const newTags = Object.entries(getTagsValue()).map(([tag, value]) => ({
      tag: tag as string,
      value: value as string,
    }));

    setTags(newTags.length ? newTags : [{ tag: null, value: null }]);
  }, [getTagsValue]);

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap={5} marginBottom={5}>
        <Box display="flex" alignItems="flex-start" gap={5} flexDirection="column">
          {fields.map((field) => (
            <Box
              flexGrow={1}
              key={field.property}
              display="flex"
              flexWrap={{
                xs: "wrap",
                sm: "nowrap",
              }}
              gap={2}
              alignItems="center"
              minWidth="unset"
              sx={{
                ...(field.property === "dates" && {
                  maxWidth: "unset",
                }),
              }}
              width={1}
            >
              {field.property === "dates" ? (
                <>
                  <Box width={105} flexShrink={0}>
                    <Select
                      fullWidth
                      inTable
                      smallWidth
                      value={filter.dates?.field || "updated_at"}
                      list={dateFields}
                      onChange={setDatesByField}
                    />
                  </Box>

                  <DatePicker setFullWidth range dates={getDatesFromFilterValue(filter.dates)} setDates={setDates} />

                  <ButtonGroupComponent
                    exclude={filter.dates?.condition === "IS_NOT"}
                    onChange={(exclude: boolean) =>
                      setFilter((s: FilterData) => ({
                        ...s,
                        dates: {
                          ...s.dates!,
                          condition: exclude ? "IS_NOT" : "IS",
                        },
                      }))
                    }
                  />
                </>
              ) : (
                <>
                  <Typography
                    width={{ xs: "auto", sm: 105 }}
                    key={field.property}
                    height={{ xs: "auto", sm: TABLE_HEIGHT }}
                    variant="main"
                    whiteSpace="nowrap"
                    justifyContent="flex-end"
                    display="flex"
                    alignItems="center"
                    flexShrink={0}
                  >
                    {field.name}
                  </Typography>

                  <MultiSelect
                    limitTags={2}
                    fullWidth
                    multiple
                    placeholder={`Select ${field.name}`}
                    value={getField(field.property)?.values || []}
                    list={(searchData[field.property as keyof InventorySearchData] as string[]).map((t) => ({
                      id: t,
                      name: t,
                    }))}
                    onChange={(val) =>
                      changeValue(field.property, val?.id || val, getField(field.property)?.condition || "IS_ONE_OF")
                    }
                  />

                  <ButtonGroupComponent
                    disabled={!getField(field.property)?.values?.length}
                    exclude={getField(field.property)?.condition === "IS_NOT_ONE_OF"}
                    onChange={(exclude) =>
                      changeValue(
                        field.property,
                        getField(field.property)?.value as string,
                        exclude ? "IS_NOT_ONE_OF" : "IS_ONE_OF",
                      )
                    }
                  />
                </>
              )}
            </Box>
          ))}
        </Box>

        {!!defaultTags.length && (
          <Box display="flex" alignItems="center" gap={2} flexWrap="wrap">
            <Box display="flex" gap={2} flexDirection="column" alignItems="center">
              <Typography variant="main">Tags</Typography>
              <Button
                disabled={tags.some((t) => !t.tag || !t.value)}
                onClick={addTag}
                icon={Add}
                onlyIcon
                smallWidth
                inTable
              />
            </Box>

            {tags.map((t, index) => (
              <InventoryTag
                key={`${t.tag}-${t.value}`}
                index={index}
                tags={tags}
                setTags={setTags}
                setTagsValue={setTagsValue}
                selectedAccounts={selectedAccounts}
                defaultTags={defaultTags}
              />
            ))}
          </Box>
        )}
      </Box>

      <Divider
        sx={{
          marginTop: 3,
          marginBottom: 5,
        }}
      />

      <Filter
        nested
        type={FILTER_PAGES.INVENTORY_DETAILS}
        fields={detailsFilterFields}
        currentFilter={detailsFilter}
        setCurrentFilter={setDetailsFilterHandler}
        entities={detailsFilterEntities}
        maxNestedElements={1}
        fieldOnlyOnce
      />

      <Divider
        sx={{
          marginTop: 3,
          marginBottom: 5,
        }}
      />

      <DataStats
        setCurrentItems={(val: DataStatsItem[]) => changeValue(FILTER_PAGES.DATA_STATS, JSON.stringify(val), "IS")}
        currentItems={getDataStatsValue()}
        title="Data Stats"
        fields={detailsFilterFields}
      />
    </Box>
  );
};

export default inject("store")(observer(InventoryFilter));
