import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";

import { Button, Select, TableItemProps } from "components/ui";
import { CursorData } from "tools/cursorHandler/cursorHandler.types";

export const DEFAULT_ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50, 100];
export const DEFAULT_ROWS_PER_PAGE = 25;

type TablePaginationProps = {
  rowsPerPageOptions?: number[];
  rowsPerPage?: number;
  data: TableItemProps[];
  currentPage: number;
  setCurrentPage: (page: number) => void;
  setRowsPerPage: (page: number) => void;
  cursorData?: CursorData<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export const TablePagination = ({
  data,
  rowsPerPageOptions = DEFAULT_ROWS_PER_PAGE_OPTIONS,
  rowsPerPage = DEFAULT_ROWS_PER_PAGE,
  currentPage,
  setCurrentPage,
  setRowsPerPage,
  cursorData,
}: TablePaginationProps) => {
  const getTotalPages = useCallback(() => {
    const total = cursorData ? cursorData?.total : data?.length;
    const count = Math.ceil((total || 0) / (rowsPerPage || 1));

    if (cursorData) return count;

    return Math.max(count, 1);
  }, [data, rowsPerPage, cursorData]);

  const [totalPages, setTotalPages] = useState(getTotalPages());

  useEffect(() => {
    if (cursorData && cursorData?.currentPage !== currentPage) {
      setCurrentPage(cursorData?.currentPage);
    }
  }, [cursorData, currentPage, setCurrentPage]);

  useEffect(() => {
    if (rowsPerPageOptions.includes(rowsPerPage)) return;

    setRowsPerPage(rowsPerPageOptions[0]);
  }, [rowsPerPage, rowsPerPageOptions, setRowsPerPage]);

  useEffect(() => setTotalPages(getTotalPages()), [getTotalPages]);

  const changePage = (value: number) => {
    const newPage = currentPage + value;

    if (newPage < 0) return;

    if (!cursorData && newPage >= totalPages) return;

    if (cursorData && newPage && !cursorData.cursors[newPage]) return;

    setCurrentPage(currentPage + value);
  };

  const showFirstPage = !!currentPage;
  const showLastPage = cursorData ? !!cursorData.cursors[currentPage + 1] : totalPages > currentPage + 1;

  return (
    <Typography
      data-testid="tablePagination"
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      color="text.secondaryDark"
      variant="main"
      fontWeight={600}
      flexWrap="wrap"
      gap={4}
    >
      <Box alignItems="center" display="flex" gap={2}>
        <Select
          extraSmall
          inTable
          list={rowsPerPageOptions.map((option) => ({ id: option, name: option }))}
          value={rowsPerPage}
          onChange={(val) => setRowsPerPage(+val)}
        />
        Item{rowsPerPage > 1 ? "s" : ""} per page
      </Box>

      <Box display="flex" alignItems="center" gap={2}>
        {!cursorData && (
          <Select
            extraSmall
            inTable
            list={new Array(totalPages).fill(0).map((_, index) => ({ id: index, name: index + 1 }))}
            value={currentPage}
            onChange={(val) => setCurrentPage(+val)}
          />
        )}
        {cursorData ? `${currentPage + 1} ` : ""}
        {!!totalPages && <>of {totalPages} </>}
        page{totalPages > 1 ? "s" : ""}
      </Box>

      <Box display="flex" gap={2}>
        {[-1, 1].map((value) => (
          <Button
            data-testid={`tablePagination${value === -1 ? "Previous" : "Next"}`}
            key={value}
            variant="text"
            extraSmall
            onClick={() => changePage(value)}
            customIcon={<NavigateNext sx={{ transform: `rotate(${value === -1 ? "180deg" : "0deg"})` }} />}
            buttonSx={{
              ...((value === -1 && !showFirstPage) || (value === 1 && !showLastPage)
                ? { opacity: 0.25, cursor: "default" }
                : { cursor: "pointer" }),
            }}
          />
        ))}
      </Box>
    </Typography>
  );
};
