import React, { ChangeEvent, useEffect, useState } from "react";
import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { inject, observer } from "mobx-react";

import { Input } from "components/ui/Input/input";
import { Button } from "components/ui/Button/button";
import { Tooltip } from "components/ui/Tooltip/tooltip";
import { FILTER_PAGES, FilterData, FilterDataItem, FilterItem, SavedFilter } from "store/types";
import { RootStore } from "store/root.store";
import { checkFilterFieldsError, getDataStatsError } from "tools";

type FilterActionsProps = {
  store?: RootStore;
  type: FILTER_PAGES;
  filterName: string;
  saveFilter: boolean;
  clear: () => void;
  filter: FilterDataItem;
  selectedFilterName?: string;
  setFilterName: (val: string) => void;
  setSaveFilter: (val: boolean) => void;
  setCurrentFilter: (val: FilterDataItem) => void;
  setSelectedFilterName?: (val: string) => void;
  savedFilters: SavedFilter[];
  setSavedFilters: (val: SavedFilter[]) => void;
};

const FilterActions = ({
  store,
  type,
  filterName,
  setFilterName,
  saveFilter,
  setSaveFilter,
  clear,
  filter,
  selectedFilterName,
  setCurrentFilter,
  setSelectedFilterName,
  savedFilters,
  setSavedFilters,
}: FilterActionsProps) => {
  const [buttonError, setButtonError] = useState("");

  useEffect(() => {
    const inventoryDetails = (filter as FilterData).items?.find(
      (f) => (f as FilterData).type === FILTER_PAGES.INVENTORY_DETAILS,
    );
    const isValid =
      (filter as FilterData).type === FILTER_PAGES.INVENTORY &&
      inventoryDetails &&
      (inventoryDetails as FilterData).items.length === 1 &&
      !((inventoryDetails as FilterData).items[0] as FilterItem).field &&
      !((inventoryDetails as FilterData).items[0] as FilterItem).value;

    let fieldsError = isValid ? "" : checkFilterFieldsError(filter);

    if (!fieldsError) {
      const dataStats = (filter as FilterData).items?.find((f) => (f as FilterItem).field === FILTER_PAGES.DATA_STATS);

      try {
        fieldsError = getDataStatsError(JSON.parse((dataStats as FilterItem).value as string));
      } catch {}
    }

    if (fieldsError) {
      setButtonError(fieldsError); // eslint-disable-line quotes

      return;
    }

    if (saveFilter) {
      if (!filterName) {
        setButtonError('Set "name" for the filter'); // eslint-disable-line quotes

        return;
      }

      if (filterName !== selectedFilterName && savedFilters.find((s) => s.name === filterName)) {
        setButtonError("Filter with this name already exist");

        return;
      }
    }

    return setButtonError("");
  }, [setButtonError, filter, filterName, selectedFilterName, savedFilters, saveFilter]);

  const setCurrentFilterHandler = async () => {
    if (buttonError) return;

    setCurrentFilter(filter);

    if (saveFilter) {
      const id = savedFilters.find((s) => s.name === filterName)?.id;

      const newFilters = (await store!.filter.saveFilter({
        type,
        name: filterName,
        filter,
        id,
      })) as SavedFilter[];

      setSavedFilters(newFilters);
      setSelectedFilterName?.(filterName);
      setSaveFilter(false);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems={{ xs: "flex-start", laptop: "flex-end" }}
      flexDirection={{ xs: "column", laptop: "row" }}
      flexWrap="wrap"
      gap={5}
    >
      <Box width={{ xs: 1, sm: "unset" }}>
        <FormControlLabel
          control={
            <Switch
              checked={saveFilter}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setSaveFilter(event.target.checked)}
            />
          }
          label={
            <Typography marginLeft={4} variant="main">
              Save filter settings
            </Typography>
          }
        />

        <Box
          width={1}
          marginTop={3}
          display="flex"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          gap={2}
        >
          <Typography whiteSpace="nowrap" variant="main">
            Custom label:
          </Typography>

          <Input
            placeholder="Filter name"
            sx={{ width: { xs: 1, sm: 320 }, marginTop: { xs: 1, sm: 0 } }}
            disabled={!saveFilter}
            value={filterName}
            onChange={setFilterName}
          />
        </Box>
      </Box>

      <Box gap={2} display="flex" justifyContent="space-between" width={{ xs: 1, laptop: "auto" }} marginLeft="auto">
        <Tooltip title={buttonError}>
          <Button inTable onClick={setCurrentFilterHandler} disabled={!!buttonError} text="Add filter" />
        </Tooltip>
        <Button
          sx={{
            svg: {
              fontSize: 16,
            },
          }}
          icon={Close}
          inTable
          onClick={clear}
          text="Clear"
          variant="text"
        />
      </Box>
    </Box>
  );
};

export default inject("store")(observer(FilterActions));
