import React, { useCallback, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useDrop } from "react-dnd";

import {
  FILTER_PAGES,
  RefreshSavedWidgetsFilters,
  Widget,
  WIDGET_FILTER_TYPES,
  WIDGET_MANAGER_VIEWS,
} from "store/types";
import { RootStore } from "store/root.store";
import { DashboardViewItem } from "routes/DashboardRoute/components/DashboardView/components";
import { getWidgetType } from "routes/DashboardRoute/dashboard.utils";

export const dashboardGroupByList = [
  {
    id: "rule_id",
    name: "Rule",
  },
  {
    id: "resource_id",
    name: "Resource",
  },
];

type DashboardViewType = {
  store?: RootStore;
  currentWidgets: Widget[];
  refreshSavedWidgetsFilters: RefreshSavedWidgetsFilters;
  editMode?: WIDGET_MANAGER_VIEWS;
  search?: string;
  changeWidgetStatus?: (id: string, disabled: boolean) => void;
  setCurrentWidgets?: (handler: (widgets: Widget[]) => Widget[]) => void;
};

const DashboardView = ({
  store,
  currentWidgets,
  refreshSavedWidgetsFilters,
  changeWidgetStatus,
  setCurrentWidgets,
  editMode,
  search,
}: DashboardViewType) => {
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isLgXl = useMediaQuery(theme.breakpoints.down("lgXl"));

  const itemsPerRow = isLg ? (store?.ui.infoItem ? 3 : 4) : store?.ui.infoItem ? 4 : isLgXl ? 5 : 6;

  const { widgets } = store!;

  const [widgetsById, setWidgetsById] = useState<Record<string, Widget>>({});

  useEffect(() => {
    setWidgetsById(currentWidgets.reduce((acc, i) => ({ ...acc, [i.id]: i }), {}));
  }, [currentWidgets]);

  const [, drop] = useDrop(() => ({
    accept: FILTER_PAGES.WIDGET_SETTINGS,
  }));

  // useEffect(
  //   () => () =>
  //     issues.forEach((issue) =>
  //       (
  //         dashboard[issue.prop as keyof DashboardStore] as CursorData<DashboardIssue>
  //       )?.handler?.resetCursorData(),
  //     ),
  //   [dashboard],
  // );

  const filterWidget = useCallback(
    (widget: Widget) =>
      editMode
        ? !search || [widget?.name, widget?.comment].find((i) => i?.toLowerCase().includes(search.toLowerCase()))
        : !widget.disabled,
    [editMode, search],
  );

  const getCountOfTheSameElementsAndCheckNextElement = useCallback(
    (index: number): [number, boolean] => {
      const widgetSettings = [...widgets.widgetSettings]
        .sort((a, b) => (widgetsById[a.id]?.order ?? a.order) - (widgetsById[b.id]?.order ?? b.order))
        .filter(filterWidget);

      if (!widgetSettings[index]) return [0, false];

      const isNextElementTheSame =
        getWidgetType(widgetSettings[index].widgetType) === getWidgetType(widgetSettings[index + 1]?.widgetType);

      const previousElements = widgetSettings.slice(0, index);

      const indexBefore = previousElements
        .reverse()
        .findIndex((w) => getWidgetType(w.widgetType) !== getWidgetType(widgetSettings[index].widgetType));

      if (indexBefore === -1) {
        return [previousElements.length, isNextElementTheSame];
      }

      return [index - previousElements.length + indexBefore, isNextElementTheSame];
    },
    [widgets.widgetSettings, filterWidget, widgetsById],
  );

  const itemsAfterCounter = useCallback(
    (index: number) => {
      const [previousCount, isNextElementTheSame] = getCountOfTheSameElementsAndCheckNextElement(index);

      return isNextElementTheSame ? 0 : itemsPerRow - 1 - (previousCount % itemsPerRow);
    },
    [getCountOfTheSameElementsAndCheckNextElement, itemsPerRow],
  );

  const isTableWide = useCallback(
    (index: number) => {
      const [previousCount, isNextElementTheSame] = getCountOfTheSameElementsAndCheckNextElement(index);

      return isNextElementTheSame ? false : !(previousCount % 2);
    },
    [getCountOfTheSameElementsAndCheckNextElement],
  );

  const getItemWidth = (widget: Widget, index: number) => {
    if (widget.widgetType === WIDGET_FILTER_TYPES.COUNTER) {
      const countAfter = itemsAfterCounter(index);

      return `calc(${100 / itemsPerRow + (100 / itemsPerRow) * countAfter}% - ${theme.spacing(
        (5 * (itemsPerRow - 1 - countAfter)) / (itemsPerRow - countAfter),
      )})`;
    }

    if (widget.widgetType === WIDGET_FILTER_TYPES.ISSUES_TABLE || isTableWide(index)) return 1;

    return `calc(50% - ${theme.spacing(5 / 2)})`;
  };

  const reOrder = (dragWidget: Widget, hoverWidget: Widget) =>
    setCurrentWidgets?.((curr) =>
      curr.map((w) => {
        if (![dragWidget.id, hoverWidget.id].includes(w.id)) return w;

        return {
          ...w,
          order: dragWidget.id === w.id ? hoverWidget.order : dragWidget.order,
        };
      }),
    );

  return (
    <Box display="flex" flexWrap="wrap" gap={{ xs: 3, laptop: 5 }} ref={drop}>
      {currentWidgets
        .sort((a, b) => a.order - b.order)
        .filter(filterWidget)
        .map((w, index) => (
          <Box
            key={w.id}
            display="flex"
            width={{
              xs: 1,
              laptopLg: getItemWidth(w, index),
            }}
            sx={{
              opacity: w.disabled ? 0.5 : 1,
            }}
          >
            <DashboardViewItem
              index={index}
              widget={w}
              refreshSavedWidgetsFilters={refreshSavedWidgetsFilters}
              editMode={editMode}
              changeWidgetStatus={changeWidgetStatus}
              isTableWide={isTableWide(index)}
              countAfter={itemsAfterCounter(index)}
              reOrder={reOrder}
            />
          </Box>
        ))}
    </Box>
  );
};

export default inject("store")(observer(DashboardView));
