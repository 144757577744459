import React, { MouseEvent } from "react";
import { Box, Switch, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Add } from "@mui/icons-material";
import { inject, observer } from "mobx-react";

import { Button, ElementWrapper, TableFields, TableItemProps, TaleWrapper } from "components/ui";
import { INTEGRATION, Integration } from "store/types";
import { AddIntegrationModal, ViewIntegrationModal } from "components/modals";
import { RootStore } from "store/root.store";

type IntegrationItemProps = {
  store?: RootStore;
  integration: Integration;
  enabled?: boolean;
  fields?: TableFields[];
  data?: TableItemProps[];
  type?: INTEGRATION;
  noMoreConnects?: boolean;
};

export const addIntegrationTitles = {
  [INTEGRATION.JIRA]: "Connect Your Jira Account",
  [INTEGRATION.EMAIL]: "Connect Your Email",
  [INTEGRATION.TERRAFORM]: "Integration Options",
  [INTEGRATION.AWS_COST]: "Connect Your AWS Cost Report",
  [INTEGRATION.GCP_COST]: "Connect Your GCP Cost Report",
  [INTEGRATION.HTTP]: "Connect Your HTTP Endpoint",
  [INTEGRATION.GITHUB]: "Connect Your Github Account",
  [INTEGRATION.SLACK]: "Connect Your Slack Account",
  [INTEGRATION.API_KEY]: "Generate an API Key",
};

const viewIntegrationTitles = {
  [INTEGRATION.JIRA]: "Added Jira Account",
  [INTEGRATION.EMAIL]: "Added Email",
  [INTEGRATION.TERRAFORM]: "Added Terraform Integration",
  [INTEGRATION.AWS_COST]: "Connected AWS Cost Report",
  [INTEGRATION.GCP_COST]: "Connected GCP Cost Report",
  [INTEGRATION.HTTP]: "Added HTTP Endpoint",
  [INTEGRATION.GITHUB]: "Added Github Account",
  [INTEGRATION.SLACK]: "Added Slack Account",
  [INTEGRATION.API_KEY]: "API Keys",
};

const IntegrationItem = ({ store, integration, enabled, fields, data, type, noMoreConnects }: IntegrationItemProps) => {
  const theme = useTheme();
  const isSmallMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const view = () =>
    store?.ui.openModal({
      title: `${viewIntegrationTitles[integration.type]}${data && data.length > 1 ? "s" : ""}`,
      maxWidth: integration.type === INTEGRATION.GITHUB ? "md" : "lg",
      component: <ViewIntegrationModal integration={integration} fields={fields} type={type} />,
    });

  const connect = () =>
    store?.ui.openModal({
      title: `${addIntegrationTitles[integration.type]}`,
      maxWidth: [INTEGRATION.AWS_COST, INTEGRATION.GCP_COST].includes(integration.type) ? "laptopLg" : "md",
      component: <AddIntegrationModal integration={integration} />,
    });

  return (
    <TaleWrapper>
      <ElementWrapper>
        <>
          <Typography
            variant="h4"
            fontSize={16}
            display="flex"
            alignItems="center"
            gap={2}
            sx={{
              svg: {
                width: 30,
                height: 30,
              },
            }}
          >
            {integration.icon}
            {integration.title}
            <Box marginLeft="auto" display="none">
              <Switch checked={enabled} onClick={(e: MouseEvent<HTMLElement>) => e.stopPropagation()} />
            </Box>
          </Typography>

          {!!integration.comment && <Typography variant="table">{integration.comment}</Typography>}

          <Box marginTop="auto" display="flex" alignItems="center" justifyContent="flex-end" flexWrap="wrap" gap={1}>
            {!!data?.length && (
              <Button
                fullWidthOnMobile={isSmallMobile}
                text={noMoreConnects ? `View integration${data.length > 1 ? "s" : ""}` : "View"}
                variant="text"
                onClick={view}
              />
            )}

            {!noMoreConnects && (
              <Button
                fullWidthOnMobile={isSmallMobile}
                buttonSx={{ marginLeft: "auto" }}
                text={integration.type === INTEGRATION.API_KEY ? "New" : "Connect"}
                icon={Add}
                onClick={connect}
              />
            )}
          </Box>
        </>
      </ElementWrapper>
    </TaleWrapper>
  );
};

export default inject("store")(observer(IntegrationItem));
