import { Typography } from "@mui/material";

import { DollarSvg } from "components/ui/Price/svg";

export const Price = ({ price, historyComponent }: { price: number; historyComponent?: boolean }) => (
  <Typography
    whiteSpace="nowrap"
    borderRadius={2}
    variant="main"
    paddingX={2}
    paddingY={1}
    display="inline-flex"
    alignItems="center"
    justifyContent="center"
    bgcolor="background.tableRow"
    gap={historyComponent ? 2 : 1.5}
  >
    <DollarSvg />
    {historyComponent && (
      <Typography variant="main" fontWeight={700}>
        Price (month to date)
      </Typography>
    )}
    {+price && +price < 0.01 ? "< $0.01" : `${(+price || 0).toFixed(2)}`}
  </Typography>
);
