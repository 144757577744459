import { SEVERITY } from "components/ui";

export type Installation = {
  installationId: number;
  name: string;
};

export type Vulnerability = {
  id: number;
  created_at: string;
  html_url: string;
  security_advisory: {
    summary: string;
    description: string;
    severity: SEVERITY;
    cve_id: string;
    cvss: { score: number; vector_string: string };
    cwes: { cwe_id: string; name: string }[];
  };
  security_vulnerability: {
    vulnerable_version_range: string;
    severity: SEVERITY;
    package: {
      name: string;
    };
  };
  state: string;
};

export enum GITHUB_VULNERABILITY_STATE {
  OPEN = "open",
  FIXED = "fixed",
  DISMISSED = "dismissed",
}

export enum GITHUB_VULNERABILITY_SEVERITY {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
  CRITICAL = "critical",
}
