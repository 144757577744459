import { makeAutoObservable } from "mobx";

import { RootStore } from "store/root.store";
import { fetchApiKeys, addApiKey, removeApiKey, rotateApiKey, updateApiKey } from "libs/api/methods";
import { AddApiKey, ApiKey, FEATURE, UpdateApiKey } from "store/types";
import { copyToClipboard } from "tools";

export class ApiKeysStore {
  apiKeys: ApiKey[] = [];

  constructor(readonly rootStore: RootStore) {
    this.rootStore = rootStore;

    this.fetchApiKeys = this.fetchApiKeys.bind(this);

    makeAutoObservable(this);
  }

  async fetchApiKeys() {
    try {
      if (this.rootStore.auth.isFeatureEnabled(FEATURE.API_ACCESS)) {
        this.apiKeys = await fetchApiKeys();
      }
    } catch {}
  }

  async updateApiKey(data: UpdateApiKey, rotate?: boolean) {
    let id: string;

    if (rotate) {
      const { key, details } = await rotateApiKey(data);

      id = details.id;

      copyToClipboard({ value: key, title: "New key is copied:", message: key, duration: 10000 });
    } else {
      const res = await updateApiKey(data);

      id = res.id;
    }

    this.apiKeys = this.apiKeys.map((key) => {
      if (key.id !== data.id) return key;

      return { ...key, ...data, id };
    });
  }

  async addApiKey(data: AddApiKey) {
    const { key, details } = await addApiKey(data);

    this.apiKeys.push(details);

    return key;
  }

  async removeKey(key: ApiKey) {
    await removeApiKey(key);

    this.apiKeys = this.apiKeys.filter((i) => i.id !== key.id);
  }
}
