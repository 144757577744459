import React from "react";
import { Box } from "@mui/material";

import { ErrorText, FormikInputValueTypes, Label } from "components/ui";
import { defaultFontFamily } from "theme/commonVariables";

export type TextareaProps = {
  error?: string;
  label?: string;
  required?: boolean;
  name?: string;
  placeholder?: string;
};

export const Textarea = ({
  required,
  name,
  placeholder,
  value,
  onChange,
  label,
  error,
}: TextareaProps & FormikInputValueTypes) => (
  <Box data-testid="textarea">
    {!!label && <Label text={label} />}

    <Box position="relative" display="flex" alignItems="center" width={1}>
      <Box
        component="textarea"
        required={required}
        name={name}
        placeholder={placeholder || label}
        onChange={onChange}
        value={value}
        width={1}
        padding={3}
        borderRadius={1}
        fontSize={14}
        lineHeight="22px"
        fontFamily={defaultFontFamily}
        sx={{
          color: "various.inputColor",
          backgroundColor: "background.inputBG",
          border: "1px solid",
          borderColor: "various.inputBorder",
          resize: "none",
          "&::placeholder": { color: "various.inputBorder" },
          "&:focus": { border: "1px solid", borderColor: "various.inputBorder", outline: "none" },
        }}
      />
    </Box>

    {!!error && <ErrorText text={error} marginTop={1} />}
  </Box>
);
