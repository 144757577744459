import store from "store";
import { NONE_SELECT_VALUE, TableFields, TableItemProps, TableRelatedData, TableSortField } from "components/ui";
import { getFieldTextValue } from "tools";
import { FILTER_TYPES, TableFiltersType, TableFilterValues } from "components/ui/Table/components";
import { ACTION_FIELD_PROPERTY } from "consts";
import { AccountCloudMapItem, InventoryAttribution } from "store/types";

export const groupByProperties = <K>({
  data,
  properties,
  sortProperty = "created_at" as keyof K,
  dataCB = (val) => val,
}: {
  data: K[];
  properties: Array<keyof K>;
  sortProperty?: keyof K;
  dataCB?: (val: K) => K;
}) => {
  const grouped = data.reduce((acc: Record<string, K[]>, item: K) => {
    const property = properties.map((p) => item[p]).join("-");

    acc[property] = acc[property] || [];
    acc[property].push(item);

    return acc;
  }, {});

  return Object.values(grouped).map((items) => {
    items.sort((a, b) => (a[sortProperty] > b[sortProperty] ? -1 : 1));

    return dataCB({ ...items[0], grouped: items });
  });
};

export const filterTableData = ({
  data,
  search,
  fields,
  filters,
  filterValues,
}: {
  data: TableItemProps[];
  fields: TableFields[];
  search?: string;
  filters?: TableFiltersType[];
  filterValues?: TableFilterValues;
}) => {
  if (!data) return [];

  const searched = data.filter((item) => {
    if (!search) return true;

    return fields.some((field) => {
      if (field.property === ACTION_FIELD_PROPERTY) return;

      return getFieldTextValue(item, field).toString().toLowerCase().includes(search.toLowerCase());
    });
  });

  return searched.filter(
    (item) =>
      !filters ||
      !filterValues ||
      Object.entries(filterValues).every(([filterType, value]) => {
        if (!value || value === NONE_SELECT_VALUE) return true;

        const initialFilter = filters.find((f) => f.type === filterType);

        if (!initialFilter) return true;

        try {
          if (filterType === FILTER_TYPES.LAST_TIME) {
            return +new Date(item[initialFilter.property]).getTime() + +value > Date.now();
          }

          return item[initialFilter.property] === value;
        } catch {
          return false;
        }
      }),
  );
};

export const sortFieldData = ({
  data,
  fields,
  sortField,
}: {
  data: TableItemProps[];
  fields: TableFields[];
  sortField?: TableSortField;
}) => {
  const sort = fields.find((f) => f.property === sortField?.field);

  if (!sort) return data;

  return data.sort((a, b) => {
    const valA = getFieldTextValue(a, sort);
    const valB = getFieldTextValue(b, sort);
    const value = sort.sortByNumber ? valA - valB : valA?.toLowerCase?.() > valB?.toLowerCase?.() ? 1 : -1;

    return value * (sortField?.order || 1);
  });
};

export const transformWithTableRelatedData = ({
  data,
  fields,
  filters,
  filterValues,
  tableRelatedData,
}: {
  data: TableItemProps[];
  fields: TableFields[];
  filters?: TableFiltersType[];
  filterValues?: TableFilterValues;
  tableRelatedData?: TableRelatedData;
}) => {
  const filtered = filterTableData({ data, search: tableRelatedData?.search, fields, filters, filterValues });

  return sortFieldData({ data: filtered, fields, sortField: tableRelatedData?.sortField });
};

export const exportCsv = ({
  data,
  fields,
  fileName,
  withTitle,
}: {
  data: TableItemProps[];
  fields: TableFields[];
  fileName: string;
  withTitle?: boolean;
}) => {
  const titles = withTitle ? [fields.map((f) => `"${f.name}"`)] : [];
  const rows = data.map((item) => fields.map((field) => `"${getFieldTextValue(item, field).replace(/#/g, "_")}"`));

  const csvContent = `data:text/csv;charset=utf-8,${[...titles, ...rows].map((e) => e.join(",")).join("\n")}`;

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");

  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${fileName}.csv`);

  document.body.appendChild(link);

  link.click();
  link.remove();
};

export const copyToClipboard = ({
  value,
  showMessage = true,
  title = "Copied!",
  message,
  duration = 1000,
}: {
  value?: string;
  showMessage?: boolean;
  title?: string;
  message?: string;
  duration?: number;
}) => {
  if (!value) return;

  navigator.clipboard?.writeText?.(value);

  if (showMessage) {
    store?.ui.showMessage({ title, message, duration });
  }
};

export const transformDataToCloudMap = <K extends { id: string; name: string; type: string }>(
  item: K,
  data: K[],
): AccountCloudMapItem[][] =>
  data.map((i) => [
    {
      id: item.id,
      name: item.name,
      type: item.type,
      data: item,
    },
    {
      id: i.id,
      name: i.name,
      type: i.type,
      data: i,
    },
  ]);

export const getChartValues = (value: number, count: number) => {
  const qwerty = value / count;
  const { length } = qwerty.toFixed(0);
  const min = Math.ceil((qwerty * 2) / 10 ** (length - 1));
  const chartValue = (min * count * (10 ** (length - 1) / 2)) / count;

  return new Array(count + 1)
    .fill(0)
    .map((_, i) => chartValue * i)
    .reverse();
};

export const getInventoryLastUpdateData = (assetAttribution?: InventoryAttribution) => {
  const email =
    assetAttribution?.principal?.email ||
    assetAttribution?.principal?.Email ||
    assetAttribution?.Principal?.email ||
    assetAttribution?.Principal?.Email;
  const id =
    assetAttribution?.principal?.id ||
    assetAttribution?.principal?.ID ||
    assetAttribution?.principal?.Id ||
    assetAttribution?.Principal?.id ||
    assetAttribution?.Principal?.ID ||
    assetAttribution?.Principal?.Id;
  const agent = assetAttribution?.agent || assetAttribution?.Agent;

  return {
    lastUpdatedBy: email || id || "",
    lastUpdatedBySource: agent?.toLowerCase() === "unknown" ? "" : agent || "",
  };
};
