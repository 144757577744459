import { api } from "libs";
import { AddAwsCostIntegration, AddGcpCostIntegration, AwsCostIntegration, CostIntegration } from "store/types";
import { AWS_INTEGRATION, AWS_INTEGRATION_BY_ACCOUNT_ID } from "libs/api/endpoints";

export const fetchCostIntegrations = async (): Promise<CostIntegration[]> => {
  const res = await api.get(AWS_INTEGRATION, {
    doNotShowErrorMessage: true,
    errorMessage: {
      title: "Failed to fetch Cost Report Integrations",
    },
  });

  return res || [];
};

export const addAwsCostIntegration = async (data: AddAwsCostIntegration): Promise<void> =>
  api.post(AWS_INTEGRATION_BY_ACCOUNT_ID(data.cloud_account_id), data, {
    errorMessage: {
      title: "Failed to add AWS Cost Report",
    },
  });

export const addGcpCostIntegration = async (data: AddGcpCostIntegration): Promise<void> =>
  api.post(
    AWS_INTEGRATION_BY_ACCOUNT_ID(data.cloud_account_id),
    {
      dataset_name: data.dataset_name,
      billing_project_id: data.billing_project_id,
    },
    {
      errorMessage: {
        title: "Failed to add CGP Cost Report",
      },
    },
  );

export const updateAwsIntegration = (integration: AddAwsCostIntegration): Promise<void> =>
  api.put(AWS_INTEGRATION_BY_ACCOUNT_ID(integration.cloud_account_id), integration, {
    errorMessage: {
      title: "Failed to update the AWS Integration",
    },
  });

export const updateGcpIntegration = (integration: AddGcpCostIntegration): Promise<void> =>
  api.put(AWS_INTEGRATION_BY_ACCOUNT_ID(integration.cloud_account_id), integration, {
    errorMessage: {
      title: "Failed to update the CGP Integration",
    },
  });

export const removeCostIntegration = (accountId: string): Promise<void> =>
  api.delete(AWS_INTEGRATION_BY_ACCOUNT_ID(accountId), {
    errorMessage: {
      title: "Failed to remove the Cost Integration",
    },
  });

export const getAwsCostIntegrationStatus = (accountId: string): Promise<AwsCostIntegration> =>
  api.get(AWS_INTEGRATION_BY_ACCOUNT_ID(accountId), {
    doNotShowErrorMessage: true,
  });
