import { TableFields, TableItemProps, Tooltip } from "components/ui";
import { FILTER_OPERATOR } from "store/types";
import { TABLE_TRUNCATE_LENGTH } from "consts";

export type FieldRenderOptions = { inTable?: boolean } | undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const truncateValue = (val: unknown, isLink?: boolean): any => {
  if (typeof val !== "string" || val.length <= TABLE_TRUNCATE_LENGTH) return val;

  const newValue = `${val.slice(0, TABLE_TRUNCATE_LENGTH)}...`;

  if (isLink) return newValue;

  return (
    <Tooltip title={val} placement="top">
      {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
      <>{newValue}</>
    </Tooltip>
  );
};

export const getFieldValue = ({
  field,
  item,
  inTable,
}: {
  field: TableFields;
  item: TableItemProps;
  inTable?: boolean;
}) => {
  const value = field.render?.(item, { inTable }) ?? item?.[field.property];

  return field.doNotTruncate || !inTable ? value : truncateValue(value);
};

export const getFieldTextValue = (tableItem: TableItemProps, field: TableFields) => {
  try {
    return field.textValue?.(tableItem) ?? getFieldValue({ field, item: tableItem }) ?? "";
  } catch {
    return "";
  }
};

export const getFieldFilterValue = (tableItem: TableItemProps, field: TableFields) =>
  field.filterValue?.(tableItem) ?? getFieldTextValue(tableItem, field);

export const getParsableDataFromQuery = (parsableQuery: string) => {
  try {
    // @ts-ignore
    const [, query] = /^\((.+)\)$/.exec(parsableQuery) || ["", parsableQuery];

    return query.split(` ${FILTER_OPERATOR.AND} `).reduce((acc: Record<string, string | string[]>, i: string) => {
      const [field, ...rest] = i.split(":");
      const queryValue = rest.join(":");

      try {
        if (queryValue.startsWith("IN ")) {
          // @ts-ignore
          const [, value] = /^IN \[(.+)]$/.exec(queryValue);

          return {
            ...acc,
            [field]: value.split(" ").map((iField: string) => {
              const [, iValue] = /^"(.+)"$/.exec(iField);

              return iValue;
            }),
          };
        }

        if (/^\{(.+)}$/.exec(queryValue)) return { ...acc, [field]: queryValue };

        const [, value] = /^"(.+)"$/.exec(queryValue);

        return { ...acc, [field]: value };
      } catch {
        return acc;
      }
    }, {});
  } catch {
    return {};
  }
};
