import React, { ReactElement } from "react";
import { Box } from "@mui/material";

import { Button, ButtonWrapper } from "components/ui";

type ConfirmModalType = {
  component?: ReactElement | string;
  confirmText?: string;
  closeText?: string;
  setShowConfirmModalLoading?: (value: boolean) => void;
  onClose: (onCloseButtonClick?: boolean) => void;
  onConfirm?: () => void;
};

export const ConfirmModal = ({
  component = "",
  confirmText = "Confirm",
  closeText = "Cancel",
  setShowConfirmModalLoading,
  onClose,
  onConfirm,
}: ConfirmModalType) => {
  const close = async () => {
    try {
      await onConfirm?.();

      onClose();
    } catch {
      setShowConfirmModalLoading?.(false);
    }
  };

  return (
    <Box data-testid="confirmModal">
      {component}

      <ButtonWrapper>
        <>
          <Button onClick={() => onClose(true)} text={closeText} variant={onConfirm ? "text" : "contained"} />
          {onConfirm && <Button onClick={close} text={confirmText} />}
        </>
      </ButtonWrapper>
    </Box>
  );
};

export default ConfirmModal;
