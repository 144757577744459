import { ReactElement } from "react";
import { makeAutoObservable } from "mobx";
import { AxiosRequestConfig } from "axios";

import { RootStore } from "store/root.store";
import { TableSortField } from "components/ui";
import {
  MESSAGE_VARIANT,
  UI_THEME,
  UI_TABLES,
  UI_TABS,
  LOCAL_STORAGE_ITEM,
  DROPDOWNS,
  SESSION_STORAGE_ITEM,
  UIModal,
  UIInfoItem,
  UIDialog,
  Message,
} from "store/types";
import { MENU_SMALL_VIEW_WIDTH, MENU_WIDTH } from "consts";

export const NONE_SORT = "NONE_SORT";

export class UIStore {
  defaultMessageDuration = 5000;
  showModalLoading = false;
  showConfirmModalLoading = false;
  message: Message | null = null;
  modal: UIDialog | null = null;
  confirmModal: UIDialog | null = null;
  infoItem: UIInfoItem | null = null;
  theme: UI_THEME = UI_THEME.LIGHT;
  smallView = false;
  mobileMenuOpened = false;
  lastCookieCheck = Date.now();
  rowsPerPage: Record<string, number> = {};
  tableSort: Record<string, TableSortField | typeof NONE_SORT> = {};
  selectedDropdownValue: Record<string, string | string[]> = {};
  selectedTabs: Record<string, number> = {};
  pageForTableData: Record<string, number> = {};

  constructor(readonly rootStore: RootStore) {
    this.rootStore = rootStore;

    this.closeModal = this.closeModal.bind(this);
    this.closeConfirmModal = this.closeConfirmModal.bind(this);
    this.closeInfoItem = this.closeInfoItem.bind(this);
    this.setShowConfirmModalLoading = this.setShowConfirmModalLoading.bind(this);
    this.removeMessage = this.removeMessage.bind(this);
    this.changeTheme = this.changeTheme.bind(this);
    this.updateInfoItem = this.updateInfoItem.bind(this);
    this.openInfoItem = this.openInfoItem.bind(this);
    this.setPageForTableData = this.setPageForTableData.bind(this);
    this.clearPageForTableData = this.clearPageForTableData.bind(this);

    this.setUserUISettings();

    makeAutoObservable(this);
  }

  get urlAfterSignIn() {
    return sessionStorage.getItem(SESSION_STORAGE_ITEM.URL_AFTER_SIGN_IN);
  }

  get isLightTheme() {
    return this.theme === UI_THEME.LIGHT;
  }

  getMenuWidth(isMobile: boolean) {
    return isMobile ? 0 : this.smallView ? MENU_SMALL_VIEW_WIDTH : MENU_WIDTH;
  }

  setShowModalLoading(value: boolean) {
    this.showModalLoading = value;
  }

  setShowConfirmModalLoading(value: boolean) {
    this.showConfirmModalLoading = value;
  }

  changeTheme(mode: UI_THEME) {
    this.theme = mode;

    localStorage.setItem(LOCAL_STORAGE_ITEM.THEME_MODE_LOCAL_STORAGE, JSON.stringify(mode));
  }

  changeMenuView(smallView: boolean) {
    this.smallView = smallView;

    localStorage.setItem(LOCAL_STORAGE_ITEM.SMALL_VIEW_LOCAL_STORAGE, JSON.stringify(smallView));
  }

  changeMobileMenuOpened(mobileMenuOpened: boolean) {
    this.mobileMenuOpened = mobileMenuOpened;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showServerErrorMessage(error: any, config: AxiosRequestConfig) {
    if (!config || config.doNotShowErrorMessage || error.response?.status === 401) return;

    this.showErrorMessage({
      title: error?.response?.statusText,
      message: error?.response?.data?.error || error?.response?.data?.toString(),
      ...config.errorMessage,
    });
  }

  showErrorMessage(data?: Message) {
    this.showMessage({
      ...data,
      duration: data?.duration || 10000,
      title: data?.title || (data?.message ? "" : "Something went wrong"),
      variant: MESSAGE_VARIANT.ERROR,
    });
  }

  removeMessage() {
    this.message = null;
  }

  showMessage(data: Message) {
    const message = {
      id: Date.now().toString(),
      variant: MESSAGE_VARIANT.INFO,
      duration: this.defaultMessageDuration,
      ...data,
    };

    if (!this.message || this.message.id === message.id) {
      this.message = message;
    } else {
      setTimeout(() => {
        this.removeMessage();
        setTimeout(() => (this.message = message), 250);
      }, 500);
    }
  }

  openModal({ type, ...data }: UIModal) {
    this[type || "modal"] = {
      fullWidth: true,
      maxWidth: "md",
      ...data,
      open: true,
    };
  }

  openAccountModal(component: ReactElement) {
    this.openModal({
      title: "Add account",
      fullWidth: false,
      maxWidth: "lg",
      minWidth: "md",
      text: "Follow simple steps to add your account",
      component,
    });
  }

  openConfirmModal(data: UIModal) {
    this.openModal({
      maxWidth: "sm",
      forbidFullScreen: true,
      ...data,
      type: "confirmModal",
    });
  }

  closeModal() {
    this.modal?.onClose?.();

    this.modal = null;
    this.setShowModalLoading(false);
  }

  changeModalData(data: Partial<UIModal>) {
    if (!this.modal) return;

    this.modal = {
      ...this.modal,
      ...data,
    };
  }

  closeConfirmModal(onCloseButtonClick?: boolean) {
    if (onCloseButtonClick) {
      this.confirmModal?.onCloseButtonClick?.();
    }

    this.confirmModal = null;
    this.setShowConfirmModalLoading(false);
  }

  closeAllUIElement() {
    this.closeModal();
    this.closeConfirmModal();
    this.closeInfoItem();
  }

  checkCookie() {
    this.lastCookieCheck = Date.now();
  }

  openInfoItem(infoItem: UIInfoItem) {
    if (!infoItem?.data?.length) return;

    this.infoItem = infoItem;
  }

  closeInfoItem() {
    this.infoItem = null;
  }

  updateInfoItem(infoItemIssue: Partial<UIInfoItem>) {
    if (!this.infoItem) return;

    this.infoItem = {
      ...this.infoItem,
      ...infoItemIssue,
    };
  }

  setUrlAfterSignIn(url?: string) {
    if (url) {
      sessionStorage.setItem(SESSION_STORAGE_ITEM.URL_AFTER_SIGN_IN, url);
    } else {
      sessionStorage.removeItem(SESSION_STORAGE_ITEM.URL_AFTER_SIGN_IN);
    }
  }

  setRowsPerPage(type: UI_TABLES | string, value: number) {
    this.rowsPerPage = {
      ...this.rowsPerPage,
      [type]: value,
    };

    localStorage.setItem(LOCAL_STORAGE_ITEM.ROWS_PER_PAGE_LOCAL_STORAGE, JSON.stringify(this.rowsPerPage));
  }

  setTableSort(type: UI_TABLES | string, value: TableSortField) {
    this.tableSort = {
      ...this.tableSort,
      [type]: value || NONE_SORT,
    };

    localStorage.setItem(LOCAL_STORAGE_ITEM.TABLE_SORT_LOCAL_STORAGE, JSON.stringify(this.tableSort));
  }

  clearNoneTableSort() {
    if (!this.tableSort) return;

    Object.entries(this.tableSort).forEach(([key, value]) => {
      if (value === NONE_SORT) {
        delete this.tableSort?.[key];
      }
    });

    localStorage.setItem(LOCAL_STORAGE_ITEM.TABLE_SORT_LOCAL_STORAGE, JSON.stringify(this.tableSort));
  }

  setPageForTableData(type: UI_TABLES, value?: number) {
    if (value === undefined) {
      delete this.pageForTableData[type];
    } else {
      this.pageForTableData = {
        ...this.pageForTableData,
        [type]: value,
      };
    }
  }

  clearPageForTableData() {
    this.pageForTableData = {};
  }

  setSelectedDropdownValue(type: DROPDOWNS, value: string) {
    this.selectedDropdownValue = {
      ...this.selectedDropdownValue,
      [type]: value,
    };

    localStorage.setItem(LOCAL_STORAGE_ITEM.SELECTED_DROPDOWN_VALUES, JSON.stringify(this.selectedDropdownValue));
  }

  setSelectedTabs(type: UI_TABS, value: number) {
    this.selectedTabs = {
      ...this.selectedTabs,
      [type]: value,
    };

    localStorage.setItem(LOCAL_STORAGE_ITEM.SELECTED_TABS, JSON.stringify(this.selectedTabs));
  }

  setUserUISettings() {
    this.setLocalStorageItem("theme", LOCAL_STORAGE_ITEM.THEME_MODE_LOCAL_STORAGE);
    this.setLocalStorageItem("smallView", LOCAL_STORAGE_ITEM.SMALL_VIEW_LOCAL_STORAGE);
    this.setLocalStorageItem("rowsPerPage", LOCAL_STORAGE_ITEM.ROWS_PER_PAGE_LOCAL_STORAGE);
    this.setLocalStorageItem("tableSort", LOCAL_STORAGE_ITEM.TABLE_SORT_LOCAL_STORAGE);
    this.setLocalStorageItem("selectedDropdownValue", LOCAL_STORAGE_ITEM.SELECTED_DROPDOWN_VALUES);
    this.setLocalStorageItem("selectedTabs", LOCAL_STORAGE_ITEM.SELECTED_TABS);

    this.clearNoneTableSort();
  }

  setLocalStorageItem(storeProperty: keyof this, property: LOCAL_STORAGE_ITEM) {
    if (localStorage.getItem(property)) {
      try {
        this[storeProperty] = JSON.parse(localStorage.getItem(property) as string);
      } catch {}
    }
  }

  getStorageNameWithUser(name: LOCAL_STORAGE_ITEM | SESSION_STORAGE_ITEM) {
    return `${name}_${this.rootStore.auth.organization?.id}_${this.rootStore.auth.user?.id}`;
  }
}
