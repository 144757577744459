import React, { MouseEvent } from "react";
import { RemoveCircleOutline, InfoOutlined } from "@mui/icons-material";

import store from "store";
import { ACTION_FIELD_PROPERTY } from "consts";
import { AwsCostIntegration } from "store/types";
import { ActionButtonsWrapper } from "components/ui";
import { AddAWSCostIntegrationModal } from "components/modals/AddIntegrationModal/components";

const awsIntegrationModalStatus = (integration: AwsCostIntegration) =>
  store?.ui.openModal({
    title: "AWS Integration Status",
    maxWidth: "laptopLg",
    component: <AddAWSCostIntegrationModal awsIntegration={integration} />,
  });

const removeCostIntegrationModal = (integration: AwsCostIntegration) =>
  store?.ui.openConfirmModal({
    title: "Are you sure you want to remove this AWS Integration?",
    confirmText: "Remove",
    onConfirm: () => store.costReport.removeCostIntegration(integration),
  });

export const awsCostFields = [
  {
    name: "Account",
    property: "cloud_account_id",
    render: (item: AwsCostIntegration) =>
      store?.accounts.data.find((a) => a.id === item.cloud_account_id)?.name || item.cloud_account_id,
  },
  { name: "Bucket", property: "bucket" },
  { name: "Path", property: "path" },
  {
    property: ACTION_FIELD_PROPERTY,
    render: (item: AwsCostIntegration) => (
      <ActionButtonsWrapper
        item={item}
        inTable
        buttons={[
          {
            icon: InfoOutlined,
            text: "Status",
            onClick: (e: MouseEvent<HTMLElement>, integration: AwsCostIntegration) =>
              awsIntegrationModalStatus(integration),
          },
          {
            icon: RemoveCircleOutline,
            text: "Remove",
            variant: "text",
            onClick: (e: MouseEvent<HTMLElement>, integration: AwsCostIntegration) => {
              removeCostIntegrationModal(integration);
            },
          },
        ]}
      />
    ),
  },
];
