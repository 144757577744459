import { makeAutoObservable } from "mobx";

import { RootStore } from "store/root.store";
import {
  DashboardActionsIssuesType,
  DashboardIssue,
  DashboardStats,
  DashboardTableIssue,
  InfoItemIssuesData,
  ISSUE_TYPE,
  IssueList,
  LOCAL_STORAGE_ITEM,
  UndoRemediation,
} from "store/types";
import {
  automaticallyFixIssue,
  automaticallyFixIssueByIds,
  closeIssue,
  closeIssuesByIds,
  getDashboardIssueById,
  getDashboardIssuesByResourceId,
  getDashboardIssuesByRuleId,
  getDashboardStats,
  getIssueRemediations,
  reopenIssue,
  reopenIssueByIds,
  undoRemediation,
  undoRemediationByIds,
} from "libs/api/methods";
// import { CursorHandler, getDefaultCursorData } from "tools/cursorHandler/cursorHandler";
import * as dashboardMethods from "libs/api/methods/dashboard-methods";
import { getIssueWord } from "routes/DashboardRoute/components/DashboardIssues/components/dashboardIssues.tools";
import { dashboardGroupByList } from "routes/DashboardRoute/components/DashboardView/dashboardView";
import { filterResolvedIssues } from "routes/DashboardRoute/dashboard.utils";

const groupByRuleId: Array<keyof DashboardIssue> = ["cloud_account", "rule_id"];
const groupByResourceId: Array<keyof DashboardIssue> = ["cloud_account", "resource_id"];

export class DashboardStore {
  isFetched = false;
  stats?: DashboardStats;
  openAllIssuesData: DashboardIssue[] = [];
  resolvedAllIssuesData: DashboardIssue[] = [];
  justifiedAllIssuesData: DashboardIssue[] = [];
  infoItemIssue: InfoItemIssuesData = {};
  groupedBy = {
    [ISSUE_TYPE.OPEN]: groupByRuleId,
    [ISSUE_TYPE.JUSTIFIED]: groupByRuleId,
    [ISSUE_TYPE.RESOLVED]: groupByRuleId,
  };
  groupedBySelectedProperty = {
    [ISSUE_TYPE.OPEN]: "rule_id",
    [ISSUE_TYPE.JUSTIFIED]: "rule_id",
    [ISSUE_TYPE.RESOLVED]: "rule_id",
  };
  allIssuesWereCalled = {
    [ISSUE_TYPE.OPEN]: false,
    [ISSUE_TYPE.JUSTIFIED]: false,
    [ISSUE_TYPE.RESOLVED]: false,
  };

  // openIssuesCursorData = getDefaultCursorData<DashboardIssue>();
  // resolvedIssuesCursorData = getDefaultCursorData<DashboardIssue>();
  // justifiedIssuesCursorData = getDefaultCursorData<DashboardIssue>();

  constructor(readonly rootStore: RootStore) {
    this.rootStore = rootStore;

    this.fetch = this.fetch.bind(this);
    this.getAllIssues = this.getAllIssues.bind(this);
    this.changeGroupByProperty = this.changeGroupByProperty.bind(this);

    makeAutoObservable(this);

    this.getGroupedByProperties();

    // this.openIssuesCursorData.handler = new CursorHandler(
    //   this.openIssuesCursorData,
    //   dashboardMethods.getDashboardIssues,
    // );
    // this.resolvedIssuesCursorData.handler = new CursorHandler(
    //   this.resolvedIssuesCursorData,
    //   dashboardMethods.getDashboardResolvedIssues,
    // );
    // this.justifiedIssuesCursorData.handler = new CursorHandler(
    //   this.justifiedIssuesCursorData,
    //   dashboardMethods.getDashboardJustifiedIssues,
    // );
  }

  get issuesLoaded() {
    return (
      this.allIssuesWereCalled[ISSUE_TYPE.OPEN] &&
      this.allIssuesWereCalled[ISSUE_TYPE.RESOLVED] &&
      this.allIssuesWereCalled[ISSUE_TYPE.JUSTIFIED]
    );
  }

  get openIssues() {
    return this.openAllIssuesData;
    // return this.openIssuesCursorData.data;
  }

  get resolvedIssues() {
    return filterResolvedIssues(this.resolvedAllIssuesData, this.openAllIssuesData);

    // return filterResolvedIssues(this.resolvedIssuesCursorData.data, this.openIssuesCursorData.data);
  }

  get justifiedIssues() {
    return this.justifiedAllIssuesData;
    // return this.justifiedIssuesCursorData.data;
  }

  async getStats() {
    try {
      this.stats = await getDashboardStats();
    } catch {}
  }

  async getAllIssues(type: ISSUE_TYPE) {
    // if (type === ISSUE_TYPE.RESOLVED) await this.getAllIssues(ISSUE_TYPE.OPEN);

    if (this.allIssuesWereCalled[type]) {
      if (type === ISSUE_TYPE.OPEN) return this.openAllIssuesData;

      if (type === ISSUE_TYPE.RESOLVED) return filterResolvedIssues(this.resolvedAllIssuesData, this.openAllIssuesData);

      if (type === ISSUE_TYPE.JUSTIFIED) return this.justifiedAllIssuesData;
    }

    const [method, property]: [string, keyof DashboardStore] =
      type === ISSUE_TYPE.OPEN
        ? ["getDashboardIssues", "openAllIssuesData"]
        : type === ISSUE_TYPE.RESOLVED
        ? ["getDashboardResolvedIssues", "resolvedAllIssuesData"]
        : ["getDashboardJustifiedIssues", "justifiedAllIssuesData"];

    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this[property] = (await (dashboardMethods as any)[method]({ count: 10000 })).data;

      this.allIssuesWereCalled[type] = true;

      // if (type === ISSUE_TYPE.RESOLVED) {
      //   return filterResolvedIssues(this.resolvedAllIssuesData, this.openAllIssuesData);
      // }

      return this[property];
    } catch {
      return [];
    }
  }

  async fetch() {
    await Promise.all([
      this.getStats(),
      this.getAllIssues(ISSUE_TYPE.OPEN),
      this.getAllIssues(ISSUE_TYPE.RESOLVED),
      this.getAllIssues(ISSUE_TYPE.JUSTIFIED),
    ]);

    this.isFetched = true;
  }

  async reFetch() {
    this.allIssuesWereCalled[ISSUE_TYPE.OPEN] = false;
    this.allIssuesWereCalled[ISSUE_TYPE.RESOLVED] = false;
    this.allIssuesWereCalled[ISSUE_TYPE.JUSTIFIED] = false;

    await Promise.all([
      this.fetch(),
      // this.openIssuesCursorData?.handler?.restart(),
      // this.resolvedIssuesCursorData?.handler?.restart(),
      // this.justifiedIssuesCursorData?.handler?.restart(),
    ]);
  }

  async getDashboardIssueById(accountId: string, issueId: string) {
    return getDashboardIssueById(accountId, issueId);
  }

  async getDashboardIssuesByResourceId(accountId: string, resourceId: string): Promise<IssueList> {
    const issues = await getDashboardIssuesByResourceId({ count: 10000, accountId, resourceId });

    return Object.entries(issues).reduce((acc, [prop, { data }]) => ({ ...acc, [prop]: data }), {} as IssueList);
  }

  async getDashboardIssuesByRuleId(accountId: string, ruleId: string): Promise<IssueList> {
    const issues = await getDashboardIssuesByRuleId({ count: 10000, accountId, ruleId });

    return Object.entries(issues).reduce((acc, [prop, { data }]) => ({ ...acc, [prop]: data }), {} as IssueList);
  }

  async closeIssues(issues: DashboardIssue[], reason: string, type: DashboardActionsIssuesType) {
    this.rootStore.ui.setShowModalLoading(true);

    try {
      if (type === DashboardActionsIssuesType.SINGLE) {
        await closeIssue(issues[0], reason);
      } else if (type === DashboardActionsIssuesType.GROUPED) {
        await closeIssuesByIds(issues[0]?.grouped || [], reason);
      } else {
        await closeIssuesByIds(issues, reason);
      }

      await this.reFetch();

      this.rootStore.ui.closeModal();
    } catch {}

    this.rootStore.ui.setShowModalLoading(false);
  }

  async automaticallyFixIssues(issues: DashboardIssue[], remediationId: string, type: DashboardActionsIssuesType) {
    this.rootStore.ui.setShowModalLoading(true);

    try {
      if (type === DashboardActionsIssuesType.SINGLE) {
        await automaticallyFixIssue(issues[0], remediationId);
      } else if (type === DashboardActionsIssuesType.GROUPED) {
        await automaticallyFixIssueByIds(issues[0]?.grouped || [], remediationId);
      } else {
        await automaticallyFixIssueByIds(issues, remediationId);
      }

      await this.reFetch();

      this.rootStore.ui.closeModal();
    } catch {}

    this.rootStore.ui.setShowModalLoading(false);
  }

  async undoRemediations(issues: DashboardIssue[], data: UndoRemediation, type: DashboardActionsIssuesType) {
    this.rootStore.ui.setShowModalLoading(true);

    try {
      if (type === DashboardActionsIssuesType.SINGLE) {
        await undoRemediation(issues[0], data);
      } else if (type === DashboardActionsIssuesType.GROUPED) {
        await undoRemediationByIds(issues[0]?.grouped || [], data);
      } else {
        await undoRemediationByIds(issues, data);
      }

      await this.reFetch();

      this.rootStore.ui.closeModal();
    } catch {}

    this.rootStore.ui.setShowModalLoading(false);
  }

  async reopenIssues(issues: DashboardIssue[], type: DashboardActionsIssuesType) {
    this.rootStore.ui.setShowModalLoading(true);

    try {
      if (type === DashboardActionsIssuesType.SINGLE) {
        await reopenIssue(issues[0]);
      } else if (type === DashboardActionsIssuesType.GROUPED) {
        await reopenIssueByIds(issues[0]?.grouped || []);
      } else {
        await reopenIssueByIds(issues);
      }

      await this.reFetch();

      this.rootStore.ui.closeModal();
    } catch {}

    this.rootStore.ui.setShowModalLoading(false);
  }

  async getIssueRemediations(issue: DashboardIssue) {
    this.rootStore.ui.setShowModalLoading(true);

    const remediations = await getIssueRemediations(issue);

    this.rootStore.ui.setShowModalLoading(false);

    return remediations;
  }

  setInfoItemIssue(issue: DashboardTableIssue) {
    this.infoItemIssue = issue;
  }

  changeModalTitle(type: DashboardActionsIssuesType) {
    const currentTitle = this.rootStore.ui.modal?.title;

    if (!currentTitle) return;

    this.rootStore.ui.changeModalData({
      title: Object.values(DashboardActionsIssuesType).reduce(
        (str: string, t: DashboardActionsIssuesType) => str.replace(getIssueWord(t), getIssueWord(type)),
        currentTitle,
      ),
    });
  }

  changeGroupByProperty(type: ISSUE_TYPE, value: keyof DashboardIssue) {
    this.rootStore.ui.closeInfoItem();

    this.groupedBySelectedProperty[type] = value;
    this.groupedBy[type] = value === "resource_id" ? groupByResourceId : groupByRuleId;

    localStorage.setItem(LOCAL_STORAGE_ITEM.DASHBOARD_GROUPED_BY, JSON.stringify(this.groupedBySelectedProperty));
  }

  getGroupedByProperties() {
    if (localStorage.getItem(LOCAL_STORAGE_ITEM.DASHBOARD_GROUPED_BY)) {
      try {
        const possibleValues = dashboardGroupByList.map((d) => d.id);
        const data = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEM.DASHBOARD_GROUPED_BY) as string);

        if (possibleValues.includes(data[ISSUE_TYPE.OPEN])) {
          this.changeGroupByProperty(ISSUE_TYPE.OPEN, data[ISSUE_TYPE.OPEN]);
        }

        if (possibleValues.includes(data[ISSUE_TYPE.JUSTIFIED])) {
          this.changeGroupByProperty(ISSUE_TYPE.JUSTIFIED, data[ISSUE_TYPE.JUSTIFIED]);
        }

        if (possibleValues.includes(data[ISSUE_TYPE.RESOLVED])) {
          this.changeGroupByProperty(ISSUE_TYPE.RESOLVED, data[ISSUE_TYPE.RESOLVED]);
        }
      } catch {}
    }
  }
}
