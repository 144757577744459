import React, { ReactNode, RefObject, useCallback, useEffect, useRef, useState } from "react";
import { Box, SvgIcon, SxProps } from "@mui/material";
import { Close } from "@mui/icons-material";

import { Label, Tooltip, ErrorText } from "components/ui";
import { SMALL_HEIGHT, TABLE_HEIGHT } from "consts";

export type InputProps = {
  iconLeft?: boolean;
  inTable?: boolean;
  error?: string;
  label?: string;
  required?: boolean;
  name?: string;
  placeholder?: string;
  sx?: SxProps;
  icon?: typeof SvgIcon;
  iconTooltip?: ReactNode;
  iconClick?: () => void;
  type?: string;
  inputRef?: RefObject<HTMLInputElement>;
  closeIconClick?: () => void;
  disabled?: boolean;
  value?: string;
  onChange?: (val: string) => void;
  delay?: number;
  extraSmall?: boolean;
  doNotShowClose?: boolean;
  onBlur?: () => void;
};

export const Input = ({
  iconLeft,
  inTable,
  required,
  name,
  placeholder,
  value,
  onChange,
  label,
  error,
  icon: Icon,
  iconTooltip,
  iconClick,
  sx = {},
  type,
  inputRef,
  closeIconClick,
  disabled,
  extraSmall,
  delay = 0,
  onBlur,
  doNotShowClose,
}: InputProps) => {
  const searchTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleChange = useCallback(
    (newValue: string) => {
      setCurrentValue(newValue);

      if (!delay) return onChange?.(newValue);

      clearTimeout(searchTimeoutRef.current);

      searchTimeoutRef.current = setTimeout(() => onChange?.(newValue), newValue ? delay : 0);
    },
    [delay, onChange],
  );

  const close = useCallback(() => {
    if (disabled) return;

    return closeIconClick ? closeIconClick() : handleChange?.("");
  }, [closeIconClick, handleChange, disabled]);

  useEffect(() => {
    const handleEsc = ({ key }: KeyboardEvent) => {
      if (key !== "Escape") return;

      setTimeout(close);
    };

    window.addEventListener("keydown", handleEsc);

    return () => window.removeEventListener("keydown", handleEsc);
  }, [close]);

  return (
    <>
      {!!label && <Label text={label} />}
      <Box position="relative" display="flex" alignItems="center" flexGrow={1}>
        <Box
          height={extraSmall ? SMALL_HEIGHT : TABLE_HEIGHT}
          data-testid="input"
          ref={inputRef}
          onBlur={onBlur}
          component="input"
          required={required}
          name={name}
          placeholder={placeholder || label}
          onChange={(e) => handleChange(e.target.value)}
          value={currentValue}
          type={type}
          width={1}
          paddingLeft={Icon && iconLeft ? 7 : 2}
          paddingRight={doNotShowClose ? 2 : Icon && !iconLeft ? 7 : 6}
          borderRadius={extraSmall ? 0.5 : 1}
          fontSize={14}
          fontWeight={600}
          disabled={disabled}
          lineHeight="20px"
          sx={{
            outline: "none",
            "&:focus": { outline: "none" },
            ...(inTable
              ? {
                  border: "none",
                  backgroundColor: "background.inputInTable",
                  color: "text.secondaryDark",
                  "&::placeholder": { color: "text.secondary" },
                }
              : {
                  color: "various.inputColor",
                  backgroundColor: "background.inputBG",
                  border: "1px solid",
                  borderColor: "various.inputBorder",
                  "&::placeholder": { color: "#C8D1E6" },
                  "&:focus": { border: "1px solid", borderColor: "various.inputBorder" },
                }),

            ...(disabled && { backgroundColor: "pending.light", opacity: 0.5 }),
            ...sx,
          }}
        />

        {!!Icon && (
          <Tooltip title={iconTooltip}>
            <Icon
              onClick={() => iconClick?.()}
              sx={{
                position: "absolute",
                fontSize: 24,
                cursor: "pointer",
                color: inTable ? "text.secondaryDark" : "various.inputBorder",
                top: 8,
                ...(iconLeft ? { left: 8 } : { right: 8 }),
              }}
            />
          </Tooltip>
        )}

        {!doNotShowClose && !disabled && (!!closeIconClick || !!value) && (!Icon || iconLeft) && (
          <Close
            onClick={close}
            sx={{
              color: inTable ? "text.secondaryDark" : "various.inputColor",
              cursor: "pointer",
              zIndex: 1,
              position: "absolute",
              right: 8,
              fontSize: extraSmall ? 14 : 20,
            }}
          />
        )}
      </Box>

      {!!error && <ErrorText text={error} marginTop={1} />}
    </>
  );
};
