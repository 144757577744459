import React, { useEffect, useState } from "react";
import { Box, Table as MuiTable, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { SyncAlt, ArrowRightAlt } from "@mui/icons-material";

import { Loading, TableProps, TableSortField } from "components/ui";
import { TableDesktopDataElement } from "components/ui/Table/components/TableDesktop/components";
import { ACTION_FIELD_PROPERTY } from "consts";
import { lightDarkBG } from "theme/commonVariables";

export type TableDesktopProps = TableProps & { isFilerEnabled?: boolean };

export const TableDesktop = ({
  type,
  smallView,
  withoutBorder,
  fields,
  data,
  noDataText,
  leftHeader,
  search,
  actions,
  additionalInfo,
  additionalInfoHeader,
  isFilerEnabled,
  sortField,
  setSortFieldHandler,
  darkerTable,
}: TableDesktopProps & { sortField: TableSortField; setSortFieldHandler: (field: string) => void }) => {
  const [showNoDataText, setShowNoDataText] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowNoDataText(true), 1000);
  }, []);

  return (
    <MuiTable data-testid="tableDesktop">
      {!leftHeader && (
        <TableHead data-testid="tableDesktopHead">
          <TableRow sx={{ backgroundColor: "background.tableHead" }}>
            {fields.map((field) => (
              <TableCell
                onClick={() =>
                  !field.notSortable && field.property !== ACTION_FIELD_PROPERTY && setSortFieldHandler(field.property)
                }
                width={field.width}
                key={field.property}
                align="left"
                sx={{
                  ...(!field.notSortable && field.property !== ACTION_FIELD_PROPERTY && { cursor: "pointer" }),
                  paddingY: 1,
                }}
              >
                <Box display="flex" alignItems="center">
                  <Typography
                    fontWeight={field.headerFontWeight || 700}
                    sx={sortField?.field === field.property ? { color: "primary.main" } : {}}
                    variant="main"
                  >
                    {field.renderHead?.() || field.name}
                  </Typography>
                  {!field.notSortable && field.property !== ACTION_FIELD_PROPERTY && (
                    <Box
                      display="flex"
                      alignItems="center"
                      marginLeft={1}
                      padding={0.5}
                      borderRadius={1}
                      bgcolor={lightDarkBG}
                      sx={{ svg: { fontSize: 16 } }}
                    >
                      {sortField?.field === field.property ? (
                        <ArrowRightAlt
                          sx={{
                            color: "primary.main",
                            ...(sortField?.order === 1
                              ? { transform: "rotate(-90deg)" }
                              : { transform: "rotate(-270deg)" }),
                          }}
                        />
                      ) : (
                        <SyncAlt sx={{ color: "text.secondaryDark", transform: "rotate(-90deg)" }} />
                      )}
                    </Box>
                  )}
                </Box>
              </TableCell>
            ))}
            {!!additionalInfo && !!data?.length && <TableCell sx={{ padding: 0, width: 35 }} />}
            {!!actions?.length && !!data?.length && <TableCell sx={{ padding: 0, width: 35 }} />}
          </TableRow>
        </TableHead>
      )}

      <TableBody data-testid="tableDesktopBody">
        {data?.map((item, index) => (
          <TableDesktopDataElement
            type={type}
            key={item.id || index}
            data={data}
            leftHeader={leftHeader}
            item={item}
            fields={fields}
            actions={actions}
            additionalInfo={additionalInfo}
            additionalInfoHeader={additionalInfoHeader}
            smallView={smallView}
            withoutBorder={withoutBorder}
            darkerTable={darkerTable}
          />
        ))}

        {!data?.length && noDataText && (
          <TableRow data-testid="tableDesktopNoText">
            <TableCell colSpan={fields.length} align={leftHeader ? "left" : "center"}>
              {showNoDataText ? (
                <Typography variant="main">{search || isFilerEnabled ? "No data found" : noDataText}</Typography>
              ) : (
                <Loading marginTop={5} />
              )}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </MuiTable>
  );
};
