import React from "react";
import { Box } from "@mui/material";

import { GCPLightSvg } from "./components";

const GCPAccountContent = () => (
  <Box data-testid="gcpAccountContent">
    <Box marginBottom={4}>
      You can find your Project ID in the{" "}
      <Box
        component="a"
        target="_blank"
        href="https://console.cloud.google.com"
        sx={{ color: "primary.tooltipLink", "&:hover": { textDecoration: "underline" } }}
      >
        Google Cloud Console
      </Box>
    </Box>
    <Box overflow="hidden" borderRadius={1}>
      <GCPLightSvg />
    </Box>
  </Box>
);

export default GCPAccountContent;
