import React from "react";
import { Box, Typography } from "@mui/material";

import { getHighlight, getHighlightColor } from "components/ui/JsonViewer/jsonViewer.utils";
import { ArrowSvg } from "components/icons";
import { JsonHighlights, JsonRow } from "components/ui/JsonViewer/jsonViewer";
import store from "store";

type JsonViewRowNumberProps = {
  rows: JsonRow[];
  row: JsonRow;
  highlights?: JsonHighlights;
  collapsible: string[];
  setCollapsible: (val: string) => void;
  expanded: boolean;
};

export const JsonViewRowNumber = ({
  rows,
  row,
  highlights,
  expanded,
  collapsible,
  setCollapsible,
}: JsonViewRowNumberProps) => {
  const isHighlighted = getHighlight(row.property, highlights);

  return (
    <Typography
      variant="main"
      fontWeight={400}
      sx={{
        ...(isHighlighted && { backgroundColor: getHighlightColor(highlights) }),
      }}
      display="flex"
      justifyContent="space-between"
      key={`${row.property}_${row.collapsible}`}
    >
      <Box
        textAlign="center"
        color="text.secondary"
        width={rows.length.toString().length * 15}
        sx={{
          ...(isHighlighted && {
            backgroundColor: store.ui.isLightTheme
              ? highlights?.added
                ? "#A0FAE2"
                : "#F9BEBE"
              : highlights?.added
              ? "#285479"
              : "#4d3879",
          }),
        }}
      >
        {row.number}
      </Box>
      <Box
        width={0}
        paddingLeft={expanded && !highlights?.list?.length ? 2 : 3}
        paddingRight={expanded && !highlights?.list?.length ? 2 : expanded ? 3 : 4}
        display="flex"
        justifyContent="center"
      >
        {expanded
          ? isHighlighted && (
              <Box color={highlights?.added ? "#00B081" : "#F56264"}>{highlights?.added ? "+" : "-"}</Box>
            )
          : row.collapsible && (
              <Box
                sx={{
                  svg: {
                    transform: collapsible.includes(row.property) ? "rotate(90deg)" : "rotate(-90deg)",
                  },
                }}
                onClick={() => setCollapsible(row.property)}
              >
                <ArrowSvg />
              </Box>
            )}
      </Box>
    </Typography>
  );
};
