import { ReactElement, useState } from "react";
import { Tooltip as MuiTooltip, TooltipProps, Typography } from "@mui/material";

export const Tooltip = ({
  children,
  placement = "right",
  autoPlacement,
  byClick,
  ...props
}: { children: ReactElement; autoPlacement?: boolean; byClick?: boolean } & TooltipProps) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (!props?.title) return children;

  return (
    <MuiTooltip
      onClick={handleOpen}
      onClose={handleClose}
      open={byClick ? open : undefined}
      placement={autoPlacement ? undefined : placement}
      {...props}
      arrow
    >
      <Typography variant="table">{children}</Typography>
    </MuiTooltip>
  );
};
