import { makeAutoObservable } from "mobx";

import { RootStore } from "store/root.store";
import { getInvitations, getUsers, inviteUser, removeInvitation, removeUser, updateUser } from "libs/api/methods";
import { Invitation, PERMISSION_TYPES, User } from "store/types";

export class UsersStore {
  isError = false;
  isFetched = false;
  data: User[] = [];
  invitations: Invitation[] = [];

  constructor(readonly rootStore: RootStore) {
    this.rootStore = rootStore;

    this.fetch = this.fetch.bind(this);

    makeAutoObservable(this);
  }

  async fetch() {
    try {
      [this.data, this.invitations] = await Promise.all([getUsers(this.isFetched), this.getInvitations()]);

      this.isFetched = true;
    } catch {
      this.isError = true;
    }
  }

  getInvitations() {
    if (!this.rootStore.auth.isPermissionAllowed(PERMISSION_TYPES.ADMIN)) {
      return [];
    }

    return getInvitations(this.isFetched);
  }

  async inviteUser({ email, roleId }: { email: string; roleId: number }) {
    this.rootStore.ui.setShowModalLoading(true);

    try {
      await inviteUser(email, roleId);

      try {
        this.invitations = await getInvitations(this.isFetched);
      } catch {}

      this.rootStore.ui.closeModal();
    } catch {}

    this.rootStore.ui.setShowModalLoading(false);
  }

  async updateUser(user: User) {
    try {
      await updateUser(user.id, { role: user.role });

      this.data = this.data.map((u) => {
        if (u.id !== user.id) return u;

        return { ...u, ...user };
      });

      this.rootStore.ui.showMessage({
        title: "Great!",
        message: "User updated!",
      });
    } catch {}
  }

  async removeUser(id: string) {
    await removeUser(id);

    this.data = this.data.filter((u) => u.id !== id);
  }

  async removeInvitation(id: string) {
    await removeInvitation(id);

    this.invitations = this.invitations.filter((i) => i.id !== id);
  }

  getUserNameById(id?: string) {
    if (!id) return "";

    return this.data.find((u) => u.id === id)?.name || id;
  }
}
