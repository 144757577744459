import React, { MouseEvent } from "react";
import { AddCircleOutline, RemoveCircleOutline, HighlightOff, ContentCopy } from "@mui/icons-material";
import { Box, useTheme } from "@mui/material";

import { JsonSearchType, Tooltip, SetJsonSearchType, JsonSearchRowItem, JSON_VIEWER_VARIANTS } from "components/ui";
import {
  getJsonSearchProperty,
  getJsonSearchValue,
  setJsonSearchRows,
} from "components/ui/JsonViewer/jsonViewer.utils";
import { SEARCH_ACTION_DATA_TYPE } from "components/ui/JsonViewer/consts";
import { SMALL_HEIGHT } from "consts";
import { copyToClipboard } from "tools";
import { darkBG, darkTextSecondaryDarkColor, lightDarkBG, lightTextSecondaryDarkColor } from "theme/commonVariables";

export type JsonSearchActionsProps = {
  type: JSON_VIEWER_VARIANTS;
  item: JsonSearchRowItem;
  jsonSearch?: JsonSearchType;
  setJsonSearch: SetJsonSearchType;
  setSelectedItem?: (val: string) => void;
  copy?: boolean;
};

export enum JSON_VIEW_ACTIONS {
  INCLUDE,
  EXCLUDE,
  REMOVE,
  COPY,
}

export const JsonSearchActions = ({
  type,
  item,
  jsonSearch,
  setJsonSearch,
  setSelectedItem,
  copy,
}: JsonSearchActionsProps) => {
  const theme = useTheme();

  if (!jsonSearch || !setJsonSearch) return null;

  const property = jsonSearch?.[getJsonSearchProperty(item.property)];
  const value = getJsonSearchValue(item.value);

  const click = (e: MouseEvent<HTMLElement>, buttonType: JSON_VIEW_ACTIONS) => {
    e.stopPropagation();

    if (buttonType === JSON_VIEW_ACTIONS.COPY) {
      copyToClipboard({ value: item.value?.toString() });
    } else {
      setJsonSearch(setJsonSearchRows(jsonSearch, item, buttonType));
    }

    setSelectedItem?.("");
  };

  const buttons = [
    {
      title: "Include",
      Icon: AddCircleOutline,
      type: JSON_VIEW_ACTIONS.INCLUDE,
      show: !property?.include.includes(value),
    },
    {
      title: "Exclude",
      Icon: RemoveCircleOutline,
      type: JSON_VIEW_ACTIONS.EXCLUDE,
      show: !property?.exclude.includes(value),
    },
    {
      title: "Remove",
      Icon: HighlightOff,
      type: JSON_VIEW_ACTIONS.REMOVE,
      show: property?.include.includes(value) || property?.exclude.includes(value),
    },
    {
      title: "Copy",
      Icon: ContentCopy,
      type: JSON_VIEW_ACTIONS.COPY,
      show: copy && type === JSON_VIEWER_VARIANTS.JSON,
    },
  ];

  return (
    <Box display="flex">
      {buttons
        .filter((button) => button.show)
        .map((button) => (
          <Box
            borderRight="1px solid"
            borderColor={type === JSON_VIEWER_VARIANTS.JSON ? lightTextSecondaryDarkColor : "transparent"}
            key={button.title}
            sx={{ "&:last-child": { borderRight: "none" } }}
          >
            <Tooltip title={button.title} placement="top">
              <Box
                width={SMALL_HEIGHT}
                height={SMALL_HEIGHT}
                display="flex"
                alignItems="center"
                justifyContent="center"
                data-type={SEARCH_ACTION_DATA_TYPE}
                sx={{
                  cursor: "pointer",
                  "&:hover": { backgroundColor: theme.palette.mode === "light" ? lightDarkBG : darkBG },
                }}
                onClick={(e) => click(e, button.type)}
              >
                <button.Icon
                  data-type={SEARCH_ACTION_DATA_TYPE}
                  sx={{
                    color: theme.palette.mode === "light" ? lightTextSecondaryDarkColor : darkTextSecondaryDarkColor,
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
        ))}
    </Box>
  );
};
