import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

export const TableItemList = ({ items }: { items: string[] }) => {
  const theme = useTheme();

  if (!items?.length) return null;

  return (
    <Box data-testid="tableItemList" display="flex" flexWrap="wrap" gap={1.5}>
      {items
        .filter((i) => i)
        .map((item) => (
          <Typography
            key={item}
            component="div"
            color={theme.palette.mode === "light" ? "text.secondary" : "text.primary"}
            border="1px solid"
            borderColor="various.inactiveColor"
            borderRadius={1}
            paddingX={1.5}
          >
            {item}
          </Typography>
        ))}
    </Box>
  );
};
