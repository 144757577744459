import React from "react";
import { Outlet } from "react-router-dom";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { inject, observer } from "mobx-react";

import { RequireAuth } from "components/guards";
import { MobileHeader, VerticalMenu, InfoItem } from "components/layouts/LoggedInFlow/components";
import { RootStore } from "store/root.store";
import { LOGIN_ROUTE } from "consts";
import { INFO_ITEM_WIDTH } from "components/layouts/LoggedInFlow/components/InfoItem/infoItem";

const LoggedInFlow = ({ store }: { store?: RootStore }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const click = () => store?.ui.changeMobileMenuOpened(false);

  return (
    <RequireAuth redirectTo={LOGIN_ROUTE}>
      <Box onClick={click}>
        <MobileHeader />

        <Box
          paddingTop={{ xs: "65px", laptop: 0 }}
          display="flex"
          bgcolor="background.default"
          minHeight="100vh"
          flexDirection="row"
        >
          <VerticalMenu />

          <Box width={1} paddingX={0}>
            <Box
              height={1}
              display="flex"
              paddingLeft={`${store?.ui.getMenuWidth(isMobile)}px`}
              paddingRight={store?.ui.infoItem ? { xs: 0, ...INFO_ITEM_WIDTH } : 0}
              sx={{
                overflow: "scroll",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                "&::-webkit-scrollbar": { display: "none" },
              }}
            >
              <Box
                bgcolor="background.default"
                paddingY={{ xs: 5, laptop: 10 }}
                paddingX={{ xs: 3, laptop: 5 }}
                flexGrow={1}
                position="relative"
              >
                <Outlet />
              </Box>
            </Box>
          </Box>

          {!!store?.ui.infoItem && <InfoItem />}
        </Box>
      </Box>
    </RequireAuth>
  );
};

export default inject("store")(observer(LoggedInFlow));
