import React, { MouseEvent } from "react";
import { Box, Theme } from "@mui/material";
import { AddCircleOutline, BuildOutlined, HourglassEmpty, RemoveCircleOutline, Undo } from "@mui/icons-material";

import store from "store";
import {
  ISSUE_TYPE,
  WIDGET_FILTER_TYPES,
  DashboardActionsIssuesType,
  DashboardIssue,
  Widget,
  DataStatsResponseItem,
} from "store/types";
import {
  AccountDetailsOnHover,
  ActionButtonsWrapper,
  GroupedCount,
  InventoryByResourceIdLink,
  IssueBy,
  PieChartItem,
  ProviderIcon,
  ProviderServiceTypeIcon,
  ResourceDetailsOnHover,
  TableFieldOptions,
} from "components/ui";
import { FieldRenderOptions, formattedDate, getIconTypeByResource, groupByProperties } from "tools";
import { getIssueWord } from "routes/DashboardRoute/components/DashboardIssues/components/dashboardIssues.tools";
import { CloseIssueModal, FixIssueModal, ReopenIssueModal, UndoRemediationModal } from "components/modals";
import { UNDO_REMEDIATION_VARIANT } from "components/modals/UndoRemediationModal/undoRemediationModal";
import { ACTION_FIELD_PROPERTY } from "consts";

const widgetNames: Record<string, string> = {
  open_issues: "Open Issues",
  resolved_issues: "Resolved Issues",
  accounts_connected: "Accounts Created",
  rules_enabled: "Rules Enabled",
  events_bar: "Events bar",
  openIssues: "Open Issues",
  resolvedIssues: "Resolved Issues",
  justifiedIssues: "Justified Issues",
};

export const getIssueType = ({ resolved, justified }: { resolved?: boolean; justified?: boolean }) =>
  resolved ? ISSUE_TYPE.RESOLVED : justified ? ISSUE_TYPE.JUSTIFIED : ISSUE_TYPE.OPEN;

export const fixIssues = (e: MouseEvent<HTMLElement>, issues: DashboardIssue[], type: DashboardActionsIssuesType) => {
  e.stopPropagation();

  store?.ui.openModal({
    title: `Are you sure you want to fix ${getIssueWord(type)}?`,
    maxWidth: "sm",
    component: <FixIssueModal type={type} issues={issues} />,
  });
};

export const closeIssues = (e: MouseEvent<HTMLElement>, issues: DashboardIssue[], type: DashboardActionsIssuesType) => {
  e.stopPropagation();

  store?.ui.openModal({
    title: `Are you sure you want to close ${getIssueWord(type)}?`,
    maxWidth: "sm",
    component: <CloseIssueModal issues={issues} type={type} />,
  });
};

export const undoIssues = (e: MouseEvent<HTMLElement>, issues: DashboardIssue[], type: DashboardActionsIssuesType) => {
  e.stopPropagation();

  store?.ui.openModal({
    title: `Are you sure you want to undo remediation for ${getIssueWord(type)}?`,
    maxWidth: "sm",
    component: <UndoRemediationModal type={type} issues={issues} variant={UNDO_REMEDIATION_VARIANT.UNDO} />,
  });
};

export const snoozeIssues = (
  e: MouseEvent<HTMLElement>,
  issues: DashboardIssue[],
  type: DashboardActionsIssuesType,
) => {
  e.stopPropagation();

  store?.ui.openModal({
    title: `Are you sure you want to snooze remediation for ${getIssueWord(type)}?`,
    maxWidth: "sm",
    component: <UndoRemediationModal type={type} issues={issues} variant={UNDO_REMEDIATION_VARIANT.SNOOZE} />,
  });
};

export const reopenIssues = (
  e: MouseEvent<HTMLElement>,
  issues: DashboardIssue[],
  type: DashboardActionsIssuesType,
) => {
  e.stopPropagation();

  store?.ui.openModal({
    title: `Are you sure you want to reopen ${getIssueWord(type)}?`,
    maxWidth: "sm",
    component: <ReopenIssueModal issues={issues} type={type} />,
  });
};

const openIssuesActions = [
  {
    property: ACTION_FIELD_PROPERTY,
    showOnHover: true,
    render: (item: DashboardIssue, options: FieldRenderOptions) => (
      <ActionButtonsWrapper
        item={item}
        inTable={options?.inTable}
        buttons={[
          {
            icon: RemoveCircleOutline,
            text: "Justify Issue",
            variant: "text",
            onClick: (e: MouseEvent<HTMLElement>, issue: DashboardIssue) =>
              closeIssues(e, [issue], DashboardActionsIssuesType.SINGLE),
            disabled: (issue: DashboardIssue) => !!(issue.remediationStatus && !issue.remediationStatus.error),
          },
          {
            icon: BuildOutlined,
            text: "Fix Issue",
            onClick: (e: MouseEvent<HTMLElement>, issue: DashboardIssue) =>
              fixIssues(e, [issue], DashboardActionsIssuesType.SINGLE),
            disabled: (issue: DashboardIssue) => !!(issue.remediationStatus && !issue.remediationStatus.error),
          },
        ]}
      />
    ),
  },
];

const resolvedIssuesActions = [
  {
    property: ACTION_FIELD_PROPERTY,
    showOnHover: true,
    render: (item: DashboardIssue, options: FieldRenderOptions) => (
      <ActionButtonsWrapper
        item={item}
        inTable={options?.inTable}
        buttons={[
          {
            icon: HourglassEmpty,
            text: "Snooze Remediation",
            variant: "text",
            onClick: (e: MouseEvent<HTMLElement>, issue: DashboardIssue) =>
              snoozeIssues(e, [issue], DashboardActionsIssuesType.SINGLE),
          },
          {
            icon: Undo,
            text: "Undo Remediation",
            onClick: (e: MouseEvent<HTMLElement>, issue: DashboardIssue) =>
              undoIssues(e, [issue], DashboardActionsIssuesType.SINGLE),
          },
        ]}
      />
    ),
  },
];

const justifiedIssuesActions = [
  {
    property: ACTION_FIELD_PROPERTY,
    showOnHover: true,
    render: (item: DashboardIssue, options: FieldRenderOptions) => (
      <ActionButtonsWrapper
        item={item}
        inTable={options?.inTable}
        buttons={[
          {
            icon: AddCircleOutline,
            text: "Reopen",
            variant: "text",
            onClick: (e: MouseEvent<HTMLElement>, issue: DashboardIssue) =>
              reopenIssues(e, [issue], DashboardActionsIssuesType.SINGLE),
          },
        ]}
      />
    ),
  },
];

const getDashboardDefaultFields = ({
  notGrouped,
  resolved,
  justified,
  inModal,
}: { notGrouped?: boolean; inModal?: boolean; resolved?: boolean; justified?: boolean } = {}) => [
  ...(notGrouped
    ? []
    : [
        {
          name: "Count",
          property: "count",
          sortByNumber: true,
          textValue: (issue: DashboardIssue) => `${issue.grouped?.length}`,
          render: (issue: DashboardIssue) => <GroupedCount itemId={issue.id} total={issue.grouped?.length || 0} />,
          mobileTopSection: true,
        },
      ]),
  {
    name: "Issue",
    property: "name",
    textValue: (issue: DashboardIssue) => `${issue.provider} ${issue.name}`,
    render: (issue: DashboardIssue, options?: TableFieldOptions) => (
      <ResourceDetailsOnHover
        inTable={options?.inTable}
        name={issue.name}
        provider={issue.provider}
        component={<ProviderIcon name={issue.name} provider={issue.provider} />}
      />
    ),
    mobileTopSection: true,
  },
  {
    name: "Account",
    property: "cloud_account",
    textValue: (issue: DashboardIssue) => issue.cloud_account,
    render: (issue: DashboardIssue, options?: TableFieldOptions) => (
      <AccountDetailsOnHover
        inTable={options?.inTable}
        id={issue.cloud_account_id}
        component={<Box>{issue.cloud_account}</Box>}
      />
    ),
    additionalInfo: "table",
  },
  {
    name: "Description",
    property: "description",
    additionalInfo: "row",
    donNotShowInMainTable: true,
  },
  {
    name: "Resource Type",
    property: "resource_type",
    additionalInfo: "table",
    textValue: (item: DashboardIssue) => item.resource_type,
    render: (item: DashboardIssue) => (
      <ProviderServiceTypeIcon type={getIconTypeByResource(item.resource_type)} text={item.resource_type} />
    ),
    ungrouped: true,
  },
  {
    name:
      notGrouped || store?.dashboard.groupedBy[getIssueType({ resolved, justified })]?.includes("resource_id")
        ? "Resource ID"
        : "Most recent Resource ID",
    property: "resource_id",
    additionalInfo: "table",
    originalBeforeGroupingName: "Resource ID",
    textValue: (item: DashboardIssue) => item.resource_id,
    render: (item: DashboardIssue) => (
      <InventoryByResourceIdLink
        showLink={!inModal}
        accountId={item.cloud_account_id}
        resourceId={item.resource_id}
        uniqueResourceIdsLength={item.uniqueResourceIds?.length || 0}
      />
    ),
    ungrouped: true,
  },
  {
    name: "Rule ID",
    property: "rule_id",
    additionalInfo: "table",
    donNotShowInMainTable: true,
    ungrouped: true,
  },
  {
    name: notGrouped ? "Detected At" : "Most recent detection",
    property: "created_at",
    originalBeforeGroupingName: "Detected At",
    render: (item: DashboardIssue) => formattedDate(item.created_at),
    ungrouped: true,
    mobileTopSection: resolved || justified,
  },
];

const getDashboardResolvedFields = (options: { notGrouped?: boolean; inModal?: boolean }) => [
  ...getDashboardDefaultFields({ ...options, resolved: true }),
  {
    name: "Resolved By",
    property: "resolved_by",
    render: (item: DashboardIssue) => (
      <IssueBy
        state={item.current_state?.state}
        userId={item.current_state?.operator_id}
        type={item.current_state?.operator}
      />
    ),
    textValue: (item: DashboardIssue) =>
      store?.users.getUserNameById(item.current_state?.operator_id) || item.current_state?.operator || "",
    hideFieldValue: (item: DashboardIssue) => !item.current_state?.operator_id && !item.current_state?.operator,
    mobileTopSection: true,
    ungrouped: true,
  },
  {
    name: "Resolved At",
    property: "resolved_at",
    render: (item: DashboardIssue) => formattedDate(item.current_state?.created_at),
    mobileTopSection: true,
    ungrouped: true,
  },
];

const getDashboardJustifyFields = (options?: { notGrouped?: boolean; inModal?: boolean }) => [
  ...getDashboardDefaultFields({ ...options, justified: true }),
  {
    name: "Justified By",
    property: "justified_by",
    render: (item: DashboardIssue) => (
      <IssueBy
        state={item.current_state?.state}
        userId={item.current_state?.operator_id}
        type={item.current_state?.operator}
      />
    ),
    textValue: (item: DashboardIssue) =>
      store?.users.getUserNameById(item.current_state?.operator_id) || item.current_state?.operator || "",
    hideFieldValue: (item: DashboardIssue) => !item.current_state?.operator_id && !item.current_state?.operator,
    mobileTopSection: true,
    ungrouped: true,
  },
  {
    name: "Reason",
    property: "justified_reason",
    render: (item: DashboardIssue) => item.current_state?.reason || "",
    hideFieldValue: (item: DashboardIssue) => !item.current_state?.reason,
    mobileTopSection: true,
    ungrouped: true,
  },
  {
    name: "Justified At",
    property: "justified_at",
    render: (item: DashboardIssue) => formattedDate(item.current_state?.created_at),
    mobileTopSection: true,
    ungrouped: true,
  },
];

export const getDashboardFields = ({
  resolved,
  justified,
  notGrouped,
  inModal,
  withActions,
}: {
  resolved?: boolean;
  justified?: boolean;
  notGrouped?: boolean;
  inModal?: boolean;
  withActions?: boolean;
} = {}) => {
  if (resolved) {
    return [...getDashboardResolvedFields({ notGrouped, inModal }), ...(withActions ? resolvedIssuesActions : [])];
  }

  if (justified) {
    return [...getDashboardJustifyFields({ notGrouped, inModal }), ...(withActions ? justifiedIssuesActions : [])];
  }

  return [...getDashboardDefaultFields({ notGrouped, inModal }), ...(withActions ? openIssuesActions : [])];
};

export const getDashboardIssueGroupedData = (data: DashboardIssue[], groupedBy?: Array<keyof DashboardIssue>) => {
  if (!groupedBy) return data;

  return groupByProperties<DashboardIssue>({
    data,
    properties: groupedBy,
    dataCB: (issue: DashboardIssue) => ({
      ...issue,
      uniqueResourceIds: [...new Set(issue.grouped?.map((i) => i.resource_id))],
    }),
  });
};

const getIssueId = (issue: DashboardIssue) => `${issue.resource_id}_${issue.rule_id}`;

export const filterResolvedIssues = (resolvedIssues: DashboardIssue[], openIssues: DashboardIssue[]) => {
  const openedIssuesIds: Record<string, boolean> = openIssues.reduce(
    (acc, i) => ({ ...acc, [getIssueId(i)]: true }),
    {},
  );

  return resolvedIssues.filter((i) => !openedIssuesIds[getIssueId(i)]);
};

export const getWidgetName = (widget: Widget) => widgetNames[widget.id] || widget.name || widget.id;

export const getWidgetComment = (widget: Widget) =>
  widget.widgetType === WIDGET_FILTER_TYPES.COUNTER
    ? "Counter"
    : widget.widgetType === WIDGET_FILTER_TYPES.PIE_CHART
    ? "Pie chart"
    : "Table";

export const getWidgetCounterWidth = (theme: Theme, countAfter: number) =>
  `calc(${100 / (countAfter + 1)}% - ${theme.spacing((5 * countAfter) / (countAfter + 1))})`;

export const getWidgetType = (widgetType: WIDGET_FILTER_TYPES) => {
  if (!widgetType || [WIDGET_FILTER_TYPES.ISSUES_TABLE, WIDGET_FILTER_TYPES.COUNTER].includes(widgetType)) {
    return widgetType;
  }

  return WIDGET_FILTER_TYPES.TABLE;
};

export const mapRandomColorsToPieChart = (items: PieChartItem[]): PieChartItem[] => {
  const colors = [
    "#FFDC8B",
    "#FFA890",
    "#FF2686",
    "#5CC9FA",
    "#1F90FF",
    "#345575FF",
    "#A0BBFF",
    "#F90",
    "#B42830",
    "#F56264",
  ].sort(() => 0.5 - Math.random());

  return items.map((i, index) => ({ ...i, color: colors[index % colors.length] }));
};

export const summarizePieChartData = (items: PieChartItem[], count = 10): PieChartItem[] => {
  items.sort((a, b) => b.value - a.value);

  if (items.length < count) return mapRandomColorsToPieChart(items);

  const restSum = items.slice(count - 1).reduce((sum, i) => sum + (i.value || 1), 0);

  return mapRandomColorsToPieChart([
    ...items.slice(0, count - 1),
    {
      title: "Rest",
      value: restSum,
    },
  ]);
};

export const transformPieChartData = (items: DataStatsResponseItem[], count = 10): PieChartItem[] => {
  try {
    if (!items?.length) return [];

    if (Object.keys(items[0]).length === 1) {
      if (typeof Object.values(items[0])[0] === "number") {
        return summarizePieChartData(
          items.map((i) => {
            const [title, value] = Object.entries(i)[0];

            return {
              title,
              value: value as number,
            };
          }),
          count,
        );
      }

      if (typeof Object.values(items[0])[0] === "string") {
        return summarizePieChartData(
          items.map((i) => {
            const [, value] = Object.entries(i)[0];

            return {
              title: value as string,
              value: 1,
            };
          }),
          count,
        );
      }
    }

    let nameField = "";
    let countField = "";

    for (let i = 0; i < items.length; i++) {
      if (!countField) {
        countField = Object.entries(items[i]).find(([, value]) => typeof value === "number")?.[0] as string;
      }

      if (countField) {
        nameField = Object.entries(items[i]).find(([, value]) => typeof value === "string")?.[0] as string;
      }

      if (nameField) break;
    }

    if (!countField) return [];

    if (!nameField) nameField = countField;

    return summarizePieChartData(
      items.map((i) => ({
        title: i[nameField] as string,
        value: +i[countField] || 1,
      })),
      count,
    );
  } catch {
    return [];
  }
};
