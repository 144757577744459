import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import { RootStore } from "store/root.store";
import { Button, ButtonWrapper, FormFieldsBuilder } from "components/ui";
import { DashboardActionsIssuesType, DashboardIssue } from "store/types";
// eslint-disable-next-line max-len
import { getIssueChangeStateSelect } from "routes/DashboardRoute/components/DashboardIssues/components/dashboardIssues.tools";

const validationSchema = yup.object({
  reason: yup.string().required("Justification is required").max(500, "Justification must be at most 500 characters"),
});

type CloseIssueModalProps = { store?: RootStore; issues: DashboardIssue[]; type: DashboardActionsIssuesType };

const CloseIssueModal = ({ store, issues, type }: CloseIssueModalProps) => {
  const initialValues = {
    reason: "",
    type,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => store?.dashboard.closeIssues(issues, values.reason, values.type),
  });

  useEffect(() => store?.dashboard.changeModalTitle(formik.values?.type), [store?.dashboard, formik.values?.type]);

  const items = [
    ...getIssueChangeStateSelect(issues, type),
    {
      property: "reason",
      label: "Justification",
      type: "textarea",
      placeholder: `Why do you want to ignore ${
        formik.values.type === DashboardActionsIssuesType.SINGLE ? "this issue" : "these issues"
      }?`,
    },
  ];

  return (
    <Box data-testid="closeIssueModal">
      <form onSubmit={formik.handleSubmit}>
        <FormFieldsBuilder formik={formik} items={items} />

        <ButtonWrapper>
          <>
            <Button onClick={store?.ui.closeModal} text="Cancel" variant="text" />
            <Button
              type="submit"
              text={`Close issue${formik.values.type === DashboardActionsIssuesType.SINGLE ? "" : "s"}`}
            />
          </>
        </ButtonWrapper>
      </form>
    </Box>
  );
};

export default inject("store")(observer(CloseIssueModal));
