import { CursorFetchData } from "tools/cursorHandler/cursorHandler.types";

export enum ISSUE_TYPE {
  OPEN = "OPEN",
  RESOLVED = "RESOLVED",
  JUSTIFIED = "JUSTIFIED",
}

export enum ISSUE_TYPE_PROPS {
  OPEN = "openIssues",
  RESOLVED = "resolvedIssues",
  JUSTIFIED = "justifiedIssues",
}

export type DashboardTableIssue = {
  title: string;
  prop: string;
  storeProp: string;
  resolved?: boolean;
  justified?: boolean;
  type: ISSUE_TYPE;
};

export type DashboardStats = {
  open_issues: number;
  resolved_issues: number;
  accounts_connected: number;
  rules_enabled: number;
};

export enum DashboardIssueBy {
  OPSHELM = "opshelm",
  EXTERNALLY = "externally",
  USER = "user",
}

export enum ISSUE_STATE_TYPE {
  OPEN = "open",
  REVERTED = "reverted",
  SNOOZED = "snoozed",
  REMEDIATED = "remediated",
  MITIGATED = "mitigated",
  JUSTIFIED = "justified",
  DELETED = "resource_deleted",
}

export type DashboardIssue = {
  id: string;
  name: string;
  description: string;
  provider: string;
  cloud_account_id: string;
  cloud_account: string;
  resource_type: string;
  resource_id: string;
  rule_id: string;
  created_at: string;
  grouped?: DashboardIssue[];
  uniqueResourceIds?: string[];
  current_state?: {
    operator: DashboardIssueBy;
    operator_id?: string;
    created_at: string;
    reason?: string;
    state?: ISSUE_STATE_TYPE;
  };
  remediationStatus?: {
    step: number;
    percentage?: number;
    error?: string;
  };
};

export type UndoRemediation = {
  snoozeUntil?: Date;
  reason: string;
};

export type IssueRemediation = {
  id: string;
  name: string;
  description: string;
};

export type InfoItemIssuesData = {
  resolved?: boolean;
  justified?: boolean;
};

export enum DashboardActionsIssuesType {
  SINGLE = "SINGLE",
  GROUPED = "GROUPED",
  ALL = "ALL",
}

export type IssueList = {
  open: DashboardIssue[];
  resolved: DashboardIssue[];
  justified: DashboardIssue[];
};

export type IssueListResponse = {
  open: CursorFetchData<DashboardIssue[]>;
  resolved: CursorFetchData<DashboardIssue[]>;
  justified: CursorFetchData<DashboardIssue[]>;
};
