import React, { ElementType, useEffect, useState, ReactElement, useMemo } from "react";
import { TableContainer, Box, useTheme, SxProps } from "@mui/material";
import { inject, observer } from "mobx-react";

import {
  TablePagination,
  TableComponent,
  TableFiltersType,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
  DEFAULT_ROWS_PER_PAGE,
} from "components/ui/Table/components";
import { CursorData } from "tools/cursorHandler/cursorHandler.types";
import { RootStore } from "store/root.store";
import { filterTableData, sortFieldData } from "tools";
import { NONE_SELECT_VALUE } from "components/ui";
import { UI_TABLES, FilterConditionKey } from "store/types";
import { NONE_SORT } from "store/ui.store";

export type TableItemProps = {
  id: string | number;
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export type TableFieldOptions = Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any

export type TableFields = {
  property: string;
  name?: string;
  originalBeforeGroupingName?: string;
  sortByNumber?: boolean;
  filterValue?: (item: any) => string | string[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  textValue?: (item: any) => string; // eslint-disable-line @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: (item: any, options?: TableFieldOptions) => ReactElement | string;
  hideFieldValue?: (item: any) => boolean; // eslint-disable-line @typescript-eslint/no-explicit-any
  renderHead?: () => ReactElement | string;
  toLeft?: boolean;
  headerFontWeight?: number;
  width?: number;
  mobileTopSection?: boolean;
  breakWordsLine?: boolean;
  dontBreakInAdditionalTable?: boolean;
  dontShowOnEachFieldAction?: boolean;
  additionalInfo?: string;
  ungrouped?: boolean;
  showOnHover?: boolean;
  donNotShowInMainTable?: boolean;
  searchableObject?: boolean;
  notSortable?: boolean;
  doNotTruncate?: boolean;
  filterConditions?: FilterConditionKey[];
  defaultSort?: number;
  fieldSx?: (item: any) => SxProps; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export type TableActionProps = {
  key: string;
  onClick: (item: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
  getButtonProps: (item: any) => Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  hide?: (item: any) => boolean; // eslint-disable-line @typescript-eslint/no-explicit-any
  withHover?: boolean;
};

export type TableSortField = { field: string; order: number } | undefined;

export type TableFilterValue = Record<string, string>;

export type TableRelatedData = {
  search: string;
  sortField: TableSortField;
};

export type TableProps = {
  type: UI_TABLES | string;
  data: TableItemProps[];
  fields: TableFields[];
  store?: RootStore;
  desktopView?: boolean;
  smallView?: boolean;
  withoutBorder?: boolean;
  removePadding?: boolean;
  noDataText?: string;
  hidePagination?: boolean;
  leftHeader?: boolean;
  rowsPerPageOptions?: number[];
  rowsPerPage?: number;
  search?: string;
  isDynamicFields?: boolean;
  filterValues?: TableFilterValue;
  filterComponents?: TableFiltersType[];
  actions?: TableActionProps[];
  loadMoreCount?: number;
  mobileTopSection?: ElementType;
  additionalInfo?: ReactElement | string;
  additionalInfoHeader?: ReactElement;
  cursorData?: CursorData<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  groupedBy?: string[];
  apiSort?: boolean;
  setTableRelatedData?: (data: TableRelatedData) => void;
  darkerTable?: boolean;
};

const Table = ({
  store,
  type,
  desktopView,
  smallView,
  withoutBorder,
  removePadding,
  data,
  fields,
  noDataText,
  hidePagination,
  leftHeader,
  rowsPerPageOptions = DEFAULT_ROWS_PER_PAGE_OPTIONS,
  rowsPerPage = store?.ui.rowsPerPage?.[type] || DEFAULT_ROWS_PER_PAGE,
  search = "",
  filterValues,
  filterComponents,
  actions,
  mobileTopSection,
  loadMoreCount,
  additionalInfo,
  additionalInfoHeader,
  cursorData,
  groupedBy,
  isDynamicFields,
  apiSort,
  setTableRelatedData,
  darkerTable,
}: TableProps) => {
  const theme = useTheme();

  const tableFields = useMemo(
    () =>
      isDynamicFields && data?.length
        ? (Object.keys(data[0]).map((property) => ({ name: property, property })) as TableFields[])
        : fields
            .filter((f) => !f.donNotShowInMainTable)
            .filter((f) => !f.ungrouped || f.originalBeforeGroupingName || groupedBy?.includes(f.property)),
    [data, groupedBy, isDynamicFields, fields],
  );

  const defaultSort: TableSortField = useMemo(() => {
    if (store?.ui.tableSort?.[type] === NONE_SORT) return;

    const defaultSortField = tableFields.find((t) => t.defaultSort);

    return (
      (store?.ui.tableSort?.[type] as TableSortField) ||
      (defaultSortField && {
        field: defaultSortField.property,
        order: defaultSortField.defaultSort!,
      })
    );
  }, [tableFields, store?.ui.tableSort, type]);

  const [rowsPerPageValue, setRowsPerPageValue] = useState(cursorData?.countPerPage || rowsPerPage);
  const [currentPage, setCurrentPage] = useState(cursorData?.currentPage || 0);
  const [filteredData, setFilteredData] = useState(cursorData?.data || data);
  const [tableData, setTableData] = useState<TableItemProps[]>([]);
  const [sortField, setSortField] = useState<TableSortField>(cursorData?.sortField || defaultSort);

  useEffect(
    () => () => {
      if (!cursorData) {
        store?.ui.clearNoneTableSort();
      }
    },
    [cursorData, store?.ui],
  );

  useEffect(() => {
    if (store?.ui.pageForTableData[type] === undefined) return;

    setCurrentPage(store?.ui.pageForTableData[type]);
  }, [store?.ui, store?.ui.pageForTableData, type]);

  useEffect(() => {
    setTableRelatedData?.({ search, sortField });
  }, [setTableRelatedData, search, sortField]);

  useEffect(() => {
    const filtered = filterTableData({ data, search, fields, filters: filterComponents, filterValues });

    if (!apiSort) sortFieldData({ data: filtered, fields, sortField });

    setFilteredData(filtered);
  }, [data, search, filterValues, filterComponents, sortField, fields, apiSort]);

  useEffect(() => {
    if (hidePagination || cursorData) return setTableData(filteredData);

    setTableData(filteredData?.slice(currentPage * rowsPerPageValue, (currentPage + 1) * rowsPerPageValue));
  }, [filteredData, currentPage, rowsPerPageValue, hidePagination, cursorData]);

  useEffect(() => {
    if (cursorData?.countPerPage === rowsPerPageValue) return;

    if (defaultSort) {
      cursorData?.handler?.setSpecificCursorData({ sortField: defaultSort });
    }

    cursorData?.handler?.start({ countPerPage: rowsPerPageValue, page: 0 });

    if (!cursorData) {
      setCurrentPage(0);
    }
  }, [defaultSort, rowsPerPageValue, cursorData, search]);

  const isFilerEnabled =
    filterValues && Object.values(filterValues).some((value) => value && value !== NONE_SELECT_VALUE);

  const changeRowsPerPage = (value: number) => {
    setRowsPerPageValue(value);

    store?.ui.setRowsPerPage(type, value);
  };

  const changePage = (page: number) => {
    cursorData?.handler?.changePage({ countPerPage: rowsPerPageValue, page });

    if (!cursorData) {
      setCurrentPage(page);
    }
  };

  const setSortFieldHandler = (field: string) => {
    setSortField((curr: TableSortField) => {
      let newSortField;

      if (curr?.field !== field) newSortField = { field, order: 1 };
      else if (curr.order === 1) newSortField = { field, order: -1 };

      cursorData?.handler?.setSpecificCursorData({ sortField: newSortField, fetch: apiSort });

      store?.ui.setTableSort(type, newSortField);

      return newSortField;
    });
  };

  return (
    <TableContainer
      data-testid="table"
      sx={{
        overflow: "unset",
        width: {
          xs: 1,
          laptop: `calc(100% + ${removePadding ? theme.spacing(8) : 0})`,
        },
        marginLeft: {
          xs: 0,
          laptop: `-${removePadding ? theme.spacing(4) : 0}`,
        },
      }}
    >
      <TableComponent
        type={type}
        fields={tableFields}
        data={tableData}
        noDataText={noDataText}
        leftHeader={leftHeader}
        search={search}
        actions={actions}
        mobileTopSection={mobileTopSection}
        loadMoreCount={loadMoreCount}
        additionalInfo={additionalInfo}
        additionalInfoHeader={additionalInfoHeader}
        isFilerEnabled={isFilerEnabled}
        desktopView={desktopView}
        smallView={smallView}
        withoutBorder={withoutBorder}
        sortField={sortField}
        setSortFieldHandler={setSortFieldHandler}
        darkerTable={darkerTable}
      />

      {!hidePagination && !!data?.length && (
        <Box paddingX={{ xs: 2, laptop: removePadding ? 4 : 0 }} marginTop={4}>
          <TablePagination
            currentPage={currentPage}
            setCurrentPage={changePage}
            rowsPerPageOptions={rowsPerPageOptions}
            rowsPerPage={rowsPerPageValue}
            setRowsPerPage={changeRowsPerPage}
            data={filteredData}
            cursorData={cursorData}
          />
        </Box>
      )}
    </TableContainer>
  );
};

export default inject("store")(observer(Table));
