import { api } from "libs";
import { API_NOTIFICATION_CHANNELS, API_NOTIFICATION_CHANNELS_BY_ID } from "libs/api/endpoints";
import { NotificationChannel } from "store/types";

export const getNotificationChannels = async (doNotShowErrorMessage: boolean): Promise<NotificationChannel[]> =>
  api.get(API_NOTIFICATION_CHANNELS, {
    doNotShowErrorMessage,
    errorMessage: {
      title: "Failed to load Notification Channels",
    },
  });

export const addNotificationChannel = async (data: Partial<NotificationChannel>): Promise<NotificationChannel> =>
  api.post(API_NOTIFICATION_CHANNELS, data, {
    errorMessage: {
      title: "Failed to add Notification Channel",
    },
  });

export const removeNotificationChannel = async (id: string): Promise<void> =>
  api.delete(API_NOTIFICATION_CHANNELS_BY_ID(id), {
    errorMessage: {
      title: "Failed to remove the Notification Channel",
    },
  });

export const updateNotificationChannel = async (id: string, data: Partial<NotificationChannel>): Promise<void> =>
  api.put(API_NOTIFICATION_CHANNELS_BY_ID(id), data, {
    errorMessage: {
      title: "Failed to update the Notification Channel",
    },
  });
