import {
  CursorData,
  CursorFetchHandler,
  CursorFilters,
  CursorParameters,
  FetchParameters,
} from "tools/cursorHandler/cursorHandler.types";
import { NONE_SELECT_VALUE, TableSortField } from "components/ui";
import { getParsableDataFromQuery } from "tools";
import store from "store";

export const buildSearchUrl = (url: string, params: CursorParameters | Record<string, string | boolean>) => {
  const urlParams = Object.entries(params).reduce((acc, [key, value]) => {
    if (!value || value === NONE_SELECT_VALUE) return acc;

    if (key === "parsableQuery") {
      return {
        ...acc,
        ...getParsableDataFromQuery(value as string),
      };
    }

    return {
      ...acc,
      [key]: ["startTime", "start_time"].includes(key)
        ? new Date(value as string).toISOString()
        : ["endTime", "end_time"].includes(key)
        ? new Date(value as string).toISOString()
        : value,
    };
  }, {});

  const query = new URLSearchParams();

  Object.entries(urlParams).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v: string) => query.append(key, v.toString()));
    } else {
      query.append(key, (value as string).toString());
    }
  });

  return `${url}${query.toString() ? `?${query}` : ""}`;
};

export const getDefaultCursorData = <K>(): CursorData<K> => ({
  isError: false,
  isFetching: false,
  data: [],
  cursors: [],
  countPerPage: 0,
  currentPage: 0,
  total: 0,
  isFullResponse: false,
  isDynamicFields: false,
});

export class CursorHandler<K> {
  fetcher: CursorFetchHandler<K>;
  cursorData: CursorData<K>;
  filters?: CursorFilters;
  apiSort?: boolean;
  sortBy?: (sortField: TableSortField) => Record<string, string>;

  constructor(
    cursorData: CursorData<K>,
    fetcher: CursorFetchHandler<K>,
    { apiSort, sortBy }: { apiSort?: boolean; sortBy?: (sortField: TableSortField) => Record<string, string> } = {},
  ) {
    this.cursorData = cursorData;
    this.fetcher = fetcher;
    this.apiSort = apiSort;
    this.sortBy = sortBy;
  }

  setFilters(filters: CursorFilters, force?: boolean) {
    this.filters = force
      ? { ...filters }
      : {
          ...this.filters,
          ...filters,
        };
  }

  resetData() {
    const defaultCursorData: CursorData<K> = getDefaultCursorData();

    Object.keys(defaultCursorData).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.cursorData as any)[key] = (defaultCursorData as any)[key];
    });
  }

  async restart() {
    const params: FetchParameters = {
      countPerPage: this.cursorData.countPerPage,
      page: 0,
    };

    await this.start(params);
  }

  async start(params: FetchParameters) {
    this.resetData();

    await this.fetch(params);
  }

  setSpecificCursorData({ sortField, fetch }: { sortField?: TableSortField; fetch?: boolean } = {}) {
    this.cursorData.sortField = sortField;

    return fetch && this.restart();
  }

  resetSpecificCursorData() {
    this.cursorData.sortField = undefined;
  }

  resetCursorData() {
    this.resetSpecificCursorData();
    this.resetData();
    this.filters = {};

    store?.ui.clearNoneTableSort();
  }

  async changePage(params: FetchParameters) {
    this.cursorData.data = [];
    this.cursorData.currentPage = params.page;

    await this.fetch(params);
  }

  async fetch(params: FetchParameters) {
    this.cursorData.countPerPage = params.countPerPage;
    this.cursorData.isFetching = true;

    try {
      const { data, total, cursor, isFullResponse, isDynamicFields } = await this.fetcher({
        ...this.filters,
        ...(this.apiSort && this.sortBy?.(this.cursorData.sortField)),
        cursor: this.cursorData.cursors[this.cursorData.currentPage],
        count: params.countPerPage,
      });

      this.cursorData.data = data || [];
      this.cursorData.cursors[this.cursorData.currentPage + 1] = cursor || "";
      this.cursorData.total = total || 0;
      this.cursorData.isFullResponse = isFullResponse;
      this.cursorData.isDynamicFields = isDynamicFields;
    } catch {
      this.cursorData.data = [];
      this.cursorData.isError = true;
    }

    this.cursorData.isFetching = false;
  }
}
