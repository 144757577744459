import React from "react";
import { EditOutlined, RemoveCircleOutline } from "@mui/icons-material";
import { inject, observer } from "mobx-react";
import { Box } from "@mui/material";

import { RefreshSavedWidgetsFilters, WidgetsSettings } from "store/types";
import { TableActions } from "components/ui/Table/components";
import { RootStore } from "store/root.store";
import { AddWidgetModal } from "components/modals";

type WidgetFilterActionsProps = {
  store?: RootStore;
  widget: WidgetsSettings;
  refreshSavedWidgetsFilters: RefreshSavedWidgetsFilters;
};

const WidgetFilterActions = ({ store, widget, refreshSavedWidgetsFilters }: WidgetFilterActionsProps) => {
  if (!widget.type) return null;

  const editWidgetModal = () =>
    store?.ui.openModal({
      title: "Edit widget",
      component: <AddWidgetModal refreshSavedWidgetsFilters={refreshSavedWidgetsFilters} widget={widget} />,
    });

  const removeWidgetModal = () =>
    store?.ui.openConfirmModal({
      title: "Are you sure you want to remove this filter?",
      confirmText: "Remove",
      onConfirm: () =>
        Promise.all([
          store?.widgets.addOrRemoveWidgetSettings({
            removeIds: [widget.id],
          }),
          refreshSavedWidgetsFilters({
            widgets: [widget],
            remove: true,
          }),
        ]),
    });

  const actions = [
    {
      key: "edit",
      onClick: editWidgetModal,
      getButtonProps: () => ({ icon: EditOutlined, text: "Edit" }),
    },
    {
      key: "remove",
      onClick: removeWidgetModal,
      getButtonProps: () => ({ icon: RemoveCircleOutline, text: "Remove", variant: "text" }),
    },
  ];

  return (
    <Box marginRight={-2}>
      <TableActions notInTable item={widget} actions={actions} />
    </Box>
  );
};

export default inject("store")(observer(WidgetFilterActions));
