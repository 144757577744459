import {
  addDays,
  addMonths,
  differenceInDays,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  startOfDay,
  startOfMonth,
  startOfWeek,
  isSameDay,
  differenceInHours,
  addHours,
} from "date-fns";

import store from "store";
import { DatePickerDates } from "components/ui";
import { FILTER_DATE_TYPES, FILTER_PAGES, FilterData, FilterDataDates, FilterItem, SavedFilter } from "store/types";

const getDefinedDates = () => ({
  now: new Date(),
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
});

export const formattedTime = (date?: Date, withSeconds?: boolean) =>
  format(new Date(date || Date.now()), `hh:mm${withSeconds ? ":ss" : ""} a`);

export const formattedDate = (date?: string | Date) => format(new Date(date || Date.now()), "M/d/yyyy hh:mm:ss a");

export const formattedYearDate = (date?: string | Date) => format(new Date(date || Date.now()), "M/d/yyyy");

export const isDateNewer = (date1: string | Date, date2?: string | Date) =>
  new Date(date1).getTime() > new Date(date2 || Date.now()).getTime();

export const getDaysDiff = (date1: string | Date, date2: string | Date) =>
  differenceInDays(startOfDay(new Date(date2)), startOfDay(new Date(date1)));

export const getDatesRange = (dates: DatePickerDates | string[]) => {
  if (!dates[0] || !dates[1]) return [];

  const date1 = formattedYearDate(formattedYearDate(dates[0]));
  const date2 = formattedYearDate(formattedYearDate(dates[1]));
  const daysDiff = getDaysDiff(date1, date2);

  return new Array(1 + daysDiff).fill(0).map((_, index) => formattedYearDate(addDays(new Date(date1), index)));
};

export const getDatesFromFilterValue = (filterDates?: FilterDataDates): DatePickerDates => {
  if (!filterDates) return [new Date(), new Date()];

  const { type, values } = filterDates;

  const definedDates = getDefinedDates();

  if (type === FILTER_DATE_TYPES.HOURS_BACK) {
    return [addHours(definedDates.now, -(values?.[0] || 0)), definedDates.now];
  }

  if (type === FILTER_DATE_TYPES.TODAY) return [definedDates.startOfToday, definedDates.endOfToday];

  if (type === FILTER_DATE_TYPES.YESTERDAY) return [definedDates.startOfYesterday, definedDates.endOfYesterday];

  if (type === FILTER_DATE_TYPES.THIS_WEEK) return [definedDates.startOfWeek, definedDates.endOfWeek];

  if (type === FILTER_DATE_TYPES.LAST_WEEK) return [definedDates.startOfLastWeek, definedDates.endOfLastWeek];

  if (type === FILTER_DATE_TYPES.THIS_MONTH) return [definedDates.startOfMonth, definedDates.endOfMonth];

  if (type === FILTER_DATE_TYPES.LAST_MONTH) return [definedDates.startOfLastMonth, definedDates.endOfLastMonth];

  return [
    addDays(definedDates.startOfToday, +(values?.[0] || 0)),
    addDays(definedDates.endOfToday, +(values?.[1] || 0)),
  ];
};

export const setFilterValueByDates = (dates: DatePickerDates): FilterDataDates => {
  const definedDates = getDefinedDates();

  const hours = differenceInHours(dates[1], dates[0]);

  if (isSameDay(dates[0], definedDates.startOfToday) && isSameDay(dates[1], definedDates.endOfToday) && hours === 23) {
    return { type: FILTER_DATE_TYPES.TODAY };
  }

  if (isSameDay(dates[0], definedDates.startOfYesterday) && isSameDay(dates[1], definedDates.endOfYesterday)) {
    return { type: FILTER_DATE_TYPES.YESTERDAY };
  }

  if (isSameDay(dates[0], definedDates.startOfWeek) && isSameDay(dates[1], definedDates.endOfWeek)) {
    return { type: FILTER_DATE_TYPES.THIS_WEEK };
  }

  if (isSameDay(dates[0], definedDates.startOfLastWeek) && isSameDay(dates[1], definedDates.endOfLastWeek)) {
    return { type: FILTER_DATE_TYPES.LAST_WEEK };
  }

  if (isSameDay(dates[0], definedDates.startOfMonth) && isSameDay(dates[1], definedDates.endOfMonth)) {
    return { type: FILTER_DATE_TYPES.THIS_MONTH };
  }

  if (isSameDay(dates[0], definedDates.startOfLastMonth) && isSameDay(dates[1], definedDates.endOfLastMonth)) {
    return { type: FILTER_DATE_TYPES.LAST_MONTH };
  }

  const daysDiffFirstDate = getDaysDiff(new Date(), dates[0]);
  const daysDiffSecondDate = getDaysDiff(new Date(), dates[1]);

  if (hours < 24 && [-1, 0].includes(daysDiffFirstDate) && daysDiffSecondDate === 0) {
    return {
      type: FILTER_DATE_TYPES.HOURS_BACK,
      values: [hours],
    };
  }

  return {
    type: FILTER_DATE_TYPES.DAYS_RANGE,
    values: [daysDiffFirstDate, daysDiffSecondDate],
  };
};

export const getDatesFromSavedFilter = (savedFilter: SavedFilter, type: FILTER_PAGES) => {
  const { dates } = savedFilter.filter as FilterData;

  if (dates) return dates;

  const dateItem =
    type === FILTER_PAGES.INVENTORY &&
    ((savedFilter.filter as FilterData).items?.find((i) => (i as FilterItem).field === "dates") as FilterItem);

  return dateItem
    ? {
        type: FILTER_DATE_TYPES.DAYS_RANGE,
        values: [
          getDaysDiff(new Date(), new Date(dateItem.values?.[0] as string) || Date.now()),
          getDaysDiff(new Date(), new Date(dateItem.values?.[1] as string) || Date.now()),
        ],
      }
    : store.filter.getDefaultFilter(type).dates;
};
