import { ReactElement, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";

import { RootStore } from "store/root.store";
import { UI_THEME } from "store/types";
import defaultTheme from "theme/defaultTheme";

type ThemeProviderProps = { store?: RootStore; children: ReactElement };

const ThemeProvider = ({ store, children }: ThemeProviderProps) => {
  const [themeMode, setThemeMode] = useState<UI_THEME>(store!.ui.theme);

  useEffect(() => {
    setThemeMode(store!.ui.theme);
  }, [store, store?.auth.user, store?.ui.theme]);

  return <MuiThemeProvider theme={defaultTheme(themeMode)}>{children}</MuiThemeProvider>;
};

export default inject("store")(observer(ThemeProvider));
