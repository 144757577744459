import React, { MouseEvent } from "react";
import { inject, observer } from "mobx-react";
import { Box, SvgIcon, useMediaQuery, useTheme } from "@mui/material";

import { Button, TableItemProps } from "components/ui";
import { RootStore } from "store/root.store";

export type ActionButtonsWrapperButton = {
  icon: typeof SvgIcon;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (e: MouseEvent<HTMLElement>, item: any) => void;
  text: string;
  variant?: "text" | "outlined" | "contained";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  disabled?: (item: any) => boolean;
};

type ActionButtonsWrapperProps = {
  store?: RootStore;
  buttons: ActionButtonsWrapperButton[];
  item: TableItemProps;
  inTable?: boolean;
};

const ActionButtonsWrapper = ({ store, buttons, item, inTable }: ActionButtonsWrapperProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const extraSmall = !isMobile && inTable;

  return (
    <Box
      data-testid="actionButtonsWrapper"
      display="flex"
      flexDirection={{ xs: store?.ui.infoItem ? "column" : "row", sm: "row" }}
      gap={extraSmall ? 1.5 : 2.5}
    >
      {buttons.map((button) => (
        <Button
          key={button.text}
          disabled={!!button.disabled?.(item)}
          onClick={(e) => !button.disabled?.(item) && button.onClick(e, item)}
          text={button.text}
          icon={button.icon}
          variant={button.variant}
          darkBg={inTable && button.variant === "text"}
          extraSmall={extraSmall}
          smallWidth={!isMobile}
          onlyIcon={!isMobile}
        />
      ))}
    </Box>
  );
};

export default inject("store")(observer(ActionButtonsWrapper));
