import { Box, Divider } from "@mui/material";

import {
  ChangeFilter,
  FILTER_PAGES,
  FilterConditions,
  FilterData,
  FilterDataItem,
  FilterEntity,
  FilterItem,
} from "store/types";
import { FilterElement, FilterOperator } from "components/ui/Filter/components";
import { TableFields } from "components/ui/Table/table";

type FilterBlockProps = {
  fields: TableFields[];
  filter: FilterData;
  changeFilter: ChangeFilter;
  removeFilterElement: ChangeFilter;
  filterElement: FilterDataItem;
  parentFilter: FilterData;
  filterConditions: FilterConditions;
  type: FILTER_PAGES;
  index?: number;
  parentIndex?: number;
  entities?: FilterEntity;
  onlyAnd?: boolean;
  fieldOnlyOnce?: boolean;
  maxNestedElements?: number;
};

export const FilterBlock = ({
  changeFilter,
  removeFilterElement,
  filterElement,
  parentFilter,
  index = 0,
  parentIndex = 0,
  ...props
}: FilterBlockProps) => {
  const removeFilterElementBlockElement = (value: FilterDataItem) => {
    if (!parentIndex) {
      if (!(value as FilterData).items) {
        removeFilterElement({
          ...parentFilter,
          items: [value],
        });
      } else {
        removeFilterElement(value);
      }
    } else {
      removeFilterElement({
        ...parentFilter,
        items: [...parentFilter.items.slice(0, index), value, ...parentFilter.items.slice(index + 1)],
      });
    }
  };

  const changeFilterBlockElement = (value: FilterDataItem) => {
    if (parentFilter.operator === (value as FilterData).operator || parentFilter.items.length === 1) {
      changeFilter(value);
    } else {
      changeFilter({
        ...parentFilter,
        items: [...parentFilter.items.slice(0, index), value, ...parentFilter.items.slice(index + 1)],
      });
    }
  };

  const showDivider = (items: FilterDataItem[], i: number) =>
    (items[i] as FilterData)?.items?.length > 1 && (items[i + 1] as FilterData)?.items?.length > 1;

  return (
    <Box display="flex">
      <FilterOperator filterElement={filterElement as FilterData} />

      <Box flexGrow={1}>
        {!(filterElement as FilterData).items && (
          <FilterElement
            item={filterElement as FilterItem}
            parentFilter={parentFilter as FilterData}
            changeFilter={changeFilter}
            removeFilterElement={removeFilterElement}
            index={index}
            parentIndex={parentIndex}
            {...props}
          />
        )}

        {(filterElement as FilterData).items?.map((currentFilterElement, currentIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={currentIndex}>
            <FilterBlock
              {...props}
              parentIndex={parentIndex + 1}
              index={currentIndex}
              parentFilter={filterElement as FilterData}
              filterElement={currentFilterElement as FilterData}
              changeFilter={changeFilterBlockElement}
              removeFilterElement={removeFilterElementBlockElement}
            />

            {showDivider((filterElement as FilterData).items, currentIndex) && (
              <Divider sx={{ marginTop: 1, marginLeft: 6, marginBottom: 3 }} />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
