import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Box, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import { Button, ButtonGroup, ButtonWrapper, CheckboxListItem, FormFieldsBuilder } from "components/ui";
import { RootStore } from "store/root.store";
import { CLOUD_PLATFORM, INTEGRATION, INTEGRATION_TERRAFORM_TYPE } from "store/types";
import { BucketSvg, TerraformSvg } from "components/modals/AddIntegrationModal/components/svg";
import { AWSSvg, GCPSvg } from "components/icons";
import { addIntegrationTitles } from "routes/IntegrationsRoute/components/integrationItem";
import CongratulationScreen from "components/modals/AddIntegrationModal/components/CongratulationScreen";

const buttonGroupItems = Object.values(INTEGRATION_TERRAFORM_TYPE).map((value) => ({
  value,
  render: (currentValue: string) => (
    <Box
      width={{ xs: "auto", sm: 250 }}
      sx={{
        svg: {
          marginRight: 1.5,
        },
      }}
      justifyContent="center"
      display="flex"
      alignItems="center"
    >
      {value === INTEGRATION_TERRAFORM_TYPE.CLOUD ? (
        <>
          <TerraformSvg active={currentValue === value} />
          Terraform Cloud
        </>
      ) : (
        <>
          <BucketSvg active={currentValue === value} />
          Bucket Integration
        </>
      )}
    </Box>
  ),
}));

const cloudStep2Texts = [
  "Create a Terraform Cloud User with read only permissions.",
  "Generate a User token and copy it.",
];

const awsStep2Texts = ["Provide Bucket details"];

const cloudAccesses = ["Run access - Read", "Variable access - Read", "State access - Read"];

const defaultItems = [
  {
    property: "name",
    label: "Name",
  },
];

const cloudItems = [
  {
    property: "token",
    label: "User token",
  },
  {
    property: "workspaceId",
    label: "Workspace Id",
  },
  {
    property: "address",
    label: "Address",
    placeholder: "https://app.terraform.io",
  },
];

const bucketItems = [
  {
    property: "provider",
    label: "Cloud Provider",
    exclusive: true,
    fullWidth: true,
    type: "buttonGroup",
    light: true,
    items: [
      {
        value: CLOUD_PLATFORM.AWS,
        component: <AWSSvg height={20} />,
      },
      {
        value: CLOUD_PLATFORM.GCP,
        component: <GCPSvg height={20} />,
      },
    ],
  },
  {
    property: "bucketName",
    label: "Bucket Name",
  },
  {
    property: "bucketPath",
    label: "Bucket Path",
  },
];

const defaultValidationSchema = {
  name: yup.string().required("Name is required"),
};

const cloudValidationSchema = yup.object({
  ...defaultValidationSchema,
  token: yup.string().required("Token is required"),
  workspaceId: yup.string().required("Workspace Id is required"),
});

const bucketValidationSchema = yup.object({
  ...defaultValidationSchema,
  provider: yup.string().required("Cloud Provider is required"),
  bucketName: yup.string().required("Bucket Name is required"),
  bucketPath: yup.string().required("Bucket Path Id is required"),
});

const AddTerraformIntegrationModal = ({ store }: { store?: RootStore }) => {
  const [type, setType] = useState(buttonGroupItems[0].value);
  const [step, setStep] = useState(1);

  const step2Texts = type === INTEGRATION_TERRAFORM_TYPE.CLOUD ? cloudStep2Texts : awsStep2Texts;
  const items = [...defaultItems, ...(type === INTEGRATION_TERRAFORM_TYPE.CLOUD ? cloudItems : bucketItems)];
  const validationSchema = type === INTEGRATION_TERRAFORM_TYPE.CLOUD ? cloudValidationSchema : bucketValidationSchema;

  useEffect(() => {
    store?.ui?.changeModalData({
      title: step === 3 ? "" : addIntegrationTitles[INTEGRATION.TERRAFORM],
    });
  }, [store?.ui, step]);

  const initialValues = {
    name: "",
    token: "",
    workspaceId: "",
    address: "",
    provider: CLOUD_PLATFORM.AWS,
    bucketName: "",
    bucketPath: "",
  };

  const nextStep = async () => setStep((s) => s + 1);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      await store?.integrations.addTerraformToken({ ...values, type });

      return nextStep();
    },
  });

  if (step === 3) {
    return <CongratulationScreen text="You have successfully connected your Terraform Cloud Account" />;
  }

  return (
    <Box
      component={step === 2 ? "form" : "div"}
      onSubmit={formik.handleSubmit}
      minHeight={{ xs: 200, laptop: 300 }}
      gap={5}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      {step === 1 && (
        <Typography variant="table">
          OpsHelm Terraform integration needs access to the Terraform state file. Access can be granted through
          integrating with Terraform cloud or completing the onboarding by granting access to a bucket that contains the
          state file.
        </Typography>
      )}
      {step === 2 && type === INTEGRATION_TERRAFORM_TYPE.CLOUD && (
        <Typography variant="table">
          To integrate OpsHelm with Terraform Cloud create a read only user and then generate a user token that OpsHelm
          will use to pull the state file.
        </Typography>
      )}

      {step === 1 && (
        <Box display="flex" justifyContent="center">
          <ButtonGroup
            flex
            exclusive
            separate
            items={buttonGroupItems}
            value={type}
            onChange={(val: string) => setType(val as INTEGRATION_TERRAFORM_TYPE)}
          />
        </Box>
      )}

      {step === 2 && (
        <Box display="flex" flexDirection="column" gap={5} marginBottom={5}>
          {step2Texts.map((text, index) => (
            <Typography key={text} lineHeight={1.5} variant="table" display="flex" alignItems="flex-start" gap={2}>
              <CheckboxListItem />

              <Box flexGrow={1}>
                {text}

                <Box marginTop={2}>
                  {!index &&
                    type === INTEGRATION_TERRAFORM_TYPE.CLOUD &&
                    cloudAccesses.map((access) => (
                      <Typography variant="table" lineHeight={1.5} color="#8F94C3" display="flex" gap={2} key={access}>
                        <span>&bull;</span>
                        {access}
                      </Typography>
                    ))}

                  {index === step2Texts.length - 1 && <FormFieldsBuilder formik={formik} items={items} />}
                </Box>
              </Box>
            </Typography>
          ))}
        </Box>
      )}

      <ButtonWrapper marginTop={0}>
        <>
          <Button onClick={store?.ui.closeModal} text="Cancel" variant="text" />
          <Button onClick={step !== 2 ? nextStep : undefined} type="submit" text="Next" />
        </>
      </ButtonWrapper>
    </Box>
  );
};

export default inject("store")(observer(AddTerraformIntegrationModal));
