import React, { useState, ElementType, useEffect, MouseEvent, ReactElement } from "react";
import { Box, Divider, SxProps, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FileDownloadOutlined, Search } from "@mui/icons-material";
import { inject, observer } from "mobx-react";

import { Button, ElementWrapper, Input, Loading, TableItemProps, TableRelatedData } from "components/ui";
import { TableFiltersType, TableFilterValues } from "components/ui/Table/components";
import { CursorData } from "tools/cursorHandler/cursorHandler.types";
import { Debounce } from "tools/debounce";
import { RootStore } from "store/root.store";
import { ArrowSvg } from "components/icons";

const debounce = Debounce();

type ElementWrapperWithActionsProps = {
  store?: RootStore;
  variant?: string;
  title?: ReactElement | string;
  data?: TableItemProps[];
  component: ElementType;
  search?: boolean;
  apiSearch?: boolean;
  filters?: TableFiltersType[];
  collapsible?: boolean;
  isLoading?: boolean;
  cursorData?: CursorData<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  apiSort?: boolean;
  hideActions?: boolean;
  predefinedSearchValue?: string;
  leftActions?: ReactElement;
  darkerTable?: boolean;
  csvExport?: {
    tooltip?: string;
    disabled?: boolean;
    handler: (data: {
      e: MouseEvent<HTMLElement>;
      filters?: TableFiltersType[];
      filterValues?: TableFilterValues;
      tableRelatedData?: TableRelatedData;
    }) => void;
  };
  wrapperCustomSx?: SxProps;
  componentProps?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

const ElementWrapperWithActions = ({
  store,
  title,
  variant,
  data,
  search = false,
  apiSearch = false,
  filters,
  isLoading,
  collapsible,
  cursorData,
  component: Component,
  csvExport,
  predefinedSearchValue,
  leftActions,
  hideActions,
  apiSort,
  wrapperCustomSx,
  componentProps,
  ...props
}: ElementWrapperWithActionsProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const [searchValue, setSearchValue] = useState("");
  const [filterValues] = useState<TableFilterValues>({});
  const [isCollapsibleOpened, setIsCollapsibleOpened] = useState(!collapsible);
  const [tableRelatedData, setTableRelatedData] = useState<TableRelatedData>();
  const [csvExporting, setCsvExporting] = useState(false);

  useEffect(() => {
    setSearchValue(predefinedSearchValue || "");
  }, [predefinedSearchValue]);

  useEffect(() => {
    if (!apiSearch || cursorData?.isFullResponse) return;

    cursorData?.handler?.setFilters({ query: searchValue });
    cursorData?.handler?.restart();
  }, [apiSearch, searchValue, cursorData?.handler, cursorData?.isFullResponse]);

  const onClick = () => {
    if (!collapsible) return;

    setIsCollapsibleOpened(!isCollapsibleOpened);
  };

  const downloadCsv = (e: MouseEvent<HTMLButtonElement>) => {
    if (csvExporting) return;

    debounce(async () => {
      setCsvExporting(true);

      try {
        await csvExport?.handler({ e, filters, filterValues, tableRelatedData });
      } catch {
        store?.ui.showErrorMessage();
      }

      setCsvExporting(false);
    });
  };

  return (
    <ElementWrapper onClick={onClick} customSx={wrapperCustomSx}>
      <>
        {!!title && (
          <Box>
            <Typography
              variant={variant === "table" ? "h4" : "h2"}
              lineHeight={1}
              display="flex"
              gap={4}
              sx={{
                ...(variant === "table" && {
                  paddingTop: 3,
                  paddingBottom: 2,
                }),
              }}
            >
              <Box flexGrow={1}>{title}</Box>

              {!!collapsible && (
                <Box
                  sx={{
                    svg: {
                      position: "relative",
                      right: 5,
                      transform: `rotate(${isCollapsibleOpened ? "90" : 0}deg)`,
                    },
                  }}
                >
                  <ArrowSvg />
                </Box>
              )}
            </Typography>

            {variant === "table" && <Divider />}
          </Box>
        )}

        {!hideActions && (
          <Box display="flex" gap={3} flexWrap="wrap" flexDirection={{ xs: "column", md: "row" }}>
            {!!leftActions && !!data?.length && leftActions}

            {(search || apiSearch) && (
              <Input
                iconLeft
                icon={Search}
                inTable
                placeholder="Search..."
                value={searchValue}
                onChange={setSearchValue}
                delay={apiSearch ? 500 : 0}
              />
            )}

            {!!csvExport && !!data?.length && (
              <Button
                fullWidth={isMobile}
                onClick={downloadCsv}
                icon={FileDownloadOutlined}
                variant="text"
                disabled={csvExport.disabled}
                text="Export"
                tooltip={csvExport.tooltip}
                inTable
                smallWidth
              />
            )}
          </Box>
        )}

        <Box
          sx={{
            ...(!isCollapsibleOpened && {
              display: "none",
            }),
          }}
        >
          {isLoading ? (
            <Box marginY={10}>
              <Loading />
            </Box>
          ) : (
            <Component
              filterValues={filterValues}
              filterComponents={filters}
              search={apiSearch ? "" : searchValue}
              setTableRelatedData={setTableRelatedData}
              data={data}
              cursorData={cursorData?.isFullResponse ? undefined : cursorData}
              isDynamicFields={cursorData?.isDynamicFields}
              apiSort={!cursorData?.isFullResponse && apiSort}
              {...props}
              {...componentProps}
            />
          )}
        </Box>
      </>
    </ElementWrapper>
  );
};

export default inject("store")(observer(ElementWrapperWithActions));
