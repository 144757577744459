import { TYPE_AUTH, FILTER_PAGES, InventoryPriceHistoryRequest } from "store/types";

export const API_BASE_URL = `${process.env.REACT_APP_BASE_API_URL || ""}/api/v1`;

// auth
export const API_AUTH = (url: string, type: TYPE_AUTH) =>
  `/auth/${url}/${type === TYPE_AUTH.LOGIN ? "login" : "signup"}`;
export const API_AUTH_ORG = (type: TYPE_AUTH, id?: string) =>
  `/auth/${type === TYPE_AUTH.LOGIN ? `login/org/${id}` : "signup/complete"}`;
export const API_ACCEPT_INVITE = "/auth/signup/validateInvitation";
export const API_LOGOUT = "/logout";
export const API_ORGS = "/self/orgs";
export const API_SELF = "/self";
export const API_JOIN_WAITLIST = "/auth/signup/waitlist";

// cloudAccounts
export const API_CLOUD_ACCOUNTS = "/cloudAccounts";
export const API_CLOUD_ACCOUNTS_BY_ID = (id: string) => `/cloudAccounts/${id}`;
export const API_CLOUD_ACCOUNTS_ON_BOARDING_STATUS = (id: string) => `/cloudAccounts/${id}/onboardingStatus`;
export const API_CLOUD_ACCOUNTS_ON_BOARDING = (id: string) => `/cloudAccounts/${id}/onboarding/continue`;
export const API_CLOUD_ACCOUNTS_CANCEL_ON_BOARDING = (id: string) => `/cloudAccounts/${id}/onboarding/cancel`;
export const API_ORG = "/cloudAccounts/ou";
export const API_ORG_BY_ID = (id: string) => `/cloudAccounts/ou/${id}`;
export const API_ORG_ON_BOARDING_CONTINUE = (id: string) => `/cloudAccounts/ou/${id}/continue`;
export const API_ORG_ON_BOARDING_STATUS = (id: string) => `/cloudAccounts/ou/${id}/onboardingStatus`;

// data
export const API_ROLES = "/admin/roles";
export const API_CLOUD_ACCOUNTS_CLOUD_MAP_DATA = (id: string) => `/cloudAccounts/${id}/cloudMap`;

// dashboard
export const API_DASHBOARD_STATS = "/dashboardStats";
export const API_DASHBOARD_ISSUES = "/issues";
export const API_DASHBOARD_ISSUES_BY_ID = (accountId: string, issueId: string) =>
  `/cloudAccounts/${accountId}/issues/${issueId}`;
export const API_DASHBOARD_UNDO_REMEDIATION_ISSUES_BY_ID = (accountId: string, issueId: string) =>
  `/cloudAccounts/${accountId}/issues/${issueId}/remediation/undo`;
export const API_DASHBOARD_RESOLVED_ISSUES = "/resolvedIssues";
export const API_DASHBOARD_JUSTIFIED_ISSUES = "/justifiedIssues";
export const API_DASHBOARD_ISSUE_REMEDIATIONS = (accountId: string, issueId: string) =>
  `/cloudAccounts/${accountId}/issues/${issueId}/availableRemediations`;
export const API_DASHBOARD_CLOSE_ISSUE = (accountId: string, issueId: string) =>
  `/cloudAccounts/${accountId}/issues/${issueId}/close`;
export const API_DASHBOARD_REOPEN_ISSUE = (accountId: string, issueId: string) =>
  `/cloudAccounts/${accountId}/issues/${issueId}/reopen`;
export const API_DASHBOARD_AUTOMATICALLY_FIX_ISSUE = (accountId: string, issueId: string) =>
  `/cloudAccounts/${accountId}/issues/${issueId}/fix`;
export const API_DASHBOARD_CLOSE_ISSUES = "/issues/close";
export const API_DASHBOARD_REOPEN_ISSUES = "/issues/reopen";
export const API_DASHBOARD_ISSUES_APPLY_REMEDIATION = (accountId: string) =>
  `/cloudAccounts/${accountId}/issues/remediations/apply`;
export const API_DASHBOARD_ISSUES_UNDO_REMEDIATION = (accountId: string) =>
  `/cloudAccounts/${accountId}/issues/remediations/undo`;

// rules
export const API_RULES = "/rules";
export const API_RULES_PACK_STATUS = (id: string) => `/rulePacks/${id}/configuration`;
export const API_RULES_STATUS = (id: string) => `/rules/${id}/configuration`;
export const API_RULES_PACK_ACCOUNT_STATUS = (accountId: string, rulePackId: string) =>
  `/cloudAccounts/${accountId}/rulePacks/${rulePackId}/configuration`;
export const API_RULES_ACCOUNT_STATUS = (accountId: string, ruleId: string) =>
  `/cloudAccounts/${accountId}/rules/${ruleId}/configuration`;
export const API_ISSUES_BY_RULE_ID = (accountId: string, ruleId: string) =>
  `/cloudAccounts/${accountId}/rules/${window.btoa(ruleId)}/issues`;

// notifications
export const API_NOTIFICATION_CHANNELS = "/notificationChannels";
export const API_NOTIFICATION_CHANNELS_BY_ID = (id: string) => `/notificationChannels/${id}`;

// github
export const API_GITHUB_INSTALLATIONS = "/github/installations";
export const API_GITHUB_DEPENDABOT_VULNERABILITIES_BY_ID = (id: number) => `/github/${id}/dependabot/vulnerabilities`;

// terraform
export const ADD_TERRAFORM_CLOUD_TOKEN = "/integrations/terraform/terraformCloud";
export const ADD_TERRAFORM_BUCKET_TOKEN = "/integrations/terraform/bucket/createIntegration";

// events
export const API_EVENTS_VIEW_BY_ACCOUNT_ID = (accountId: string) => `/cloudAccounts/${accountId}/events`;

// inventory
export const API_INVENTORY_BY_ACCOUNT_ID = (accountId: string) => `/cloudAccounts/${accountId}/assets`;
export const API_INVENTORY_SUMMARY_BY_ACCOUNT_ID = (accountId: string) => `/cloudAccounts/${accountId}/assetSummary`;
export const API_INVENTORY_TAGS_BY_ACCOUNT_ID = (accountId: string) => `/cloudAccounts/${accountId}/assets/tags`;
export const API_RELATED_RESOURCES_BY_ACCOUNT_ID = (accountId: string, assetId: string) =>
  `/cloudAccounts/${accountId}/assets/${assetId}/associations`;
export const API_INVENTORY_SEARCH_DATA_BY_ACCOUNT_ID = (accountId: string) => `/cloudAccounts/${accountId}/assets/data`;
export const API_ASSET_HISTORY = (accountId: string, assetId: string) =>
  `/cloudAccounts/${accountId}/assets/${assetId}/history`;
export const API_ASSET_HISTORY_VERSION = (accountId: string, assetId: string, version: string) =>
  `/cloudAccounts/${accountId}/assets/${assetId}/history/${version}`;
export const API_ASSET_PRICE_HISTORY = ({ accountId, assetId, type }: InventoryPriceHistoryRequest) =>
  `/cloudAccounts/${accountId}/assets/${assetId}/priceHistory?type${type}`;

// resources
export const API_ISSUES_BY_RESOURCE_ID = (accountId: string, resourceId: string) =>
  `/cloudAccounts/${accountId}/resources/${window.btoa(resourceId)}/issues`;
export const API_INVENTORY_ITEM_BY_RESOURCE_ID = (accountId: string, resourceId: string) =>
  `/cloudAccounts/${accountId}/resources/${window.btoa(resourceId)}/asset`;

// filters
export const API_FILTERS = "/filters";
export const API_FILTERS_BY_ID = (id: string) => `/filters/${id}`;
export const API_FILTERS_BY_TYPE = (type: FILTER_PAGES) => `/filters/${type}`;

// aws
export const AWS_INTEGRATION = "/integrations/cur";
export const AWS_INTEGRATION_BY_ACCOUNT_ID = (accountId: string) => `/integrations/cur/${accountId}`;

// admin
// admin users
export const API_USERS = "/admin/users";
export const API_USERS_BY_ID = (id: string) => `/admin/users/${id}`;

// admin invitations
export const API_USERS_INVITATIONS = "/admin/invitations";
export const API_USERS_INVITATIONS_BY_ID = (id: string) => `/admin/invitations/${id}`;

// admin api-key
export const API_KEY = "/admin/keys";
export const API_KEY_BY_ID = (id: string) => `/admin/keys/${id}`;
export const API_KEY_ROTATE = (id: string) => `/admin/keys/${id}/rotate`;
