import { api } from "libs";
import {
  API_DASHBOARD_CLOSE_ISSUE,
  API_DASHBOARD_ISSUES,
  API_DASHBOARD_RESOLVED_ISSUES,
  API_DASHBOARD_JUSTIFIED_ISSUES,
  API_DASHBOARD_STATS,
  API_DASHBOARD_ISSUES_BY_ID,
  API_DASHBOARD_UNDO_REMEDIATION_ISSUES_BY_ID,
  API_DASHBOARD_REOPEN_ISSUE,
  API_DASHBOARD_AUTOMATICALLY_FIX_ISSUE,
  API_DASHBOARD_ISSUE_REMEDIATIONS,
  API_DASHBOARD_CLOSE_ISSUES,
  API_DASHBOARD_REOPEN_ISSUES,
  API_DASHBOARD_ISSUES_UNDO_REMEDIATION,
  API_DASHBOARD_ISSUES_APPLY_REMEDIATION,
  API_ISSUES_BY_RESOURCE_ID,
  API_ISSUES_BY_RULE_ID,
} from "libs/api/endpoints";
import { DashboardIssue, DashboardStats, IssueListResponse, IssueRemediation, UndoRemediation } from "store/types";
import { CursorFetchData, CursorParameters } from "tools/cursorHandler/cursorHandler.types";
import { buildSearchUrl } from "tools/cursorHandler/cursorHandler";

export const getDashboardStats = (): Promise<DashboardStats> =>
  api.get(API_DASHBOARD_STATS, {
    cache: {
      maxAge: 0,
    },
    doNotShowErrorMessage: true,
  });

export const getDashboardIssueById = async (accountId: string, id: string): Promise<DashboardIssue> =>
  api.get(API_DASHBOARD_ISSUES_BY_ID(accountId, id), {
    errorMessage: {
      title: "Failed to load Issue",
    },
  });

export const getDashboardIssues = async (params: CursorParameters): Promise<CursorFetchData<DashboardIssue>> =>
  api.get(buildSearchUrl(API_DASHBOARD_ISSUES, params), {
    cache: {
      maxAge: 0,
    },
    errorMessage: {
      title: "Failed to load Issues",
    },
  });

export const getDashboardResolvedIssues = async (params: CursorParameters): Promise<CursorFetchData<DashboardIssue>> =>
  api.get(buildSearchUrl(API_DASHBOARD_RESOLVED_ISSUES, params), {
    cache: {
      maxAge: 0,
    },
    errorMessage: {
      title: "Failed to load Resolved Issues",
    },
  });

export const getDashboardJustifiedIssues = async (params: CursorParameters): Promise<CursorFetchData<DashboardIssue>> =>
  api.get(buildSearchUrl(API_DASHBOARD_JUSTIFIED_ISSUES, params), {
    cache: {
      maxAge: 0,
    },
    errorMessage: {
      title: "Failed to load Justified Issues",
    },
  });

export const getDashboardIssuesByResourceId = async (params: CursorParameters): Promise<IssueListResponse> =>
  api.get(
    buildSearchUrl(API_ISSUES_BY_RESOURCE_ID(params.accountId as string, params.resourceId as string), {
      ...params,
      resourceId: "",
      accountId: "",
    }),
    {
      errorMessage: {
        title: "Failed to load Issues",
      },
    },
  );

export const getDashboardIssuesByRuleId = async (params: CursorParameters): Promise<IssueListResponse> =>
  api.get(
    buildSearchUrl(API_ISSUES_BY_RULE_ID(params.accountId as string, params.ruleId as string), {
      ...params,
      ruleId: "",
      accountId: "",
    }),
    {
      errorMessage: {
        title: "Failed to load Issues",
      },
    },
  );

export const getIssueRemediations = async (issue: DashboardIssue): Promise<IssueRemediation[]> => {
  const res = await api.get(API_DASHBOARD_ISSUE_REMEDIATIONS(issue.cloud_account_id, issue.id), {
    errorMessage: {
      title: "Failed to get Remediations",
    },
  });

  return res.remediations;
};

export const closeIssue = async (issue: DashboardIssue, reason: string): Promise<void> =>
  api.post(
    API_DASHBOARD_CLOSE_ISSUE(issue.cloud_account_id, issue.id),
    { reason },
    {
      errorMessage: {
        title: "Failed to close Issue",
      },
    },
  );

export const automaticallyFixIssue = async (issue: DashboardIssue, remediationId: string): Promise<void> =>
  api.post(
    API_DASHBOARD_AUTOMATICALLY_FIX_ISSUE(issue.cloud_account_id, issue.id),
    { remediationId },
    {
      errorMessage: {
        title: "Failed to fix Issue",
      },
    },
  );

export const reopenIssue = async (issue: DashboardIssue): Promise<void> =>
  api.post(API_DASHBOARD_REOPEN_ISSUE(issue.cloud_account_id, issue.id), null, {
    errorMessage: {
      title: "Failed to reopen Issue",
    },
  });

export const undoRemediation = (issue: DashboardIssue, data: UndoRemediation): Promise<void> =>
  api.post(API_DASHBOARD_UNDO_REMEDIATION_ISSUES_BY_ID(issue.cloud_account_id, issue.id), data, {
    errorMessage: {
      title: "Failed to undo Remediation",
    },
  });

export const closeIssuesByIds = async (issues: DashboardIssue[], reason: string): Promise<void> =>
  api.post(
    API_DASHBOARD_CLOSE_ISSUES,
    {
      issueIds: issues.map((i) => i.id),
      reason,
    },
    {
      errorMessage: {
        title: "Failed to close Issues",
      },
    },
  );

export const reopenIssueByIds = async (issues: DashboardIssue[]): Promise<void> =>
  api.post(
    API_DASHBOARD_REOPEN_ISSUES,
    {
      issueIds: issues.map((i) => i.id),
    },
    {
      errorMessage: {
        title: "Failed to reopen Issues",
      },
    },
  );

export const undoRemediationByIds = (issues: DashboardIssue[], data: UndoRemediation): Promise<void> =>
  api.post(
    API_DASHBOARD_ISSUES_UNDO_REMEDIATION(issues[0].cloud_account_id),
    {
      issueIds: issues.filter((i) => i.cloud_account_id === issues[0].cloud_account_id).map((i) => i.id),
      ...data,
    },
    {
      errorMessage: {
        title: "Failed to undo Remediations",
      },
    },
  );

export const automaticallyFixIssueByIds = (issues: DashboardIssue[], remediationId: string): Promise<void> =>
  api.post(
    API_DASHBOARD_ISSUES_APPLY_REMEDIATION(issues[0].cloud_account_id),
    {
      remediations: issues
        .filter((i) => i.cloud_account_id === issues[0].cloud_account_id)
        .map((i) => ({
          issueId: i.id,
          remediationId,
        })),
    },
    {
      errorMessage: {
        title: "Failed to fix Issues",
      },
    },
  );
