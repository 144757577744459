import React, { ChangeEvent, MouseEvent } from "react";
import { inject, observer } from "mobx-react";
import { Box, Switch, Typography } from "@mui/material";

import { PERMISSION_TYPES, Rule, UI_TABLES } from "store/types";
import { ProviderIcon, Table, TableItemList, Tooltip } from "components/ui";
import { RootStore } from "store/root.store";
import RequirePerm from "components/guards/RequirePerm/requirePerm";
import RulePackTableAdditionalInfo from "routes/RulesRoute/components/rulesPackTableAdditionalInfo";
import rootStore from "store";

type RulesPackTableProps = {
  store?: RootStore;
  data: Rule[];
  search?: string;
};

type RuleSwitchProps = {
  store?: RootStore;
  rule: Rule;
};

const RuleSwitch = ({ store, rule }: RuleSwitchProps) => (
  <RequirePerm
    access={{
      permission: PERMISSION_TYPES.ADMIN,
      fallback: (
        <Tooltip id={rule.id} title="You don't have permission to edit this." byClick>
          <Switch disabled checked={rule.enabled} />
        </Tooltip>
      ),
    }}
    element={
      <Switch
        checked={rule.enabled}
        onClick={(e: MouseEvent<HTMLElement>) => e.stopPropagation()}
        onChange={(event: ChangeEvent<HTMLInputElement>) => store?.rules.changeRuleStatus(rule, event.target.checked)}
      />
    }
  />
);

export const rulePackFields = [
  {
    name: "Rule",
    property: "name",
    render: (rule: Rule) => <ProviderIcon name={rule.name} provider={rule.provider} />,
    textValue: (rule: Rule) => `${rule.provider} ${rule.name}`,
    mobileTopSection: true,
    toLeft: true,
  },
  {
    name: "Description",
    property: "description",
    additionalInfo: "row",
    dontBreakInAdditionalTable: true,
  },
  {
    name: "Covered environments",
    property: "environments",
    textValue: (rule: Rule) => rule.environments.filter((r) => r).join(" "),
    render: (rule: Rule) => <TableItemList items={rule.environments} />,
  },
  /*
  {
    name: "Date Added",
    property: "created_at",
    render: (rule: Rule) => formattedDate(rule.created_at),
  },
  */
  {
    name: "Enabled",
    property: "enabled",
    textValue: (rule: Rule) => (rule.enabled ? "enabled" : "disabled"),
    render: (rule: Rule) => <RuleSwitch rule={rule} store={rootStore} />,
    mobileTopSection: true,
    dontShowOnEachFieldAction: true,
  },
];

const RulesPackTable = ({ store, data, search }: RulesPackTableProps) => {
  const mobileTopSection = ({ item: rule }: { item: Rule }) => (
    <Box display="flex" alignItems="center">
      <ProviderIcon provider={rule.provider} />
      <Box marginX={2}>
        <Typography variant="title">{rule.name}</Typography>
      </Box>
      <Box marginLeft="auto">
        <RuleSwitch rule={rule} store={store} />
      </Box>
    </Box>
  );

  return (
    <Table
      type={UI_TABLES.RULES_PACK}
      noDataText="No rules found for the rule pack"
      fields={rulePackFields}
      loadMoreCount={4}
      mobileTopSection={mobileTopSection}
      search={search}
      data={data}
      removePadding
      hidePagination
      additionalInfo={<RulePackTableAdditionalInfo fields={rulePackFields} />}
    />
  );
};

export default inject("store")(observer(RulesPackTable));
