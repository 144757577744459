import React, { MouseEvent, useEffect, useState } from "react";
import { Box, Menu, MenuItem } from "@mui/material";
import { FilterList } from "@mui/icons-material";

import { Select, SelectList, Button, TableFilterValue, Label, NONE_SELECT_VALUE } from "components/ui";

export enum FILTER_TYPES {
  LAST_TIME = "lastTime",
  STATE = "state",
}

export type TableFilterValues = Record<string, string>;

export type TableFiltersType = {
  type: FILTER_TYPES;
  componentType: "select";
  property: string;
  list: SelectList;
  name?: string;
  defaultValue?: string;
};

type TableFiltersProps = {
  filters: TableFiltersType[];
  onChange: (item: TableFilterValues) => void;
};

export const TableFilters = ({ onChange, filters }: TableFiltersProps) => {
  const initialFilters = filters.reduce(
    (acc, item) => ({ ...acc, [item.type]: item.defaultValue || NONE_SELECT_VALUE }),
    {},
  );

  const [filterValues, setFilterValues] = useState<TableFilterValue>(initialFilters);
  const [filtersAnchorEl, setFiltersAnchorEl] = useState<null | HTMLElement>(null);

  const openFilters = (event: MouseEvent<HTMLElement>) => setFiltersAnchorEl(event.currentTarget);

  const closeFilters = () => setFiltersAnchorEl(null);

  const handleChange = (type: string, value: string) => {
    setFilterValues((oldFilters) => ({
      ...oldFilters,
      [type]: value,
    }));

    closeFilters();
  };

  useEffect(() => onChange(filterValues), [onChange, filterValues]);

  const clearAllFilters = () => {
    const clearedFilters = filters.reduce((acc, item) => ({ ...acc, [item.type]: NONE_SELECT_VALUE }), {});

    setFilterValues(clearedFilters);
    onChange(clearedFilters);

    closeFilters();
  };

  if (!filters?.length) return null;

  return (
    <Box data-testid="tableFilters">
      <Box display="flex" position="relative" onClick={openFilters}>
        <FilterList />
      </Box>
      <Menu anchorEl={filtersAnchorEl} open={!!filtersAnchorEl} onClose={closeFilters}>
        {filters.map((filter) => (
          <MenuItem
            key={filter.type}
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              "&.Mui-focusVisible": { background: "none" },
              "&:focus-visible": { background: "none" },
              ...(["select"].includes(filter.componentType)
                ? { "&:hover": { background: "none", cursor: "default" } }
                : {}),
            }}
          >
            {!!filter.name && <Label text={filter.name} marginBottom={0.5} />}
            {filter.componentType === "select" && (
              <Select
                fullWidth
                value={filterValues[filter.type]}
                list={[{ id: NONE_SELECT_VALUE, name: "None" }, ...filter.list]}
                onChange={(val) => handleChange(filter.type, val)}
              />
            )}
          </MenuItem>
        ))}
        <MenuItem
          sx={{
            "&.Mui-focusVisible": { background: "none" },
            "&:focus-visible": { background: "none" },
            "&:hover": { background: "none", cursor: "default" },
          }}
        >
          <Button onClick={clearAllFilters} variant="text" text="Clear all filters" />
        </MenuItem>
      </Menu>
    </Box>
  );
};
