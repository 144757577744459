export enum CLOUD_PLATFORM {
  AWS = "AWS",
  GCP = "GCP",
  Azure = "Azure",
}

export enum ONBOARDING_STATUSES {
  COMPLETED = "completed",
  RUNNING = "running",
  AWAITING_AUTH = "awaiting_auth",
  FAILED = "failed",
  UNKNOWN = "unknown",
}

export type AccountCloudMapItem = {
  id: string;
  name: string;
  type: string;
  data?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export type OnBoardingDataCommon = {
  status: ONBOARDING_STATUSES;
  description: string;
  step_id: string;
};

export type OnBoardingDataAWS = {
  customer_id: string;
  cloud_account_id: string;
  role_name: string;
  opshelm_role_arn: string;
  aws_external_id: string;
  awaiting_customer_action: boolean;
  service_account_added: boolean;
} & OnBoardingDataCommon;

export type OnBoardingDataGCP = {
  service_account_name?: string;
  service_account_added: boolean;
} & OnBoardingDataCommon;

export type OnBoardingData = OnBoardingDataAWS | OnBoardingDataGCP;

export type OrgOnBoardingData = {
  description: string;
  status: ONBOARDING_STATUSES;
};

export type AddCloudRequest = {
  name: string;
  provider: string;
  account_id: string;
};

export enum ORGANIZATION_STATUSES {
  PENDING = "pending",
  ACTIVE = "active",
  FAILED = "failed",
  CANCELLED = "cancelled",
  FAULT = "fault",
  UNKNOWN = "unknown",
}

export enum ACCOUNT_STATUSES {
  SUCCESS = "success",
  ERROR = "error",
  PENDING = "pending",
  ACTIVE = "active",
  FAILED = "failed",
  TIMED_OUT = "timed_out",
  CANCELLED = "cancelled",
  FAULT = "fault",
  UNKNOWN = "unknown",
}

export type Account = {
  id: string;
  name: string;
  provider: CLOUD_PLATFORM;
  provider_account_id: string;
  organizational_unit_id: string;
  created_at: string;
  status: ACCOUNT_STATUSES;
};

export type UpdateAccountRequest = {
  aws_role_name: string;
};

export type AddOrgRequest = {
  name: string;
  provider: string;
  parent_account_id: string;
  aws_admin_account_id?: string;
  gcp_service_account?: string;
};

export type AddOrgResponse = {
  id: string;
  organization_id: string;
  parent_account_id: string;
  provider: string;
  name: string;
  aws_role_arn?: string;
  aws_external_id?: string;
  gcp_service_account?: string;
  status: ORGANIZATION_STATUSES;
  service_account_added: boolean;
};
