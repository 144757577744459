import React, { ChangeEvent } from "react";
import { Box, Switch } from "@mui/material";

import { WIDGET_MANAGER_VIEWS, RefreshSavedWidgetsFilters, Widget } from "store/types";
import { WidgetFilterActions } from "components/ui";

export const DashboardViewItemCustomTitle = ({
  editMode,
  refreshSavedWidgetsFilters,
  widget,
  changeWidgetStatus,
}: {
  widget: Widget;
  refreshSavedWidgetsFilters: RefreshSavedWidgetsFilters;
  editMode?: WIDGET_MANAGER_VIEWS;
  changeWidgetStatus?: (id: string, disabled: boolean) => void;
}) => (
  <Box display="flex" alignItems="center" justifyContent="flex-end" width={1} gap={1}>
    {editMode && (
      <Switch
        checked={!widget.disabled}
        onChange={(event: ChangeEvent<HTMLInputElement>) => changeWidgetStatus?.(widget.id, !event.target.checked)}
      />
    )}

    <WidgetFilterActions refreshSavedWidgetsFilters={refreshSavedWidgetsFilters} widget={widget} />
  </Box>
);
