import React, { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Box } from "@mui/material";

import { RootStore } from "store/root.store";
import { Loading } from "components/ui";

type RequireAuthProps = {
  store?: RootStore;
  children: ReactElement;
  redirectTo: string;
  unauthed?: boolean;
};

/**
 * RequireAuth checks if a user is signed. If not, the user
 * is redirected to the path specified by redirectTo.
 *
 * If unauthed is set to true, this behavior is reversed.
 */
const RequireAuth = ({ store, children, redirectTo, unauthed = false }: RequireAuthProps) => {
  const { user, isLoading } = store!.auth;

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" bgcolor="background.default" minHeight="100vh">
        <Loading marginTop={35} />
      </Box>
    );
  }

  if (user && !unauthed && store?.ui?.urlAfterSignIn) {
    window.location.href = store?.ui?.urlAfterSignIn;

    store?.ui.setUrlAfterSignIn();

    return null;
  }

  if (!!user === unauthed) {
    return <Navigate to={redirectTo} />;
  }

  return children;
};

export default inject("store")(observer(RequireAuth));
