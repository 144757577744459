import React, { MouseEvent, ReactElement, useRef, useState } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { inject, observer } from "mobx-react";

import {
  getHighlight,
  getHighlightColor,
  getHighlightMainColor,
  getJsonSearchProperty,
  getKey,
  isRowSelected,
} from "components/ui/JsonViewer/jsonViewer.utils";
import { BASE_SEPARATOR, CUT_VALUE_LENGTH, GET_JSON_VIEW_ACTIONS_COUNT } from "components/ui/JsonViewer/consts";
import { SMALL_HEIGHT } from "consts";
import { JSON_VIEWER_VARIANTS } from "components/ui/JsonViewer/jsonViewer";
import { copyToClipboard } from "tools";

import { FieldValueProps } from "../jsonViewFieldValue";
import { JsonViewSearchActions } from "./jsonViewSearchActions";
import { JsonBorderWrapper } from "./jsonBorderWrapper";

const JsonViewPrimitiveValue = ({
  store,
  currentKey,
  keyValue,
  indexKeyValue,
  value,
  offset,
  isNotLastIndex,
  offsetIndex,
  copy,
  highlights,
  unsearchableProperties,
  jsonSearch,
  setJsonSearch,
  numbers,
  expanded,
  collapsible,
  setCollapsible,
  selectedItem,
  setSelectedItem,
  rows,
}: FieldValueProps): ReactElement => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const [leftOffset, setLeftOffset] = useState(0);

  const rowRef = useRef<HTMLInputElement>(null);
  const property = getKey(currentKey, indexKeyValue);
  const searchItem = { value: value as string | number | boolean, property };
  const isCurrentRowSelected = isRowSelected({ item: searchItem, jsonSearch });
  const fontWeight = isCurrentRowSelected ? 700 : 400;
  const highlight = getHighlight(property, highlights);
  const highlightColor = getHighlightColor(highlights);
  const row = numbers[[property, value].join(BASE_SEPARATOR)];

  const click = (e: MouseEvent<HTMLElement>) => {
    if (unsearchableProperties?.includes(getJsonSearchProperty(searchItem.property))) return;

    const actionWidth = GET_JSON_VIEW_ACTIONS_COUNT({ copy }) * SMALL_HEIGHT;
    const padding = parseInt(theme.spacing(2), 10);
    const max = (rowRef?.current?.clientWidth || 0) - actionWidth - padding;

    setLeftOffset(
      Math.min(
        Math.max(e.pageX - (rowRef?.current?.getBoundingClientRect().left || 0) - actionWidth / 2, padding),
        max,
      ),
    );

    setSelectedItem(searchItem.property);
  };

  const copyHandler = () => !row?.collapsible && copyToClipboard({ value: value?.toString() });

  return (
    <Box
      ref={rowRef}
      onClick={setJsonSearch ? click : copy ? copyHandler : undefined}
      whiteSpace="nowrap"
      fontWeight={fontWeight}
      position="relative"
      paddingRight={2.5}
      sx={{
        ...(!highlight && {
          "&:hover": {
            backgroundColor: "background.infoItemTableRow",
          },
        }),
        ...(selectedItem === searchItem.property ? { backgroundColor: "background.infoItemTableRow" } : {}),
        ...(setJsonSearch && !unsearchableProperties?.includes(getJsonSearchProperty(searchItem.property))
          ? {
              "&:hover": {
                cursor: "pointer",
              },
            }
          : {}),
        ...(highlight && { backgroundColor: highlightColor }),
      }}
    >
      <JsonBorderWrapper
        numbers={numbers}
        property={[property, value].join(BASE_SEPARATOR)}
        offset={offset}
        count={offsetIndex}
        highlights={highlights}
        expanded={expanded}
        collapsible={collapsible}
        setCollapsible={setCollapsible}
      >
        <Box display="flex" alignItems="flex-start">
          <Box display="inline-block">
            {keyValue ? (
              <>
                <Typography variant="main" fontWeight={fontWeight} display="inline" color="primary.main">
                  &quot;{keyValue}&quot;
                </Typography>
                :{" "}
              </>
            ) : (
              ""
            )}
          </Box>
          <Box
            component="span"
            display="inline-block"
            sx={{
              maxWidth: `calc(100vw - 180px - ${
                ((keyValue?.length || 0) + (rows?.length.toString().length || 0)) * 7
              }px - ${theme.spacing(offset * offsetIndex)} - ${store?.ui.getMenuWidth(isMobile)}px)`,
              overflowWrap: "break-word",
              textWrap: "balance",
              ...(highlight &&
                !highlight.full && {
                  backgroundColor: getHighlightMainColor(highlights),
                  color: "#fff",
                }),
            }}
          >
            &nbsp;
            {["string", "number"].includes(typeof value) && collapsible.includes(property)
              ? `"${value.toString().slice(0, CUT_VALUE_LENGTH)}${
                  value.toString().length > CUT_VALUE_LENGTH ? "..." : ""
                }"`
              : typeof value === "string"
              ? `"${value.toString()}"`
              : value.toString()}
            {isNotLastIndex ? ", " : ""}
          </Box>
        </Box>
      </JsonBorderWrapper>

      {!!setJsonSearch &&
        !unsearchableProperties?.includes(getJsonSearchProperty(searchItem.property)) &&
        selectedItem === searchItem.property && (
          <JsonViewSearchActions
            copy={copy}
            type={JSON_VIEWER_VARIANTS.JSON}
            leftOffset={leftOffset}
            setSelectedItem={setSelectedItem}
            jsonSearch={jsonSearch}
            item={searchItem}
            setJsonSearch={setJsonSearch}
            row={row}
          />
        )}
    </Box>
  );
};

export default inject("store")(observer(JsonViewPrimitiveValue));
