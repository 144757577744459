import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Box, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import {
  Button,
  ButtonWrapper,
  CheckboxListItem,
  FormFieldsBuilder,
  Loading,
  StepsProgress,
  UnderlineLink,
} from "components/ui";
import { RootStore } from "store/root.store";
import { AddGcpCostIntegration, GcpCostIntegration, CLOUD_PLATFORM } from "store/types";
import CongratulationScreen from "components/modals/AddIntegrationModal/components/CongratulationScreen";
import { GCPSvg } from "components/icons";

const firstStepValidationSchema = yup.object({
  provider: yup.string().required("Provider is required"),
  cloud_account_id: yup.string().required("Account ID is required"),
});

const secondStepValidationSchema = yup.object({
  dataset_name: yup.string().required("Dataset Name is required"),
  billing_project_id: yup.string().required("Billing Project Id is required"),
});

const secondStepItems = [
  {
    property: "dataset_name",
    label: "Dataset Name",
  },
  {
    property: "billing_project_id",
    label: "Billing Project Id",
  },
];

const step2Texts = ["Create an GCP CUR export", "Provide dataset and project info"];

const stepsItems = [
  {
    title: "Getting Started",
  },
  {
    title: "Creating GCP Cost Report",
    subItems: step2Texts,
  },
  {
    title: "Modifying OpsHelm role to allow access to GCP CUR report",
  },
];

const instructionsLinkText =
  "Follow the following instructions for detailed steps and configuration to enable GCP CUR report";

const AddGCPCostIntegrationModal = ({
  store,
  gcpIntegration,
}: {
  store?: RootStore;
  gcpIntegration?: GcpCostIntegration;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(gcpIntegration ? 3 : 1);

  const { activeAccounts } = store!.accounts;
  const { notAddedGcpCostAccounts } = store!.costReport;

  const initialValues: AddGcpCostIntegration = {
    provider: gcpIntegration?.provider || CLOUD_PLATFORM.GCP,
    cloud_account_id: gcpIntegration?.cloud_account_id || notAddedGcpCostAccounts[0]?.id,
    dataset_name: gcpIntegration?.dataset_name || "",
    billing_project_id: gcpIntegration?.billing_project_id || "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: step === 1 ? firstStepValidationSchema : secondStepValidationSchema,
    onSubmit: async (values) => {
      if (gcpIntegration) {
        await store?.costReport.updateIntegration(CLOUD_PLATFORM.GCP, { ...gcpIntegration, ...values });

        store?.ui.closeModal();

        return;
      }

      if (step === 2) {
        setIsLoading(true);

        try {
          await store?.costReport.addCostIntegration(CLOUD_PLATFORM.GCP, values);

          setStep((curr) => curr + 1);
        } catch {
          setTimeout(() => setIsLoading(false), 500);
        }
      }
    },
  });

  const firstStepItems = [
    {
      property: "provider",
      label: "Cloud Provider",
      exclusive: true,
      fullWidth: true,
      type: "buttonGroup",
      light: true,
      items: [
        {
          value: CLOUD_PLATFORM.GCP,
          component: <GCPSvg height={20} />,
        },
      ],
    },
    {
      property: "cloud_account_id",
      label: "GCP Account Id",
      type: "select",
      fullWidth: true,
      disabled: !!gcpIntegration,
      list: gcpIntegration
        ? activeAccounts.filter((a) => a.provider?.toUpperCase() === CLOUD_PLATFORM.GCP)
        : notAddedGcpCostAccounts,
    },
  ];

  if (step === 3) {
    return <CongratulationScreen text="You have successfully connected your GCP Cost Report" />;
  }

  return (
    <Box
      onSubmit={formik.handleSubmit}
      component="form"
      gap={5}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      flexGrow={1}
    >
      <Typography variant="table">Follow simple steps to connect Your GCP Cost Report</Typography>

      <Box display="flex" flexGrow={1}>
        <StepsProgress items={stepsItems} currentStep={step} stepId="stepId" steps={[]} />

        <Box width={1} display="flex" gap={5} flexDirection="column">
          {step === 1 && <FormFieldsBuilder formik={formik} items={firstStepItems} />}

          {step === 2 &&
            (isLoading ? (
              <Loading marginTop={10} />
            ) : (
              step2Texts.map((text, index) => (
                <Typography key={text} lineHeight={1.5} variant="table" display="flex" alignItems="flex-start" gap={2}>
                  <CheckboxListItem />

                  <Box flexGrow={1}>
                    {text}

                    <Box marginTop={2}>
                      {!index ? (
                        <Typography variant="table" lineHeight={1.5} color="#8F94C3" display="flex" gap={2}>
                          <span>&bull;</span>
                          <UnderlineLink
                            to="https://www.opshelm.com/docs/guides/features/cost-enrichment/gcp"
                            separateSite
                            text={instructionsLinkText}
                          />
                        </Typography>
                      ) : (
                        <FormFieldsBuilder formik={formik} items={secondStepItems} />
                      )}
                    </Box>
                  </Box>
                </Typography>
              ))
            ))}

          <ButtonWrapper marginTop="auto">
            <>
              <Button onClick={store?.ui.closeModal} text="Cancel" variant="text" />
              {step === 2 && <Button type="submit" text="Continue" />}
              {step !== 2 && <Button onClick={() => setStep((c) => c + 1)} text="Continue" />}
            </>
          </ButtonWrapper>
        </Box>
      </Box>
    </Box>
  );
};

export default inject("store")(observer(AddGCPCostIntegrationModal));
