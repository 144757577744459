import { JsonViewerType } from "components/ui";

export enum COMPLIANCE_ITEM {
  HITRUST = "hitrust",
  AWS = "aws",
  NIST = "nist",
}

export enum COMPLIANCE_STATUS {
  PASSED = "passed",
  NEEDS_REVIEW = "needsReview",
  INFO = "info",
}

export type ComplianceAnalysedItemStats = {
  id: string;
  title: string;
  passed?: number;
  needsReview?: number;
  info?: number;
};

export type ComplianceAnalysedItemType = {
  id: string;
  title: string;
  analysedItems: ComplianceAnalysedItemStats[];
};

export type ComplianceItem = {
  id: string;
  type: COMPLIANCE_ITEM;
  name: string;
  total: number;
  controlled: number;
  account_id: string;
  description: string;
  items: ComplianceAnalysedItemType[];
};

export type ComplianceSubItem = {
  type: string;
  resource_id: string;
  created_at: string;
  status: COMPLIANCE_STATUS;
  details?: JsonViewerType;
};
