import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Button, ButtonWrapper, Input, Loading, Tooltip } from "components/ui";
import { RootStore } from "store/root.store";
import { DASHBOARD_BY_ID_ROUTE } from "consts";

const AddDashboardModal = ({ store, duplicate }: { store?: RootStore; duplicate?: boolean }) => {
  const { dashboards } = store!.multipleDashboards;

  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const getAddButtonError = () => {
    if (!name) {
      return "Name is required";
    }

    if (dashboards.some((d) => d.name === name)) {
      return "Dashboard with this name already exist";
    }

    return "";
  };

  const create = async () => {
    setIsLoading(true);

    try {
      const dashboardId = await store?.multipleDashboards.createDashboard(name, duplicate);

      navigate(DASHBOARD_BY_ID_ROUTE.replace(":dashboardId", dashboardId as string));

      store?.ui.closeModal();
    } catch {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loading marginTop={16.5} marginBottom={16.5} />;
  }

  return (
    <Box component="form" onSubmit={create}>
      <Typography component="div" variant="table" marginBottom={4}>
        Before proceeding, please create a name for the new dashboard. After creation, you can change it by
        double-clicking on the page title.
      </Typography>

      <Input label="Name" placeholder="Dashboard" value={name} onChange={setName} />

      <ButtonWrapper>
        <>
          <Button onClick={store?.ui.closeModal} text="Cancel" variant="text" buttonSx={{ marginRight: "auto" }} />
          <Tooltip title={getAddButtonError()}>
            <Button disabled={!!getAddButtonError()} type="submit" text="Next" />
          </Tooltip>
        </>
      </ButtonWrapper>
    </Box>
  );
};

export default inject("store")(observer(AddDashboardModal));
